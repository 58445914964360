import { Autocomplete, Box, FormControl, FormHelperText, TextField } from "@mui/material";
import "./OpcionesAsignacion.css";
import usePatentesUsuarioAsignaciones from "../../../Hooks/Patentes/usePatentesUsuarioAsignacion";
import AutocompleteSimple from "../../AutocompleteSimple/AutocompleteSimple";


export const PatentesUsuarioSeleccionado = ({formik}) => {
    const {patentesActivas} = usePatentesUsuarioAsignaciones(formik.values.idPersona);

    return(
        <FormControl fullWidth>
            <AutocompleteSimple
                disablePortal={true}
                freeSolo = {true}
                listadoElementos={patentesActivas}
                label="Patentes"
                onSelectionChange={(newValue) => {
                    if(newValue === null)
                    {
                        formik.setFieldValue("idPatente", -1)                                           
                    }
                    else{
                        formik.setFieldValue("idPatente", newValue.idPatente)
                    }
                }}
                keyListadoElementos="idPatente"
                mostrarElemento={(option) => option["patente"] || ''}
                elementoSeleccionado={patentesActivas.find(patente => formik.values.idPatente === patente.idPatente) || null}
            />
            <FormHelperText>
                {
                    formik.errors.idPatente
                }
            </FormHelperText>
        </FormControl>
    );
}