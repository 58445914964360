import axios from "axios";
import { useEffect, useState } from "react";
import { TiposActuadores } from "../../Service/API/Api";

export default function useTipoActuador(cargarDatos = true){
    const [tipoActuadores, setTipoActuadores] = useState([]);
    useEffect(() => {
        if(cargarDatos){
            axios.get(TiposActuadores)
            .then(
                (response) => {
                var lista = []
                var valor0 = { id: 0, tipo: "Tipos"}
                lista.push(valor0)
                response.data.forEach(element => {
                        var tipo = { id: element.idTipoActor, tipo: element.tipo}
                        lista.push(tipo);
                });
                
                setTipoActuadores(lista);
                }
            )
            .catch(
                (error) => {
                    console.log("Error al obtener la información de tipos de actuadores")
                }
            )
        }
    }, [cargarDatos]);
    

    return{
        tipoActuadores
    }
}