import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice(
    {
        name: "authentication",
        initialState:
        {
            authenticated: false,
            token: null,
            expiracion : null,
            idEmpresa: null,
            usuario: null,
            correo: null,
            idPersona: null,
            rol: null,
            rememberMe: false,
        },
        reducers:
        {
            setUser: (state, action) => {
                state.authenticated = action.payload;
                state.token = action.payload;
                state.expiracion = action.payload;
                state.idEmpresa = action.payload;
                state.usuario = action.payload;
                state.correo = action.payload;
                state.idPersona = action.payload;
                state.rol = action.payload;
                state.rememberMe = action.payload;
            },
            disconnect: (state, action) => {
                state.authenticated = action.payload;
                state.token = action.payload;
                state.expiracion = action.payload;
                state.idEmpresa = action.payload;
                state.usuario = action.payload;
                state.correo = action.payload;
                state.idPersona = action.payload;
                state.rol = action.payload;
                state.rememberMe = action.payload;
            }
        }
    }
)
export const { setUser, disconnect } = authSlice.actions;
export const authReducer = authSlice.reducer;

const filtroTiempoRealSlice = createSlice({
    name: 'filtroTiempoReal',
    initialState: {
        listadoFiltros: [],
        filtroActualizado: false, 
        //Por ahora se saca el id 14 de camara faciaL aunque idelamente tambien deberia tener la opcion de switch en el PopoverBoton
        idsTiposActuadoresAccesos: [2,3,4,5,6,7,8,9,10,11,12,15,16,17], // listado de las ids de los tipos de dispositivos que se le podrá aplicar el filtro de tiempo real
    },
    reducers: {
        actualizarFiltroTiempoReal: (state, action) => {
        state.listadoFiltros = action.payload;
        },
        actualizarFiltroActualizado: (state, action) => {
            state.filtroActualizado = action.payload;
        },
        actualizarIdsTiposActuadoresAccesos: (state, action) => {
            state.idsTiposActuadoresAccesos = action.payload;
        }
    }
});
export const { actualizarFiltroTiempoReal, actualizarFiltroActualizado, actualizarIdsTiposActuadoresAccesos } = filtroTiempoRealSlice.actions;
export const filtroTiempoRealReducer = filtroTiempoRealSlice.reducer;



const zonasFormadasRegistradasSlice = createSlice({
    name: 'zonasFormadasRegistradas',
    initialState: {
        listadoZonasFormadas: [],
        listadoZonasFormadasCargado: false
    },
    reducers: {
        actualizarZonasFormadas: (state, action) => {
            state.listadoZonasFormadas = action.payload;
        },
        changeZonasFormadasCargado: (state, action) => {
            state.listadoZonasFormadasCargado = action.payload;
        }
    }
});
export const { actualizarZonasFormadas, changeZonasFormadasCargado } = zonasFormadasRegistradasSlice.actions;
export const zonasFormadasRegistradasReducer = zonasFormadasRegistradasSlice.reducer;
    

const tipoActuadoresSlice = createSlice({
    name: 'tipoActuadores',
    initialState: {
        listadoTipoActuadores: [],
        listadoTipoActuadoresCargado: false
    },
    reducers: {
        actualizarTipoActuadores: (state, action) => {
            state.listadoTipoActuadores = action.payload;
        },
        changeTipoActuadoresCargado: (state, action) => {
            state.listadoTipoActuadoresCargado = action.payload;
        }
    }
});
export const { actualizarTipoActuadores, changeTipoActuadoresCargado } = tipoActuadoresSlice.actions;
export const tipoActuadoresReducer = tipoActuadoresSlice.reducer;



const tipoVisitasSlice = createSlice({
    name: 'tipoVisitas',
    initialState: {
        listadoTipoVisitas: [],
        listadoTipoVisitasCargado: false
    },
    reducers: {
        actualizarTipoVisitas: (state, action) => {
            state.listadoTipoVisitas = action.payload;
        },
        changeTipoVisitasCargado: (state, action) => {
            state.listadoTipoVisitasCargado = action.payload;
        }
    }
});
export const { actualizarTipoVisitas, changeTipoVisitasCargado } = tipoVisitasSlice.actions;
export const tipoVisitasReducer = tipoVisitasSlice.reducer;



const ubicacionesEventoSlice = createSlice({
    name: 'ubicacionesEvento',
    initialState: {
        listadoUbicacionesEvento: [],
        listadoUbicacionesEventoCargado: false
    },
    reducers: {
        actualizarUbicacionesEvento: (state, action) => {
            state.listadoUbicacionesEvento = action.payload;
        },
        changeUbicacionesEventoCargado: (state, action) => {
            state.listadoUbicacionesEventoCargado = action.payload;
        }
    }
});
export const { actualizarUbicacionesEvento, changeUbicacionesEventoCargado } = ubicacionesEventoSlice.actions;
export const ubicacionesEventoReducer = ubicacionesEventoSlice.reducer;



const tiposUsuariosSlice = createSlice({
    name: 'tiposUsuarios',
    initialState: {
        listadoTiposUsuarios: [],
        listadoTiposUsuariosCargado: false
    },
    reducers: {
        actualizarTiposUsuarios: (state, action) => {
            state.listadoTiposUsuarios = action.payload;
        },
        changeTiposUsuariosCargado: (state, action) => {
            state.listadoTiposUsuariosCargado = action.payload;
        }
    }
});
export const { actualizarTiposUsuarios, changeTiposUsuariosCargado } = tiposUsuariosSlice.actions;
export const TiposUsuariosReducer = tiposUsuariosSlice.reducer;



const tiposUbicacionesSlice = createSlice({
    name: 'tiposUbicaciones',
    initialState: {
        listadoTiposUbicaciones: [],
        listadoTiposUbicacionesCargado: false
    },
    reducers: {
        actualizarTiposUbicaciones: (state, action) => {
            state.listadoTiposUbicaciones = action.payload;
        },
        changeTiposUbicacionesCargado: (state, action) => {
            state.listadoTiposUbicacionesCargado = action.payload;
        }
    }
});
export const { actualizarTiposUbicaciones, changeTiposUbicacionesCargado } = tiposUbicacionesSlice.actions;
export const TiposUbicacionesReducer = tiposUbicacionesSlice.reducer;



const tiposTareasSlice = createSlice({
    name: 'tiposTareas',
    initialState: {
        listadoTiposTareas: [],
        listadoTiposTareasCargado: false
    },
    reducers: {
        actualizarTiposTareas: (state, action) => {
            state.listadoTiposTareas = action.payload;
        },
        changeTiposTareasCargado: (state, action) => {
            state.listadoTiposTareasCargado = action.payload;
        }
    }
});
export const { actualizarTiposTareas, changeTiposTareasCargado } = tiposTareasSlice.actions;
export const TiposTareasReducer = tiposTareasSlice.reducer;


//Si bien esto se guarda en el store tiposTareasSlice con la informacion del servidor, es mejor guardar los valores de las ids de acuerdo al tipo de tarea en otro store que tenga valores constantes
// Para referenciar a estos y así se evitar estar escribiendo ceros y unos en el código que será mas difícil de mantener.
const idsTiposTareasSlice = createSlice({
    name: 'idsTiposTareas',
    initialState: {
        idTareaActivarDispositivo: 1,
        idTareaNotificar: 0
    },
    reducers: {
        actualizarIdTareaActivarDispositivo: (state, action) => {
            state.idTareaActivarDispositivo = action.payload;
        },
        actualizarIdTareaNotificar: (state, action) => {
            state.idTareaNotificar = action.payload;
        }
    }
});
export const { actualizarIdTareaActivarDispositivo, actualizarIdTareaNotificar } = tiposTareasSlice.actions;
export const idsTiposTareasReducer = idsTiposTareasSlice.reducer;