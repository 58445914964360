import axios from "axios";
import { useEffect, useState } from "react";
import { ListaVisitantesEmpresa } from "../../Service/API/Api";


export default function useTodosVisitantes()
{
    const [listaVisitantes, setListaVisitantes] = useState([]);
    

    useEffect(() => {
        async function Obtener(){
            await axios.get(ListaVisitantesEmpresa)
            .then(
                (response) =>
                {
                    var listaVisitantesFormada = []
                    listaVisitantesFormada.push({idVisitante:-1, nombreRut:"Visitantes", rut: null, pasaporte: null})
                    response.data.forEach(element => {
                        var persona = { idVisitante: element.idVisitante, nombreRut: element.nombreRut, rut: element.rut, pasaporte: element.pasaporte}
                        listaVisitantesFormada.push(persona)
                    });
                    setListaVisitantes(listaVisitantesFormada);
                }
            )
            .catch(
                (error) => {
                    console.error("Error al obtener el listado de visitantes")
                }
            );
        }
        Obtener();
    }, [])


    return {
        listaVisitantes
    }
}
