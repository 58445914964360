import axios from 'axios';
import { CargaMasivaVisitante, CrearVisitante, EliminarTodosVisitantes, EliminarVisitaVisitante, EliminarVisitanteSeleccionados, EnviarCorreoAccesoIndividual, EnviarInvitacionIndividual, EnviarTodaslasInvitaciones, VisitantesFormatoImporte,  } from '../../Service/API/Api';
import { useState } from 'react';


function useInvitados(){
    const [loading, setLoading] = useState();
    const [respuesta, setRespuesta] = useState();

    const GuardarInvitado = (invitado, idVisita) => {
        return new Promise((resolve, reject) => {
            const nuevoInvitado = {
                "idVisita": idVisita,
                "rut": invitado.rut,
                "nombres": invitado.nombres,
                "apellidos": invitado.apellidos,
                "correo": invitado.correo,
                "telefono": invitado.telefono,
                "Patente": invitado.patente === "" ? null : invitado.patente
              }
            axios.post(CrearVisitante, nuevoInvitado)
            .then(
                (response) => {
                    resolve(response.data);
                    setRespuesta(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setRespuesta(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setRespuesta("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setRespuesta("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                }
            )
        });
    }

    const EnviarInvitacionesMasivas = (idVisita, invitados) => {
        return new Promise((resolve, reject) => {
            var listaInvitar = []

            invitados.forEach(element => {
                var invitado = {
                    "idVisitante": element.idVisitante,
                    "correo": element.correo
                }
                listaInvitar.push(invitado)
            });

            var enviar = {
                "idVisita": idVisita,
                "invitados": listaInvitar
            }
            axios.post(EnviarTodaslasInvitaciones, enviar)
            .then(
                (response) => {
                    resolve(response.data);
                    setRespuesta(response.status);
                    setLoading(false);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setRespuesta(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setRespuesta("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setRespuesta("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
        });
    }

    const EnviarInvitacionUnica = (idVisita, idVisitante) => {
        return new Promise((resolve, reject) => {            
            const data ={
                "idVisita": idVisita,
                "idInvitado": idVisitante
            }
            axios.post(EnviarInvitacionIndividual, data)
            .then(
                (response) => {
                    resolve(response.data);
                    setRespuesta(response.status);
                    setLoading(false);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setRespuesta(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setRespuesta("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setRespuesta("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
        });
    }

    const EnviarAccesoIndividual = (idVisita, idVisitante) => {
        return new Promise((resolve, reject) => {
            const data ={
                "idVisita": idVisita,
                "idInvitado": idVisitante
            }
            axios.post(EnviarCorreoAccesoIndividual, data)
            .then(
                (response) => {
                    resolve(response.data);
                    setRespuesta(response.status);
                    setLoading(false);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setRespuesta(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setRespuesta("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setRespuesta("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
        });
    }


    const EliminarInvitado = (idVisitaVisitante) => {
        return new Promise((resolve, reject) => {
            axios.delete(EliminarVisitaVisitante + idVisitaVisitante)
            .then(
                (response) => {
                    resolve(true);
                }
            )
            .catch(
                (error) => {
                    reject(false)
                }
            )
        });
    }

    const EliminarTodosInvitados = (idVisita) => {
        return new Promise((resolve, reject) => {
          
            axios.delete(EliminarTodosVisitantes + idVisita)
            .then(
                (response) => {
                    resolve(true);
                }
            )
            .catch(
                (error) => {
                    reject(false)
                }
            )
        });
    }


    const EliminarInvitadosSeleccionados = (idVisita, invitados) => {
        return new Promise((resolve, reject) => {
            var invitadosEliminar = []

            invitados.forEach(element => {
                invitadosEliminar.push(element)
            });

            const data = {
                "idVisita": idVisita,
                "invitados": invitadosEliminar
            }
            axios.post(EliminarVisitanteSeleccionados, data)
            .then(
                (response) => {
                    resolve(true);
                }
            )
            .catch(
                (error) => {
                    reject(false)
                }
            )
        });
    }

    async function DescargarFormatoIdealInvitados(){
        return new Promise((resolve, reject) => {
            axios.request({
                method: "GET",
                url: VisitantesFormatoImporte,
                responseType: "blob",
            })
            .then((response) => {
                let filename = "";
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
                var disposition = response.headers['content-disposition']
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);                    
                }
                else
                {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);
    
                    if (filename) {
                        var a = document.createElement("a");
                        if (typeof a.download === 'undefined') {
                            window.location.href = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location.href = downloadUrl;
                    }
                    resolve(true);
                    setRespuesta(response.status);
                    setLoading(false);
                    setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); 
                }
            })
            .catch (
                (error) =>{
                    reject(false);
                    setLoading(false);
                    setRespuesta(error);
                }
            );
        })
    }

    async function ImportarArchivoExcelInvitados(idEmpresa, IdVisita, archivo) {

        const formData = new FormData();
        formData.append('invitados', archivo);
        formData.append('idEmpresa', idEmpresa);
        formData.append('idVisita', IdVisita);
        try {
            const response = await axios.post(CargaMasivaVisitante, formData);
            setRespuesta(response.status);
            setLoading(false);
            return response;
        } catch (error) {
            throw error;
        }
    }

    return {
        loading, 
        setLoading,
        GuardarInvitado,
        respuesta,
        EnviarInvitacionesMasivas,
        EnviarInvitacionUnica,
        EnviarAccesoIndividual,
        EliminarInvitado,
        EliminarTodosInvitados,
        EliminarInvitadosSeleccionados,
        DescargarFormatoIdealInvitados,
        ImportarArchivoExcelInvitados
    };
}


export default useInvitados;