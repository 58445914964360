import { Box, IconButton, Typography } from "@mui/material";
import { useFormik } from "formik";
import { IconoAgregarNuevo, IconoCargaMasiva, IconoLimpiarNombreEvento, IconoUsuario } from "./CabeceraIcons";
import "./CabeceraInvitados.css";
import {InitialValues} from "./InitialValues";
import {ValidationSchema} from "./ValidationSchema";
import IMGConfirmacionIndividual from "../../../../assets/imagenes/ImgMCESingle.png";
import { useEffect, useRef, useState } from "react";
import useInvitados from "../../../../Hooks/Eventos/useInvitados";
import { SnackBarTipoUno } from "../../../snackBar/SnackBar";
import TooltipTipoUno from "../../../ToolTip/Tooltip";
import useConfirmDos from "../../../../Hooks/ConfirmDialog/useConfirmDialogDos";
import { useSelector } from "react-redux";
import SyncIcon from '@mui/icons-material/Sync';
import { AgregarInvitado } from "../AgregarInvitado/AgregarInvitado";
import CargaMasivaComponent from "../../../CargaMasivaComponent/CargaMasivaComponent";
import { validarArchivoExcelInvitados } from "../../../CargaMasivaComponent/funcionesCargaMasivaComponent";
import { IconoExito, IconoFallo } from "../../../Modal/RespuestaServidor/RespuestaServidorIcon";
import { LoadingMasRespuestaCinco } from "../../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import SelectFechaEvento from "./SelectFechaEvento/SelectFechaEvento";

function CabeceraInvitados({setRows, selectedRows, invitados, idVisita, setIdVisita, actualizar, esEventoRecurrente, listaEventosRecurrentes})
{
    const setIdVisitaRef = useRef(setIdVisita);

    const {loading, respuesta, setLoading, GuardarInvitado, EliminarTodosInvitados, EliminarInvitadosSeleccionados, DescargarFormatoIdealInvitados, ImportarArchivoExcelInvitados} = useInvitados();
    
    const data = useSelector(action => action.user.authenticated)
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [ConfirmationDialogDos, confirmDos]= useConfirmDos();

    //Estado para manejar el LoadingMasRespuestaCinco
    const [openLoading, setOpenLoading] = useState(Boolean(false)); 

    //variables y funciones relacionadas con el manejo del popover de Carga Masiva
    const [anchorElCargaMasiva, setAnchorElCargaMasiva] = useState(null);
    const handlePopoverCargaMasivaOpen = (event) => {
        setAnchorElCargaMasiva(event.currentTarget);
    };
    const handlePopoverCargaMasivaClose = () => {
        setAnchorElCargaMasiva(null);
    };
    const handleDescargarFormatoIdeal = async () => {
        setLoading(true);
        setOpenLoading(true);
    
        try {
            const resultado = await DescargarFormatoIdealInvitados(); // Espera el resultado
    
            if (resultado) {
                // La descarga fue exitosa
                console.log("Descarga completada con éxito.");
            } else {
                // Hubo un error en la descarga
                console.log("Hubo un problema al descargar el archivo.");
            }
        } catch (error) {
            // Manejo de errores en la llamada a la función
            console.error("hubo un error al descargar el archivo"); // O puedes manejar el error de otra forma
        } finally {
            setLoading(false); // Asegúrate de detener el loading en cualquier caso
        }
    }

    //variables y funciones relacionadas con el manejo del popover de agregar invitado
    const [anchorElAgregarInvitado, setAnchorElAgregarInvitado] = useState(null);
    const handlePopoverAgregarInvitadoOpen = (event) => {
        setAnchorElAgregarInvitado(event.currentTarget);
    };
    const handlePopoverAgregarInvitadoClose = () => {
        setAnchorElAgregarInvitado(null);
    };

    //Variables y funciones para manejar el select que aparecerá en la cabecera en caso de ser un evento recurrente
    const [fechaSeleccionada, setFechaSeleccionada] = useState(null);

    // setIdVisita es estable y no cambiará, por lo que se puede ignorar el warning.
    useEffect(() => {
        if(fechaSeleccionada !== null && fechaSeleccionada !== undefined){
            setIdVisitaRef.current(fechaSeleccionada.idVisita);
        }
        else{
            setIdVisitaRef.current(-1);
        }
    }, [fechaSeleccionada]);

    const formik = useFormik(
        {
            initialValues: InitialValues,
            validationSchema: ValidationSchema,
            onSubmit: async () => {
                console.log("Submit de formulario activado");
                await GuardarInvitado(formik.values, idVisita)
                .then(
                    (resolve) => {
                        setRows((oldRows) => [...oldRows, 
                            {
                                idVisitante: resolve.idVisitante,
                                idVisitaVisitante:resolve.idVisitaVisitante,
                                nombres:formik.values.nombres,
                                apellidos:formik.values.apellidos,
                                rut:formik.values.rut,
                                pasaporte:formik.values.pasaporte,
                                correo:formik.values.correo,
                                patente: formik.values.patente === "" ? "-" : formik.values.patente,
                                estadoAceptacion: "PENDIENTE",
                                estadoEnvioCorreo: false,
                                isNew: false 
                            }
                        ]);
                        setSnackbarSeverity("success")
                        setSnackMsg("Invitado agregado exitosamente")
                        setSnackbar(true);
                        handleOtro();
                        
                    }
                    ,
                    (reject) => {
                        setSnackbarSeverity("error")
                        setSnackMsg("No pudo ser agregado, reintente!")
                        setSnackbar(true);
                    }
                );
            }
    });

    const handleSelectedDelete = async () =>{
        let response = await confirmDos();
        if(response === true)
        {
            EliminarInvitadosSeleccionados(idVisita, selectedRows)
            .then
            (
                (resolve) => {
                    
                    const selectedIDs = new Set(selectedRows);
                    console.log(selectedIDs);
                    setRows((r) => r.filter((x) => !selectedIDs.has(x.idVisitaVisitante)));
                    setSnackbarSeverity("success")
                    setSnackMsg("Invitados eliminados exitosamente")
                    setSnackbar(true);
                }
                ,
                (reject) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrió un error al eliminar a los invitados, reintente")
                    setSnackbar(true);
                }
            )
        }
        else
        {
            console.log("usuario rechaza la eliminación");
        }
    }

    const handleDeleteAll = async () => 
    {
        let response = await confirmDos();
        if(response === true)
        {
            EliminarTodosInvitados(idVisita)
            .then
            (
                (resolve) => {
                    setRows([]);
                    setSnackbarSeverity("success")
                    setSnackMsg("Invitados eliminados exitosamente")
                    setSnackbar(true);
                }
                ,
                (reject) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al eliminar a los invitados, reintente")
                    setSnackbar(true);
                }
            )
        }
        else
        {
            console.log("usuario rechaza la eliminacion");
        }
         
    };

    const handleOtro = () => {
        formik.resetForm();

    }

    return(
       <Box id="BoxNuevoInvitadoManual" component={'form'} onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Box id="BoxIconoAgregarInvitado">
                <IconoUsuario id="IconoUsuarioInvitado" />
                <Typography id="TextoTituloAgregarInvitado">
                    {
                        "Invitados"
                    }
                </Typography>
            </Box>

            <Box id="BoxTextFieldNuevoUsuarioOpciones">
                    {esEventoRecurrente && <SelectFechaEvento setFechaSeleccionada={(fecha)=>setFechaSeleccionada(fecha)} label={"Fecha evento"} keyItem={"idVisita"} atributoMostrable={"fechaVisita"} listaObjetos={listaEventosRecurrentes}/>}
                <TooltipTipoUno titulo={"Actualizar"} ubicacion={"bottom"}>
                    <IconButton onClick={actualizar}>
                        <SyncIcon fontSize="large" sx={{color:"#175676"}}/>
                    </IconButton>
                </TooltipTipoUno>
                <TooltipTipoUno titulo={"Agregar Invitado"} ubicacion={"bottom"}>
                    {/* <IconButton onClick={formik.submitForm} id="btnIconoAccionInvitado"> */}
                    <IconButton onClick={handlePopoverAgregarInvitadoOpen} id="btnIconoAccionInvitado">
                        <IconoAgregarNuevo id="IconoMenuOpciones" />
                    </IconButton>
                </TooltipTipoUno>
                <TooltipTipoUno titulo={"Cargar masiva"} ubicacion={"bottom"}>
                    <IconButton onClick={handlePopoverCargaMasivaOpen}>
                        <IconoCargaMasiva id="IconoMenuOpciones" />
                    </IconButton>
                </TooltipTipoUno>
                {
                    selectedRows.length > 0?
                    <TooltipTipoUno titulo={"Borrar Seleccionados"} ubicacion={"bottom"}>
                        <IconButton onClick={handleSelectedDelete}>
                            <IconoLimpiarNombreEvento id="IconoMenuOpciones" />                    
                        </IconButton>
                    </TooltipTipoUno>
                    :
                    <TooltipTipoUno titulo={"Borrar todos"} ubicacion={"bottom"}>
                        <IconButton onClick={handleDeleteAll}>
                            <IconoLimpiarNombreEvento id="IconoMenuOpciones" />
                        </IconButton>
                    </TooltipTipoUno>
                }
            </Box>
            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />
            <ConfirmationDialogDos
            img={IMGConfirmacionIndividual}
            titulo={"Eliminar Invitados"}
            mensaje={selectedRows.length > 0? 
                "estás seguro(a) de eliminar a los invitados seleccionados" 
                : 
                "estás seguro(a) de eliminar a todos los invitados de la lista"}
            usuario={data.usuario.split(' ')[0]}
            />

            <AgregarInvitado
                anchorEl={anchorElAgregarInvitado}
                onClose={handlePopoverAgregarInvitadoClose}
                idVisita={idVisita}
                setRows={(row)=>setRows(row)}
                guardarInvitado={GuardarInvitado}
                esEventoRecurrente={esEventoRecurrente}
                listaEventosRecurrentes={listaEventosRecurrentes}
                actualizar={actualizar}
            />

            <CargaMasivaComponent
                anchorEl={anchorElCargaMasiva} 
                onClose={handlePopoverCargaMasivaClose}
                handleDescargarFormatoIdeal={handleDescargarFormatoIdeal}
                importarArchivoExcel={(idEmpresa, archivo)=>ImportarArchivoExcelInvitados(idEmpresa, idVisita, archivo)}
                tipoCargaMasiva={"visitantes"}
                funcionValidadoraExcel={(entrada)=>validarArchivoExcelInvitados(entrada)}
                recargarPagina={false}
                actualizarTabla={()=>actualizar()}
            />

            <LoadingMasRespuestaCinco
                open={openLoading} 
                setOpen={setOpenLoading} 
                msj={"El reporte se está descargando. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."} 
                id={"RespuestaDescargarReporteExcel"} 
                tituloLoading={"Descargando reporte..."}
                loading={loading} 
                respuesta={respuesta} 
                icono={
                    respuesta === 200 ?
                    <IconoExito id="IconoRespuestaServidor" />
                    :
                    <IconoFallo id="IconoRespuestaServidor" />
                }
                textoBoton={
                    respuesta === 200 ? 
                    "Aceptar"
                    :
                    "Reintentar"
                }
                titulo={respuesta === 200 ? "Descarga exitosa":"Error al Descargar"}
                mensajeRespuesta={respuesta === 200 ? 
                    "El reporte se ha descargado correctamente"
                    :
                    "Ha ocurrido un error y no se ha podido descargar el reporte. Por favor, verifica tu conexión y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
            />
        </Box>   
    );
}

export default CabeceraInvitados;