import axios from "axios";
const { useState, useEffect } = require("react");
const { UbicacionesParaEventos, CrearPersonaUbicacion, EditarPersonaUbicacion, BuscarUbicacion } = require("../../Service/API/Api");

function useUbicacionesEvento(cargarDatos = true){
    const [ubicacionesEvento, setUbicacionesEvento] = useState([]);
    const [loading, setLoading] = useState(null);
    const [response, setResponse] = useState(null);

    useEffect(() => {
        if(cargarDatos) {
            axios.get(UbicacionesParaEventos)
            .then((response) => {
                var lista = []
                var ubic0 = {
                    idUbicacion:-1, 
                    nombreUbicacion:"Destinos"
                }
                lista.push(ubic0);
                response.data.forEach(element => {
                    lista.push(element)
                });
                setUbicacionesEvento(lista);
            }).catch((response) => {
                console.log("Error al obtener ubicaciones");
        });
        }
    }, [cargarDatos])




    function GuardarAsignacionUbicacion(usuario, ubicacion){
        return new Promise(async (resolve, reject) => {
            let create = {
                "idPersona": usuario,
                "idUbicacion": ubicacion
            }
            await axios.post(CrearPersonaUbicacion, create)    
            .then(
                (response) =>{
                    resolve(response.data);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }
   
    function EditarAsignacionUbicacion(idPersonaUbicacion, usuario, ubicacion){
        return new Promise(async (resolve, reject) => {
            let editar = {
                "idPersonaUbicacion": idPersonaUbicacion,
                "idUbicacion": ubicacion,
                "idPersona": usuario
              }
            console.log(usuario, ubicacion);
            await axios.put(EditarPersonaUbicacion, editar)    
            .then(
                (response) =>{
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }

    async function BuscarUbicacionInfo(ubicacion){
        return new Promise(async (resolve, reject) => {
            await axios.get(BuscarUbicacion + ubicacion)    
            .then(
                (response) =>{
                    resolve(response.data);
                }
            )
            .catch (
                (error) =>{
                    reject(error)
                }
            ); 
        });
        
    }

    return {
        ubicacionesEvento,
        loading, 
        setLoading,
        response,
        GuardarAsignacionUbicacion,
        EditarAsignacionUbicacion,
        BuscarUbicacionInfo,
    }
}

export default useUbicacionesEvento;