import axios from "axios";
import { useState } from "react"
import { CargaMasivaTarjeta, CrearTarjeta, DesactivarTarjeta, EditarTarjeta, ReactivarTarjeta, TarjetasFormatoImporte } from "../../Service/API/Api";

export function useManejoTarjetas(){
    const [loading, setLoading] = useState(null);
    const [loadingManejoTarjetas, setLoadingManejoTarjetas] = useState(null);
    const [response, setResponse] = useState(null);

    const CrearTarjetaRequest = (values, idEmpresa) => {
        return new Promise((resolve, reject) => {
            const nuevaTarjeta = {
                "codigo": values.codigo,
                "idTipoTarjeta": values.idTipoTarjeta,
                "idEmpresa": idEmpresa
            }
            axios.post(CrearTarjeta, nuevaTarjeta)
            .then(
                (response) => {
                    resolve(response.data);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            )
        })
    }

    const EditarTarjetaRequest = (idTarjeta, values, idEmpresa) => {
        return new Promise((resolve, reject) => {
            const Tarjeta = {
                "idTarjeta": idTarjeta,
                "codigo": values.codigo,
                "idTipoTarjeta": values.idTipoTarjeta,
                "idEmpresa": idEmpresa
            }
            axios.put(EditarTarjeta, Tarjeta)
            .then(
                (response) => {
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            )
        })
    }
    
    const ManejoEstadoTarjeta = (idTarjeta, estadoActual) => {
        return new Promise((resolve, reject) => {
            axios.patch(estadoActual === true ? DesactivarTarjeta + idTarjeta : ReactivarTarjeta + idTarjeta)
            .then(
                (response) => {
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            )
        })
    }

    async function DescargarFormatoIdealTarjetas(){
        return new Promise((resolve, reject) => {
            axios.request({
                method: "GET",
                url: TarjetasFormatoImporte,
                responseType: "blob",
            })
            .then((response) => {
                let filename = "";
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
                var disposition = response.headers['content-disposition']
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);                    
                }
                else
                {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);
    
                    if (filename) {
                        var a = document.createElement("a");
                        if (typeof a.download === 'undefined') {
                            window.location.href = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location.href = downloadUrl;
                    }
                    resolve(true);
                    setResponse(response.status);
                    setLoadingManejoTarjetas(false);
                    setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); 
                }
            })
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoadingManejoTarjetas(false);
                }
            );
        })
    }

    async function ImportarArchivoExcelTarjetas(idEmpresa, archivo) {
        const formData = new FormData();
        formData.append('tarjetas', archivo);
        formData.append('idEmpresa', idEmpresa);
    
        try {
            const response = await axios.post(CargaMasivaTarjeta, formData);
            setResponse(response.status);
            setLoading(false);
            return response;
        } catch (error) {
            throw error;
        }
    }
    

    return{
        loading,
        loadingManejoTarjetas,
        setLoadingManejoTarjetas,
        setLoading,
        response,
        CrearTarjetaRequest,
        EditarTarjetaRequest,
        ManejoEstadoTarjeta,
        DescargarFormatoIdealTarjetas,
        ImportarArchivoExcelTarjetas
    }
}