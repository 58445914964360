import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { 
    authReducer, 
    filtroTiempoRealReducer, 
    idsTiposTareasReducer, 
    tipoActuadoresReducer, 
    TiposTareasReducer, 
    TiposUbicacionesReducer, 
    TiposUsuariosReducer, 
    tipoVisitasReducer, 
    ubicacionesEventoReducer, 
    zonasFormadasRegistradasReducer 
} from "./misSlice";
import { configureStore, combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
    key: 'root',
    storage,
};

// Reducer combinado con lógica para resetear
const combinedReducer = combineReducers({
    user: authReducer,
    filtroTiempoReal: filtroTiempoRealReducer,
    zonasFormadasRegistradas: zonasFormadasRegistradasReducer,
    tipoActuadores: tipoActuadoresReducer,
    tipoVisitas: tipoVisitasReducer,
    ubicacionesEvento: ubicacionesEventoReducer,
    tiposUsuarios: TiposUsuariosReducer,
    tiposUbicaciones: TiposUbicacionesReducer,
    tiposTareas: TiposTareasReducer,
    idsTiposTareas: idsTiposTareasReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET') {
        storage.removeItem('persist:root'); // Limpia el almacenamiento persistente
        state = undefined; // Reinicia el estado a su valor inicial
    }
    return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});

export const persistor = persistStore(store);

// import storage from 'redux-persist/lib/storage';
// import { persistReducer, persistStore, PURGE } from 'redux-persist';
// import thunk from 'redux-thunk';
// import { authReducer, filtroTiempoRealReducer, idsTiposTareasReducer, tipoActuadoresReducer, TiposTareasReducer, TiposUbicacionesReducer, TiposUsuariosReducer, tipoVisitasReducer, ubicacionesEventoReducer, zonasFormadasRegistradasReducer } from "./misSlice";
// const { configureStore, combineReducers } = require("@reduxjs/toolkit");

// const persistConfig = {
//     key: 'root',
//     storage,
// }

// const rootReducer = combineReducers({
//     user: authReducer,
//     // tiposVisita: TiposVisitaReducer,
//     filtroTiempoReal: filtroTiempoRealReducer,
//     zonasFormadasRegistradas: zonasFormadasRegistradasReducer,
//     tipoActuadores: tipoActuadoresReducer,
//     tipoVisitas: tipoVisitasReducer,
//     ubicacionesEvento: ubicacionesEventoReducer,
//     tiposUsuarios: TiposUsuariosReducer,
//     tiposUbicaciones: TiposUbicacionesReducer,
//     tiposTareas: TiposTareasReducer,
//     idsTiposTareas: idsTiposTareasReducer
//   });

// const persistedReducer = persistReducer(persistConfig, rootReducer)

// export const store = configureStore({
//     reducer: persistedReducer,
//     middleware: [thunk]
// })

// export const persistor = persistStore(store);