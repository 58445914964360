import { Box, IconButton, Modal } from "@mui/material";
import "./ManejadorOpciones.css";
import { NuevoEstacionamiento } from "./NuevoEstacionamiento";
import { CerrarModalNuevoUsuario } from "../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import { EditarEstacionamiento } from "./EditarEstacionamientos";


const style = {
    width: "1108px",
    height: "680px",
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};


export const HandleOpcionesEstacionamiento = ({ open, setOpen, setRows, rows, opcion, dataEdit, edicionEstacionamientoActivada}) => {
    
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Modal open={open} onClose={handleClose} sx={{border:"unset"}}>
            <Box sx={{...style}}>
                <Box id="BoxCabeceraEstacionamiento">
                    <IconButton onClick={handleClose}>
                        <CerrarModalNuevoUsuario />
                    </IconButton>
                </Box>
                <Box id="BoxOpcionesGestionEstacionamientos">
                    {
                        opcion === 1 ?
                        <NuevoEstacionamiento setOpen={setOpen} setRows={setRows} />
                        :
                        <EditarEstacionamiento rows={rows} setRows={setRows} setOpen={setOpen} dataEditar={dataEdit} edicionEstacionamientoActivada={edicionEstacionamientoActivada} />
                    }
                </Box>
            </Box>
        </Modal>
    )
}