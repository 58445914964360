import { Box, Typography } from "@mui/material";
import "./CabeceraAgenda.css";
import { CalendarioPrueba } from "../../LeftBar/IconosMenu";
import { BotonTipoCalendarioUno, BotonTipoCalendarioUnoSelect } from "../../Botones/BotonesCalendario/BotonesCalendario";
import { HandlerOpciones } from "./OpcionesCabeceraAgenda/HandlerOpciones";
import { PopOverNuevoEvento } from "../../Popover/PopoverTipoUno";
import NuevoEvento from "../../Eventos/NuevoEvento/NuevoEvento";
import { useState } from "react";

const OpcionesNavegacionAgenda = [
    {id:2, tipo: "Semana"},
    {id:3, tipo: "Mes"},
    {id:4, tipo: "Año"},
]


const CabeceraAgenda = ({opcion, setOpcion, fechaActual, handleHoy, addMonth, restMonth, setMonth, setYear, addYear, restYear}) => {
    const [anchorElNE, setAnchorElNE] = useState(false);
    const openNE = Boolean(anchorElNE)

    const HandlerOpenNuevoEvento = (e) => {
        setAnchorElNE(e.currentTarget)
    }

    const handleOptionDay = () => {
        handleHoy()
        setOpcion(2)
    }
    
    return (
        <Box id="BoxCabeceraAgenda">
            <Box id="BoxContenedorCabeceraAgenda">
                <Box id="BoxIconoTituloHoyCabecerAgenda">
                    <Box id="BoxIconoCabeceraAgenda">
                        <CalendarioPrueba id="IconoCabeceraAgenda" />
                    </Box>
                    <Box id="BoxTituloCabecera">
                        <Typography id="txtTitulo">
                        {
                                "Calendario"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxBotonNuevoEventoAgenda" >
                         <BotonTipoCalendarioUno textoBoton={"Hoy"} showStartIcon={false} event={handleOptionDay} /> 
                    </Box>
                </Box>
                <Box id="BoxContenedorOpcionesAgenda">
                    <Box id="BoxOpcionSelectedAcciones">
                        <HandlerOpciones opcion={opcion} fechaActual={fechaActual} addMonth={addMonth} restMonth={restMonth} setMonth={setMonth} setYear={setYear} addYear={addYear} restYear={restYear} />
                    </Box>
                    <Box id="BoxSelectOpcionCabeceraAgenda">
                        <BotonTipoCalendarioUnoSelect 
                        listaOpciones={OpcionesNavegacionAgenda}
                        setOpcion={setOpcion}
                        opcion={opcion}
                        />
                    </Box>  
                </Box>
            </Box>
            <Box id="BoxBotonNuevoEventoAgenda" >
                <BotonTipoCalendarioUno textoBoton={"Agendar"} showStartIcon={true} event={(e) => HandlerOpenNuevoEvento(e)}  />
            </Box>
            <PopOverNuevoEvento open={openNE} anchorEl={anchorElNE} setOpen={setAnchorElNE} children={<NuevoEvento setOpen={setAnchorElNE} />}/>
        </Box>
    );
}
export default CabeceraAgenda;