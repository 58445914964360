import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fragment, useState } from 'react';
import { SwitchTablas } from '../Switch/switch';
import TooltipTipoUno from '../ToolTip/Tooltip';
import BotonEliminar from '../Botones/BotonEliminar/BotonEliminar';

function Row(props) {
    const { row, changeEstadoSwitch, eliminarFila } = props;
    const [open, setOpen] = useState(false);
    return (
      <Fragment>
        {/* Fila principal */}

        {/* Modificando borde de la fila */}
        <TableRow sx={{ borderBottom: '2px solid #94B1C0'}}>
          <TableCell align='left' sx={{ borderBottom: '1px solid #94B1C0', width: "5%" }}> {/* Borde para la celda */}
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="left" component="th" scope="row" sx={{borderBottom: '1px solid #94B1C0'}}>
            {row.nombrePersona}
          </TableCell>
          <TableCell align="left" sx={{ borderBottom: '1px solid #94B1C0' }}>{row.rut}</TableCell>
          <TableCell align="left" sx={{ borderBottom: '1px solid #94B1C0' }}>{row.pasaporte}</TableCell>
          <TableCell align="left" sx={{ borderBottom: '1px solid #94B1C0' }}>
            <SwitchTablas
                id={row.idAcceso?.toString()}
                value={row.estadoPersona}
                disable={true}
                mensajeSwitchActivado='Activado'
                mensajeSwitchDesactivado="Desactivado"
            />
          </TableCell>
        </TableRow>


  
        {/* Fila de detalles colapsada */}
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 10 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 0, width: "100%" }}>

                {/* Borde para la tabla interna */}
                <Table size="small" aria-label="purchases" sx={{width: "100%"}} >
                  <TableHead>
                    {/* Encabezado de la tabla interna */}
                    <TableRow sx={{backgroundColor: "#C9C9C9", width: "100%"}}>
                      <TableCell sx={{ color: '#175676', borderBottom: '1px solid #94B1C0' }} align="center">Nombre de la zona</TableCell>
                      <TableCell sx={{ color: '#175676', borderBottom: '1px solid #94B1C0' }} align="center">Estado</TableCell>
                      <TableCell sx={{ color: '#175676', borderBottom: '1px solid #94B1C0' }} align="center">Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* Borde para la fila de accesos */}
                    {row.accesos.map((acceso) => (
                      <TableRow key={acceso.idAcceso} sx={{backgroundColor: "#dfdfdf"}}>
                        <TableCell align="center" component="th" scope="row" sx={{ borderBottom: '1px solid #94B1C0' }}>
                          {acceso.nombreZona}
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: '1px solid #94B1C0' }}>
                          <SwitchTablas
                            id={row.idAcceso?.toString()}
                            value={acceso.estado}
                            handler={() => changeEstadoSwitch(acceso)}
                            disable={false}
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: '1px solid #94B1C0' }}>
                          <TooltipTipoUno titulo={"Eliminar"} ubicacion={"bottom"}>
                            <IconButton onClick={eliminarFila(acceso.idAcceso)}>
                              <BotonEliminar />
                            </IconButton>
                          </TooltipTipoUno>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
  
  export default function CollapsibleTable({ rows, columns, changeEstadoSwitch, eliminarFila }) {
    return (
      <TableContainer component={Paper} sx={{ backgroundColor: "transparent", boxShadow: "initial", marginBottom: 5}}>
        <Table aria-label="collapsible table">
          <TableHead>
            {/* Borde para la fila del encabezado principal */}
            <TableRow sx={{backgroundColor: "transparent"}} >
              <TableCell sx={{ borderBottom: '1px solid #C9C9C9' }} />
              {columns.map((columna) => (
                <TableCell key={columna.field} sx={{ color: "#175676", borderBottom: '1px solid #C9C9C9' }} align="inherit">
                  {columna.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row
                key={row.idPersona}
                row={row}
                changeEstadoSwitch={changeEstadoSwitch}
                eliminarFila={eliminarFila}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }