import { useFormik } from "formik";
import "./ManejadorOpcionesUbicacion.css"
import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { EsquemaNuevaUbicacion } from "./validationSchema";
import useUbicacionesGestion from "../../../Hooks/Ubicaciones/useUbicacionesGestion";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import { useState } from "react";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import { useSelector } from "react-redux";
import AutocompleteSimple from "../../AutocompleteSimple/AutocompleteSimple";


export const EditarUbicacion = ({rows, setRows, setOpen, dataEditar}) => {
    const zonasFormadas = useSelector((state) => state.zonasFormadasRegistradas.listadoZonasFormadas);
    const tiposUbicaciones = useSelector(action => action.tiposUbicaciones.listadoTiposUbicaciones);
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [confirmMessage, setConfirmMessage] = useState({titulo: "", mensaje: ""});
    const {loading, setLoading, response, EditarUbicacionExistente } = useUbicacionesGestion();

    const handlerUpdateRow = () => {
        var findedRow = rows.find(u => u.idUbicacion === dataEditar.idUbicacion);
        findedRow.idZona = formik.values.idZona;
        findedRow.nombreZona = formik.values.nombreZona;
        findedRow.estadoZona = dataEditar.estadoZona;
        findedRow.idTipoUbicacion = formik.values.idTipoUbicacion;
        findedRow.tipoUbicacion = formik.values.tipoUbicacion;
        findedRow.nombre = formik.values.nombreUbicacion;
        findedRow.piso = formik.values.piso;
        findedRow.numeracion = formik.values.numeracion

        setRows(rows.map((row) => (row.idUbicacion === dataEditar.idUbicacion ? findedRow : row)))
    }

    
    const handlerClose = () => {
        setOpen(false);
    }

    const formik = useFormik(
        {
            initialValues: {
                idZona: dataEditar.idZona,
                nombreZona: dataEditar.nombreZona,
                nombreUbicacion: dataEditar.nombre,
                idTipoUbicacion: dataEditar.idTipoUbicacion,
                tipoUbicacion: dataEditar.tipoUbicacion,
                piso:dataEditar.piso,
                numeracion: dataEditar.numeracion
            },
            enableReinitialize: true,
            validationSchema: EsquemaNuevaUbicacion,
            onSubmit: async () => {
                setConfirmMessage({ titulo: "¿Actualizar datos?", mensaje: "Actualizar la informacion de la ubicacion ¿Continuar?"})
                var response = await confirm();
                if(response){
                    setLoading(true);
                    setOpenLoading(true);
                    await EditarUbicacionExistente(dataEditar.idUbicacion, formik.values)
                    .then(
                        (resolve) => {
                            handlerUpdateRow();
                        },
                        (reject) => {
                            console.log("fallo la actualizacion");
                        }
                    )
                }
            }
        }
    );

    return (
        <Box id="BoxAgregarNuevaUbicacion">
            <Box id="BoxTituloSubtituloOpcionNuevaUbicacion">
                <Box id="BoxTituloOpcionNuevaUbicacion">
                    <Typography id="TxtTituloOpcionNuevaUbicacion">
                        {
                            "Editar ubicación"
                        }
                    </Typography>
                </Box>
                <Box id="BoxSubTituloNuevaUbicacion">
                    <Typography id="TxtSubTituloOpcionNuevaUbicacion">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxFormularioOpcionNuevaUbicacion" >
                <Box id="BoxContenidoFormularioOpcionNuevaUbicacion" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxSegundaInformacionOpcionNuevaUbicacion">
                        <Box id="BoxTituloSegundaInformacionOpcionNuevaUbicacion">
                            <Typography id="TxtTitutlosFormulariosOpcionNuevaUbicacion">
                                {
                                    "Información de zona"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxSegundasOpcionesFormularioNuevaUbicacion">
                        <FormControl fullWidth>
                            <AutocompleteSimple
                                disablePortal={true}
                                freeSolo = {true}
                                listadoElementos={zonasFormadas}
                                label="Zonas"
                                onSelectionChange={(newValue) => {
                                    formik.setFieldValue('idZona', newValue ? newValue["id"] : -2);
                                    formik.setFieldValue('nombreZona', newValue ? newValue["tipo"] : '');
                                }}
                                keyListadoElementos="id"
                                mostrarElemento={(option) => option["tipo"] || ''}
                                elementoSeleccionado={zonasFormadas.find(zonaFormada => formik.values.idZona === zonaFormada.id) || null}
                            />
                            <FormHelperText>
                                {formik.errors.idZona}
                            </FormHelperText>
                        </FormControl>
                        </Box>
                    </Box>
                    <Box id="BoxSegundaInformacionOpcionNuevaUbicacion">
                        <Box id="BoxTituloSegundaInformacionOpcionNuevaUbicacion">
                            <Typography id="TxtTitutlosFormulariosOpcionNuevaUbicacion">
                                {
                                    "Info. de la ubicación"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxFormularioNombreTipoNuevaUbicacion">
                            <Box id="BoxInformacionSobreUbicacion">
                                <Box id="BoxNombreNuevaUbicacion">
                                    <TextFieldTres
                                        id={"nombreUbicacion"}
                                        label={"Nombre de la ubicación"}
                                        placeholder={"residencia A-03"}
                                        icon={null}
                                        helper={formik.errors.nombreUbicacion}
                                        value={formik.values.nombreUbicacion}
                                        name={"nombreUbicacion"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                    />
                                </Box>
                                <Box id="BoxTipoNuevaUbicacion">
                                    <FormControl fullWidth>
                                        <AutocompleteSimple
                                            disablePortal={true}
                                            freeSolo = {true}
                                            listadoElementos={tiposUbicaciones}
                                            label="Zonas"
                                            onSelectionChange={(newValue) => {
                                                if(newValue === null)
                                                {
                                                    formik.setFieldValue("idTipoUbicacion", -2)                                           
                                                }
                                                else{
                                                    formik.setFieldValue("idTipoUbicacion", newValue.id)
                                                }
                                            }}
                                            keyListadoElementos="id"
                                            mostrarElemento={(option) => option["tipo"] || ''}
                                            elementoSeleccionado={tiposUbicaciones.find(ubicacion => formik.values.idTipoUbicacion === ubicacion.id) || null}
                                        />
                                        <FormHelperText>
                                            {
                                                formik.errors.idTipoUbicacion
                                            }
                                        </FormHelperText>                                   
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box id="BoxInformacionSobreUbicacion">
                                <Box id="BoxNombreNuevaUbicacion">
                                    <TextFieldTres
                                        id={"piso"}
                                        label={"Piso"}
                                        placeholder={"1"}
                                        icon={null}
                                        helper={formik.errors.piso}
                                        value={formik.values.piso}
                                        name={"piso"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                    />
                                </Box>
                                <Box id="BoxTipoNuevaUbicacion">
                                    <TextFieldTres
                                        id={"numeracion"}
                                        label={"Numeración"}
                                        placeholder={"101"}
                                        icon={null}
                                        helper={formik.errors.numeracion}
                                        value={formik.values.numeracion}
                                        name={"numeracion"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxHandlerFormularioOpcionNuevaUbicacion">
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                    </Box>
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Actualizar"} event={formik.submitForm} disabled={loading} />
                        
                        <ConfirmationDialog
                        icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
                        titulo={confirmMessage.titulo}
                        mensaje={confirmMessage.mensaje}
                        />

                        <LoadingMasRespuestaCinco
                        open={openLoading} 
                        setOpen={setOpenLoading} 
                        msj={"La información sobre la ubicación está siendo actualizada. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
                        id={"RespuestaGuardarEditarUbicacion"} 
                        tituloLoading={"Actualizando datos..."}
                        loading={loading} 
                        respuesta={response} 
                        icono={
                            response === 200 ?
                            <IconoExito id="IconoRespuestaServidor" />
                            :
                            <IconoFallo id="IconoRespuestaServidor" />
                        }
                        textoBoton={
                            response === 200 ? 
                            "Aceptar"
                            :
                            "Reintentar"
                        }
                        titulo={response === 200 ? "Actualización exitosa":"Error al actualizar"}
                        mensajeRespuesta={response === 200 ? 
                            "La información se ha actualizado correctamente"
                            :
                            "Ha ocurrido un error y no se ha podido actualizar la información"}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}