import { cleanRut, validateRut } from "../../Helper/ValidarRut";

////////////////////// FUNCIONES DE VALIDACION INDIVIDUAL ///////////////////////////////////

const validarFormatoPasaporte = (pasaporte) => {
    // Verifica si que el pasaporte contiene solo números usando una expresión regular
    if (/^\d+$/.test(pasaporte)) {
      return true;
    } else {
        return false;
    }
  }

const validarFormatoNombres = (nombres) => {
  // Verifica si la entrada contiene solo letras usando una expresión regular
  if (/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(nombres)) {
    return true; // Solo contiene letras (mayúsculas, minúsculas, y letras con acentos o ñ)
  } 
  else {
      return false; // Contiene números o caracteres especiales
  }
}

const validarFormatoEmail = (email) => {
  // Verifica si el email tiene un formato válido usando una expresión regular
  if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
    return true;
  } else {
    return false;
  }
}

const validarFormatoId = (id) => {
  // Verifica si la entrada es un número entero positivo usando una expresión regular
  if (/^\d+$/.test(id)) {
    return true; // Es un número entero válido (solo contiene dígitos)
  } else {
    return false; // Contiene caracteres especiales, letras o no es un número entero
  }
}

const validarIdHorario = (id, horarios) => {
  return horarios.some(horario => horario.idHorario === id);
}

const validarFormatoPatente = (patente) => {
  // Expresión regular para los dos formatos de patentes
  const formatoAntiguo = /^[A-Z]{2}[0-9]{4}$/; // 2 letras, 4 números
  const formatoNuevo = /^[A-Z]{4}[0-9]{2}$/;   // 4 letras, 2 números

  // Verificar si cumple con alguno de los dos formatos
  if (formatoAntiguo.test(patente) || formatoNuevo.test(patente)) {
    return true;  // Formato válido
  } else {
    return false; // Formato inválido
  }
}

const validarNombreUbicacion = (ubicacion) => {
  // Expresión regular para verificar que solo tenga letras, números y espacios
  const regex = /^[a-zA-Z0-9\s]+$/;
  return regex.test(ubicacion);
};

const verificarUbicacionesRepetidas = (ubicaciones) => {
    const combinaciones = new Set();
    const duplicados = new Set();
    const combinacionesRepetidas = new Set(); // Usamos un Set para evitar duplicados

    for (const ubicacion of ubicaciones) {
        const combinacion = `${ubicacion["Nombre de la ubicacion"]}-${ubicacion["Piso"]}-${ubicacion["Numeracion"]}`;
        
        if (combinaciones.has(combinacion)) {
            // Si ya existe, agregamos a duplicados
            duplicados.add(combinacion);
            combinacionesRepetidas.add(JSON.stringify(ubicacion)); // Agregamos la ubicación única
        } else {
            // Si no existe, la agregamos al Set de combinaciones
            combinaciones.add(combinacion);
        }
    }

    // Convertimos el Set de duplicados a un arreglo
    const ubicacionesFinales = Array.from(combinacionesRepetidas).map(ubicacion => JSON.parse(ubicacion));
    
    return {
        tieneDuplicados: duplicados.size > 0,
        ubicacionesRepetidas: ubicacionesFinales
    };
};


const validarFormatoAntiPassBack = (entrada) => {
    const entradaActualizada = entrada.toLowerCase();
    if(entradaActualizada === "si" || entradaActualizada === "no"){
        return true;
    }
    else{
        return false;
    }
}

const validarFormatoCodigo = (codigo) => {
    const regex = /^[0-9]+$/; // Expresión regular que verifica solo números
    return regex.test(codigo);
};

const validarFormatoTarjeta = (tarjeta) => {
    const tarjetaActualizada = tarjeta.toUpperCase();
    if(tarjetaActualizada === "RFID" || tarjetaActualizada === "TAG"){
        return true;
    }
    else{
        return false;
    }
}

const validarIdZonas = (id, zonas) => {
    return zonas.some(zona => zona.idZona === id);
};

const validarIdTipoUbicaciones = (id, tipoUbicaciones) => {
    return tipoUbicaciones.some(tipoUbicacion => tipoUbicacion.id === id);
};

const validarFormatoNombreUbicacion = (nombre) => {
    // Permite letras, números y espacios, de 1 a 15 caracteres.
    const regex = /^[a-zA-Z0-9 ]{1,15}$/;
    return regex.test(nombre);
};

const validarFormatoNumeracion = (numeracion) => {
    const regex = /^[a-zA-Z0-9]{1,10}$/; // Permite letras y números, de 1 a 10 caracteres
    return regex.test(numeracion);
};

////////////////////////////////////////////////////////////////////////////////////


// Funcion que tiene como objetivo verificar si en las columnas del archivo existen ciertos datos repetidos.
// En realidad lo que hace es tomar un arreglo de objetos, que es el producto de pasar la info del archivo a formato JSON,
// y posteriormente evalua si ciertas columnas (definido en el arreglo de entrada "columnas") tienen datos repetidos.
// En caso de que sí, guarda esta info en un arreglo de objetos, donde se tiene el nombre de la columna repetida y el o los datos duplicados en cuestion.
// Ademas de esto, la funcion retorna un booleano indicando si efectivamente existen o no columnas con datos repetidos.
export const verificarColumnasRepetidas = (data, columnas) => {
  const seen = new Map(); // Usamos un Map para rastrear los valores de cada columna
  const columnasRepetidas = []; // Arreglo para almacenar columnas con datos repetidos

  // Iteramos sobre cada objeto en el arreglo
  data.forEach(item => {
      columnas.forEach(columna => {
          const valor = item[columna];

          // Verificamos si ya hemos visto este valor en la columna
          if (seen.has(columna)) {
              if (seen.get(columna).has(valor)) {
                  // Si el valor ya se había visto, lo agregamos al resultado
                  const resultado = columnasRepetidas.find(res => res.nombreColumna === columna);
                  if (resultado) {
                      // Agregamos el valor a datosRepetidos si ya existe en resultados
                      if (!resultado.datosRepetidos.includes(valor)) {
                          resultado.datosRepetidos.push(valor);
                      }
                  } else {
                      // Si no existe aún, lo creamos
                      columnasRepetidas.push({
                          nombreColumna: columna,
                          datosRepetidos: [valor]
                      });
                  }
              } else {
                  // Si no se ha visto, lo agregamos al Map
                  seen.get(columna).add(valor);
              }
          } else {
              // Inicializamos un Set para la columna si es la primera vez que la vemos
              seen.set(columna, new Set([valor]));
          }
      });
  });

  // Determinamos si hay columnas repetidas
  const hayRepetidos = columnasRepetidas.length > 0;

  return [hayRepetidos, columnasRepetidas];
};


export const generarMensajeColumnasRepetidas = (columnasRepetidas) => {
  // Usamos un arreglo para almacenar las partes del mensaje
  const mensajes = columnasRepetidas.map(columna => {
      return `${columna.nombreColumna}: ${columna.datosRepetidos.join(", ")}`;
  });

  // Unimos los mensajes y agregamos un salto de línea o separador
  return mensajes.join(" | \n");
};


export const validarArchivoExcelUsuarios = (archivoObjeto, horarios) => {
    let respuesta = true;
    let mensajeError = "";
    
    // Validación de columnas
    for (let i = 0; i < archivoObjeto.length; i++) {
      const fila = archivoObjeto[i];
      const RUT = fila["RUT"];
      const pasaporte = fila["PASAPORTE"];
      const nombres = fila["NOMBRES"];
      const apellidos = fila["APELLIDOS"];
      const telefono = fila["TELEFONO"];
      const correo = fila["CORREO"];
      const id_tipo_persona = fila["ID TIPO PERSONA"];
      const id_horario = fila["ID HORARIO"];
      const patente = fila["PATENTE"];
      const nombre_ubicacion = fila["NOMBRE UBICACION"];
  
      //Validación de RUT y/o pasaporte
      //Si no hay RUT, se debe verificar que exista pasaporte
      if(RUT === "" || RUT === "-"){
        //Si no hay pasaporte tampoco, entonces el formato es invalida ya que no cuenta con ninguno de los dos datos
        if(pasaporte === "" || pasaporte === "-"){
          mensajeError = `Fila ${i+1} campo 'RUT' y/o 'pasaporte' vacío. \n`;
          respuesta = false;
          break;
        }
        //En caso de que exista el pasaporte, pero tenga un formato inválida, tambien se retorna false
        else if(!validarFormatoPasaporte(pasaporte))
          respuesta = false;
          mensajeError = `Fila ${i+1} pasaporte con formato inválido`;
          break;
      }
      //Si el RUT tiene un formato inválido, se retorna false
      else if(!validateRut(cleanRut(RUT))){
        mensajeError = `Fila ${i+1} RUT con formato inválido`;
        respuesta = false;
        break;
      }
      // Validación de nombres
      else if (nombres === "" || nombres === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'Nombres' vacío. \n`;
        break;
      } 
      
      else if (!validarFormatoNombres(nombres)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} nombres con formato inválido`;
        break;
      }
  
      // Validación de apellidos
      else if (apellidos === "" || apellidos === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'Apellidos' vacío. \n`;
        break;
      } 
      else if (!validarFormatoNombres(apellidos)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} apellidos con formato inválido`;
        break;
      }
  
      // Validación de teléfono
      else if (telefono === "" || telefono === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'Telefono' vacío. \n`;
        break;
      } 
      else if (!validarFormatoPasaporte(telefono)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} teléfono con formato inválido`;
        break;
      }
  
      // Validación de correo
      else if (correo === "" || correo === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'Correo' vacío. \n`;
        break;
      } 
      else if (!validarFormatoEmail(correo)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} correo con formato inválido`;
        break;
      }
  
      // Validación de id_tipo_persona
      else if (id_tipo_persona === "" || id_tipo_persona === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'ID TIPO PERSONA' vacío. \n`;
        break;
      } 
      else if (!validarFormatoId(id_tipo_persona) || id_tipo_persona === 1) {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'ID TIPO PERSONA' con valor inválido.\n`;
        break;
      }
  
      // Validación de id_horario
      else if (id_horario === "" || id_horario === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'ID HORARIO' vacío. \n`;
        break;
      } 
      else if (!validarFormatoId(id_horario)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'ID HORARIO' con valor inválido.\n`;
        break;
      }
      else if(!validarIdHorario(id_horario, horarios)){
        respuesta = false;
        mensajeError = `Fila ${i+1} ID HORARIO no está en la lista de horarios disponibles.\n`;
        break;
      }
  
      // Validación de patente
      else if (patente === "" || patente === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'patente' vacío`
        break;
      }
      else if (!validarFormatoPatente(patente)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} patente con formato inválido`;
        break;
      }
  
      // Validación de nombre ubicación
      else if (nombre_ubicacion === "" || nombre_ubicacion === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'Nombre Ubicacion' vacío`;
        break;
      }
      else if (!validarNombreUbicacion(nombre_ubicacion)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} 'Nombre Ubicación' con formato inválido`;
        break;
      }
    }
    return [respuesta, mensajeError];
  };


  export const validarArchivoExcelZonas = (archivoObjeto) => {
    
    let respuesta = true;
    let mensajeError = "";

    for (let i = 0; i < archivoObjeto.length; i++) {

        const fila = archivoObjeto[i];
        const nombres = fila["Nombre"];
        const antipassback = fila["Anti-PassBack ( opciones =>  si/no)"];

        // Validación de nombres
        if (nombres === "" || nombres === "-") {
            respuesta = false;
            mensajeError = `Fila ${i+1} campo 'Nombre' vacío`
            break;

        } 
        
        else if (!validarFormatoNombres(nombres)) {
            respuesta = false;
            mensajeError = `Fila ${i+1} campo 'Nombre' con formato inválido`;
            break;
        }

        // Validación de campo Anti-PassBack
        else if (antipassback === "" || antipassback === "-") {
            respuesta = false;
            mensajeError = `Fila ${i+1} campo 'Anti-PassBack' vacío`;
            break;
        } 
        else if (!validarFormatoAntiPassBack(antipassback)) {
            respuesta = false;
            mensajeError = `Fila ${i+1} campo 'Anti-PassBack' con formato inválido`;
            break;
        }
    }
    return [respuesta, mensajeError] // Si pasa todas las validaciones
  };


  export const validarArchivoExcelTarjetas = (archivoObjeto) => {
    let respuesta = true;
    let mensajeError = "";

    for (let i = 0; i < archivoObjeto.length; i++) {
        const fila = archivoObjeto[i];
        const tarjeta = fila["Tipo de Tarjeta (RFID/TAG)"];
        const codigo = fila["Codigo"];

        // Validación de formato de tarjeta
        if (tarjeta === "" || tarjeta === "-") {
          respuesta = false;
          mensajeError = `Fila ${i+1} campo 'Tipo de Tarjeta' vacío`;
          break;
        } 
        else if (!validarFormatoTarjeta(tarjeta)) {
          respuesta = false;
          mensajeError = `Fila ${i+1} campo 'Tipo de Tarjeta' con formato inválido`;
          break;
        }

        // Validación de formato de codigo de tarjeta
        else if (codigo === "" || codigo === "-") {
            respuesta = false;
            mensajeError = `Fila ${i+1} campo 'Codigo' vacío`;
            break;
        } 
        else if (!validarFormatoCodigo(codigo)) {
            respuesta = false;
            mensajeError = `Fila ${i+1} código con formato inválido`;
            break;
        }
    }
    return [respuesta, mensajeError];
  }


  export const validarArchivoExcelUbicaciones = (archivoObjeto, zonas, tipoUbicaciones) => {

    let respuesta = true;
    let mensajeError = "";

    for (let i = 0; i < archivoObjeto.length; i++) {
        const fila = archivoObjeto[i];
        const id_zona = fila["Id Zona"];
        const id_tipo_ubicacion = fila["Id Tipo Ubicacion"];
        const nombre_ubicacion = fila["Nombre de la ubicacion"];
        const piso = fila["Piso"];
        const numeracion = fila["Numeracion"];

        // Validación de id_zona
        if (id_zona === "" || id_zona === "-") {
            respuesta = false;
            mensajeError = `Fila ${i+1} campo 'Id Zona' vacío`;
            break;
        } 
        
        else if(!validarFormatoId(id_zona)){
          respuesta = false;
          mensajeError = `Fila ${i+1} campo 'Id Zona' con formato invalido`;
          break;
        }

        else if(!validarIdZonas(id_zona, zonas)){
          respuesta = false;
          mensajeError = `Fila ${i+1} id de zona no está en la lista de zonas disponibles`;
          break;
        }

        // Validación de formato de id_tipo_ubicacion
        else if (id_tipo_ubicacion === "" || id_tipo_ubicacion === "-") {
            respuesta = false;
            mensajeError = `Fila ${i+1} campo 'Id Tipo Ubicación' vacío`;
            break;
        }

        // Validación de formato de id_tipo_ubicacion
        else if (!validarFormatoId(id_tipo_ubicacion)){
          respuesta = false;
          mensajeError = `Fila ${i+1} campo 'Id Tipo Ubicación' con formato invalido`;
          break;
        }

        // Validación de id_tipo_ubicacion en el sistema
        else if(!validarIdTipoUbicaciones(id_tipo_ubicacion, tipoUbicaciones)){
          respuesta = false;
          mensajeError = `Fila ${i+1} id de tipo de ubicación no esta en la lista de tipos de ubicaciones disponibles`;
          break;
        }

        // Validación de nombre_ubicacion
        else if (nombre_ubicacion === "" || nombre_ubicacion === "-") {
            respuesta = false;
            mensajeError = `Fila ${i+1} campo 'Nombre de la ubiacion' vacío`;
            break;
        } 
        else if (!validarFormatoNombreUbicacion(nombre_ubicacion)) {
            respuesta = false;
            mensajeError = `Fila ${i+1} 'Nombre de la ubiación' con formato inválido`;
            break;
        }

        //Validacion de piso
        else if (piso === "" || piso === "-") {
            respuesta = false;
            mensajeError = `Fila ${i+1} campo 'Piso' vacío`;
            break;
        } 
        else if (!validarFormatoId(piso)) {
            respuesta = false;
            mensajeError = `Fila ${i+1} campo 'Piso' con formato inválido`;
            break;
        }

        // Validación de numeración
        else if (numeracion === "" || numeracion === "-") {
            respuesta = false;
            mensajeError = `Fila ${i+1} campo 'Numeracion' vacío`;
            break;
        } 
        else if (!validarFormatoNumeracion(numeracion)) {
            respuesta = false;
            mensajeError = `Fila ${i+1} campo 'Numeracion' con formato inválido`;
            break;
        }

        else {
          const { tieneDuplicados, ubicacionesRepetidas } = verificarUbicacionesRepetidas(archivoObjeto);
          if (tieneDuplicados) {
            respuesta = false;
            const repetidos = ubicacionesRepetidas.map(ubicacion => ubicacion["Nombre de la ubicacion"]).join(", ");
            mensajeError = `La(s) siguiente(s) ubicacion(es) tiene(n) información repetida: ${repetidos}.`;
            break;
          }
        
        }

    }
    return [respuesta,mensajeError];
  }


  export const validarArchivoExcelInvitados = (archivoObjeto, zonas, tipoUbicaciones) => {
    let respuesta = true;
    let mensajeError = "";
    for (let i = 0; i < archivoObjeto.length; i++) {
        const fila = archivoObjeto[i];
        const rut = fila["RUT"];
        const pasaporte = fila["PASAPORTE"];
        const nombres = fila["NOMBRES"];
        const apellidos = fila["APELLIDOS"];
        const correo = fila["CORREO"];
        const telefono = fila["TELEFONO"];
        const patente = fila["PATENTE"];

      //Validación de RUT y/o pasaporte
      //Si no hay RUT, se debe verificar que exista pasaporte
      if(rut === "" || rut === "-"){
        //Si no hay pasaporte tampoco, entonces el formato es invalida ya que no cuenta con ninguno de los dos datos
        if(pasaporte === "" || pasaporte === "-"){
          mensajeError = `Fila ${i+1} campo 'RUT' y/o 'pasaporte' vacío. \n`;
          respuesta = false;
          break;
        }
        //En caso de que exista el pasaporte, pero tenga un formato inválida, tambien se retorna false
        else if(!validarFormatoPasaporte(pasaporte))
          respuesta = false;
          mensajeError = `Fila ${i+1} pasaporte con formato inválido`;
          break;
      }
      //Si el RUT tiene un formato inválido, se retorna false
      else if(!validateRut(cleanRut(rut))){
        mensajeError = `Fila ${i+1} RUT con formato inválido`;
        respuesta = false;
        break;
      }

      //Validacion de pasaporte
      else if (pasaporte === "" || pasaporte === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'Pasaporte' vacío`;
        break;
      }
      else if (!validarFormatoPasaporte(pasaporte)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} pasaporte con formato inválido`;
        break;
      }
  
      // Validación de nombres
      else if (nombres === "" || nombres === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'Nombres' vacío. \n`;
        break;
      } 
      else if (!validarFormatoNombres(nombres)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} nombres con formato inválido`;
        break;
      }
  
      // Validación de apellidos
      else if (apellidos === "" || apellidos === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'Apellidos' vacío. \n`;
        break;
      } 
      else if (!validarFormatoNombres(apellidos)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} apellidos con formato inválido`;
        break;
      }
  
      // Validación de teléfono
      else if (telefono === "" || telefono === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'Telefono' vacío. \n`;
        break;
      } 
      else if (!validarFormatoPasaporte(telefono)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} teléfono con formato inválido`;
        break;
      }
  
      // Validación de correo
      else if (correo === "" || correo === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'Correo' vacío. \n`;
        break;
      } 
      else if (!validarFormatoEmail(correo)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} correo con formato inválido`;
        break;
      }

      // Validación de patente
      else if (patente === "" || patente === "-") {
        respuesta = false;
        mensajeError = `Fila ${i+1} campo 'Patente' vacío`
        break;
      }
      else if (!validarFormatoPatente(patente)) {
        respuesta = false;
        mensajeError = `Fila ${i+1} patente con formato inválido`;
        break;
      }

    }

    return [respuesta,mensajeError];
  }