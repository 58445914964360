import { Box, IconButton,  Menu, Typography } from '@mui/material';
import { useState } from 'react'
import { CerrarPerfilIcon } from '../../Profile/IconProfile';
import "./MenuNotificaciones.css"; 
import ModalNotificacion from './ModalNotificacion/ModalNotificacion';
import dayjs from 'dayjs';
import { Opciones } from './ModalNotificacion/Opciones/Opciones';
import { TresPuntosIcos } from '../../Calendario/CalendarioIcons';
import { MenuTipoDos } from '../MenuTipoUno';

export const MenuNotificaciones = ({open, anchorEl, handleClose, notificaciones}) => {

    const [anchorElOpciones, setAnchorElOpciones] = useState(null);
    const openOpciones= Boolean(anchorElOpciones);
	const [openNotificacion, setOpenNotificacion] = useState(false);
    const [notificacion, setNotificacion] = useState({});
	

    const handleOpenOpciones = (e, notificacion)  => {
		setNotificacion(notificacion);
		setAnchorElOpciones(e.currentTarget)
    } 

    const handleOpenNotificacion = (notificacion) => () => {
        setNotificacion(notificacion);
        setOpenNotificacion(true);
    }
    
    return (
        <Box>
            <Menu         
            disableRestoreFocus={true}
            aria-disabled
            open={open} 
            anchorEl={anchorEl} 
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
                }}
            onClose={handleClose} 
			slotProps={{ paper: { sx: {borderRadius: "16px", padding:"0px"}} }}
			sx={{ position: "absolute",
                top: "0px",
                left: "-150px",
                right:"0px",
                bottom:"0px", 
                borderRadius: "16px",
				"& .MuiList-root":{padding:"0px"}}}
              >
                <Box id="ContainerMenuNotificaciones">					
                    <Box id="MenuNotificacionHeader">
                        <Box id="BoxTituloNotificacionescomponente">
							<Typography id="TextoTituloNotificaciones">
								{
									"Alertas"
								}
							</Typography>
						</Box>
						<Box id="BoxIconoAccionCierre">
							<IconButton onClick={handleClose}>
								<CerrarPerfilIcon id="CerrarMenuNotificacionesIcon"/>
							</IconButton> 	 	
						</Box>
                    </Box>
                    <Box id="BoxCuerpoNotificaciones">
						<Box id="BoxContenidoNotificaciones">
							<Box id="BoxListaNotificaciones">
								{
									notificaciones.map((item, index) => {
										let esHoy = dayjs(new Date()).date() === dayjs(item.fecha).date() ? "Hoy a las " + dayjs(item.fecha).format("HH:mm") : "El " + dayjs(item.fecha).format("DD/MM/YYYY") + " a las " + dayjs(item.fecha).format("HH:mm:ss") 
										
										return (
											<Box key={index} id="BoxContenidoItemNotificacion">
												<Box id="BoxItemNotificacion" onClick={handleOpenNotificacion(item)}>
													<Box id="TxtMensajeNotificacion">
														{
															item.mensajeNotificacion
														}
													</Box>
													<Typography id="TxtFechaNotificacion">
														{
															esHoy
														}
													</Typography>
												</Box>
												<Box id="BoxIconButtonTresPuntosItemNotificaciones">
													<IconButton onClick={(e) => handleOpenOpciones(e, item)}>
														<TresPuntosIcos id="IconButtonItemNotificaciones" />
													</IconButton>
												</Box>
											</Box>
										)
									})
								}
							</Box>
							<Box id="BoxCantidadNotificaciones">
								<Typography>
									{
										notificaciones.length === 0 ?
										"Sin notificaciones"
										:
										notificaciones.length + " notificacion(es)"
									}
								</Typography>
							</Box>
						</Box>
					</Box>
                </Box>
            </Menu>
            <ModalNotificacion 
            open={openNotificacion} 
            setOpen={setOpenNotificacion} 
            notificacion={notificacion} />
			<MenuTipoDos
            open={openOpciones}
            anchorEl={anchorElOpciones}
            setOpen={setAnchorElOpciones}
            children={<Opciones notificacion={notificacion} />}
            />
        </Box>
    );
}
