import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import useGestZonasEmpresa from "../../../Hooks/Zona/useGetZonasEmpresa";
import { useFormik } from "formik";
import "./ManejadorOpcionesUbicacion.css";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import useTiposUbicaciones from "../../../Hooks/TiposUbicaciones/useTiposUbicaciones";
import { EsquemaNuevaUbicacion } from "./validationSchema";
import useUbicacionesGestion from "../../../Hooks/Ubicaciones/useUbicacionesGestion";
import { useState } from "react";
import { LoadingMasRespuestaCuatro } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import AutocompleteSimple from "../../AutocompleteSimple/AutocompleteSimple";

export const NuevaUbicacion = ({setRows, setOpen}) => {
    const {zonasFormadas} = useGestZonasEmpresa();
    const {tiposUbicaciones} = useTiposUbicaciones();
    const {loading, setLoading, response, GuardarUbicacion } = useUbicacionesGestion();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    
    const AgregarRow = (nuevaId) => {
        var zonaFinded = zonasFormadas.find(z => z.id === formik.values.idZona);
        var tipoFinded = tiposUbicaciones.find(z => z.id === formik.values.idTipoUbicacion);
        setRows((oldRows) => [
            
            ...oldRows,
            {
                idUbicacion: nuevaId,
                idZona: formik.values.idZona,
                nombreZona: zonaFinded.tipo,
                estadoZona: true,
                idTipoUbicacion: formik.values.idTipoUbicacion,
                tipoUbicacion: tipoFinded.tipo,
                nombre: formik.values.nombreUbicacion,
                piso: formik.values.piso,
                numeracion: formik.values.numeracion,
                isNew: true,
            },
        ]);
    }

    const formik = useFormik(
        {
            initialValues: { 
                idZona: 0,
                nombreZona: "Zonas",
                nombreUbicacion: "",
                idTipoUbicacion: -1,
                tipoUbicacion: "Seleccione tipo",
                piso:"",
                numeracion: ""
            },
            validationSchema: EsquemaNuevaUbicacion,
            onSubmit: async () => {
                setLoading(true)
                setOpenLoading(true)
                await GuardarUbicacion(formik.values)
                .then(
                    (resolve) => {
                        AgregarRow(resolve);
                    },
                    (reject) =>{
                        console.log("fallo la creacion de la ubicacion");
                    }
                )
            }
        }
    );

    const handleAgregarOtro = () => {
        formik.resetForm();
    }

    const handlerClose = ()=> {
        setOpen(false);
        formik.resetForm();
    }
    
    return (
        <Box id="BoxAgregarNuevaUbicacion">
            <Box id="BoxTituloSubtituloOpcionNuevaUbicacion">
                <Box id="BoxTituloOpcionNuevaUbicacion">
                    <Typography id="TxtTituloOpcionNuevaUbicacion">
                        {
                            "Agregar ubicación"
                        }
                    </Typography>
                </Box>
                <Box id="BoxSubTituloNuevaUbicacion">
                    <Typography id="TxtSubTituloOpcionNuevaUbicacion">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxFormularioOpcionNuevaUbicacion" >
                <Box id="BoxContenidoFormularioOpcionNuevaUbicacion" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxSegundaInformacionOpcionNuevaUbicacion">
                        <Box id="BoxTituloSegundaInformacionOpcionNuevaUbicacion">
                            <Typography id="TxtTitutlosFormulariosOpcionNuevaUbicacion">
                                {
                                    "Información de zona"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxSegundasOpcionesFormularioNuevaUbicacion">
                            <FormControl fullWidth>
                                <AutocompleteSimple
                                    disablePortal={true}
                                    freeSolo = {true}
                                    listadoElementos={zonasFormadas}
                                    label="Zonas"
                                    onSelectionChange={(newValue) => {
                                        formik.setFieldValue('idZona', newValue ? newValue["id"] : -2);
                                        formik.setFieldValue('nombreZona', newValue ? newValue["tipo"] : '');
                                    }}
                                    keyListadoElementos="id"
                                    mostrarElemento={(option) => option["tipo"] || ''}
                                    elementoSeleccionado={zonasFormadas.find(zonaFormada => formik.values.idZona === zonaFormada.id) || null}
                                />
                                <FormHelperText>
                                    {
                                        formik.errors.idZona
                                    }
                                </FormHelperText>                                   
                            </FormControl>
                        </Box>
                    </Box>
                    <Box id="BoxSegundaInformacionOpcionNuevaUbicacion">
                        <Box id="BoxTituloSegundaInformacionOpcionNuevaUbicacion">
                            <Typography id="TxtTitutlosFormulariosOpcionNuevaUbicacion">
                                {
                                    "Info. de la ubicación"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxFormularioNombreTipoNuevaUbicacion">
                            <Box id="BoxInformacionSobreUbicacion">
                                <Box id="BoxNombreNuevaUbicacion">
                                    <TextFieldTres
                                        id={"nombreUbicacion"}
                                        label={"Nombre de la ubicación"}
                                        placeholder={"residencia A-03"}
                                        icon={null}
                                        helper={formik.errors.nombreUbicacion}
                                        value={formik.values.nombreUbicacion}
                                        name={"nombreUbicacion"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                    />
                                </Box>
                                <Box id="BoxTipoNuevaUbicacion">
                                    <FormControl fullWidth>
                                        <AutocompleteSimple
                                            disablePortal={true}
                                            freeSolo = {true}
                                            listadoElementos={tiposUbicaciones}
                                            label="Zonas"
                                            onSelectionChange={(newValue) => {
                                                if(newValue === null)
                                                {
                                                    formik.setFieldValue("idTipoUbicacion", -2)                                           
                                                }
                                                else{
                                                    formik.setFieldValue("idTipoUbicacion", newValue.id)
                                                }
                                            }}
                                            keyListadoElementos="id"
                                            mostrarElemento={(option) => option["tipo"] || ''}
                                            elementoSeleccionado={tiposUbicaciones.find(ubicacion => formik.values.idTipoUbicacion === ubicacion.id) || null}
                                        />
                                        <FormHelperText>
                                            {
                                                formik.errors.idTipoUbicacion
                                            }
                                        </FormHelperText>                                   
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box id="BoxInformacionSobreUbicacion">
                                <Box id="BoxNombreNuevaUbicacion">
                                    <TextFieldTres
                                        id={"piso"}
                                        label={"Piso"}
                                        placeholder={"1"}
                                        icon={null}
                                        helper={formik.errors.piso}
                                        value={formik.values.piso}
                                        name={"piso"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                    />
                                </Box>
                                <Box id="BoxTipoNuevaUbicacion">
                                    <TextFieldTres
                                        id={"numeracion"}
                                        label={"Numeración"}
                                        placeholder={"101"}
                                        icon={null}
                                        helper={formik.errors.numeracion}
                                        value={formik.values.numeracion}
                                        name={"numeracion"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxHandlerFormularioOpcionNuevaUbicacion">
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                    </Box>
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} disabled={loading} />
                        <LoadingMasRespuestaCuatro
                            id={"LoadingMasRespuestaDosNuevoActuador"}
                            open={openLoading}
                            setOpen={setOpenLoading}
                            msj={
                                "Se está agregando una nueva ubicacion al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."
                            }
                            tituloLoading={"Agregando ubicacion..."}
                            loading={loading}
                            respuesta={response}
                            titulo={
                                response === 201 ? 
                                "Ubicacion agregada"
                                : 
                                "Ubicacion no agregada"
                            }
                            mensajeRespuesta={
                                response === 201
                                ? "La Ubicacion ha sido agregado exitosamente al sistema. Ahora puedes agregar miembros a este destino"
                                : response === 403 ?
                                    "No se ha podido generar la solicitud de creacion, ya que no cuenta con el perfil requerido, contacte al administrador del sistema"
                                    :
                                    "No se ha podido agregar la ubicacion debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia"
                            }
                            txtBotonUno={"Agregar nueva ubicacion"}
                            eventBotonUno={handleAgregarOtro}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}