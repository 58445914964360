import { AccesosTablaGU } from "./TablaAccesos";
import { LayoutMenuOpcion } from "../../../../Box/OpcionMenuGestionUsuario/LayoutMenuGU";
import { useEffect, useState } from "react";
import { NuevoAcceso } from "./NuevoAcceso";
import { NoData } from "../../../../Box/NoDataMenuGestionUsuario/NoData";
import useAccesosUsuario from "../../../../../Hooks/Acceso/useAccesosUsuario";
import useGestZonasEmpresa from "../../../../../Hooks/Zona/useGetZonasEmpresa";

export const GestionUsuariosAccesos = ({userData}) =>{
    const [crear, setCrear] = useState(Boolean(false));
    const { accesos } = useAccesosUsuario(userData.idPersona);
    const { zonasFormadas } = useGestZonasEmpresa();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(accesos);
    }, [accesos])
    

    return (
        crear ? 
        <NuevoAcceso setCrearOpen={setCrear} setRows={setRows} userData={userData} zonas={zonasFormadas}/>
        :
        <LayoutMenuOpcion titulo={"Accesos"} showIcon={true} handleIcon={setCrear} >
            {
                rows.length === 0 ?
                <NoData 
                mensaje={"Actualmente, no hay accesos a zonas asignados a este usuario. Definir accesos específicos facilitará la gestión y el control de entradas a áreas restringidas.Utiliza el botón “Agregar acceso” para comenzar"}
                textoBtn={"Agregar acceso"}
                event={setCrear}
                /> 
                :
                <AccesosTablaGU dataAccesos={rows} setRows={setRows}/>
            }
        </LayoutMenuOpcion>
    );
}