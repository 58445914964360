import axios from "axios";
import { useEffect, useState } from "react"
import { TiposPersonaEmpresa } from "../../Service/API/Api";

export function useTiposUsuario(cargarDatos = true){
    const [tipos, setTipos] = useState([]);
    const [recargar, setRecargar] = useState(false);

    async function ObtenerTipos(){
        var response = await axios.get(TiposPersonaEmpresa)
        
        let list = []
        var valor0 ={
            idTipoPersona: -1,
            tipo: "Seleccione"
        }
        list.push(valor0)
        response.data.forEach(element => {
            list.push(element);
        });

        return list;
    }

    useEffect(() => {
        if(cargarDatos){
            ObtenerTipos().then(lista =>  {
                setTipos(lista);
            })
        }
        return () => setRecargar(false) 
    }, [recargar, cargarDatos])
    

    return {
        tipos,
    }
}