import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { LayoutCrearNuevo } from "../../../../Box/CrearNuevoMenuGestionUsuario/LayoutCrearNuevo";
import "./Horario.css"
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import { SeleccionFecha } from "../../../../DatePicker/DatePicker";
import { useFormik } from "formik";
import { ValidationNuevaAsignacion } from "./Validation";
import dayjs from "dayjs";
import { LoadingMasRespuestaCuatro } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";
import { useState } from "react";
import useAsignaciones from "../../../../../Hooks/Asignaciones/useAsignaciones";


export const AsignarHorario = ({setOpenAsignar, horariosAsignar, setRows, user}) =>{
    const {loading, setLoading, response, CrearNuevaAsignacion} = useAsignaciones();
    const [openLoading, setOpenLoading] = useState(Boolean(false));

    const handleClose = () => {
        setOpenAsignar(false);
    }

    const handleOtro = () =>{
        formik.setFieldValue("idHorario", '');
        formik.setFieldValue("inicio", '');
        formik.setFieldValue("termino", '');
        setOpenLoading(false);
    }

    const AgregarRow = async (values, nuevaId) => {
        let horario = horariosAsignar.find(h => h.idHorario === values.idHorario);
        setRows((oldRows) => [...oldRows, 
            {
                idAsignacion: nuevaId, 
                nombreHorario: horario.nombreHorario,
                inicio: values.inicio,
                termino: values.termino,
                estado: true,
                isNew: true 
            }
        ]);

        setTimeout(() => {
            handleClose()
            setOpenLoading(false);
        }, 3000);
    }


    const formik = useFormik({
        initialValues: {idHorario:'', inicio: dayjs(Date.now()), termino: dayjs(Date.now())},
        validationSchema:ValidationNuevaAsignacion,
        onSubmit: async () =>{
            setLoading(true);
            setOpenLoading(true);
            await CrearNuevaAsignacion(formik.values, user)
            .then(
                (resolve) => {
                    AgregarRow(formik.values, resolve);
                }
                ,
                (reject) => {
                    console.log(reject);
                }
            )
        }
    })

    return(
        <LayoutCrearNuevo titulo={"Asignar Horarios"}>
            <Box id="BoxLayoutAsignarHorarioGU">
                <Box id="BoxTituloSeleccionAsignacionHorarioGU">
                    <Box id="BoxTituloAsignacionHorarioGU">
                        <Typography id="TxtTituloAsignacionHorarioGU">
                            {
                                "Asignación de horario"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxSeleccionAsignacionHorarioGU" component={"form"} onSubmit={formik.handleSubmit}>
                        <Box id="BoxSelectHorarioAsignacionGU">
                            <FormControl fullWidth>
                                <InputLabel id="LblHorario">
                                    {
                                        "Horarios Disponibles"
                                    }
                                </InputLabel>
                                <Select 
                                fullWidth 
                                variant="outlined"
                                name="idHorario"
                                labelId="LblHorario"
                                label="Horarios Disponibles"
                                value={formik.values.idHorario}
                                onChange={formik.handleChange}
                                MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        maxHeight: '280px',
                                        overflowY: 'auto',
                                        width: '220px',
                          
                                        // Estilos para WebKit (Chrome, Safari, Edge) en el Paper (menú desplegable)
                                        '&::-webkit-scrollbar': {
                                          width: '8.024px !important',
                                          background: 'transparent !important',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                          background: 'transparent !important',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                          background: '#C9C9C9',
                                          borderRadius: '100px',
                                        },
                                      },
                                    },
                                }}
                                >
                                {
                                    horariosAsignar.map((item, index) => {
                                    return (
                                        <MenuItem disableRipple key={index} value={item.idHorario} id="MiZonaGU" >
                                            <Box id="BoxZonaGU">
                                                <Typography>
                                                    {
                                                        item.nombreHorario
                                                    }
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                        );
                                    })
                                }      
                                </Select>
                                <FormHelperText>
                                    {
                                        formik.errors.idHorario
                                    }
                                </FormHelperText>
                            </FormControl>
                        </Box>
                        <Box id="BoxFechaInicioTerminoAsignacionHorarioGU">
                            <Box id="BoxFechaInicioAsignacionHorarioGU">
                                <FormControl fullWidth>
                                    <SeleccionFecha 
                                    valueSelected={formik.values.inicio}
                                    handleDate={(value)=> formik.setFieldValue("inicio", value)}
                                    labelName={"Fecha de Inicio"}
                                    name={"inicio"}
                                    variante={"out"}
                                    />
                                    <FormHelperText>
                                        {
                                            formik.errors.inicio
                                        }
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                            <Box id="BoxFechaTerminoAsignacionHorarioGU">
                                <FormControl fullWidth>
                                    <SeleccionFecha 
                                    valueSelected={formik.values.termino}
                                    handleDate={(value)=> formik.setFieldValue("termino", value)}
                                    labelName={"Fecha de Termino"}
                                    name={"termino"}
                                    variante={"out"}
                                    />
                                    <FormHelperText>
                                        {
                                            formik.errors.termino
                                        }
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxAccionesAsignarHorarioGU">
                    <Box id="BoxBtnCancelarAsignarHorarioGU">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                    </Box>
                    <Box id="BoxBtnAsignarAsignarHorarioGU">
                        <BotonTipo2 textoBoton={"Asignar"} event={formik.submitForm} />
                        <LoadingMasRespuestaCuatro
                        id={"LoadingMasRespuestaCuatroNuevaAsignacion"}
                        open={openLoading}
                        setOpen={setOpenLoading}
                        msj={"Se está asignando el horario al usuario. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                        tituloLoading={"Agregando Horario..."}
                        loading={loading}
                        respuesta={response}
                        titulo={response === 201 ? "Horario agregado":"Horario no agregado"}
                        mensajeRespuesta={response === 201 ? 
                            "El nuevo Horario ha sido agregado exitosamente. Ahora puedes gestionar los accesos o agregar otro método."
                            :
                            "No se ha podido agregar el Horario debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
                        txtBotonUno={"Agregar otro Acceso"}
                        txtBotonDos={null}
                        eventBotonUno={handleOtro}
                        eventoBotonDos={null} 
                        />
                    </Box>
                </Box>
            </Box>
        </LayoutCrearNuevo>
    );
}