import { Box, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { ModalInvitar } from "../../../../Eventos/Invitados/Invitar";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../../../Usuarios/UsuariosIcon";
import TooltipTipoUno from "../../../../ToolTip/Tooltip";
import { LoadingMasRespuestaCinco } from "../../../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../../../Modal/RespuestaServidor/RespuestaServidorIcon";
import useEventos from "../../../../../Hooks/Eventos/useEventos";
import { PopOverNuevoEvento } from "../../../../Popover/PopoverTipoUno";
import EditarEvento from "../../../../Eventos/NuevoEvento/EditarEvento";

export const MenuOpcionesEvento = ({idVisita, estadoEvento, dataEvento}) => {
    const [openInvitaciones, setOpenInvitaciones] = useState(false);
    
    const { DesactivarEvento, loading, setLoading, response } = useEventos();
    const [openLoading, setOpenLoading] = useState(false);
    const [anchorElEdit, setAnchorElEdit] = useState(false);
    const [ConfirmationDialog, confirm ]= useConfirm();
    const HandleOpenInvitaciones = () => {
        setOpenInvitaciones(true)
    }

    const openEdit = Boolean(anchorElEdit);

    const handleOpenEditar = (e) => {
        setAnchorElEdit(e.currentTarget)
    }

    const HandlerConfirmacionDesactivacion = async () => {
        var response = await confirm();
        if(response)
        {
            setLoading(true);
            setOpenLoading(true);
            await DesactivarEvento(idVisita)
            .then(
                (resolve) => {
                    //actualizar la row.
                }
                ,
                (resolve) => {
                    console.log("fallo la desactivacion");                    
                }
            )
            //ejecutamos la desactivacion del evento
        }
        else
        {
            console.log("Rechaza la modificacion");
            
        }
    }

    return (
        <Box >
            <MenuItem onClick={HandleOpenInvitaciones}>
                <Typography>
                    {
                        "Gestionar invitados"
                    }
                </Typography>
            </MenuItem>
            <MenuItem disabled={dataEvento.idTipoVisita === 2 ? true:false} onClick={(e) => handleOpenEditar(e)}>
                <Typography>
                    {
                        "Editar"
                    }
                </Typography>
            </MenuItem>
            <MenuItem disabled={estadoEvento ? false:true} onClick={estadoEvento ? HandlerConfirmacionDesactivacion:null}>
                <TooltipTipoUno ubicacion={"right"} titulo={estadoEvento === false ? "Este evento ya se encuentra finalizado":null}>
                    <Typography>
                        {
                            "Desactivar evento"
                        }
                    </Typography>
                </TooltipTipoUno>
            </MenuItem> 
            <ModalInvitar openInvitacion={openInvitaciones} setOpenInvitacion={setOpenInvitaciones} idVisita={idVisita} />           
            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={"¿Desactivar evento?"}
            mensaje={"Estás a punto de desactivar este evento. Esto afectara el acceso de los invitados a este evento. ¿Deseas continuar?"}
            />
            <LoadingMasRespuestaCinco
            open={openLoading} 
            setOpen={setOpenLoading} 
            msj={"Se está desactivando el evento. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
            id={"RespuestaDesactivarEvento"} 
            tituloLoading={"Desactivando evento"}
            loading={loading} 
            respuesta={response} 
            icono={
                response === 200 ?
                <IconoExito id="IconoRespuestaServidor" />
                :
                <IconoFallo id="IconoRespuestaServidor" />
            }
            textoBoton={
                response === 200 ? 
                "Aceptar"
                :
                "Reintentar"
            }
            titulo={response === 200 ? "Evento desactivado":"Error al desactivar"}
            mensajeRespuesta={
                typeof response === 'string' ?            
                    response 
                :    
                    response === 200 ? 
                        "El evento se ha desactivado exitosamente"
                    :
                        "Ha ocurrido un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
            }
            />

            <PopOverNuevoEvento 
            open={openEdit} 
            anchorEl={anchorElEdit} 
            setOpen={setAnchorElEdit}
            children={<EditarEvento setOpen={setAnchorElEdit} dataEvento={dataEvento}/>}/>
        </Box>
    );
}