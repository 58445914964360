import axios from "axios";
import { useEffect, useState } from "react";
import { PersonaEstacionamientoEmpresa, CrearEstacionamiento, EditarEstacionamiento, EliminarPersonaEstacionamiento, CrearPersonaEstacionamiento, EditarPersonaEstacionamiento, DesactivarEstacionamiento, ReactivarEstacionamiento } from "../../Service/API/Api";

export default function useGetEstacionamientos(){
    const [loading, setLoading] = useState();
    const [data, setData] = useState([]);
    const [response, setResponse] = useState();

    useEffect(() => {
        axios.get(PersonaEstacionamientoEmpresa)
        .then(
            (response) => {
                setLoading(true);
                setData(response.data);
                setLoading(false);
            }
        ).catch(
            (error) => {
                console.error("Error al obtener la información de estacionamientos");
                setLoading(false);
            }
        )
    }, [])


    const CreateEstacionamiento = (values) => {
        return new Promise(async (resolve, reject) => {
            let nuevoEstacionamiento = {
                "idZona": values.idZonaEstacionamiento,
                "numero": values.nombreEstacionamiento,
            }

            await axios.post(CrearEstacionamiento, nuevoEstacionamiento )
            .then(
                (response) =>{
                    resolve(response.data);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }


    const EditEstacionamiento = (values, idEstacionamiento) => {
        return new Promise(async (resolve, reject) => {
            const editData = {
                "idEstacionamiento": idEstacionamiento,
                "idZona": values.idZonaEstacionamiento,
                "numero": values.nombreEstacionamiento
            }
            await axios.put(EditarEstacionamiento, editData)
            .then(
                (response) =>{
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }

    const EliminarAsociacion = (idPersonaEstacionamiento) => {
        return new Promise(async (resolve, reject) => { 
            await axios.delete(EliminarPersonaEstacionamiento + idPersonaEstacionamiento)
            .then(
                (response) =>{
                    resolve(true);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }


    const NuevaAsociacion = (values, idEstacionamiento) => {
        return new Promise(async (resolve, reject) => { 
            let nueva = {
                "idEstacionamiento": idEstacionamiento,
                "idPersona": values.idPersona < 0 ? null : values.idPersona,
                "idVisitante": values.idVisitante < 0 ? null : values.idVisitante,
            }
            console.log(nueva);
            await axios.post(CrearPersonaEstacionamiento, nueva)
            .then(
                (response) =>{
                    resolve(response.data);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }

    const EditarAsociacion = (idPersonaEstacionamiento, idEstacionamiento, values) => {
        return new Promise(async (resolve, reject) => { 
            let editarAsignacion = {
                "idPersonaEstacionamiento": idPersonaEstacionamiento,
                "idEstacionamiento": idEstacionamiento,
                "idPersona": values.idPersona < 0 ? null : values.idPersona,
                "idVisitante": values.idVisitante < 0 ? null : values.idVisitante,
            }
            await axios.put(EditarPersonaEstacionamiento, editarAsignacion)
            .then(
                (response) =>{
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }

    const HandleEstadoEstacionamiento = (idEstacinamiento, estadoActual) => {
        return new Promise(async (resolve, reject) => {             
            await axios.patch(estadoActual === true ? DesactivarEstacionamiento + idEstacinamiento : ReactivarEstacionamiento + idEstacinamiento)
            .then(
                (response) =>{
                    resolve(true);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }
    

    return{
        loading,
        data,
        setLoading,
        response,
        CreateEstacionamiento,
        EditEstacionamiento,
        EliminarAsociacion,
        NuevaAsociacion,
        EditarAsociacion,
        HandleEstadoEstacionamiento
    }
}