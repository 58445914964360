import { Box, IconButton, Popover, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "./PopoverBoton.css";
import { ObtenerIconoAsociado } from '../Botones';
import dayjs from 'dayjs';
import TooltipTipoUno from '../../ToolTip/Tooltip';
import { SwitchTablas } from '../../Switch/switch';
import useConfirm from '../../../Hooks/ConfirmDialog/useConfirmDialog';
import { SnackBarTipoUno } from '../../snackBar/SnackBar';
import { IconoAlerta } from '../../Usuarios/UsuariosIcon';
import { useDispatch, useSelector } from 'react-redux';
import { actualizarFiltroTiempoReal } from '../../../store/misSlice';
import { dispositivoReportadoHoy } from './funcionesPopoverBoton';

export const PopoverBoton = ({open, anchorEl, handlePopoverClose, botonSeleccionado, handleOpenCard, actuadores}) => {

    const [actuadorSeleccionado, setActuadorSeleccionado] = useState();

    const [filtroSeleccionado, setFiltroSeleccionado] = useState();

    //Estado controlado por Redux para filtrar los accesos en tiempo real
    const filtroTiempoReal = useSelector((state) => state.filtroTiempoReal.listadoFiltros);

    const dispatch = useDispatch();

    // Este es el listado de ids de tipos de actuadores que se le aplicará la posibilidad de activar o desactivar el filtrado en tiempo real
    // de los accesos
    const idsTiposActuadoresAccesos = useSelector(
        (state) => state.filtroTiempoReal.idsTiposActuadoresAccesos
    );

    // useEffect para actualizar el estado de filtros en tiempo real cuando se cambia el filtro en el Popover
    useEffect(() => {
        setActuadorSeleccionado(actuadores?.find(actuador => actuador.idActor === botonSeleccionado.idActor))
        setFiltroSeleccionado(filtroTiempoReal?.find(filtro => filtro.idActor === botonSeleccionado.idActor));
    }, [actuadores, botonSeleccionado, filtroTiempoReal]);

    //Variables para manejar el confirm dialog y el snackbar a la hora de cambiar el valor del switch en un acceso desde el Popover
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [snackMsg, setSnackMsg] = useState('');
    const [dialogText, setDialogText] = useState({titulo: '', mensaje: ''});
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const handleEstado = () => async () => {
        if(filtroSeleccionado.filtroActivado === true){
            setDialogText(
                {
                    titulo: "¿Desactivar Filtrado?",
                    mensaje: "Estás a punto de desactivar el filtro de tiempo real para este actuador. ¿Deseas continuar?"
                }
            );
        }
        else
        {
            setDialogText(
                {
                    titulo: "¿Activar Filtrado?",
                    mensaje: "Estás a punto de activar el filtro de tiempo real para este actuador. ¿Deseas continuar?"
                }
            );
        }

        const responseConfirm = await confirm();

        if(responseConfirm === true){

            // Se crea una copia del filtro seleccionado con filtroActivado invertido
            const filtroActualizado = {
                ...filtroSeleccionado,
                filtroActivado:!filtroSeleccionado.filtroActivado
            }

            // Se crea un nuevo arreglo con los filtros actualizados, se actualiza el filtro cambiado.
            const arregloFiltrosActualizados = filtroTiempoReal.map(filtro =>
                filtro.idActor === filtroActualizado.idActor ? filtroActualizado : filtro
            );

            //Se modifica el valor del filtro en tiempo real con los valores actualizados
            setFiltroSeleccionado(filtroActualizado);
            dispatch(actualizarFiltroTiempoReal(arregloFiltrosActualizados));

            //Se muestra un mensaje de que la operación se realizó con éxito
            setSnackbarSeverity("success")
            setSnackMsg("Acción realizada con éxito!");
            setSnackbar(true);

        }
    }//fin handleEstado


  return (
    <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        slotProps={{paper:
            {sx:{
                boxShadow: "none",
                borderRadius: "16px",
                width: "350px",
                padding: "20px",
                backgroundColor: actuadorSeleccionado
                    ? (actuadorSeleccionado.fechaUltimaConexion === null || actuadorSeleccionado.fechaUltimaConexion === undefined || actuadorSeleccionado.estado === false || !dispositivoReportadoHoy(actuadorSeleccionado.fechaUltimaConexion)
                        ? "rgba(255, 0, 0, 0.8)"
                        : "rgba(23, 86, 118, 0.8)")
                    : "rgba(255, 0, 0, 0.8)",
            }}}}
    >
        <Box id="BoxContenidoPopover">
            <Box id="HeaderPopover">
                <Typography id="TituloHeader">{actuadorSeleccionado?.nombre}</Typography>
                {
                    //En caso de tratarse de una cámara
                    (actuadorSeleccionado?.idTipoActor === 1 || actuadorSeleccionado?.idTipoActor === 2 || actuadorSeleccionado?.idTipoActor === 14) ? (
                        <TooltipTipoUno titulo={"Ver Cámara"} ubicacion={"bottom"} >
                            <IconButton onClick={handleOpenCard}>
                                {ObtenerIconoAsociado(actuadorSeleccionado.idTipoActor)}
                            </IconButton>
                        </TooltipTipoUno>

                        //En caso de tratarse de un acceso
                    // ) : ((botonSeleccionado.idTipoActor === 3 || botonSeleccionado.idTipoActor === 6 || botonSeleccionado.idTipoActor === 7 || botonSeleccionado.idTipoActor === 8 || botonSeleccionado.idTipoActor === 11 || botonSeleccionado.idTipoActor === 12 || botonSeleccionado.idTipoActor === 13 || botonSeleccionado.idTipoActor === 15) && (actuadorSeleccionado !== undefined) && (filtroSeleccionado !== undefined)) ? (
                    ) : (idsTiposActuadoresAccesos?.includes(actuadorSeleccionado?.idTipoActor)) ? (

                            <SwitchTablas
                                id={""}
                                value={ filtroSeleccionado?.filtroActivado || false }
                                handler={handleEstado()}
                                disable={false}
                                mensajeSwitchActivado='Filtro activado'
                                mensajeSwitchDesactivado='Filtro desactivado'
                            />

                    //     //En caso de tratarse de una luz
                    // ) : (botonSeleccionado.idTipoActor === 14) ? (
                    //     <h4>Switch Luz</h4>

                        //En caso de ser un sensor u otra cosa no se pone nada
                    ) : null
                }
            </Box>
            <Typography id="Descripcion">{actuadorSeleccionado?.tipoActor}</Typography>
            <Typography id="Descripcion">{actuadorSeleccionado?.nombreZona}</Typography>
            <Typography id="UltimaConexion">
                Última conexión: {actuadorSeleccionado ?
                    (actuadorSeleccionado?.fechaUltimaConexion === null
                        ? "No reportada"
                        : `Fecha: ${dayjs(actuadorSeleccionado?.fechaUltimaConexion).format('DD/MM/YYYY')} | Hora: ${dayjs(actuadorSeleccionado.fechaUltimaConexion).format('HH:mm')}`
                ) : null}
            </Typography>
            <Typography id="UltimaConexion">
                {actuadorSeleccionado?
                    actuadorSeleccionado.fechaUltimaConexion === null || actuadorSeleccionado.fechaUltimaConexion === undefined ?
                        null
                    :
                        dispositivoReportadoHoy(actuadorSeleccionado.fechaUltimaConexion)?
                        "Dispositivo reportado hoy"
                        :
                        "Dispositivo no reportado hoy"
                :
                    null

               }
            </Typography>

        </Box>
        
        <SnackBarTipoUno
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
        />

        <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={dialogText.titulo}
            mensaje={dialogText.mensaje}
        />
    </Popover>
  )
}