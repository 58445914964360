import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

/* Componente Select de MaterialUI perzonalizado para ser re utilizado en otras partes. */
/* Props Entrada: 

- label: Este será el label que tendrá el Select a la hora de cargarse la primera vez.

- hanldeChange: Función que se ejecutará en caso de detectar un cambio o un valor seleccionado del select.

- listadoElementos: arreglo de objetos que será lo que se va a recorrer para mostrar los items del select.

- keyListadoElementos: atributo del arreglo de objetos que funcionará como key o id para recorrerlo y mostrar las opciones.

- atributoMostrable: atributo del objeto que se mostrará en el Select.

- atributoValue: atributo del objeto que se usará como valor del Select una vez se selecciona una opción. Puede ser igual al atributo mostrable como no, eso depende del objetivo de la persona que utilice el componente.

- elementoSeleccionado: valor seleccionado en el Select, por defecto es null. Esto deberia ser un estado que se gestiona por parte del componente Padre que invoca al Select.

*/

const SelectMui = ({ name = "", label, handleChange, listadoElementos, keyListadoElementos, atributoMostrable, atributoValue, elementoSeleccionado }) => {
    return (
        <Box sx={{ minWidth: 150 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    name={name}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={elementoSeleccionado}
                    label={label}
                    onChange={handleChange}
                >
                    {listadoElementos.map((item) => (
                        <MenuItem key={item[keyListadoElementos]} value={item[atributoValue]}>
                            {item[atributoMostrable]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default SelectMui;
