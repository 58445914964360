import { Box, CardMedia, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./Live.css";
import useGestionCamaras from "../../Hooks/GestionCamaras/useGestionCamaras";
import TooltipTipoUno from "../ToolTip/Tooltip";

const NoCamara = ({titulo}) => {
    return(
        <Box id="BoxNoCamaraLiveStream">
            <Typography id="TextoNoCamaraLiveStream">
                {
                    "Camara: " + titulo  +" no disponible..."
                }
            </Typography>
        </Box>
    );
}


export const LiveStreamCamaras = () => {
    const { data } = useGestionCamaras();
    const [ camaras, setCamaras ] = useState([]);

    useEffect(() => {
        setCamaras(data);
    }, [data])
    
    return(
        <Box id="BoxLiveCamaras">
            <Box id="BoxLayoutCamarasLiveStream">
                <Grid id="GridCamarasLiveStream" container  >
                {
                    camaras.length > 0 ?
                    camaras.map((cam, index) => {

                        return (
                            <Grid item key={index} id="BoxContenedorLiveStreamCamaras">
                                <ReproductorComponent camData={cam} />
                            </Grid>
                        );
                    })
                    :
                    <Typography>
                        {
                            "No se han encontrado camaras..."
                        }
                    </Typography>
                }
                </Grid>
            </Box>
        </Box>
    );
}

export const ReproductorComponent = ({ camData, setImagenCargada = ()=>{} }) => {
    const [failLoad, setFailLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Nuevo estado para la carga

    useEffect(() => {
      setImagenCargada(isLoading);
    }, [isLoading, setImagenCargada])

    const handleFail = () => {
        setFailLoad(true);
        setIsLoading(false);
    };

    const handleLoad = () => {
        setIsLoading(false); // La imagen ha cargado, con o sin error, detener la carga
    };

    return (
        failLoad ? (
                <NoCamara titulo={camData.nombreCamara} />
        ) : (
            <>
                {isLoading && (
                    <TooltipTipoUno titulo={camData.nombreCamara} ubicacion={"bottom"}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: "100%",
                                width: "520px",
                            }}
                        >
                            <CircularProgress />
                            <Typography ml={2}>Cargando...</Typography>
                        </Box>
                    </TooltipTipoUno>
                )}
                <TooltipTipoUno titulo={camData.nombreCamara} ubicacion={"bottom"}>
                    <CardMedia
                        onError={handleFail}
                        onLoad={handleLoad}
                        component="img"
                        src={camData.url}
                        sx={{ display: isLoading ? 'none' : 'block'}} // Esconder la imagen mientras carga
                    />
                </TooltipTipoUno>
            </>
        )
    );
};