import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import "./ModalGestionActuador.css"
import { useFormik } from "formik";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import { TextFieldCuatroMask } from "../../TextField/TextFieldCuatro";
import { EsquemaNuevoActuador } from "./schemasGestionOpcionesActuadores";
import { TextMaskCustomMAC } from "../../../Helper/Mask";
import useActuadores from "../../../Hooks/Actuadores/useActuador";
import { LoadingMasRespuestaCuatro } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { useState } from "react";
import { useSelector } from "react-redux";

const sentidos = [
    { id: 0 , tipo: "Entrada"},
    { id: 1 , tipo: "Salida"},
]


export const AgregarNuevo = ({ setRows, handler }) => {
    const zonasFormadas = useSelector((state) => state.zonasFormadasRegistradas.listadoZonasFormadas);
    const tipoActuadores = useSelector((state)=> state.tipoActuadores.listadoTipoActuadores);
    const [openLoading, setOpenLoading] =  useState(Boolean(false));
    const { CrearNuevo, loading, response, setLoading } = useActuadores();
    const handlerClose = () => {
        handler(false);
        formik.resetForm();
    }

    const AgregarNuevaRow = async (nuevaId, values) => {
        const tipoActorFiltrado = tipoActuadores.find(t => t.id === formik.values.idTipoActuador);
        const sentidoFiltrado = sentidos.find(s => s.id === formik.values.idSentido);
        const zonafiltrada = zonasFormadas.find(z => z.id === formik.values.idZona);

        setRows((oldRows) => [    
            ...oldRows,
            {
              idActor: nuevaId,
              nombre: values.nombreActuador,
              idTipoActor: values.idTipoActuador,
              tipoActor: tipoActorFiltrado.tipo,
              idSentido: values.idSentido,
              sentido: sentidoFiltrado.tipo,
              fechaActualizacion: Date.now(),
              mac: values.mac.toUpperCase(),
              serial: values.serial,
              clave: values.clave === "" ? "-" : formik.values.clave,
              ip: values.ip,
              puerto: values.puerto,
              idZona: values.idZona,
              nombreZona: zonafiltrada.tipo,
              remoto: false,
              estado: true,
              isNew: true,
            },
        ]);
    }

    const handleAgregarOtro = ()=> {
        formik.resetForm();
    }

    const formik = useFormik({
        initialValues: { 
            nombreActuador: "", 
            idTipoActuador: 0, 
            idSentido: 0, 
            mac: "", 
            serial: "", 
            clave:"", 
            ip:"", 
            puerto: "", 
            idZona: 0 
        },
        validationSchema: EsquemaNuevoActuador,
        onSubmit: async () => {
            setLoading(true);
            setOpenLoading(true);
           
            CrearNuevo(formik.values)
            .then(
                (resolve) => {
                    AgregarNuevaRow(resolve, formik.values);
                }
                ,
                (reject) => {
                    console.log(reject)
                }
            )
        }
    })

    return(
        <Box id="BoxAgregarNuevoActuador">
            <Box id="BoxTituloSubtituloOpcionGestionActuador">
                <Box id="BoxTituloOpcionGestionActuador">
                    <Typography id="TxtTituloOpcionGestionActuador">
                        {
                            "Agregar actuador"
                        }
                    </Typography>
                </Box>
                <Box id="BoxSubTituloOpcionGestion">
                    <Typography id="TxtSubTituloOpcionGestionActuador">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxFormularioOpcionGestionActuador" >
                <Box id="BoxContenidoFormularioOpcionGestionActuador" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxInformacionOpcionGestionActuador">
                        <Box id="BoxTituloInformacionOpcionGestionActuador">
                            <Typography id="TxtTitutlosFormulariosOpcionGestionActuador">
                                {
                                    "Información de actuador"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxOpcionesFormularioGestionActuador">
                            <Box id="BoxTextFieldOpcionesFormularioGestionActuador">
                                <Box id="BoxOpcionTextFielFormularioGestionActuador">
                                <TextFieldTres
                                    id={"nombreActuador"}
                                    label={"Nombre"}
                                    placeholder={"Porton entrada A"}
                                    icon={null                                    }
                                    helper={formik.errors.nombreActuador}
                                    value={formik.values.nombreActuador}
                                    name={"nombreActuador"}
                                    handlerChange={formik.handleChange}
                                    handlerClick={null} 
                                    disabled={false}
                                />
                                </Box>
                                <Box id="BoxOpcionTextFielFormularioGestionActuador">
                                    <FormControl fullWidth sx={{borderBottom:"2px solid #8A8A8A"}}>
                                        <Select 
                                        id={"idTipoActuador"}
                                        disableUnderline 
                                        variant="filled" 
                                        fullWidth
                                        name={"idTipoActuador"}
                                        value={formik.values.idTipoActuador}
                                        onChange={formik.handleChange}
                                        MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                maxHeight: '300px',
                                                overflowY: 'auto',
                                                width: '220px',

                                                // Estilos para WebKit (Chrome, Safari, Edge) en el Paper (menú desplegable)
                                                '&::-webkit-scrollbar': {
                                                    width: '8.024px !important',
                                                    background: 'transparent !important',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                    background: 'transparent !important',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    background: '#C9C9C9',
                                                    borderRadius: '100px',
                                                },
                                              },
                                            },
                                        }}
                                        >
                                            {
                                                tipoActuadores.length > 0 ?
                                                tipoActuadores.map((item, index) => {
                                                    return(
                                                        <MenuItem
                                                        disableRipple
                                                        key={index}
                                                        value={item.id}
                                                        id=""
                                                        >
                                                        <Box id="">
                                                            <Typography>{item.tipo}</Typography>
                                                        </Box>
                                                        </MenuItem>
                                                    )
                                                })
                                                :
                                                <MenuItem
                                                disableRipple
                                                key={0}
                                                value={0}
                                                >
                                                    <Typography>{"obteniendo..."}</Typography>
                                                </MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box id="BoxTextFieldOpcionesFormularioGestionActuador">
                                <Box id="BoxOpcionTextFielFormularioGestionActuador">
                                    <FormControl fullWidth sx={{borderBottom:"2px solid #8A8A8A"}}>
                                        <Select 
                                        disableUnderline 
                                        sx={{height:"56px"}} 
                                        variant="filled" 
                                        fullWidth
                                        id={"idSentido"}
                                        name={"idSentido"}
                                        value={formik.values.idSentido}
                                        onChange={formik.handleChange}
                                        MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                // Estilos para WebKit (Chrome, Safari, Edge) en el Paper (menú desplegable)
                                                '&::-webkit-scrollbar': {
                                                    width: '8.024px !important',
                                                    background: 'transparent !important',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                    background: 'transparent !important',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    background: '#C9C9C9',
                                                    borderRadius: '100px',
                                                },
                                              },
                                            },
                                        }}
                                        >
                                            {
                                                sentidos.length > 0 ?
                                                sentidos.map((item, index) => {
                                                    return(
                                                        <MenuItem
                                                        disableRipple
                                                        key={index}
                                                        value={item.id}
                                                        id=""
                                                        >
                                                        <Box id="">
                                                            <Typography>{item.tipo}</Typography>
                                                        </Box>
                                                        </MenuItem>
                                                    )
                                                })
                                                :
                                                <MenuItem
                                                disableRipple
                                                key={0}
                                                value={0}
                                                id=""
                                                >
                                                    <Box id="">
                                                        <Typography>{"obteniendo..."}</Typography>
                                                    </Box>
                                                </MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box id="BoxOpcionTextFielFormularioGestionActuador">
                                    <TextFieldCuatroMask
                                    id={"macActuador"}
                                    mask={TextMaskCustomMAC}
                                    label={"MAC"}
                                    placeholder={"A0-A1-A2-B0-B1-B2"}
                                    icon={null}
                                    helper={formik.errors.mac}
                                    value={formik.values.mac.toUpperCase()}
                                    name={"mac"}
                                    handlerChange={formik.handleChange}
                                    handlerClick={null}
                                    disabled={false}
                                    />
                                </Box>
                            </Box>
                            <Box id="BoxTextFieldOpcionesFormularioGestionActuador">
                                <Box id="BoxOpcionTipoDosTextFielFormularioGestionActuador">
                                    <Box id="BoxOpcionTipoDos">
                                        <TextFieldTres
                                        id={"serial"}
                                        label={"Serial"}
                                        placeholder={"1234656789A"}
                                        icon={null}
                                        helper={formik.errors.serial}
                                        value={formik.values.serial}
                                        name={"serial"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                        />
                                    </Box>
                                    <Box id="BoxOpcionTipoDos">
                                        <TextFieldTres
                                        id={"clave"}
                                        label={"Clave"}
                                        placeholder={"ABCDEF45@"}
                                        icon={null}
                                        helper={formik.errors.clave}
                                        value={formik.values.clave}
                                        name={"clave"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                        />
                                    </Box>
                                </Box>
                                <Box id="BoxOpcionTipoDosTextFielFormularioGestionActuador">
                                    <Box id="BoxOpcionTipoDos">
                                    <TextFieldTres
                                        id={"ip"}
                                        label={"IP"}
                                        placeholder={"255.255.255.255"}
                                        icon={null}
                                        helper={formik.errors.ip}
                                        value={formik.values.ip}
                                        name={"ip"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                        />
                                    </Box>
                                    <Box id="BoxOpcionTipoTres">
                                        <TextFieldTres
                                        id={"puerto"}
                                        label={"Puerto"}
                                        placeholder={"4370"}
                                        icon={null}
                                        helper={formik.errors.puerto}
                                        value={formik.values.puerto}
                                        name={"puerto"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxSegundaInformacionOpcionGestionActuador">
                        <Box id="BoxTituloSegundaInformacionOpcionGestionActuador">
                            <Typography id="TxtTitutlosFormulariosOpcionGestionActuador">
                                {
                                    "Información de zona"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxSegundasOpcionesFormularioGestionActuador">
                            <FormControl fullWidth sx={{borderBottom:"2px solid #8A8A8A"}}>
                                <Select 
                                id={"idZona"}
                                name={"idZona"}                                
                                disableUnderline 
                                sx={{height:"56px"}} 
                                variant="filled" 
                                fullWidth
                                value={formik.values.idZona}
                                onChange={formik.handleChange}
                                MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        maxHeight: '200px',
                                        overflowY: 'auto',
                                        width: '220px',
                                        // Estilos para WebKit (Chrome, Safari, Edge) en el Paper (menú desplegable)
                                        '&::-webkit-scrollbar': {
                                            width: '8.024px !important',
                                            background: 'transparent !important',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            background: 'transparent !important',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            background: '#C9C9C9',
                                            borderRadius: '100px',
                                        },
                                      },
                                    },
                                }}
                                >
                                {
                                    zonasFormadas.length > 0 ?
                                    zonasFormadas.map((item, index) => {
                                            return(
                                                        <MenuItem
                                                        disableRipple
                                                        key={index}
                                                        value={item.id}
                                                        id=""
                                                        >
                                                        <Box id="">
                                                            <Typography>{item.tipo}</Typography>
                                                        </Box>
                                                        </MenuItem>
                                                    )
                                                })
                                        :
                                        <MenuItem
                                            disableRipple
                                            key={0}
                                            value={0}
                                            id=""
                                            >
                                            <Box id="">
                                                <Typography>{"obteniendo..."}</Typography>
                                            </Box>
                                        </MenuItem>
                                }
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxHandlerFormularioOpcionGestionActuador">
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                    </Box>
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} />
                        <LoadingMasRespuestaCuatro
                            id={"LoadingMasRespuestaDosNuevoActuador"}
                            open={openLoading}
                            setOpen={setOpenLoading}
                            msj={
                            "Se está agregando un nuevo actuador al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."
                            }
                            tituloLoading={"Agregando actuador..."}
                            loading={loading}
                            respuesta={response}
                            titulo={
                                response === 201 ? 
                                "Actuador agregado"
                                : 
                                "Actuador no agregado"
                            }
                            mensajeRespuesta={
                                response === 201
                                ? "El actuador ha sido agregado exitosamente al sistema. Ahora puedes gestionar sus configuraciones o añadir otro actuador"
                                : response === 403 ?
                                    "No se ha podido generar la solicitud de creacion, ya que no cuenta con el perfil requerido, contacte al administrador del sistema"
                                    :
                                    "No se ha podido agregar el actuador debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia"
                            }
                            txtBotonUno={"Agregar nuevo actuador"}
                            eventBotonUno={handleAgregarOtro}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}