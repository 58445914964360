import axios from "axios";
import { useEffect, useState } from "react";
import { CrearAcceso, DesactivarAcceso, ReactivarAcceso, BuscarAccesosEmpresa, EliminarAcceso } from "../../Service/API/Api";

export default function useAcceso(){
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();
    const [accesos, setAccesos] = useState([]);

    const CrearNuevoAcceso = (idZona, idPersona) =>{
        return new Promise((resolve, reject) => {
            const nuevoAcceso= {
                idZona:idZona,
                idPersona:idPersona,
            }
            axios.post(CrearAcceso, nuevoAcceso)
            .then(
                (response) => {
                    resolve(response.data);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
        })
    }

    //Función para agregar un nuevo Acceso al estado de accesos para mantener la consistencia con el servidor sin la necesidad de consultarle
    //nuevamente todos los datos.
    //Entradas:
        // nuevaId: Id que tendrá el acceso a agregar
        // values: Este es el objeto con los valores actuales del formulario hecho con formik
    const AgregarNuevoAcceso = (nuevoAcceso) => {

        setAccesos([...accesos, nuevoAcceso]);
    }

    const DeshabilitarActivarAcceso = (idAcceso, estado) =>{
        return new Promise((resolve, reject) => {
            
            axios.patch(estado === true ? DesactivarAcceso + idAcceso : ReactivarAcceso + idAcceso)
            .then(
                (response) => {
                    resolve(true);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
        })
    }

    //Se obtienen todos los accesos y se guardan en el estado "Accesos"
    useEffect(() => {
        axios.get(BuscarAccesosEmpresa)
        .then(
            (response) => {
                setLoading(true);
                setAccesos(response.data);
                setLoading(false);
            }
        ).catch(
            (error) => {
                setLoading(false);
            }
        )
        }, [])

    const EliminarAccesoEspecifico = (idAcceso) => {
        return new Promise(async (resolve, reject) => { 
            await axios.delete(EliminarAcceso + idAcceso)
            .then(
                (response) =>{
                    resolve(true);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }

    return{
        loading,
        setLoading,
        response,
        CrearNuevoAcceso,
        DeshabilitarActivarAcceso,
        accesos,
        EliminarAccesoEspecifico,
        AgregarNuevoAcceso,
        setAccesos
    }
}