import {useState } from 'react';
import axios from 'axios';
import { LOGINPATH } from '../../Service/API/Api'
import { useDispatch } from 'react-redux';
// import { actualizarFiltroActualizado, actualizarFiltroTiempoReal, actualizarTipoActuadores, actualizarTiposTareas, actualizarTiposUbicaciones, actualizarTiposUsuarios, actualizarTipoVisitas, actualizarUbicacionesEvento, actualizarZonasFormadas, changeTipoActuadoresCargado, changeTiposTareasCargado, changeTiposUbicacionesCargado, changeTiposUsuariosCargado, changeTipoVisitasCargado, changeUbicacionesEventoCargado, changeZonasFormadasCargado, disconnect, setUser } from '../../store/misSlice';
import { disconnect, setUser } from '../../store/misSlice';
import { Navigate } from 'react-router-dom';
import { persistor } from '../../store/store';


function useAuth() {
    const [response, setResponse] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const GuardarInfo = (response, values) =>{

        dispatch(setUser({
            authenticated: true,
            token: response.token,
            expiracion: response.expiracion,
            idEmpresa: response.idEmpresa,
            usuario: response.usuario,
            idPersona: response.idUsuario,
            rol: response.rol,
            correo: values.correo,
            rememberMe: values.recordarMe,
        }
        ));
        //return <Navigate to={"/Inicio"} replace/>  
    }

    const Iniciar = async (values) => {
        return new Promise(async (resolve, reject) =>{
            const login = {
                email: values.correo,
                contrasena: values.contrasena,
            };
            
            await axios.post(LOGINPATH, login)
            .then(
                (response) => {
                    setResponse(response.status)
                    GuardarInfo(response.data, values)
                    resolve(true)
                    setLoading(false);
                    // console.log("respuesta", response);    
                    console.log('Datos en localStorage después de inicio de sesion:', JSON.parse(localStorage.getItem('persist:root')));            
                }
            )
            .catch((error) => {

                // Asegurarse de que error.response existe antes de acceder a error.response.status
                if (error.response) {
                    setResponse(error.response.status); // Código de estado si el servidor respondió
                } else if (error.request) {
                    // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                    setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                } else {
                    // Otro error que no está relacionado con la respuesta ni la solicitud
                    setResponse("Error desconocido. Contáctanos para más información.");
                }
    
                reject(false);
                setLoading(false);
            }
            )
        })
    }

 

    const Salir = () => {
        dispatch(disconnect(
            {
                authenticated: false,
                token: null,
                expiracion: null,
                idEmpresa: null,
                usuario: null,
                idPersona: null,
                rol: null,
                correo: null,
                rememberMe: null
            }
        ));

        // dispatch(actualizarFiltroTiempoReal([]));
        // dispatch(actualizarFiltroActualizado(false));

        // dispatch(actualizarZonasFormadas([]));
        // dispatch(changeZonasFormadasCargado(false));

        // dispatch(actualizarTipoActuadores([]));
        // dispatch(changeTipoActuadoresCargado(false));

        // dispatch(actualizarTipoVisitas([]));
        // dispatch(changeTipoVisitasCargado(false));

        // dispatch(actualizarUbicacionesEvento([]));
        // dispatch(changeUbicacionesEventoCargado(false));

        // dispatch(actualizarTiposUsuarios([]));
        // dispatch(changeTiposUsuariosCargado(false));

        // dispatch(actualizarTiposUbicaciones([]));
        // dispatch(changeTiposUbicacionesCargado(false));

        // dispatch(actualizarTiposTareas([]));
        // dispatch(changeTiposTareasCargado(false));

        // console.log('Datos en localStorage antes de purge:', JSON.parse(localStorage.getItem('persist:root')));

        const handleReset = async () => {
            // Despacha la acción RESET para limpiar el estado en Redux
            dispatch({ type: 'RESET' });
    
            // Limpia los datos persistidos
            await persistor.purge();
        };
        handleReset();

        // console.log('Datos en localStorage después de purge:', JSON.parse(localStorage.getItem('persist:root')));



        return <Navigate to={"/login"} replace />;     
    }


    return {
        loading,
        setLoading,
        response,
        Iniciar,
        Salir
    }
};

export default useAuth;
