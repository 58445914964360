import { useEffect, useState } from "react"
import { ActivarPatente, BuscarPatenteEmpresa, CrearPatente, DesactivarPatente, EditarPatenteUsuario } from "../../Service/API/Api";
import axios from "axios";

export default function usePatentes(){
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();
    const [patentes, setPatentes] = useState([])

    useEffect(() => {        
        axios.get(BuscarPatenteEmpresa)
        .then(
            (response) => {                
                setPatentes(response.data);
                setLoading(false);
            }
        )
        .catch(
            (error) => {
                console.log(error.response);
                setLoading(false);
            }
        )
    }, [])
    


    const CrearNuevo = (patente, usuario) => {
        return new Promise((resolve, reject) => {
            const nuevaPatente = {
                "patenteDigitos" : patente.toUpperCase(),
                "idPersona": usuario,
                "idVisitante": null
            }
            axios.post(CrearPatente,  nuevaPatente)
            .then(
                (response) => {
                    resolve(response.data);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                        reject(error.response.status);
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                        reject("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                        reject("Error desconocido. Contáctanos para más información.");
                    }
                    setLoading(false);
                }
            )
        })
    }

    const EditarPatente = (patente, usuario, idPatente) => {
        return new Promise((resolve, reject) => {
            const editarPatente = {
                "idPatente": idPatente,
                "patenteDigitos": patente.toUpperCase(),
                "idPersona": usuario,
                "idVisitante": null
            }

            axios.put(EditarPatenteUsuario,  editarPatente)
            .then(
                (response) => {
                    resolve(true);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                        reject(error.response.status);
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                        reject("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                        reject("Error desconocido. Contáctanos para más información.");
                    }
                    setLoading(false);
                }
            )
        })
    }

    const ActivarDesactivarPatente = (idPatente, estado) => {
        return new Promise((resolve, reject) => {
            axios.patch(estado === true ? DesactivarPatente + idPatente : ActivarPatente + idPatente )
            .then(
                (response) => {
                    resolve(true);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                        reject(error.response.status);
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                        reject("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                        reject("Error desconocido. Contáctanos para más información.");
                    }
                    setLoading(false);
                }
            )
        })
    }

    

    return {
        loading,
        setLoading,
        CrearNuevo,
        response,
        EditarPatente,
        ActivarDesactivarPatente,
        patentes,
    }
}