import {
    Navigate,
    createBrowserRouter
} from "react-router-dom";
import Inicio from "../Pages/Inicio/Inicio";
import { RecuperarContrasenaPage } from "../Pages/RecuperarContrasena/RecuperarContrasena";
import CambiarEmail from "../Pages/Login/CambiarEmail/CambiarEmail";
import NotFound from "../Pages/NoPage.js/NotFound";
import UnderConstruction from "../Pages/NoPage.js/UnderConstruction";
import ValidacionCambioCorreo from "../Pages/ValidacionCambioCorreo/ValidacionCambioCorreo";
import RutaPrivada from "./ProteccionRutas/HacerPrivado";
import Reportes from "../Pages/Reportes/Reportes";
import GestionUsuariosPage from "../Pages/GestionUsuarios/GestionDeUsuarios";
import GestionDeAccesos from "../Pages/GestionAccesos/GestionDeAccesos";
import { ConfirmarRegistro } from "../Pages/ConfirmarRegistro/ConfirmarUsuario";
import GestionEstacionamientosPage from "../Pages/GestionEstacionamientos.jsx/GestionEstacionamientosPage";
// import GestionHuellas from "../Pages/GestionHuellas/GestionHuellas";
// import GestionFacialComponent from "../Pages/GestionFacial/GestionFacialComponent";
import GraficosC from "../Pages/Graficos/GraficosC";
// import VerificarEvento from "../Components/VerificarEvento/VerificarEvento";
import ActuadoresPage from "../Pages/GestionActuadores/ActuadoresPage";
import TarjetasPage from "../Pages/GestionTarjetas/TarjetasPage";
import GenerarQRPage from "../Pages/GenerarQRVisita/GenerarQRPage";
import CamarasPage from "../Pages/LiveStream/Camaras";
import Ubicaciones from "../Pages/GestionUbicaciones/GestionUbicaciones";
import GestionPatentesPage from "../Pages/GestionPatentes/GestionPatentesPage";
import GestionHorariosPage from "../Pages/GestionHorarios/GestionHorarios";
import ListaNegraPage from "../Pages/GestionListaNegra/ListaNegraPage";
import AgendaPage from "../Pages/Agenda/AgendaPage";
import { ConfirmarPage } from "../Pages/ConfirmarAsistencia/Confirmar";
import ZonasPage from "../Pages/GestionZonas/ZonasPage";
import { LoginPage } from "../Pages/Login/LoginPage";
import { AsistenciaConfirmadaPage } from "../Pages/ConfirmarAsistencia/AsistenciaConfirmadaPage/AsistenciaConfirmadaPage";
import { InvitacionRechazadaPage } from "../Pages/ConfirmarAsistencia/InvitacionRechazadaPage/InvitacionRechazadaPage";

export const MisRouter = createBrowserRouter([
        {
            path: "/",
            element: <Navigate to="/login" replace />
        }
        ,
        {
            path: "*",
            element: <NotFound /> 
        }
        ,
        {
            path: "/login",
            element: <LoginPage />
        }
        ,
        {
            path: "/inicio",
            element:<RutaPrivada>
                        <Inicio />
                    </RutaPrivada>
        }
        ,
        {
            path: "/Cuenta/Recuperar/?",
            element: <RecuperarContrasenaPage />
        }
        ,
        {
            path: "/Cuenta/validar/?",
            element: <ConfirmarRegistro />
        }
        ,
        {
            path: "/Cuenta/Correo/Validacion/?",
            element: <ValidacionCambioCorreo />
        }
        ,
        {
            path: "/Eventos/Visita/?",
            element:<ConfirmarPage />
                    
        },
        {
            path: "/Visita/Confirmada",
            element:<AsistenciaConfirmadaPage/>
        },
        {
            path: "/Visita/Rechazada",
            element:<InvitacionRechazadaPage/>
        },
        {
            path: "/cambiarEmail/?",
            element: <CambiarEmail />
        }
        ,
        {
            path: "/Agenda",
            element:<RutaPrivada>
                        <AgendaPage />
                    </RutaPrivada>,
        }        
        ,
        {
            path: "/Camaras/Live",
            element:<RutaPrivada>
                        <CamarasPage />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/Actuadores",
            element:<RutaPrivada>
                        <ActuadoresPage />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/ListaNegra",
            element:<RutaPrivada>
                        <ListaNegraPage />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/Reportes",
            element:<RutaPrivada>
                        <Reportes />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/Configuracion",
            element:<RutaPrivada>
                        <UnderConstruction />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/Configuracion/Gestion/Usuarios",
            element:<RutaPrivada>
                        <GestionUsuariosPage />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/Configuracion/Gestion/Zonas",
            element:<RutaPrivada>
                        <ZonasPage />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/Configuracion/Gestion/Accesos",
            element:<RutaPrivada>
                        <GestionDeAccesos />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/Configuracion/Gestion/Ubicaciones",
            element:<RutaPrivada>
                        <Ubicaciones />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/Configuracion/Gestion/Estacionamientos",
            element:<RutaPrivada>
                        <GestionEstacionamientosPage />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/Configuracion/Gestion/Horarios",
            element:<RutaPrivada>
                        <GestionHorariosPage />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/Configuracion/Gestion/Tarjetas",
            element:<RutaPrivada>
                        <TarjetasPage />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/Configuracion/Gestion/Patentes",
            element:<RutaPrivada>
                        <GestionPatentesPage />
                    </RutaPrivada>,
        }
        ,
        {
            path: "/Graficos",
            element:<RutaPrivada>
                        <GraficosC />
                    </RutaPrivada>,
        },
        {
            path: "/Eventos/visita/Acceso/?",
            element:<GenerarQRPage />
        }
    ]);





    
        // ,
        // {
        //     path: "/Configuracion/Gestion/Camaras",
        //     element:<RutaPrivada>
        //                 <GestionCamarasC />
        //             </RutaPrivada>,
        // }
        //,
        // {
        //     path: "/Configuracion/Gestion/Huellas",
        //     element:<RutaPrivada>
        //                 <GestionHuellas />
        //             </RutaPrivada>,
        // }
        // ,
        // {
        //     path: "/Configuracion/Gestion/facial",
        //     element:<RutaPrivada>
        //                <GestionFacialComponent/>
        //             </RutaPrivada>,
        // }
        // ,

        // ,
        // { //deprecated, se deja de usar desde el 02/09/2024
        //     path: "/calendario",
        //     element:<RutaPrivada>
        //                 <Calendario />
        //             </RutaPrivada>
        // }