import { Box, Typography } from "@mui/material";
import { TextFieldUno } from "../../TextField/TextFieldUno";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { SnackBarTipoUno } from "../../snackBar/SnackBar";
import { useFormik } from "formik";
import { IconoBorrarCampoGU } from "../../Modal/MenuGestionUsuario/MenuGestionUsuarioIcon";
import { SwitchTablas } from "../../Switch/switch";
import { useState } from "react";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import useZonas from "../../../Hooks/Zona/useZonas";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import { EsquemaZona } from "./Validation";

export const EditarZona = ({setOpen, setRows, rows, zonaEdit}) => {
    const [openLoading, setOpenLoading] = useState(false);
    const [snackMsg, setSnackMsg] = useState({ severity: '', mensaje: ''});
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const { loading, response, setLoading, HandleEditarZona} = useZonas();
    const [ConfirmationDialog, confirm ]= useConfirm();

    const handleClose = () => {
        setOpen(false);
    }

    const EditarRow = () => {
        var rowUpdated = {
            idZona: zonaEdit.idZona,
            nombre: formik.values.nombreZona,
            antiPassBack: formik.values.apb,
            estado: zonaEdit.estado,
            actuadoresEnZona: [],
            idEmpresa: zonaEdit.idEmpresa,
            isNew: false,
        }
        setRows(rows.map((row) => (row.idZona === zonaEdit.idZona ? rowUpdated : row)))
    }

    const formik = useFormik({
        initialValues: {
            nombreZona: zonaEdit.nombre, 
            apb: zonaEdit.antiPassBack
        },
        enableReinitialize: true,
        validationSchema: EsquemaZona,
        onSubmit: async () => {
            var res = await confirm();
            if(res)
            {
                setLoading(true);
                setOpenLoading(true);
                HandleEditarZona(formik.values, zonaEdit.idEmpresa, zonaEdit.idZona)
                .then(
                    (resolve) => {
                        EditarRow()
                    }
                    ,
                    (reject) => {
                        setSnackMsg({ severity: "error", mensaje:"Ocurrió un error en la actualizacion, reintente nuevamente"})
                        setSnackbar(true);
                    }
                )
            }
            else
            {
                console.log("Modificacion rechazada");
                
            }
        }
    })

    return(
        <Box id="BoxNuevaZona">
            <Box id="BoxBodyContenidoNuevaZona">
                <Box id="BoxContenidoFormularioNuevaZona">
                    <Box id="BoxTituloSubTituloNuevaZona">
                        <Box id="BoxTituloNuevaZona">
                            <Typography id="TxtTituloNuevaZona">
                                {"Crear zona"}
                            </Typography>
                        </Box>
                        <Box id="BoxSubTituloNuevaZona">
                            <Typography id="TxtSubtituloNuevaZona">
                                {
                                    "Todos los campos son obligatorios para completar el registro."
                                }
                            </Typography>
                        </Box>
                    </Box>
                    <Box id="BoxFormularioNuevaZona">
                        <Box id="BoxFormularioOpcionNombreNuevaZona">
                            <Box id="BoxTituloOpcionNombreFormularioNuevaZona">
                                <Typography id="TxtTituloOpcionFormularioNuevo">
                                    {
                                        "Información de la zona"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTxtOpcionNombreFormularioNuevaZona">
                                <TextFieldUno
                                id="nombreZona"
                                label={"Nombre de la zona"}
                                placeholder={"Z-tres"}
                                name={"nombreZona"}
                                icon={<IconoBorrarCampoGU id="IconoEnTFUnoZona" />}
                                disabled={false}
                                formHandle={() => formik.setFieldValue("nombreZona", "")}
                                value={formik.values.nombreZona}
                                handlerChange={formik.handleChange}
                                focus={false}
                                helper={formik.errors.nombreZona}
                                />
                            </Box>
                        </Box>
                        <Box id="BoxFormularioOpcionAPBNuevaZona">
                            <Box id="BoxTituloOpcionAPBFormularioNuevaZona">
                                <Typography id="TxtTituloOpcionFormularioNuevo">
                                    {
                                        "Anti-passback en la zona"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTxtOpcionNombreFormularioNuevaZona">
                                <SwitchTablas 
                                value={formik.values.apb}
                                disabled={false}
                                handler={() => formik.setFieldValue("apb", !formik.values.apb)} 
                                />
                                
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxAccionesFormularioNuevaZona">
                    <Box id="BoxBtnAccionNuevaZona">
                        <BotonTipo2 
                        textoBoton={"Cancelar"}
                        event={handleClose}
                        disabled={false}
                        />
                    </Box>
                    <Box id="BoxBtnAccionNuevaZona">
                        <BotonTipo2 
                        textoBoton={"Actualizar"}
                        event={formik.submitForm}
                        disabled={false}
                        />
                    </Box>
                </Box>
            </Box>
            
            <LoadingMasRespuestaCinco
            open={openLoading} 
            setOpen={setOpenLoading} 
            msj={"La información de la zona está siendo actualizada. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
            id={"RespuestaGuardarEditarUsuario"} 
            tituloLoading={"Actualizando zona..."}
            loading={loading} 
            icono={
                response === 200 ?
                <IconoExito id="IconoRespuestaServidor" />
                :
                <IconoFallo id="IconoRespuestaServidor" />
            }
            textoBoton={
                response === 200 ? 
                "Aceptar"
                :
                "Reintentar"
            }
            titulo={response === 200 ? "Actualización exitosa":"Error al actualizar"}
            mensajeRespuesta={response === 200 ? 
                "La información de la zona se ha actualizado correctamente. Ahora puedes gestionar sus accesos"
                :
                "Ha ocurrido un error y no se ha podido actualizar la información. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
            />

            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackMsg.severity}
            msg={snackMsg.mensaje}
            />

            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
            titulo={"¿Actualizar datos?"}
            mensaje={"Estás a punto de actualizar la información de la zona. ¿Deseas continuar?"}
            />
        </Box>
    );
}