import { Autocomplete, Box, Checkbox, FormControl, FormHelperText, IconButton, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from "@mui/material";
import "./FiltrosDescarga.css";
import { useFormik } from "formik";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import useGestZonasEmpresa from "../../../Hooks/Zona/useGetZonasEmpresa";
import { useState } from "react";
import useReporteDownload from "../../../Hooks/Reportes/useReporteDownload";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { useSelector } from "react-redux";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import dayjs from "dayjs";
import { SeleccionFechaOut } from "../../DatePicker/DatePicker";
import { CerrarModalNuevoUsuario } from "../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import { EsquemaDescargar } from "./Validation";
import { SnackBarTipoUno } from "../../snackBar/SnackBar";

const OpcionInvitadoUsuario = [
    {
        id: -1, tipo: "Seleccione"
    }
    ,
    {
        id: 0, tipo: "Todos"
    }
    ,
    {
        id: 1, tipo: "Solo Usuarios"
    }
    ,
    {
        id: 2, tipo: "Solo Visitas"
    }
]

const OpcionEntradaSalida = [
    {
        id: -1, tipo: "Seleccione"
    }
    ,
    {
        id: 0, tipo: "Todos"
    }
    ,
    {
        id: 1, tipo: "Solo Entradas"
    }
    ,
    {
        id: 2, tipo: "Solo Salidas"
    }
]


export const FiltrosDescarga = ({setOpen}) => {
    const data = useSelector(action => action.user.authenticated);
    const [openLoading, setOpenLoading] = useState(Boolean(false));    
    const {DescargarArchivoReporte, loadingDescarga, setLoadingDescarga, respuesta} = useReporteDownload();
    const [ConfirmationDialog, confirm ]= useConfirm();
    const {zonasFormadasReporte} = useGestZonasEmpresa();
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    


    const formik = useFormik({
        initialValues: {
            idTipoUsuario: -1, 
            nombreTipoUsuario: "Seleccione",
            idTipoAcceso: -1,
            nombreTipoAcceso: "Seleccione",
            zonasSeleccionadas: ["Todas"],
            fechaInicio: dayjs(Date.now()),
            fechaTermino: dayjs(Date.now())
        },
        validationSchema: EsquemaDescargar,
        onSubmit: async () => {
            
            
            let res = await confirm();
            if(res === true) {
                if(zonasFormadasReporte !== undefined && zonasFormadasReporte !== null && zonasFormadasReporte.length > 0){
                    var listaIds = []
                    formik.values.zonasSeleccionadas.forEach(element => {
                        var idSeleccionadas = zonasFormadasReporte.find(z => z.tipo === element)
                        listaIds.push(idSeleccionadas.id);
                    });   
                    
                    var zonasEscogidas = listaIds.join(";");
                    if(zonasEscogidas.includes("-1")){
                        zonasEscogidas = null;
                    }

                    const reporteOpciones = {
                        "inicio": formik.values.fechaInicio,
                        "fin":formik.values.fechaTermino,
                        "visitas": formik.values.idTipoUsuario === 0 ? true : formik.values.idTipoUsuario === 1 ? false : true,
                        "usuarios": formik.values.idTipoUsuario === 0 ? true : formik.values.idTipoUsuario === 1 ? true : false,
                        "idZonas": zonasEscogidas,
                        "entrada": formik.values.idTipoAcceso === 0 ? true : formik.values.idTipoAcceso === 1 ? true : false,
                        "salida": formik.values.idTipoAcceso === 0 ? true : formik.values.idTipoAcceso === 1 ? false : true,
                        "idEmpresa": data.idEmpresa
                    }
                    setLoadingDescarga(true);
                    setOpenLoading(true);
                    
                    await DescargarArchivoReporte(reporteOpciones)
                    .then(
                        (resolve) => {
                            setSnackbarSeverity("success")
                            setSnackMsg("Descarga exitosa!")
                            setSnackbar(true);
                        }
                        ,
                        (reject) => {
                            setSnackbarSeverity("error")
                            setSnackMsg("fallo la descarga, intente nuevamente!")
                            setSnackbar(true);
                        }
                    )
                }
                else{
                    setSnackbarSeverity("error")
                    setSnackMsg("fallo la descarga, intente nuevamente!")
                    setSnackbar(true);
                }
            }
            else{
                console.log("rechaza descarga")
            }
        }
    })

    const handleChange = (event) => {
        const {target: { value },} = event;
        formik.setFieldValue("zonasSeleccionadas",(typeof value === 'string' ? value.split(',') : value));
    };


    const handlerClose = () => {
        setOpen(null);
        formik.resetForm();
    }


    return(
        <Box id="BoxContenedorFiltrosDescarga" >
            <Box id="BoxCabeceraCerrarFiltroDescarga">
                <Box id="BoxTituloEnCabeceraFiltroDescarga">
                    <Typography id="TxtTituloCabeceraFiltroDescarga">
                        {
                            "Opciones para descargar"
                        }
                    </Typography>
                </Box>
                <Box id="BoxContenedorIconoCierreFiltroDescarga">
                    <IconButton onClick={handlerClose}>
                        <CerrarModalNuevoUsuario id="IconoCerrarFiltroDescarga" />
                    </IconButton>
                </Box>
            </Box>
            <Box id="BoxLayoutContenidoFiltroDescarga">
                <Box id="BoxContenidoFormularioDescarga">
                    <Box id="BoxContenedorTipoUsuarioReporte">
                        <FormControl fullWidth sx={{ borderBottom: "unset"}}>
                            <Autocomplete
                            disablePortal
                            id="idTipoUsuario"                                    
                            options={OpcionInvitadoUsuario}
                            fullWidth
                            getOptionKey={(o) => o.id}
                            isOptionEqualToValue={(o, v) => o.tipo === v}
                            freeSolo={true}
                            onChange={(e, v) => {
                                if(v === null)
                                {
                                    formik.setFieldValue("idTipoUsuario", -1)                                           
                                }
                                else{
                                    formik.setFieldValue("idTipoUsuario", v.id)
                                }
                            }}
                            renderInput={(params) => <TextField 
                                InputLabelProps={{shrink: true}}
                                {...params}                                        
                                label="Usuarios/Visitantes"                          
                                />
                            }
                            value={formik.values.nombreTipoUsuario}
                            inputValue={formik.values.nombreTipoUsuario}
                            onInputChange={(e, v) => formik.setFieldValue("nombreTipoUsuario", v)}
                            componentname="idZonaEstacionamiento"
                            getOptionLabel={(option) => option.tipo ?? option}
                            renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <Box
                                    key={key}
                                    component="li"
                                   {...optionProps}>
                                    {option.tipo}
                                    </Box>
                                );
                              }}
                            />
                            <FormHelperText>
                                {
                                    formik.errors.idTipoUsuario
                                }
                            </FormHelperText>                                   
                        </FormControl>
                    </Box>
                    <Box id="BoxContenedorTipoUsuarioReporte">
                        <FormControl fullWidth>
                            <Autocomplete
                            disablePortal
                            id="idTipoAcceso"                                    
                            options={OpcionEntradaSalida}
                            fullWidth
                            getOptionKey={(o) => o.id}
                            isOptionEqualToValue={(o, v) => o.tipo === v}
                            freeSolo={true}
                            onChange={(e, v) => {
                                if(v === null)
                                {
                                    formik.setFieldValue("idTipoAcceso", -1)                                           
                                }
                                else{
                                    formik.setFieldValue("idTipoAcceso", v.id)
                                }
                            }}
                            renderInput={(params) => <TextField 
                                InputLabelProps={{shrink: true}}
                                {...params}                                        
                                label="Entradas/Salidas"                          
                                />
                            }
                            value={formik.values.nombreTipoAcceso}
                            inputValue={formik.values.nombreTipoAcceso}
                            onInputChange={(e, v) => formik.setFieldValue("nombreTipoAcceso", v)}
                            componentname="idTipoAcceso"
                            getOptionLabel={(option) => option.tipo ?? option}
                            renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <Box
                                    key={key}
                                    component="li"
                                   {...optionProps}>
                                    {option.tipo}
                                    </Box>
                                );
                              }}
                            />
                            <FormHelperText>
                                {
                                    formik.errors.idTipoAcceso
                                }
                            </FormHelperText>                                   
                        </FormControl>
                    </Box>
                    <Box id="BoxContenedorTipoUsuarioReporte">
                        <FormControl fullWidth>
                            <InputLabel id="zonasMultiple">{"Seleccion de Zonas"}</InputLabel>
                            <Select
                            labelId="zonasMultiple"
                            id="zonasMultiple" 
                            fullWidth
                            label="Seleccion de Zonas"
                            multiple                
                            value={formik.values.zonasSeleccionadas}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(', ')}
                            >
                                {zonasFormadasReporte.map((item, index) => (
                                    <MenuItem key={index} value={item.tipo}>
                                    <Checkbox checked={formik.values.zonasSeleccionadas.indexOf(item.tipo) > -1} />
                                    <ListItemText primary={item.tipo} />
                                    </MenuItem>
                                ))}
                            </Select>                              
                        </FormControl>
                    </Box>
                    <Box id="BoxContenedorTipoUsuarioReporte">
                        <SeleccionFechaOut
                        id="fechaInicio"
                        variante={"out"}                         
                        valueSelected={formik.values.fechaInicio} 
                        handleDate={(value)=> formik.setFieldValue("fechaInicio", value)} 
                        labelName={"Fecha de inicio"} 
                        name={"fechaInicio"}
                        helperText={formik.errors.fechaInicio}
                        />
                    </Box>
                    <Box id="BoxContenedorTipoUsuarioReporte">
                        <SeleccionFechaOut 
                        id="fechaTermino"
                        variante={"out"}                         
                        valueSelected={formik.values.fechaTermino} 
                        handleDate={(value)=> formik.setFieldValue("fechaTermino", value)} 
                        labelName={"Fecha de termino"} 
                        name={"fechaTermino"}
                        helperText={formik.errors.fechaTermino}
                        />
                    </Box>
                </Box>
                <Box id="BoxBotonAccionFormularioDescarga">
                    <BotonTipo2
                    textoBoton={"Descargar"}
                    event={formik.submitForm}                    
                    />
                </Box>
            </Box>
            

            <ConfirmationDialog 
                icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
                titulo={'¿Descargar reporte?'}
                mensaje={'Estás a punto de descargar un reporte de accesos ¿Continuar?'}
                />
            <LoadingMasRespuestaCinco
                open={openLoading} 
                setOpen={setOpenLoading} 
                msj={"El reporte se esta descargando. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."} 
                id={"RespuestaDescargarReporteExcel"} 
                tituloLoading={"Descargando reporte..."}
                loading={loadingDescarga} 
                respuesta={respuesta} 
                icono={
                    respuesta === 200 ?
                    <IconoExito id="IconoRespuestaServidor" />
                    :
                    <IconoFallo id="IconoRespuestaServidor" />
                }
                textoBoton={
                    respuesta === 200 ? 
                    "Aceptar"
                    :
                    "Reintentar"
                }
                titulo={respuesta === 200 ? "Descarga exitosa":"Error al Descargar"}
                mensajeRespuesta={respuesta === 200 ? 
                    "El reporte se ha descargado correctamente"
                    :
                    "Ha ocurrido un error y no se ha podido descargar el reporte. Por favor, verifica tu conexión y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
                />
                <SnackBarTipoUno 
                open={snackbar}
                handler={handleCloseSnackbar}
                duration={6000}
                severity={snackbarSeverity}
                msg={snackMsg}
                />
        </Box>
    );
}