import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import { PatentesUsuarioSeleccionado } from "./PatentesUsuario";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { useState } from "react";
import usePase from "../../../Hooks/Pase/usePase";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import { EsquemaEditarAsignacion } from "./ValidationSchema";
import useGetAll from "../../../Hooks/Usuarios/useGetAll";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import AutocompleteSimple from "../../AutocompleteSimple/AutocompleteSimple";

export const EditarAsignacion = ({setOpen, setRows, rows, dataEditar}) => {
    const {loading, setLoading, ActualizarPase, EliminacionDelPase, response} = usePase();
    const {listaUsuarios} = useGetAll();
    const [openLoading, setOpenLoading] = useState(false);
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [confirmMessage, setConfirmMessage] = useState({titulo: "", mensaje: ""});

    const handlerClose = () => { 
        setOpen(false);
    }


    const EliminarRow = () => {
        var findRow = rows.find(t => t.idTarjeta === dataEditar.idTarjeta);


        findRow.asignada = false;
        findRow.idPase = null;
        findRow.idPatente = null;
        findRow.idPersona = null;
        findRow.nombreCompleto = null;
        findRow.pasaporte = null;
        findRow.rut = null;
        findRow.patenteDigitos = null;
        
        setRows(rows.map((r) => (r.idTarjeta === findRow.idTarjeta ? findRow : r)));
    }



    const formik = useFormik({
        initialValues: {
            codigo: dataEditar.codigo,
            tipo: dataEditar.tipoTarjeta,
            idPersona: dataEditar.idPersona ?? -1,
            nombrePersona: dataEditar.nombreCompleto ?? "Usuarios",
            idPatente: dataEditar.idTipoTarjeta === 1 ? "" : dataEditar.idPatente,
            patenteDigitos: dataEditar.idTipoTarjeta === 1 ? "" : dataEditar.patenteDigitos
        },
        validationSchema: EsquemaEditarAsignacion,
        enableReinitialize: true,
        onSubmit: async () => {
            if(formik.values.idPersona === -1 && dataEditar.idPase !== null)
            {
                setConfirmMessage({ titulo: "¿Quitar asignacion?", mensaje: "Se esta elminando la asociacion del usuario con la tarjeta ¿Continuar?"})
                let res = await confirm();
                if(res)
                {
                    setLoading(true);
                    setOpenLoading(true)
                    await EliminacionDelPase(dataEditar.idPase)
                    .then(
                        (resolve) => {
                            EliminarRow()
                        }
                        ,
                        (reject) => {
                            console.log("fallo la modificacion");
                        }
                    )              
                }
                else{
                    console.log("Rechaza la modificacion");
                }
            }
            else{
                setConfirmMessage({ titulo: "¿Actualizar?", mensaje: "Actualizar la informacion de esta asignacion ¿Continuar?"})
                let res = await confirm();
                if(res)
                {
                    setLoading(true);
                    setOpenLoading(true)
                    ActualizarPase(dataEditar.idPase, formik.values.idPersona, formik.values.idPatente, dataEditar.idTarjeta)
                    .then(
                        (resolve) => {
                            var rowEdit = rows.find(t => t.idTarjeta === dataEditar.idTarjeta)
                            var userSelected = listaUsuarios.find(u => u.idPersona === formik.values.idPersona)
                            rowEdit.idPersona = userSelected.idPersona;
                            rowEdit.nombreCompleto = userSelected.nombreRut.split("/")[0];
                            rowEdit.idPatente = formik.values.idPatente === "" ? null : formik.values.idPatente;
                            rowEdit.patenteDigitos = formik.values.patenteDigitos;
                            setRows(rows.map((row) => (row.idTarjeta === rowEdit.idTarjeta ? rowEdit : row)))
                        }
                        ,
                        (reject) => {
                            console.log("fallo la actualizacion");
                        }
                    )
                    
                }
                else{
                    console.log("Rechaza la modificacion");
                }
            }
        }
    })


    return(
        <Box id="BoxAgregarNuevaAsignacionPase">
            <Box id="BoxTituloSubtituloOpcionNuevaAsignacionPase">
                <Box id="BoxTituloOpcionNuevaAsignacion">
                    <Typography id="TxtTituloOpcionNuevaAsignacion">
                        {
                            "Editar asignación"
                        }
                    </Typography>
                </Box>
                <Box>
                    <Typography id="TxtSubTituloOpcionNuevaAsignacion">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxFormularioOpcionNuevaAsignacionPase" >
                <Box id="BoxContenidoFormularioOpcionNuevaAsignacionPase" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxInformacionTarjetaNuevaAsociacionPase">
                        <Box id="BoxTituloInformacionTarjetaOpcionNuevaAsociacionPase">
                            <Typography id="TxtTituloInformacionTarjetaNuevaAsignacionPase">
                                {
                                    "Información de la Tarjeta"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxContenidoInformacionTarjetaOpcionNuevaAsociacionPase">
                            <Box id="BoxCodigoTarjetaOpcionNjuevaAsociacionPase">
                                <TextFieldTres
                                id="codigo"
                                label={"Codigo tarjeta"}
                                placeholder={"000000000000"}
                                icon={null}
                                helper={formik.errors.codigo}
                                value={formik.values.codigo}
                                name={"codigo"}
                                handlerChange={formik.handleChange}
                                handlerClick={() => formik.setFieldValue("codigo", "")}
                                disabled={true}
                                />
                            </Box>
                            <Box id="BoxTipoTarjetaOpcionNuevaAsociacionPase">
                                <TextFieldTres
                                id="tipo"
                                label={"Tipo de Tarjeta"}
                                placeholder={"RFID/TAG"}
                                icon={null}
                                helper={formik.errors.tipo}
                                value={formik.values.tipo}
                                name={"tipo"}
                                handlerChange={formik.handleChange}
                                handlerClick={null}
                                disabled={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxInformacionUsuarioNuevaAsociacionPase">
                        <Box id="BoxTituloInformacionTarjetaOpcionNuevaAsociacionPase">
                            <Typography id="TxtTituloInformacionTarjetaNuevaAsignacionPase">
                                {
                                    "Información del Usuario"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxContenidoInformacionTarjetaOpcionNuevaAsociacionPase">
                            <FormControl fullWidth>
                                <AutocompleteSimple
                                    disablePortal={true}
                                    freeSolo = {true}
                                    listadoElementos={listaUsuarios}
                                    label="Usuarios"
                                    onSelectionChange={(newValue) => {
                                        if(newValue === null)
                                        {
                                            formik.setFieldValue("idPersona", -1)                                           
                                            formik.setFieldValue("idPatente", dataEditar.idTipoTarjeta === 1 ? "" : -1)                                           
                                            formik.setFieldValue("patenteDigitos", "Patentes")                                           
                                        }
                                        else
                                        {
                                            formik.setFieldValue("idPersona", newValue.idPersona)
                                            formik.setFieldValue("idPatente", dataEditar.idTipoTarjeta === 1 ? "" : -1)
                                            formik.setFieldValue("patenteDigitos", "Patentes")
                                        }
                                    }}
                                    keyListadoElementos="idPersona"
                                    mostrarElemento={(option) => option["nombreRut"] || ''}
                                    elementoSeleccionado={listaUsuarios.find(usuario => formik.values.idPersona === usuario.idPersona) || null}
                                />
                                <FormHelperText>
                                    {
                                        formik.errors.idPersona
                                    }
                                </FormHelperText>                         
                            </FormControl>
                        </Box>
                    </Box>
                    <Box id="BoxInformacionUsuarioNuevaAsociacionPase">
                        <Box id="BoxTituloInformacionTarjetaOpcionNuevaAsociacionPase">
                            <Typography id="TxtTituloInformacionTarjetaNuevaAsignacionPase">
                                {
                                    "Información de la Patente"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxContenidoInformacionTarjetaOpcionNuevaAsociacionPase">
                            {
                                dataEditar.idTipoTarjeta === 1 ?
                                <TextFieldTres
                                id="patenteFalse"
                                label={"Patente"}
                                placeholder={"Patentes"}
                                icon={null}
                                helper={null}
                                value={"Patentes"}
                                name={null}
                                handlerChange={null}
                                handlerClick={null}
                                disabled={true}
                                />
                                :
                                dataEditar.idPersona === null ?
                                    <TextFieldTres
                                    id="patenteFalse"
                                    label={"Patente"}
                                    placeholder={"Patentes"}
                                    icon={null}
                                    helper={null}
                                    value={"Patentes"}
                                    name={null}
                                    handlerChange={null}
                                    handlerClick={null}
                                    disabled={true}
                                    />
                                    :
                                    <PatentesUsuarioSeleccionado formik={formik} />
                            }
                        </Box>
                    </Box>
                    
                </Box>
                <Box id="BoxHandlerFormularioOpcionNuevaAsignacionPase">
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                    </Box>
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Actualizar"} event={formik.submitForm} /> 
                        
                        <ConfirmationDialog
                        icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
                        titulo={confirmMessage.titulo}
                        mensaje={confirmMessage.mensaje}
                        />         

                        <LoadingMasRespuestaCinco
                        open={openLoading} 
                        setOpen={setOpenLoading} 
                        msj={"Se está actualizando la información de la tarjeta. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
                        id={"RespuestaActualizarTarjetaInformacion"} 
                        tituloLoading={"Actualizando..."}
                        loading={loading} 
                        respuesta={response} 
                        icono={
                            response === 200 ?
                            <IconoExito id="IconoRespuestaServidor" />
                            :
                            <IconoFallo id="IconoRespuestaServidor" />
                        }
                        textoBoton={
                            response === 200 ? 
                            "Aceptar"
                            :
                            "Reintentar"
                        }
                        titulo={response === 200 ? "Actualizacion exitosa":"Error al Actualizar"}
                        mensajeRespuesta={response === 200 ? 
                            "La información se ha actualizado correctamente"
                            :
                            "Ha ocurrido un error y no se ha podido actualizar la información"}
                        />   
                        <ConfirmationDialog
                        icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
                        titulo={confirmMessage.titulo}
                        mensaje={confirmMessage.mensaje}
                        />            
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}