import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';
import { FilledInput } from '@mui/material';
import "./SelectInvitadosEventoRecurrente.css";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 320,
    },
  },
};

export default function SelectInvitadosEventoRecurrente({ listaEventosRecurrentes, eventosNuevoInvitado ,setEventosNuevoInvitado }) {
  const nombreDias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

  const handleCheckbox = (evento) => {
    // Verificar si el evento ya está en eventosSeleccionados
    const existeEvento = eventosNuevoInvitado.some(ev => ev.idVisita === evento.idVisita);
  
    // Solo agrega el evento si no existe
    if (!existeEvento) {
      setEventosNuevoInvitado([
        ...eventosNuevoInvitado,
        evento,
      ]);
    }
    //En caso de ya estar el evento, se saca del arreglo
    else {
      setEventosNuevoInvitado(prevEventos => prevEventos.filter((ev) => ev.idVisita!== evento.idVisita));
    }
  };

  return (
      <FormControl sx={{ m: 1, width: 320 }}>
        <InputLabel id="demo-multiple-checkbox-label">Eventos invitado</InputLabel>
        <Select
          id="demo-multiple-checkbox"
          multiple
          value={eventosNuevoInvitado}
          input={<FilledInput label="Eventos" />}
          // onChange={handleChange}
          renderValue={()=>eventosNuevoInvitado.map(ev =>nombreDias[dayjs(ev.fechaVisita).day()]).join(', ')}
          MenuProps={MenuProps}
        >
          {listaEventosRecurrentes.map((evento) => (
            <MenuItem 
              key={evento.idVisita} 
              value={evento.nombreEvento} 
              onClick={() => handleCheckbox(evento)}
            >
              <Checkbox 
                checked={eventosNuevoInvitado.some(ev => ev.idVisita === evento.idVisita)} 
              />
              <ListItemText 
                primary={nombreDias[dayjs(evento.fechaVisita).day()]} 
                secondary={dayjs(evento.fechaVisita).format('DD/MM/YYYY HH:mm')}
              />
            </MenuItem>
          ))}


        </Select>
      </FormControl>

  );
}