import axios from "axios";
import { useEffect, useState } from "react";
import { AccesosSegunUsuario } from "../../Service/API/Api";

export default function useAccesosUsuario(idPersona)
{
    const [accesos, setAccesos] = useState([]);

    useEffect(() => {
        axios.get(AccesosSegunUsuario + idPersona)
        .then(
            (response) => {
                setAccesos(response.data);
            }
        )
        .catch(
            (error) => {
                console.error("Error al obtener la información");
            }
        )
    }, [idPersona])

    return {
        accesos
    }
    
}