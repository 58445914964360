import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import usePase from "../../../Hooks/Pase/usePase";
import "./OpcionesAsignacion.css";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import useGetAll from "../../../Hooks/Usuarios/useGetAll";
import { PatentesUsuarioSeleccionado } from "./PatentesUsuario";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import { useState } from "react";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { EsquemaNuevaAsignacion } from "./ValidationSchema";
import AutocompleteSimple from "../../AutocompleteSimple/AutocompleteSimple";

export const NuevaAsignacion = ({setOpen, setRows, rows, dataEditar}) => {
    const {loading, setLoading, CrearNuevo, response} = usePase();
    const {listaUsuarios} = useGetAll();
    const [openLoading, setOpenLoading] = useState(false);
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [confirmMessage, setConfirmMessage] = useState({titulo: "", mensaje: ""});

    const handlerClose = () => { 
        setOpen(false);
    }


    const formik = useFormik({
        initialValues: {
            codigo: dataEditar.codigo,
            tipo: dataEditar.tipoTarjeta,
            idPersona: -1,
            nombrePersona: "Usuarios",
            idPatente: dataEditar.idTipoTarjeta === 1 ? null : -1,
            patenteDigitos: dataEditar.idTipoTarjeta === 2 ? "Patentes" : "Tipo de tarjeta no acepta patentes"
        },
        validationSchema: EsquemaNuevaAsignacion,
        enableReinitialize: true,
        onSubmit: async () => {
            setConfirmMessage({ titulo: "¿Asignar tarjeta?", mensaje: "Asignar esta tarjeta al usuario ¿Continuar?"})
            let res = await confirm();
            if(res)
            {
                setLoading(true);
                setOpenLoading(true)
                await CrearNuevo(dataEditar.idTipoTarjeta, formik.values.idPersona, formik.values.idPatente, dataEditar.idTarjeta)
                .then(
                    (resolve) => {
                        var rowFinded = rows.find(t => t.idTarjeta === dataEditar.idTarjeta)
                        var userFinded = listaUsuarios.find(u => u.idPersona === formik.values.idPersona)
                        
                        console.log(rowFinded);
                        console.log(userFinded);
                        
                        rowFinded.idPase = resolve;
                        rowFinded.asignada = true;
                        rowFinded.nombreCompleto = userFinded.nombreRut.split("/")[0];
                        rowFinded.idPatente = dataEditar.idTipoTarjeta === 1 ? null : formik.values.idPatente;
                        rowFinded.patenteDigitos = dataEditar.idTipoTarjeta === 1 ?  null : formik.values.patenteDigitos;
                        rowFinded.idPersona = formik.values.idPersona;
                        rowFinded.rut = userFinded.rut;
                        rowFinded.pasaporte = userFinded.pasaporte;
                        setRows(rows.map((row) => (row.idTarjeta === rowFinded.idTarjeta ? rowFinded : row)))
                    }
                    ,
                    (reject) => {
                        console.log("fallo la asignacion");
                    }
                )
            }
        }
    })

    return(
        <Box id="BoxAgregarNuevaAsignacionPase">
            <Box id="BoxTituloSubtituloOpcionNuevaAsignacionPase">
                <Box id="BoxTituloOpcionNuevaAsignacion">
                    <Typography id="TxtTituloOpcionNuevaAsignacion">
                        {
                            "Asignar tarjeta"
                        }
                    </Typography>
                </Box>
                <Box>
                    <Typography id="TxtSubTituloOpcionNuevaAsignacion">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxFormularioOpcionNuevaAsignacionPase" >
                <Box id="BoxContenidoFormularioOpcionNuevaAsignacionPase" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxInformacionTarjetaNuevaAsociacionPase">
                        <Box id="BoxTituloInformacionTarjetaOpcionNuevaAsociacionPase">
                            <Typography id="TxtTituloInformacionTarjetaNuevaAsignacionPase">
                                {
                                    "Información de la Tarjeta"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxContenidoInformacionTarjetaOpcionNuevaAsociacionPase">
                            <Box id="BoxCodigoTarjetaOpcionNjuevaAsociacionPase">
                                <TextFieldTres
                                id="codigo"
                                label={"Codigo tarjeta"}
                                placeholder={"000000000000"}
                                icon={null}
                                helper={formik.errors.codigo}
                                value={formik.values.codigo}
                                name={"codigo"}
                                handlerChange={formik.handleChange}
                                handlerClick={() => formik.setFieldValue("codigo", "")}
                                disabled={true}
                                />
                            </Box>
                            <Box id="BoxTipoTarjetaOpcionNuevaAsociacionPase">
                                <TextFieldTres
                                id="tipo"
                                label={"Tipo de Tarjeta"}
                                placeholder={"RFID/TAG"}
                                icon={null}
                                helper={formik.errors.tipo}
                                value={formik.values.tipo}
                                name={"tipo"}
                                handlerChange={formik.handleChange}
                                handlerClick={null}
                                disabled={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxInformacionUsuarioNuevaAsociacionPase">
                        <Box id="BoxTituloInformacionTarjetaOpcionNuevaAsociacionPase">
                            <Typography id="TxtTituloInformacionTarjetaNuevaAsignacionPase">
                                {
                                    "Información del Usuario"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxContenidoInformacionTarjetaOpcionNuevaAsociacionPase">
                            <FormControl fullWidth>
                                <AutocompleteSimple
                                    disablePortal={true}
                                    freeSolo = {true}
                                    listadoElementos={listaUsuarios}
                                    label="Usuarios"
                                    onSelectionChange={(newValue) => {
                                        if(newValue === null)
                                        {
                                            formik.setFieldValue("idPersona", -1)                                           
                                            formik.setFieldValue("idPatente", dataEditar.idTipoTarjeta === 1 ? "" : -1)                                           
                                            formik.setFieldValue("patenteDigitos", "Patentes")                                           
                                        }
                                        else
                                        {
                                            formik.setFieldValue("idPersona", newValue.idPersona)
                                            formik.setFieldValue("idPatente", dataEditar.idTipoTarjeta === 1 ? "" : -1)
                                            formik.setFieldValue("patenteDigitos", "Patentes")
                                        }
                                    }}
                                    keyListadoElementos="idPersona"
                                    mostrarElemento={(option) => option["nombreRut"] || ''}
                                    elementoSeleccionado={listaUsuarios.find(usuario => formik.values.idPersona === usuario.idPersona) || null}
                                />
                                <FormHelperText>
                                    {
                                        formik.errors.idPersona
                                    }
                                </FormHelperText>                         
                            </FormControl>
                        </Box>
                    </Box>
                    <Box id="BoxInformacionUsuarioNuevaAsociacionPase">
                        <Box id="BoxTituloInformacionTarjetaOpcionNuevaAsociacionPase">
                            <Typography id="TxtTituloInformacionTarjetaNuevaAsignacionPase">
                                {
                                    "Información de la Patente"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxContenidoInformacionTarjetaOpcionNuevaAsociacionPase">
                            {
                                dataEditar.idTipoTarjeta === 2 ?
                                <PatentesUsuarioSeleccionado formik={formik} />
                                :
                                <TextFieldTres
                                id="idPatente"
                                label={"Patente"}
                                placeholder={"ACDF00"}
                                icon={null}
                                helper={formik.errors.idPatente}
                                value={formik.values.patenteDigitos}
                                name={"idPatente"}
                                handlerChange={formik.handleChange}
                                handlerClick={null}
                                disabled={true}
                                />
                            }
                        </Box>
                    </Box>
                    
                </Box>
                <Box id="BoxHandlerFormularioOpcionNuevaAsignacionPase">
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                    </Box>
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} /> 
                        <LoadingMasRespuestaCinco
                        open={openLoading} 
                        setOpen={setOpenLoading} 
                        msj={"Se está asignando la tarjeta al usuario. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
                        id={"RespuestaGuardarEditarUbicacion"} 
                        tituloLoading={"Asignando usuario..."}
                        loading={loading} 
                        respuesta={response} 
                        icono={
                            response === 201 ?
                            <IconoExito id="IconoRespuestaServidor" />
                            :
                            <IconoFallo id="IconoRespuestaServidor" />
                        }
                        textoBoton={
                            response === 201 ? 
                            "Aceptar"
                            :
                            "Reintentar"
                        }
                        titulo={response === 201 ? "Asignacion exitosa":"Error al asignar"}
                        mensajeRespuesta={response === 201 ? 
                            "La información se ha actualizado correctamente"
                            :
                            "Ha ocurrido un error y no se ha podido actualizar la información"}
                        />   
                        <ConfirmationDialog
                        icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
                        titulo={confirmMessage.titulo}
                        mensaje={confirmMessage.mensaje}
                        />                    
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}