import axios from "axios";
import { useState } from "react";
const { BuscarVisita, ValidarInvitacionRut, ValidarInvitacionPasaporte, GenerarQRData, BuscarVisitanteRut, BuscarVisitanteTelefono, BuscarVisitantePasaporte } = require("../../Service/API/Api");

function useVisitas(){
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState();


    const BuscarEvento = async (id) => {
        return new Promise(async (resolve, reject) => {            
            await axios.get(BuscarVisita + id)
            .then(
                (response) =>{
                    resolve(response.data);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                }
            );
            
        });
    }

    const ValidarInvitado = async (rutPasaporte, idVisita, tipo) => {
        return new Promise(async (resolve, reject) => {
            var validar = {
                "rut": tipo === true ? rutPasaporte : null,
                "pasaporte": tipo === false ? rutPasaporte : null,
                "idVisita": idVisita
            }            
            tipo === true ?
            await axios.post(ValidarInvitacionRut, validar)
            .then(
                (response) =>{
                    resolve(response.data);  
                    setResponse(response.status)                
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
            :
            await axios.post(ValidarInvitacionPasaporte, validar)
            .then(
                (response) =>{
                    resolve(response.data);
                    setResponse(response.status) 
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
            
        });
    }

    const BuscarInvitado = async (identificador, tipo) => {
        return new Promise(async (resolve, reject) => {            
            await axios.get(tipo === 1 ? BuscarVisitanteRut + identificador : tipo === 2 ? BuscarVisitantePasaporte + identificador : BuscarVisitanteTelefono + identificador)  
            .then(
                (response) =>{
                    setResponse(response.status)  
                    resolve(response.data);                    
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
            
        });
    }

    const GenerarQRInformacion = async (idVivistaVisitante) => {
        return new Promise(async (resolve, reject) => {           
            await axios.get(GenerarQRData + idVivistaVisitante)
            .then(
                (response) =>{
                    resolve(response.data);                    
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                }
            )
        });
    }

    return {
        BuscarEvento,
        loading,
        setLoading,
        ValidarInvitado,
        GenerarQRInformacion,
        BuscarInvitado,
        response
    }
}

export default useVisitas;