import { Box, CardMedia, Typography } from "@mui/material";
import "./AsistenciaConfirmadaPage.css";

import CP2IMG from "./../../../assets/imagenes/CP2.png";

export const AsistenciaConfirmadaPage = () => {
  return(
    <Box id="ContainerAsistenciaConfirmada">
        <Box id="BoxCabeceraAsistenciaConfirmada">
            <Box id="BoxContenedorIMGCP2AsistenciaConfirmada">
                <CardMedia 
                    id="CMImagenCP2" 
                    src={CP2IMG} 
                    component="img"
                    sx={{
                        objectFit: 'contain', // Esta propiedad hace que la imagen se ajuste al contenedor sin distorsionarse. La imagen será redimensionada para que encaje completamente dentro del contenedor, manteniendo sus proporciones originales.
                    }}
                />
            </Box>
            <Box id="BoxTituloAsistenciaConfirmada">
                <Typography id="TxtTituloAsistenciaConfirmada">
                    {
                        "¡Asistencia Confirmada!"
                    }
                </Typography>
            </Box>
        </Box>
        <Box id="BoxTituloMensajeAsistenciaConfirmada">
            <Typography id="TxtMensajeAsistenciaConfirmada">
                {
                    "Su asistencia al evento ha sido confirmada con éxito. Puede cerrar esta página."
                }
            </Typography>
        </Box>
    </Box>
  );
}
