import { Box, Divider, IconButton, Modal, Typography } from "@mui/material";
import { IconCerrarAccesos } from "../../../GestionAccesos/IconsGestionAccesos";
import "./ModalNotificacion.css"
import { BotonTipo2 } from "../../../Botones/BotonesTabla/Botones";
import dayjs from "dayjs";


export const ModalNotificacion = ({ open, setOpen, notificacion }) => {

    const handlerClose = () => {
        setOpen(false);
    }

    return (
        <Modal open={open} onClose={handlerClose}>
            <Box sx={{ ...style }}>
                <Box id="CabeceraModalNotificacion">
                    <Box id="BoxTituloModalNotificacion">
                        <Typography id="TituloModalNotificacion">
                            {"Notificación"}
                        </Typography>                        
                    </Box>
                    <Box id="BoxIconoTituloModalNotificaciones">
                        <IconButton onClick={handlerClose}>
                            <IconCerrarAccesos sx={{fontSize: "10px"}}/>
                        </IconButton>
                    </Box>
                </Box>
                <Divider orientation="horizontal" flexItem />
                <Box id="BoxCuerpoModalNotificacion">   
                    <Box id="BoxFechaCuerpoModalNotificacion">
                        <Typography id="FechaModalNotificacion">
                            {
                                dayjs(notificacion.fecha).date() === dayjs(new Date()).date() ? "Hoy a las " + dayjs(notificacion.fecha).format("HH:mm") : dayjs(notificacion.fecha).format('DD/MM/YYYY HH:mm')
                            }
                        </Typography> 
                    </Box>
                     
                    <Box id="BoxMensajeCuerpoModalNotificacion">
                        <Typography id="TypographyContenidoNotificacion" variant="body1" color={"inherit"} fontFamily={"inherit"}>
                           {
                                notificacion.mensajeNotificacion
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxBotonModalNotificacion">
                        <BotonTipo2 textoBoton={"Aceptar"} event={handlerClose} />
                    </Box>            
                </Box>{/* Fin BoxCuerpoModalNotificacion */}

    
                
            </Box>
        </Modal>
    );
}


export default ModalNotificacion;

const style = {
    width: "460px",
    height: "320px",
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
  };