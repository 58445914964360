import axios from "axios";
const { useState, useEffect} = require("react");
const { VisitantesEnVisita } = require("../../Service/API/Api");

function useVisitantesEnVisita(idVisita){
    const [visitantes, setCantidadVisitantes] = useState([]);

    useEffect(() => {
        axios.get(VisitantesEnVisita + idVisita)
        .then((response) => {
            setCantidadVisitantes(response.data);
        }).catch((response) => {
            console.error("Error al realizar la operación");
        });
    }, [idVisita])
    


    return {
        visitantes
    }
}

export default useVisitantesEnVisita;