import { Box, Grid, IconButton, Typography } from "@mui/material"
import dayjs from "dayjs"
import useEventosData from "../../../../Hooks/Agenda/useEventosData";
import { useEffect, useState } from "react";
import "./CuerpoSemana.css"
import { Mayorque, Menorque } from "../../../Calendario/CalendarioIcons";
import { EventosDias } from "./EventosDias/EventosDias";

const semana = [
    {id: 0, nombre:"Domingo"},
    {id: 1, nombre:"Lunes"},
    {id: 2, nombre:"Martes"},
    {id: 3, nombre:"Miercoles"},
    {id: 4, nombre:"Jueves"},
    {id: 5, nombre:"Viernes"},
    {id: 6, nombre:"Sabado"}
]

const GetDayOfWeek = (day) => {
    return semana.find(d => d.id === day).nombre; 
}


export const CuerpoAgendaVistaSemana = ({fechaActual, addWeek, restWeek, setNewDate, setOpcion}) => {
    const {listaEventos} = useEventosData();
    const [dataSemana, setDataSemana] = useState([]);
    
    useEffect(() => {
        var dayOfWeek = dayjs(fechaActual).day();
        var diaInicial = ""
        var diaFinal = ""
        
       
        if(dayOfWeek === 0)//domingo
        {
            diaInicial = dayjs(fechaActual)
            diaFinal = dayjs(fechaActual).add(6, "day")
        }
        if(dayOfWeek === 1)//lunes
        {
            diaInicial = dayjs(fechaActual).add(-1, "day")
            diaFinal = dayjs(fechaActual).add(5, "day")
        }
        if(dayOfWeek === 2)//martes
        {
            diaInicial = dayjs(fechaActual).add(-2, "day")
            diaFinal = dayjs(fechaActual).add(4, "day")
        }
        if(dayOfWeek === 3)//miercoles
        {
            diaInicial = dayjs(fechaActual).add(-3, "day")
                
            diaFinal = dayjs(fechaActual).add(3, "day")
        }
        if(dayOfWeek === 4)//jueves
        {
            diaInicial = dayjs(fechaActual).add(-4, "day")
            diaFinal = dayjs(fechaActual).add(2, "day")
        }
        if(dayOfWeek === 5)//viernes
        {
            diaInicial = dayjs(fechaActual).add(-5, "day")
            diaFinal = dayjs(fechaActual).add(1, "day")
        }
        if(dayOfWeek === 6)//sabado
        {
            diaInicial = dayjs(fechaActual).add(-6, "day")
            diaFinal = dayjs(fechaActual)
        }
        var fechaInicioSemana = dayjs(diaInicial).set("h", 0).set("m", 0).set("s", 0);
        var fechaTerminoSemana = dayjs(diaFinal).set("h", 23).set("m", 59).set("s", 59)
        
        var listaDias = [{ id:0, fecha: fechaInicioSemana, eventos: []}, 
                        { id:1, fecha: fechaInicioSemana.add(1, 'days'), eventos: []},
                        { id:2, fecha: fechaInicioSemana.add(2, 'days'), eventos: []},
                        { id:3, fecha: fechaInicioSemana.add(3, 'days'), eventos: []},
                        { id:4, fecha: fechaInicioSemana.add(4, 'days'), eventos: []},
                        { id:5, fecha: fechaInicioSemana.add(5, 'days'), eventos: []},
                        { id:6, fecha: fechaTerminoSemana, eventos: []}]
       
        var listaEventosPeriodo = [];

        listaEventos.forEach(evento => {
            var inicio = dayjs(evento.fechaVisita)
            var fin = dayjs(evento.fechaTermino)
            if((inicio.isAfter(fechaInicioSemana) || inicio.isSame(fechaInicioSemana) )
                &&
            (fin.isBefore(fechaTerminoSemana) || fin.isSame(fechaTerminoSemana) ))
            {
                listaEventosPeriodo.push(evento);
            }
        });

        
        
        var listaOrdenadaFecha = listaEventosPeriodo.sort(e => e.fechaVisita)
        
        listaOrdenadaFecha.forEach(evento => {
            var inicioEvento = dayjs(evento.fechaVisita).format("DD/MM/YYYY");
            var finEvento = dayjs(evento.fechaTermino).format("DD/MM/YYYY");

            var horaEncontrada = listaDias.find(d => dayjs(d.fecha).format("DD/MM/YYYY") === inicioEvento && dayjs(d.fecha).format("DD/MM/YYYY") === finEvento)

            if(horaEncontrada !== undefined)
            {
                horaEncontrada.eventos.push(evento)
            }
            
        });
        
        setDataSemana(listaDias);
        
        
    }, [fechaActual, listaEventos])
    
    var horasDia = [
        {id:0, hora: "00:00"},
        {id:1, hora: "01:00"},
        {id:2, hora: "02:00"},
        {id:3, hora: "03:00"},
        {id:4, hora: "04:00"},
        {id:5, hora: "05:00"},
        {id:6, hora: "06:00"},
        {id:7, hora: "07:00"},
        {id:8, hora: "08:00"},
        {id:0, hora: "09:00"},
        {id:10, hora: "10:00"},
        {id:11, hora: "11:00"},
        {id:12, hora: "12:00"},
        {id:13, hora: "13:00"},
        {id:14, hora: "14:00"},
        {id:15, hora: "15:00"},
        {id:16, hora: "16:00"},
        {id:17, hora: "17:00"},
        {id:18, hora: "18:00"},
        {id:19, hora: "19:00"},
        {id:20, hora: "20:00"},
        {id:21, hora: "21:00"},
        {id:22, hora: "22:00"},
        {id:23, hora: "23:00"}
    ]

   
    return(
        <Box id="BoxLayoutVistaSemana">
            <Box id="BoxVistaCalendarioSemana">
                <Grid id="ContenedorHoras" container spacing={0}>
                    <Grid item id="EspacioLibreVistaSemana">
                    </Grid>
                    <Grid item id="GridItemAccion">
                        <IconButton onClick={restWeek}>
                            <Menorque id="iconoAccionCambioVistaDiaAgenda" />
                        </IconButton>
                    </Grid>
                    <Grid container spacing={0}>
                        {
                            horasDia.map((item, index) => {
                                return(
                                    <Grid id="HoraDia" item key={index}>
                                        <Typography id="TxtHoraDiaVistaDiaAgenda">
                                            {
                                                item.hora
                                            }
                                        </Typography>
                                    </Grid>
                                );                      
                            })
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={0} id="BoxContenedorSemana">
                    {
                        dataSemana.map((item, index) => {
                            let isActualDate = dayjs(item.fecha).format("DD/MM/YYYY") === dayjs(new Date()).format("DD/MM/YYYY") ? true : false

                            return(
                               <Grid container key={index} id="GridContenedorDiaSemana" >
                                    <Grid item id="GridNombreDiaVistaSemana" sx={{ borderRadius: "15px 15px 0px 0px"}}>
                                        <Typography id="TxtFechaNombreVistaSemana">
                                            {
                                                GetDayOfWeek(dayjs(item.fecha).day())
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item id="GridDiaVistaSemana" sx={{
                                        border: isActualDate ? "1px solid black": null,
                                        background: isActualDate ? "#175676":null, 
                                        borderRadius:"10px"}}>
                                            <Typography id="TxtNumeroDiaVistaSemana" sx={{ 
                                                color: isActualDate ? "#FFF":"#175676" }}>
                                                {
                                                    dayjs(item.fecha).format("DD")
                                                }
                                            </Typography>
                                        
                                    </Grid>
                                    <Grid container spacing={0} id="GridListaDeHorasDiaSemana"  >
                                        <EventosDias 
                                        eventosDia={item.eventos} 
                                        setNewDate={setNewDate} 
                                        setOpcion={setOpcion}
                                        />
                                    </Grid>
                               </Grid>
                            );
                        })
                    }
                </Grid>
                <Grid id="ContenedorHoras" container spacing={0}>
                    <Grid item id="EspacioLibreVistaSemana">
                    </Grid>
                    <Grid item id="GridItemAccion">
                        <IconButton onClick={addWeek}>
                            <Mayorque id="iconoAccionCambioVistaDiaAgenda" />
                        </IconButton>
                    </Grid>                    
                    <Grid  container spacing={0}>
                        {
                            horasDia.map((item, index) => {
                                return(
                                    <Grid id="HoraDia" item key={index}>
                                        <Typography id="TxtHoraDiaVistaDiaAgenda">
                                            {
                                                item.hora
                                            }
                                        </Typography>
                                    </Grid>
                                );                      
                            })
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}