import axios from "axios";
import { useEffect, useState } from "react";
import { FacialUsuario } from "../../Service/API/Api";

export default function useFacialUsuario(rut)
{
    const [facial, setFacial] = useState([]);

    useEffect(() => {
        axios.get(FacialUsuario + rut)
        .then(
            (response) => {
                setFacial(response.data);
            }
        )
        .catch(
            (error) => {
                // console.log(error.response);
            }
        )
    }, [rut])

    return {
        
        facial
    }
    
}


