/** server */
const BACK = "https://api.control-pass.com" //"http://192.168.30.136:2952" //"https://api.control-pass.com" //"http://localhost:2952" //"http://192.168.30.126:2952" //"https://api.control-pass.com" //"http://186.10.124.182:2952"; //"http://192.168.30.120:2952", "http://localhost:2952", "https://api.control-pass.com" // "http://100.29.5.28"

/** Accesos */
export const CrearAcceso = `${BACK}/Acceso/Crear`;
export const BuscarAcceso = `${BACK}/Acceso/Buscar?idAcceso=`; //busca por su id, debe agregarse en la query
export const BuscarAccesosEmpresa = `${BACK}/Acceso/Buscar/Empresa`;
export const AccesosUsuario = `${BACK}/Acceso/Validar/Empresa/Persona?idPersona=`; //busca por su id, debe agregarse en la query
export const EditarAcceso = `${BACK}/Acceso/Editar`;
export const DesactivarAcceso = `${BACK}/Acceso/Desactivar?idAcceso=`; //segun la id
export const ReactivarAcceso = `${BACK}/Acceso/Reactivar?idAcceso=`; //segun la id
export const CargaMasivoAcceso = `${BACK}/Acceso/Masivo/Cargar`;
export const AccesosSegunUsuario = `${BACK}/Acceso/Usuario?idPersona=`;
export const EliminarAcceso = `${BACK}/Acceso/Borrar/Usuario?idAcceso=`


/** Actor */
export const CrearActuador = `${BACK}/Actuador/Crear`;
export const BuscarActuador = `${BACK}/Actuador/Buscar?idActor=`; //segun la id
export const BuscarActuadoresEmpresa = `${BACK}/Actuador/Actores/Empresas/Buscar?idEmpresa=`; 
export const BuscarActuadorZonaEmpresa = `${BACK}/Actuador/Buscar/Empresa/Zona?idZona=`; //segun la id
export const EditarActuador = `${BACK}/Actuador/Editar`;
export const DesactivarActuador = `${BACK}/Actuador/Desactivar?idActor=`;
export const ReactivarActuador = `${BACK}/Actuador/Reactivar?idActor=`;
export const VerificadorEstadoEquipoZona = `${BACK}/Actuador/Estados/Verificador?idZona=`; //segun id
export const ActuadorSegunTipo = `${BACK}/Actuador/Lista?idTipo=`;//segun tipo de actuador que se esta buscando
export const DesactivarRemoto = `${BACK}/Actuador/Remoto/desactivar?idActor=`;//segun idActor
export const ActivarRemoto = `${BACK}/Actuador/Remoto/activar?idActor=`;//segun idActor

/** Horario */
export const CrearHorario = `${BACK}/Horario/Crear`;
export const BuscarHorario = `${BACK}/Horario/Buscar?idHorario=`; // segun id
export const BuscarHorarioPersona = `${BACK}/Horario/Empresa/Persona?idPersona=`; // segun id
export const HorariosEmpresa = `${BACK}/Horario/Empresa`;
export const EditarHorario = `${BACK}/Horario/Editar`;
export const ListaHorariosAsignacionEmpresa = `${BACK}/Horario/Lista/Horario/Empresa`;

/** Asignacion horario */
export const AsignarHorario = `${BACK}/Asignacion/Crear`;
export const BuscarAsignacionHorario = `${BACK}/Asignacion/Buscar?idAsignacion=`; //agregar id
export const BuscarAsignacionHorarioEmpresa = `${BACK}/Asignacion/Buscar/Empresa`;
export const BuscarAsignacionHorarioPersona = `${BACK}/Asignacion/Buscar/Empresa/Persona?idPersona=`; //agregar id
export const EditarAsignacionUsuario = `${BACK}/Asignacion/Editar`;
export const DesactivarAsignacion = `${BACK}/Asignacion/Desactivar/Empresa/Asignacion?idAsignacion=`; //agregar id
export const ReactivarAsignacion = `${BACK}/Asignacion/ReActivar/Empresa/Asignacion?idAsignacion=`; //agregar id
export const AsignacionesUsuario = `${BACK}/Asignacion/Empresa/Asignacion/usuario?idPersona=`; //agregar id

/** Botones */
export const CrearBoton = `${BACK}/Botones/Crear`;
export const EditarBoton = `${BACK}/Botones/Editar`;
export const Eliminar = `${BACK}/Botones/Eliminar?idBotonEmpresa=`; //agregar id
export const ObtenerBotonesEmpresa = `${BACK}/Botones/Obtener`;

/** Camaras NVR Live */
export const MisCamaras = `${BACK}/Camara/Live`;

/** Acciones a realizar con Camaras NVR */
export const ReiniciarCamara = `${BACK}/Camara/Reiniciar?ip=`; //agregar ip
export const TomarCaptura = `${BACK}/Camara/Capturar?ip=`; //agregar ip
export const CapturasPorPeriodo = `${BACK}/Camara/Capturas/Periodo?ip=`; //agregar ip
export const IniciarGrabacion = `${BACK}/Camara/Grabar/Iniciar?ip=`; //agregar ip
export const DetenerGrabacion = `${BACK}/Camara/Grabar/Detener?ip=`; //agregar ip
export const GrabacionesPorPeriodo = `${BACK}/Camara/Grabaciones/Periodo?ip=`; //agregar ip
export const ReproducirGrabacion = `${BACK}/Camara/Grabacion/Reproducir`; //agregar ip
export const DescargarRecord = `${BACK}/Camara/Descarga`; //agregar ip
export const GetCamaras = `${BACK}/API/Movil/Cam/Live`; 

/** Control de versiones */
export const CrearVersion = `${BACK}/Version/Crear`;
export const BuscarVersion = `${BACK}/Version/Buscar?idControlVersion=`; //agregar id
export const EditarVersion = `${BACK}/Version/Editar`;
export const EliminarVersion = `${BACK}/Version/Eliminar?idControlVersion=`; //agregar id

/** Dispositivo */
export const RegistrarDispositivo = `${BACK}/Dispositivo/Crear`;

/** Empresa */
export const CrearEmpresa = `${BACK}/Empresa/Crear`;
export const BuscarEmpresa = `${BACK}/Empresa/Buscar?idEmpresa=`; //agregar id
export const BuscarEmpresasSociedad = `${BACK}/Empresa/Sociedad?idSociedad=`; //agregar id
export const TodasLasEmpresas = `${BACK}/Empresa/Buscar/Todos`;
export const EditarEmpresa = `${BACK}/Empresa/Editar`;
export const DesactivarEmpresa = `${BACK}/Empresa/Desactivar?idEmpresa=`; //agregar id
export const ReactivarEmpresa = `${BACK}/Empresa/Reactivar?idEmpresa=`; //agregar id

/** Error */
export const BuscarErrores = `${BACK}/Error/Buscar/Todos`;
export const EditarError = `${BACK}/Error/Editar`;

/** Estacionamiento */
export const CrearEstacionamiento = `${BACK}/Estacionamiento/Crear`;
export const BuscarEstacionamiento = `${BACK}/Estacionamiento/Buscar?idEstacionamiento=`; //agregar id
export const BuscarEstacionamientosEmpresa = `${BACK}/Estacionamiento/Empresa`;
export const EditarEstacionamiento = `${BACK}/Estacionamiento/Editar`;
export const DesactivarEstacionamiento = `${BACK}/Estacionamiento/Desactivar?idEstacionamiento=`; //agregar id
export const ReactivarEstacionamiento = `${BACK}/Estacionamiento/Reactivar?idEstacionamiento=`; //agregar id

/** Facial */
export const BuscarFacial = `${BACK}/Facial/Buscar?idFacial=`; //agregar id
export const FacialUsuario = `${BACK}/Facial/usuario?idPersona=`; //agregar rut a buscar
export const BuscarFacialesEmpresa = `${BACK}/Facial/Registros/Empresa`;
export const EliminarFacial = `${BACK}/Facial/Eliminar?idFacial=`;  //agregar id
export const AsignarFacialesAActuador = `${BACK}/Facial/Asignar?idActor=`; //agregar id

/** Historial notificaciones */
export const Historial = `${BACK}/Historial/Historial`;
export const EliminarRegistroHistorial = `${BACK}/Historial/Historial/Eliminar?idHistorial=`; //agregar id

/** huella */
export const BuscarHuella = `${BACK}/Huella/Buscar?idHuella=`; //agregar id
export const HuellasUsuario = `${BACK}/Huella/usuario?idPersona=`; //agregar id
export const EliminarHuella = `${BACK}/Huella/Eliminar?idHuella=`; //agregar id
export const BuscarHuellasEmpresa = `${BACK}/Huella/Empresa`;
export const AsignarHuellasAActuador = `${BACK}/Huella/Enviar?idActor=`; //agregar id

/** Lista Negra */
export const CrearListaNegra = `${BACK}/ListaNegra/Crear`;
export const BuscarListaNegra = `${BACK}/ListaNegra/Buscar?idListaNegra=`; //agregar id
export const BuscarListaNegraEmpresa = `${BACK}/ListaNegra/Empresa`;
export const EditarListaNegra = `${BACK}/ListaNegra/Editar`;
export const EliminarDeListaNegra = `${BACK}/ListaNegra/Usuario/Borrar?idListaNegra=`;
export const ListaNegraUsuario = `${BACK}/ListaNegra/Usuario/Buscar`;
export const UsuariosNoEnListaNegra = `${BACK}/Persona/Usuarios/ListaNegra/NoIngresados`;

/** Pase */
export const CrearPase = `${BACK}/Pase/Crear`;
export const BuscarPase = `${BACK}/Pase/Buscar?idPase=`;//agregar id
export const BuscarPasePersona = `${BACK}/Pase/Buscar/Persona?idPersona=`;//agregar id
export const BuscarPasesEmpresa = `${BACK}/Pase/Buscar/Empresa`;
export const EditarPase = `${BACK}/Pase/Editar`;
export const DesactivarPase = `${BACK}/Pase/Buscar/Desactivar?idPase=`;//agregar id
export const ReActivarPase = `${BACK}/Pase/Buscar/Reactivar?idPase=`;//agregar id
export const EliminarPase = `${BACK}/Pase/Eliminar?idPase=`;//agregar id

/** Patente */
export const CrearPatente = `${BACK}/Patente/Crear`;
export const BuscarPatente = `${BACK}/Patente/Buscar?idPatente=`;//agregar id
export const BuscarPatentePersona = `${BACK}/Patente/Buscar/Persona?idPersona=`;//agregar id
export const BuscarPatentePersonaAsignacion = `${BACK}/Patente/Buscar/Persona/Asignaciones?idPersona=`;//agregar id
export const BuscarPatenteEmpresa = `${BACK}/Patente/Buscar/Empresa`;
export const EditarPatenteUsuario = `${BACK}/Patente/Editar`;
export const DesactivarPatente = `${BACK}/Patente/Desactivar?idPatente=`;
export const ActivarPatente = `${BACK}/Patente/reactivar?idPatente=`;
export const BuscarPatenteUbicacion = `${BACK}/Patente/Buscar/Empresa/Ubicacion?idUbicacion=`;//agregar id

/** Persona */
export const LOGINPATH = `${BACK}/Persona/Ingresar`;
export const LOGOUT = `${BACK}/Persona/Salir`;
export const CrearSA = `${BACK}/Persona/Crear/SA`;
export const CrearAdmin = `${BACK}/Persona/Crear/Admin`;
export const CrearUsuario = `${BACK}/Persona/Crear/Usuario`;
export const ConfirmacionCorreo = `${BACK}/Persona/Confirmacion/Correo`;
export const BuscarUsuario = `${BACK}/Persona/Buscar?idPersona=`; //agregar id
export const BuscarUsuariosEmpresa = `${BACK}/Persona/MostrarUsuarios`;
export const EditarUsuario = `${BACK}/Persona/Editar`;
export const EditarUsuarioTabla = `${BACK}/Persona/Editar/Usuario`;
export const CambioContrasena = `${BACK}/Persona/Cambio/Contrasena`;
export const OlvidoContrasena = `${BACK}/Persona/Olvido`;
export const RecuperarContrasena = `${BACK}/Persona/Recuperar`; //aqui se agrega el token
export const ReactivarUsuario = `${BACK}/Persona/Reactivar?idPersona=`; //agregar id
export const DesactivarUsuario = `${BACK}/Persona/Desactivar?idPersona=`; //agregar id
export const CambioCorreo = `${BACK}/Persona/Cambiar/Correo`;
export const CompletarCambioCorreo = `${BACK}/Persona/Cambiar/Correo/Confirmacion`; 
export const CargaMasivaUsuarios = `${BACK}/Persona/Crear/Masivo`;
export const ActivarAccesoAPPMovil = `${BACK}/Persona/Usuarios/Movil/Activar?idPersona=`;
export const DesactivarAccesoAPPMovil = `${BACK}/Persona/Usuarios/Movil/Desactivar?idPersona=`;  
export const ListaUsuariosEmpresa = `${BACK}/Persona/Usuarios/Todos`;  
export const CargaImagenPerfil = `${BACK}/Persona/Perfil/Imagen/Crear`;  

/** Persona Empresa */

export const DesactivarUsuarioEnEmpresa = `${BACK}/PersonaEmpresa/Desactivar?idPersona=`; //agregar id
export const ReActivarUsuarioEnEmpresa = `${BACK}/PersonaEmpresa/Reactivar?idPersona=`; //agregar id
export const AsignarNotificador = `${BACK}/PersonaEmpresa/Admin/Asignar?Correo=`; //agregar correo

/** Persona Estacionamiento */
export const CrearPersonaEstacionamiento = `${BACK}/PersonaEstacionamiento/Crear`;
export const BuscarPersonaEstacionamiento = `${BACK}/PersonaEstacionamiento/Buscar?idPersonaEstacionamiento=`; //agregar id
export const PersonaEstacionamientoEmpresa = `${BACK}/PersonaEstacionamiento/Buscar/Empresa`;
export const EditarPersonaEstacionamiento = `${BACK}/PersonaEstacionamiento/Editar`;
export const EliminarPersonaEstacionamiento = `${BACK}/PersonaEstacionamiento/Eliminar?idPersonaEstacionamiento=`; //agregar id

/** Persona Ubicacion */
export const CrearPersonaUbicacion = `${BACK}/PersonaUbicacion/Crear`;
export const BuscarPersonaUbicacion = `${BACK}/PersonaUbicacion/Buscar?idPersonaUbicacion=`; //agregar id
export const BuscarPersonasUbicacion = `${BACK}/PersonaUbicacion/Buscar/Ubicacion?idUbicacion=`; //agregar id
export const EditarPersonaUbicacion = `${BACK}/PersonaUbicacion/Editar`;
export const EliminarPersonaUbicacion = `${BACK}/PersonaUbicacion/Eliminar?idPersonaUbicacion=`; //agregar id
export const BuscarPersonasyUbicaciones = `${BACK}/PersonaUbicacion/Buscar/Empresa`;
export const BuscarUbicacionPersona = `${BACK}/PersonaUbicacion/Buscar/Empresa/Persona?idPersona=`; //agregar id
export const CargaMasivaUbicacionPersona = `${BACK}/PersonaUbicacion/Masivo/Cargar`;
export const ListaUsuariosSinUbicacion = `${BACK}/PersonaUbicacion/Buscar/Ubicacion/NoAsignado`;
/** PinPass */
export const CrearPinPass = `${BACK}/PinPass/Crear`;
export const BuscarPinPass = `${BACK}/PinPass/Buscar?idPinPass=`; //agregar id
export const CrearPinPassPersona = `${BACK}/PinPass/Buscar/Persona?idPersona=`; //agregar id
export const EditarPinPass = `${BACK}/PinPass/Editar`; //agregar id

/** Registro */
export const TiempoRealEmpresa = `${BACK}/Registro/TiempoReal/Empresa`;
export const PreviewReporteEmpresa = `${BACK}/Registro/Reporte/Empresa`;
export const ExportarReporte = `${BACK}/Registro/Reporte/Exportar`;
export const ExportarUsuariosEmpresa = `${BACK}/Registro/Reporte/Exportar/Usuarios/Empresa`;
export const ExportarVisitasDelDiaEmpresa = `${BACK}/Registro/Reporte/Exportar/Visitas/Empresa?hoy=`; //agregar fecha
export const ReportesGraficos = `${BACK}/Registro/Reporte/Grafico`
export const ReporteUsuariosFormato = `${BACK}/Registro/Reporte/Exportar/Usuarios/Formato`

/** Sociedad */
export const CrearSociedad = `${BACK}/Sociedad/Crear`;
export const BuscarSociedad = `${BACK}/Sociedad/Buscar?idSociedad=`; //agregar id
export const TodasLasSociedades = `${BACK}/Sociedad/Todos`;
export const EditarSociedad = `${BACK}/Sociedad/Editar`;
export const EliminarSociedad = `${BACK}/Sociedad/Eliminar?idSociedad=`; //agregar id

/** Tareas Actor */
export const ObtenerTareasActor = `${BACK}/ActorTarea/Tareas?idActor=`; //agregar id
export const CrearTarea = `${BACK}/ActorTarea/Crear`;
export const EliminarTarea = `${BACK}/ActorTarea/Eliminar?idActorTarea=`;
export const EditarTarea = `${BACK}/ActorTarea/Editar`;

/** tarjeta */
export const CrearTarjeta = `${BACK}/Tarjeta/Crear`;
export const BuscarTarjetasEmpresa = `${BACK}/Tarjeta/Tarjetas/Empresa`;
export const EditarTarjeta = `${BACK}/Tarjeta/Editar`;
export const DesactivarTarjeta = `${BACK}/Tarjeta/Desactivar?idTarjeta=`; //agregar id
export const ReactivarTarjeta = `${BACK}/Tarjeta/Reactivar?idTarjeta=`; //agregar id
export const TarjetasDisponibles = `${BACK}/Tarjeta/Disponibles`; 
export const TarjetasFormatoImporte = `${BACK}/Tarjeta/Masiva/Formato/Importe`;
export const CargaMasivaTarjeta = `${BACK}/Tarjeta/Nuevas/Masiva`;

/** Tipos Actuadores */
export const CrearTipoActuador = `${BACK}/TipoActor/Crear`;
export const BuscarTipoActuador = `${BACK}/TipoActor/Buscar?idTipoActor=`; //agregar id
export const TiposActuadores = `${BACK}/TipoActor/Tipos`;
export const EditarTipoActuador = `${BACK}/TipoActor/Editar`;

/** Tipos Empresas */
export const CrearTipoEmpresa = `${BACK}/TipoEmpresa/Crear`;
export const BuscarTipoEmpresa = `${BACK}/TipoEmpresa/Buscar?idTipoEmpresa=`; //agregar id
export const TiposEmpresa = `${BACK}/TipoEmpresa/Tipos`;
export const EditarTipoEmpresa = `${BACK}/TipoEmpresa/Editar`;

/** Tipos Personas */
export const CrearTipoPersona = `${BACK}/TipoPersona/Crear`;
export const BuscarTipoPersona = `${BACK}/TipoPersona/Buscar?idTipoPersona=`; //agregar id
export const EditarTipoPersona = `${BACK}/TipoPersona/Editar`;

/** Tipos Personas Empresa*/
export const TiposPersonaEmpresa = `${BACK}/TiposPersonaEmpresa/Tipos/Todos`;

/** Tipos Tareas */
export const TiposTareas = `${BACK}/TipoTarea/Todos`;

/** Tipos Tarjetas */
export const CrearTipoTarjeta = `${BACK}/TipoTarjeta/Crear`;
export const BuscarTipoTarjeta = `${BACK}/TipoTarjeta/Buscar?idTipoTarjeta=`; //agregar id
export const TiposTarjetas = `${BACK}/TipoTarjeta/Tipos`;
export const EditarTipoTarjeta = `${BACK}/TipoTarjeta/Editar`;

/** Tipos Ubicaciones */
export const CrearTipoUbicacion = `${BACK}/TipoUbicacion/Crear`;
export const BuscarTipoUbicacion = `${BACK}/TipoUbicacion/Buscar?idTipoUbicacion=`; //agregar id
export const TiposUbicacionesEmpresa = `${BACK}/TipoUbicacion/Empresa`;
export const EditarTipoUbicacion = `${BACK}/TipoUbicacion/Editar`;

/** Tipos Visitas */
export const CrearTipoVisita = `${BACK}/TipoVisita/Crear`;
export const BuscarTipoVisita = `${BACK}/TipoVisita/Buscar?idTipoVisita=`; //agregar id
export const TiposVisitasEmpresa = `${BACK}/TipoVisita/Buscar/Empresa`;
export const EditarTipoVisita = `${BACK}/TipoVisita/Editar`;

/** Ubicacion */
export const CrearUbicacion = `${BACK}/Ubicacion/Crear`;
export const BuscarUbicacion = `${BACK}/Ubicacion/Buscar?idUbicacion=`; //agregar id
export const UbicacionesEnEmpresa = `${BACK}/Ubicacion/Ubicaciones/Empresa`;
export const EditarUbicacion = `${BACK}/Ubicacion/Editar`;
export const EliminarUbicacion = `${BACK}/Ubicacion/Buscar/Eliminar?idUbicacion=`; //agregar id
export const UbicacionesEnZona = `${BACK}/Ubicacion/Buscar/Zonas?idZona=`; //agregar id
export const CargaMasivaUbicaciones = `${BACK}/Ubicacion/Masivo/Cargar`; //agregar id
export const UbicacionesParaEventos = `${BACK}/Ubicacion/Empresa/Todos/Disponibles`;
export const UbicacionesFormatoImporte = `${BACK}/Ubicacion/Masivo/Formato/Importe`;


/** Visita */
export const CrearVisita = `${BACK}/Visita/Crear`;
export const BuscarVisita = `${BACK}/Visita/Buscar?idVisita=`; //agregar id
export const BuscarVisitaRecurrentes = `${BACK}/Visita/Buscar/Recurrentes?idVisita=`; //agregar id
export const VisitasCreadasPorUsuario = `${BACK}/Visita/Persona?idPersona=`;  //agregar id
export const VisitasEmpresaPeriodo = `${BACK}/Visita/Empresa/Todos`;
export const VisitasCalendario = `${BACK}/Visita/Empresa/Buscar/Todos`;
export const EditarVisita = `${BACK}/Visita/Editar`;
export const DesactivarVisita = `${BACK}/Visita/Desactivar?idVisita=`; //agregar id
export const ReActivarVisita = `${BACK}/Visita/Reactivar?idVisita=`; //agregar id

/** Visitantes */
export const CrearVisitante = `${BACK}/Visitantes/Crear`;
export const BuscarVisitante = `${BACK}/Visitantes/Buscar?idVisitante=`; //agregar id
export const VisitantesEnVisita = `${BACK}/Visitantes/Visita?idVisita=`; //agregar id
export const VistantesAnteriores = `${BACK}/Visitantes/Persona?idPersona=`; //agregar id
export const EditarVisitante = `${BACK}/Visitantes/Editar`;
export const EliminarVisitante = `${BACK}/Visitantes/Eliminar?idvisitante=`;//agregar id
export const BuscarVisitanteTelefono = `${BACK}/Buscar/Telefono?telefono=`;//agregar telefono a  buscar
export const BuscarVisitanteRut = `${BACK}/Buscar/Rut?rut=`;//agregar rut a buscar
export const BuscarVisitantePasaporte = `${BACK}/Buscar/Pasaporte?pasaporte=`;//agregar rut a buscar
export const ValidarInvitacionRut = `${BACK}/Buscar/Visitante/Rut`;
export const ValidarInvitacionPasaporte = `${BACK}/Buscar/Visitante/Pasaporte`;
export const BuscarEstadoVisitante= `${BACK}/Buscar/Visitante/Estado`;
export const ListaVisitantesEmpresa = `${BACK}/Visitantes/Visitantes/Todos`;  
export const GenerarQRData = `${BACK}/Visitantes/Generar/QR?idVisitaVisitante=`;
export const VisitantesFormatoImporte = `${BACK}/Visitantes/Invitados/Formato/Importe`;
export const CargaMasivaVisitante = `${BACK}/Visitantes/Invitados/Importe`;


/** Visita Visitante */
export const CrearVisitaVisitante = `${BACK}/VisitaVisitante/Crear`;
export const EditarVisitaVisitante = `${BACK}/VisitaVisitante/Editar`;
export const EliminarVisitaVisitante = `${BACK}/VisitaVisitante/Eliminar?idVisitaVisitante=`; //agregar id
export const EliminarTodosVisitantes = `${BACK}/VisitaVisitante/Evento/Eliminar/Todos?idVisita=`; //agregar id
export const EliminarVisitanteSeleccionados = `${BACK}/VisitaVisitante/Evento/Eliminar/Seleccionados`;
export const EnviarTodaslasInvitaciones = `${BACK}/VisitaVisitante/Evento/Invitaciones/Enviar/Todos`;
export const EnviarInvitacionIndividual = `${BACK}/VisitaVisitante/Evento/Invitaciones/Enviar`;
export const EnviarCorreoAccesoIndividual = `${BACK}/VisitaVisitante/Evento/Invitaciones/Enviar/Acceso`;
export const AceptarInvitacionPut = `${BACK}/Visitantes/Editar`;

/** Zona */
export const CrearZona = `${BACK}/Zona/Crear`;
export const BuscarZona = `${BACK}/Zona/Buscar?idZona=`; //agregar id
export const ZonasEmpresa = `${BACK}/Zona/Empresa`;
export const EditarZona = `${BACK}/Zona/Editar`;
export const DesactivarZona = `${BACK}/Zona/Desactivar?idZona=`; //agregar id
export const ReActivarZona = `${BACK}/Zona/ReActivar?idZona=`; //agregar id
export const DesactivarAPBZona = `${BACK}/Zona/APB/Desactivar?idZona=`; //agregar id
export const ReactivarAPBZona = `${BACK}/Zona/APB/Activar?idZona=`; //agregar id
export const CargaMasivaZona = `${BACK}/Zona/Masivo`;
export const ZonasEmpresaParaAsignar = `${BACK}/Zona/Lista/Empresa`;
export const ZonasSinAccesoUsuario = `${BACK}/Zona/Lista/Usuario/SinAcceso?idPersona=`; //agregar id
export const ZonasFormatoImporte = `${BACK}/Zona/Formato/Importe`
