import { Avatar, Box,IconButton, Menu, MenuItem, Typography } from "@mui/material";
import "./Profile.css";
import { CerrarPerfilIcon } from "./IconProfile";
import useAuth from "../../Hooks/Auth/useAuth";
import { useSelector } from "react-redux";
import Informacion from "./Informacion/Informacion";
import { useState } from "react";
import ImgCambioCorreo from "../../assets/imagenes/CambioCorreo.png"
import { useNavigate } from "react-router-dom";
import useConfirmDos from "../../Hooks/ConfirmDialog/useConfirmDialogDos";
import usePerfilUsuario from "../../Hooks/OpcionesPerfil/usePerfilUsuario";

const Profile = ({open, anchorEl, handleClosePerfil}) => {
    const data = useSelector(action => action.user.authenticated);
    const { user } = usePerfilUsuario(data.idPersona);
    const [ConfirmationDialogDos, confirmDos]= useConfirmDos();
    const navigate = useNavigate();
    const {Salir} = useAuth();
    const [openInfo, setOpenInfo] = useState(Boolean(false));
    const [tipo, setTipo] = useState(null);
    
    const handlerInfo = (tipo) =>{
        setTipo(tipo);
        setOpenInfo(true);
    }

    const handleCloseInfo = () =>{
        setOpenInfo(false);
        setTipo(null);
    }

    

    const handleCambiarCorreo = async () => {
        let response = await confirmDos();
        if(response === true){
            navigate("/cambiarEmail", { replace: true, state: { correoActual: data.correo}})
        }
        else{
            console.log("rechaza cambio");
        }
    };


    return (
        <Box>
            <Menu
            id="MenuPerfilUsuario"
            disableRestoreFocus={true}
            keepMounted
            aria-disabled
            open={open} 
            anchorEl={anchorEl} 
            onClose={handleClosePerfil} 
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} 
            slotProps={{paper:{sx:{overflowY:"hidden", borderRadius: '16px'}}}}
            sx={{"& .MuiList-root":{padding:"0px"}}}
            >
                <Box id="CardOpcionesPerfil">
                    <Box id="CardHeader">
                        <Typography variant="inherit" id="TextoHeader">
                            Mi Perfil
                        </Typography>
                        <IconButton id="BotonCerrarPerfil" onClick={handleClosePerfil}>
                            <CerrarPerfilIcon id="CerrarIconPerfil" />
                        </IconButton>
                    </Box>
                    <Box id="PerfilContent">
                        <Box id="BoxImagenDataPerfil">
                            <Box id="BoxImagenPerfil">
                                <Avatar id="ImagenUsuarioPerfil" src={user?.urlImagenPerfil ? `data:image/jpeg;base64,${user.urlImagenPerfil}` : "#"}
                                />
                            </Box>
                            <Box id="TextoImagenPerfil">
                                <Typography id="NombreUsuarioPerfil">
                                    {
                                        data.usuario
                                    }
                                </Typography>
                                <Typography id="TipoUSuarioEnPerfil">
                                    {
                                        data.rol
                                        
                                    }
                                </Typography>
                            </Box>
                            </Box>
                            <Box id="BoxOpcionesUsuario">
                                <MenuItem id="botonPerfil" onClick={() => handlerInfo(1)} >
                                    Mi Información
                                </MenuItem>
                                <MenuItem id="botonPerfil" onClick={() => handlerInfo(2)} >
                                    Editar Contraseña
                                </MenuItem>
                                <MenuItem id="botonPerfil" onClick={handleCambiarCorreo} >
                                    Editar Correo Electrónico
                                </MenuItem>
                                <MenuItem id="botonPerfil" onClick={Salir} >
                                    Cerrar Sesión
                                </MenuItem>
                            </Box>
                    </Box>
                    
                </Box>
            </Menu>
        <Informacion 
        handle={handleCloseInfo} 
        openInfo={openInfo} 
        tipo={tipo} 
        handleOpenConfirmacion={handleCambiarCorreo} 
        />

        <ConfirmationDialogDos
        img={ImgCambioCorreo}
        titulo={"Confirmar cambio"}
        mensaje={"estas seguro(a) que quieres cambiar tu correo electrónico"}
        usuario={data.usuario.split(' ')[0]}
        />
        </Box>
    );
}

export default Profile;