import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputsBarraNavegacion from '../BarraNavegacion/inputsBarraNavegacion.js';
import { CandadoGestionZonas } from './IconsGestionAccesos.js';
import './EstilosGestionAccesos.css'
import useAcceso from '../../Hooks/Acceso/useAcceso.js'
import ModalNuevoAcceso from './ModalNuevoAcceso.js';
import CabeceraTablasGestion from '../CabeceraTablasGestion/CabecerasTablasGestion.js';
import { IconoAgregarNuevaRow } from '../CabeceraTablasGestion/IconosCabecera.js';
import useConfirm from '../../Hooks/ConfirmDialog/useConfirmDialog.js';
import { IconoAlerta } from '../Usuarios/UsuariosIcon.js';
import { SnackBarTipoUno } from '../snackBar/SnackBar.js';
import CollapsibleTable from './CollapsibleTable.js';

const opciones =  [{'nombre':'Estado', 'valor' : 0}, {'nombre':'Habilitados', 'valor' : 1}, {'nombre':'Desactivados', 'valor' : 2}]    

const GestionAccesos = () => {
    const [filtro, setFiltro] = useState(0);

    const {accesos, response, DeshabilitarActivarAcceso, loading, setLoading, EliminarAccesoEspecifico, CrearNuevoAcceso, AgregarNuevoAcceso, setAccesos} = useAcceso();
    // useEffect(() => {console.log("accesos => ",accesos)}, [accesos]);

    const [rows, setRows] = useState([]);
    // useEffect(() => {console.log("rows => ",rows)}, [rows]);

    const [nuevoAcceso, setNuevoAcceso] = useState(Boolean(false));
    const [searchText, setSearchText] = useState('');
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [titulo, setTitulo] = useState('');
    const [mensaje, setMensaje] = useState('');

    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);

    const handlerInputBarraNavegacion = (seleccion) => {

        let estadoFiltro = seleccion;
        if(seleccion === 0){
            setRows(accesos);
        }

        else {

            if(seleccion === 1){
                estadoFiltro = true;
            }
        

            else if(seleccion === 2){
                estadoFiltro = false;
            }

            const accesosFiltro = accesos.filter(acceso => acceso.estado === estadoFiltro);
            setRows(accesosFiltro);
        }
    }

    const handleOpenNT = () => {
        setNuevoAcceso(true); 
    }

    useEffect(() => {
        setRows(accesos);
    }, [accesos])

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);

        if(searchValue === ""){
            setRows(accesos);
        }

        else{
            const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
            const filteredRows = rows.filter((row) => {
                return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
                });
            });
            setRows(filteredRows);
        }
                
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value);

    }

    const clearSearch = () => {
        requestSearch('')
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const changeEstado = async  (row) =>{
        if (row.estado){
            setTitulo("¿Desactivar?")
            setMensaje("Estás a punto de desactivar a esta persona de la zona. ¿Deseas continuar?")            
        }else{
            setTitulo("¿Activar?")
            setMensaje("Estás a punto de activar a esta persona de la zona. ¿Deseas continuar?")
        }
        
        let respuesta = await confirm();

        if(respuesta){
            DeshabilitarActivarAcceso(row.idAcceso, row.estado)
            .then((res) => {

                const rowActualizado = {
                    ...row,
                    estado: !row.estado
                }
                const rowsActualizados = accesos.map((row) => (row.idAcceso === rowActualizado.idAcceso ? rowActualizado : row));
                setAccesos(rowsActualizados);
            })
            .catch((error) => (console.log("Error al habilitar o deshabilitar acceso: ",error)));
        }
    }

    ///////////////////////     CODIGO PARA EL CollapsibleTable /////////////////////////////////////////////////////////

    const columnas = [
        { field: 'nombrePersona', headerName: 'Nombres',   type: 'string', minwidth: 320, flex: 1, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'rut', headerName: 'Rut',   type: 'string', width: 190, headerAlign: 'center', align:'center', sortable: false, editable: false, valueFormatter: params => params?.value ?? "-"},
        { field: 'pasaporte', headerName: 'Pasaporte',   type: 'string', width: 190, headerAlign: 'center', align:'center', sortable: false, editable: false, valueFormatter: params => params?.value ?? "-"},
        { field: 'estadoUsuario', headerName: 'Estado Persona',   type: 'boolean', width: 120, headerAlign: 'center', align:'center', sortable: false, editable: false, valueFormatter: params => params?.value? "Habilitado" : "Deshabilitado"},
    ]
    const orderAccessByPersonId = (accesos) => {
        const idsPersonas = [...new Set(accesos.map(acceso => acceso.idPersona))];  

        const accesosPersonas = idsPersonas.map((idPersona) => {
            const accesosPersona = {
                idPersona: idPersona,
                nombrePersona: accesos.find((acceso) => acceso.idPersona === idPersona)?.nombrePersona?? "-",
                estadoPersona: accesos.find((acceso) => acceso.idPersona === idPersona)?.estadoPersona?? "-",
                rut: accesos.find((acceso) => acceso.idPersona === idPersona)?.rut?? "-",
                pasaporte: accesos.find((acceso) => acceso.idPersona === idPersona)?.pasaporte?? "-",
                accesos: accesos.filter((acceso) => acceso.idPersona === idPersona)
            }
            return accesosPersona;
        })
        // console.log("antes de retornar, accesosPersonas: ", JSON.stringify(accesosPersonas));
        return accesosPersonas;
    }

    const [accesosOrdenadosPorId, setAccesosOrdenadosPorId] = useState(accesos);
    useEffect(() => {
      setAccesosOrdenadosPorId(orderAccessByPersonId(rows))
    }, [rows])
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleEliminar = (id) => async () => {
        let rowEdit = rows.find(r => r.idAcceso === id);

        if(rowEdit.idAcceso === null){
            setSnackbarSeverity("error")
            setSnackMsg("Ha ocurrido un error al eliminar el acceso");
            setSnackbar(true);
        }
        else{
            setTitulo("¿Eliminar?");
            setMensaje("Estás a punto de eliminar este acceso ¿Deseas continuar?");
            let res = await confirm();
            if(res)
            {
                await EliminarAccesoEspecifico(rowEdit.idAcceso)
                    .then(
                        (resolve) => {
                            setAccesos(accesos.filter((row) => row.idAcceso !== id));
                            setSnackbarSeverity("success")
                            setSnackMsg("Asignacion eliminada correctamente")
                            setSnackbar(true); 
                        }
                        ,
                        (reject) => {
                            setSnackbarSeverity("error")
                            setSnackMsg("Ocurrio un error al eliminar el acceso, reintente nuevamente")
                            setSnackbar(true); 
                        }
                    )
            }
            else
            {
                console.log("cancelo la eliminacion de la asociacion");
            }
        }
    }//fin handleEliminar

    return (
        <Box id="BoxContenedorTablaAccesos">
            <CabeceraTablasGestion
                icono1={<CandadoGestionZonas/>}
                titulo={"Gestión de accesos"}
                data={rows}
                agregarNuevoIcon={<IconoAgregarNuevaRow />}

                //Acá debe ir una función que cambia el estado del ModalNuevoAcceso (variable nuevoAcceso) a true, osea hace uso de la misma función que se pone en el setOpen 
                // que es parte de las props del componente modal
                handlerAN={handleOpenNT}

                cargaMasivaIcon={null}
                descargarIcon={false}
                handlerCM={null}
                handlerDownload={null}
                value={searchText}
                onChange={handleSearch}
                clearSearch={clearSearch}
            >
                <Box id='BoxGestionAccesosNavA'>
                    <InputsBarraNavegacion opciones={opciones} filtro={filtro} setFiltro={setFiltro} handlerInputBarraNavegacion={handlerInputBarraNavegacion}/>
                </Box>
            </CabeceraTablasGestion>

            <CollapsibleTable
                columns={columnas}
                rows={accesosOrdenadosPorId}
                changeEstadoSwitch={(row) => changeEstado(row)}
                eliminarFila={(id) => handleEliminar(id)}
            />

            <ModalNuevoAcceso 
                open={nuevoAcceso} //Acá debe ir la variable que indica el estado booleano para abrir o no el modal
                setOpen={setNuevoAcceso} //Acá debe ir la función que cambia el estado de la variable añadida dentro de "open"
                setRows={setRows}
                loading={loading}
                setLoading={setLoading}
                response={response}
                crearNuevoAcceso={CrearNuevoAcceso}
                agregarNuevoAcceso={AgregarNuevoAcceso}

            />

            <SnackBarTipoUno 
                open={snackbar}
                handler={handleCloseSnackbar}
                duration={6000}
                severity={snackbarSeverity}
                msg={snackMsg}
            />

            <ConfirmationDialog
                icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
                titulo={titulo}
                mensaje={mensaje}
            />

        </Box>
    );
}

export default GestionAccesos;
