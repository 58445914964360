import { Box, Button, CardMedia, Checkbox, FormControlLabel, Typography } from "@mui/material";
import "./LoginVentanaUno.css"
import lcp2 from "../../../assets/imagenes/CP2.png"
import { BotonConLoading } from "../../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import { EmailIcon } from "../LoginIcons";
import { TextFieldDosPuntoDos, TextFieldDosPuntoUno } from "../../TextField/TextFieldDos";
import { IconoOjoAbierto, IconoOjoCerrado } from "../../Modal/Perfil/EditarContrasena/EditarContrasenaIcon";
import { useEffect, useState } from "react";
import { EsquemaLogin } from "./Esquemas";
import { useSelector } from "react-redux";
import useAuth from "../../../Hooks/Auth/useAuth";
import { useNavigate } from "react-router-dom";


export const LoginForm = ({handleOpcion}) => {
    const [mostrar, setMostrar] = useState(false);
    const navigate = useNavigate();
    const data = useSelector(action => action.user.authenticated);
    const { loading, setLoading, response, Iniciar } = useAuth();

    useEffect(() => {
      console.log("response ha cambiado: ", response)
    }, [response])

    useEffect(() => {
      console.log("loading ha cambiado: ", loading)
    }, [loading])
    
    

    const HandleToRecuperar = () => {
        handleOpcion(2)
    }

    useEffect(() => {
        if(data.authenticated){
            if(data.rol === "ADMINISTRADOR" || data.rol === "SUPERADMIN"){
                navigate("/inicio" , {replace: true})
            }
            else{
                console.log("rol no permitido")
            }
        }
    }, [data.authenticated, data.rol, navigate])

    const handleMostrar = () => {
        setMostrar(!mostrar);
    }

    const formik = useFormik({
        initialValues: {
            correo: "",
            contrasena: "",
            recordarMe: false,
        },
        validationSchema: EsquemaLogin,
        onSubmit: () => {
            setLoading(true);
            Iniciar(formik.values)
            .then(
                (resolve) => {
                    navigate("/inicio", {replace: true})
                },
                (reject) => {
                    console.log("fallo reject", reject)
                }
            )

        }
    })

    return (
        <Box id="BoxLoginFormVentanUno">
            <Box id="BoxContenidoLoginFormVentanaUno">
                <Box id="BoxContenidoImgCPLoginFormVentanaUno">
                    <CardMedia src={lcp2} component={"img"} id="ImgLogoCPLogin" />
                </Box>
                <Box id="BoxContenidoFormularioLoginFormVentanaUno" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxContenidoFormularioTituloLoginFormVentanaUno">
                        <Typography id="TxtTituloFormularioLoginFormVentanaUno">
                            {
                                "¡Bienvenido(a) a ControlPass!"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxContenidoFormularioCorreoLoginFormVentanaUno">
                        <TextFieldDosPuntoUno
                        id={"TxtFieldCorreoLoginFormVentanaUno"}
                        label="Correo electronico"
                        placeholder={"admin@admin.cl"}
                        icon={<EmailIcon id="IconoBorrarCorreoLoginFormVentanaUno" />}
                        disabled={false}
                        helper={formik.errors.correo}
                        value={formik.values.correo}
                        name={"correo"}
                        handlerChange={formik.handleChange}
                        handlerClick={() => formik.setFieldValue("correo", "")}
                        type={"email"}
                        />
                    </Box>
                    <Box id="BoxContenidoFormularioContrasenaLoginFormVentanaUno">
                        <TextFieldDosPuntoDos
                        id={"ContrasenaIdentificacionUsuarioLoginFormVentanaUno"}
                        blur={formik.handleBlur} 
                        label="Contraseña"
                        placeholder={"Contraseña"}
                        icon={<IconoOjoAbierto />}
                        icon2={<IconoOjoCerrado />}
                        disabled={false}
                        helper={formik.errors.contrasena}
                        value={formik.values.contrasena}
                        name={"contrasena"}
                        handlerChange={formik.handleChange}
                        handlerClick={handleMostrar}
                        type={mostrar === true ? "text" : "password"}
                        />
                    </Box>
                    <Box id="BoxContenidoFormularioRecordarRecuperarLoginFormVentanaUno">
                        <Box id="BoxCheckRememberMeLoginFormVentanaUno">
                            <FormControlLabel id="ControlLblChekRecordarmeLoginFormVentanaUno"
                                control=
                                    {
                                        <Checkbox sx={{ '&.Mui-checked': { color: "#CC261A" }, 
                                        '& .MuiSvgIcon-root': { fontSize: '20px' }, padding: "0px" }}
                                        value={formik.values.recordarMe} 
                                        checked={formik.values.recordarMe} 
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur} 
                                        name='recordarMe' 
                                        id="recordarMe" 
                                        />
                                    }
                                label=
                                    {
                                        <Typography id="TxtRecordarCuenta">
                                            {"Recordar Cuenta"}
                                        </Typography>
                                    }
                            />
                        </Box>
                        <Box id="BoxRecuperarContrasenaLoginFormVentanaUno">
                            <Button href="#" id="LinkOlvidoContrasenaLoginFormVentanaUno" onClick={HandleToRecuperar} >
                                <Typography id="TxtOlvidoContrasenaLoginFormVentanaUno">
                                    {"¿Olvidaste tu contraseña?"}
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxContenidoBtnAccionLoginFormVentanaUno">
                    <Box id="BoxBtnAccionIngresoLoginFormVentanaUno">
                        <BotonConLoading
                        loadingStatus={loading}
                        textoBoton={"Iniciar sesion"}
                        event={formik.submitForm}
                        />
                    </Box>
                    <Box id="BoxTextoEstadoIngreso" >
                        <Typography id="TxtEstadoIngresoLoginFormVentanaUno">
                            {
                                loading ?
                                "Validando, Espere por favor..."
                                : response === 200 ?
                                "Validacion exitosa ingresando..."
                                : response === 404 ?
                                "Usuario no encontrado, confirma tus datos de acceso..."
                                : response === null || response === undefined ?
                                null
                                :
                                typeof response === 'string' && isNaN(Number(response)) ?
                                    response
                                :
                                    "Oops algo ha fallado... Contactanos para mas información..."
                            }
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}