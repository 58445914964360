import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import TooltipTipoUno from '../ToolTip/Tooltip';
import { GridActionsCellItem } from '@mui/x-data-grid';
import useReportes from '../../Hooks/Reportes/useReportes';
import "./Reportes.css";
import { ComentarioIcono, ReportesIcon } from './ReportesIcons';
import dayjs from 'dayjs';
import CabeceraTablasGestion from '../CabeceraTablasGestion/CabecerasTablasGestion';
import { PopOverTipoDos, PopOverTipoReportes } from '../Popover/PopoverTipoUno';
import { DetalleRegistro } from '../Box/DetalleRegistro/DetalleRegistro';
import { FiltrosDescarga } from './FiltrosDescarga/FiltrosDescarga';
import DataGridMui from '../DataGrid/DataGridMui';




const ReportesComponent = () => {
    const {loading, reportes} = useReportes();
    const [rows, setRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElDescarga, setAnchorElDescarga] = useState(null);
    const [registroDetalle, setRegistroDetalle] = useState({nombreEvento:'',nombreDestino: '', destino: '', comentario:''});
    const [searchText, setSearchText] = useState('');    
    const openDetalle = Boolean(anchorEl);
    const openDescarga = Boolean(anchorElDescarga);

    useEffect(() => {
        
        setRows(reportes)
    }, [reportes])



    const handleDetalle = (e, row) => {
        setAnchorEl(e.currentTarget)
        setRegistroDetalle({
            nombreEvento: row.nombreEvento,
            nombreDestino: row.nombreDestino,
            destino: row.destino,
            comentario: row.comentario
        });
    }
    


    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = reportes.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field]);
            });
          });
        setRows(filteredRows);
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value)
    }

    const clearSearch = () => {
        requestSearch('')
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handlerOpenDescarga = (e) => {
        setAnchorElDescarga(e.currentTarget)
    }
    const columns = [
        { field: 'fechaRegistro', headerName: 'Fecha', width: 170, headerAlign: 'center', align:'center', sortable: false, editable: false, valueFormatter: (params) => dayjs(params?.value).format("DD/MM/YYYY HH:mm:ss")  },
        { field: 'rut', headerName: 'RUT', type: 'string',  width: 120, headerAlign: 'center', align:'center', sortable: false, editable: false, valueFormatter: (params) => params?.value ?? "-" },
        { field: 'pasaporte', headerName: 'Pasaporte', type: 'string',  width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false, valueFormatter: (params) => params?.value ?? "-" },
        { field: 'nombreCompleto', headerName: 'Nombres', type: 'string', flex: 1, minWidth: 170, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'nombreActuador', headerName: 'Lugar', type: 'string',  width: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'tipoActuador', headerName: 'Metodo', type: 'string',  width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'sentido', headerName: 'Sentido', type: 'string',  width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'codigoPatente', headerName: 'Patente', type: 'string',  width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'codigoTarjeta', headerName: 'Tarjeta', type: 'string',  width: 140, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'nombreZona', headerName: 'Zona', type: 'string',  width: 130, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'estadoAcceso', headerName: 'Acceso', type: 'string',  width: 100, headerAlign: 'center', align:'center', valueFormatter: (params) => params?.value === true ? "Concedido":"Denegado", sortable: false, editable: false},
        { field: 'opciones', headerName: 'Ver más', type:'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false, cellClassName: 'actions', 
            getActions:({row})=>{
                return [
                    <TooltipTipoUno titulo={"Detalle del registro"} ubicacion={"right-start"}>
                        <GridActionsCellItem
                        icon={<ComentarioIcono  id='iconoEnTablaUsuarios'/>}
                        label="Detalle del registro"
                        onClick={(e) => handleDetalle(e, row)}
                        />
                    </TooltipTipoUno>
                    ]
            }
        },
      ];

    
    return (
            <Box id="BoxContenedorTablaReportes">
                <CabeceraTablasGestion 
                icono1={<ReportesIcon />}
                titulo={"Reportes"}
                rows={rows}
                setRows={setRows}
                agregarNuevoIcon={null}
                handlerAN={null}
                cargaMasivaIcon={null}
                handlerCM={null}
                descargarIcon={true}
                handlerDownload={(e) => handlerOpenDescarga(e)}
                onChange={handleSearch}
                value={searchText}
                clearSearch={clearSearch}
                >
                </CabeceraTablasGestion>        

                <DataGridMui
                id='TablaReportes'
                getRowId={(row) => row.idRegistro}
                columns={columns}
                rows={rows}
                loading={loading}
                style={{ width:'100%'}}
                checkboxSelection={false}
                disableColumnFilter={true}
                disableColumnMenu={true}
                disableColumnSelector={true}
                disableDensitySelector={true}
                disableVirtualization={true}
                showCellVerticalBorder={false}
                showColumnVerticalBorder={false}
                hideFooterSelectedRowCount={true}
                rowSelection={true}
                disableRowSelectionOnClick={true}
                />
                
                
                <PopOverTipoDos 
                open={openDetalle}
                anchorEl={anchorEl}
                setOpen={setAnchorEl}
                children={<DetalleRegistro registroData={registroDetalle} />}
                />
                <PopOverTipoReportes 
                open={openDescarga}
                anchorEl={anchorElDescarga}
                setOpen={setAnchorElDescarga}
                children={<FiltrosDescarga setOpen={setAnchorElDescarga}/>}
                />
                
            </Box>
    );
}

export default ReportesComponent;
