import { IconButton, TextField } from "@mui/material";

export const TextFieldTres = ({id, label, placeholder, icon, helper, value, name, handlerChange, handlerClick, disabled}) => {

    
    return(
        <TextField id={id}
        label={label}
        placeholder={placeholder}
        autoComplete="off"
        name={name}
        value={value}
        fullWidth
        autoFocus={false}
        onChange={handlerChange}
        helperText={helper}
        disabled={disabled}
        sx={{ 
        "& .Mui-disabled":{":before":{borderBottomStyle:"solid"}}, 
        "& .MuiInputBase-root":{":before":{borderBottomStyle:"solid"}, paddingRight:"16px", background:"#F8F8F8"},
        "& .MuiFilledInput-input": {"& .Mui-disabled":{":before":{borderBottomStyle:"solid"}}}
        }}
        inputProps={{
            style:{
                color:"#333",
                paddingLeft: "16px" ,
                WebkitTextFillColor: "#333",
                backgroundColor: "#F8F8F8",
            },
        }}
        FormHelperTextProps={{
            style:{
                width:"100%",
                color:"#CC261A",
                fontFamily: "inherit",
                fontSize:"12px",
                fontStyle:"normal",
                fontWeight:"400",
                lineHeight:"16px",
                height:"10px",
                margin: "0px",
                background: "none",
                padding:"0px"
            }
        }}
        InputProps={{
            disableUnderline: true,
            sx:{display:"flex", 
                justifyContent:"center", 
                alignItems:"center", 
                backgroundColor:"#f8f8f8f8", 
                ":hover": {backgroundColor:"#f8f8f8f8"}
            },
            endAdornment:<IconButton
                            sx={{
                                padding: "0px",
                                }}
                            edge={"end"} onClick={() => {
                            // Verificamos que `disabled` sea false antes de ejecutar `handlerClick`
                            if (!disabled) {
                                handlerClick();
                            }
                        }}>{icon}
                         </IconButton> 
         }}
        InputLabelProps={{
            shrink: true,
            style:{
                paddingLeft: "5px",
                marginTop: "5px",
                color: "#626262",
                fontFamily: "inherit",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                
            },
        }}
        
        variant= "filled" />
    );
}



export const TextFieldTresPuntoDos = ({id, label, placeholder, icon, helper, value, name, handlerChange, handlerClick, disabled}) => {

    
    return(
        <TextField id={id}
        label={label}
        placeholder={placeholder}
        autoComplete="off"
        name={name}
        value={value}
        fullWidth
        autoFocus={false}
        onChange={handlerChange}
        helperText={helper}
        disabled={disabled}
        sx={{ 
        "& .Mui-disabled":{":before":{borderBottomStyle:"solid"}}, 
        "& .MuiInputBase-root":{":before":{borderBottomStyle:"solid"}, paddingRight:"16px", background:"#F8F8F8"},
        "& .MuiFilledInput-input": {"& .Mui-disabled":{":before":{borderBottomStyle:"solid"}}}
        }}
        inputProps={{
            style:{
                color:"#333",
                paddingLeft: "16px" ,
                WebkitTextFillColor: "#333",
            },
        }}
        FormHelperTextProps={{
            style:{
                width:"100%",
                color:"#CC261A",
                fontFamily: "inherit",
                fontSize:"12px",
                fontStyle:"normal",
                fontWeight:"400",
                lineHeight:"16px",
                height:"10px",
                margin: "0px",
                background: "none",
                padding:"0px"
            }
        }}
        InputProps={{
            disableUnderline: true,
            sx:{display:"flex", 
                justifyContent:"center", 
                alignItems:"center", 
                backgroundColor:"#f8f8f8f8", 
                ":hover": {backgroundColor:"#f8f8f8f8"}
            },
            endAdornment:<IconButton
                            sx={{
                                padding: "0px",
                                }}
                            edge={"end"} onClick={handlerClick}>{icon}
                         </IconButton> 
         }}
        InputLabelProps={{
            shrink: true,
            style:{
                paddingLeft: "5px",
                marginTop: "5px",
                color: "#626262",
                fontFamily: "inherit",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                
            },
        }}
        
        variant= "filled" />
    );
}

