import {Box, Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import "./EventosDias.css"
import {v4 as uuidv4} from 'uuid';
import { HandlerOpcionesDetalleEventos } from "../../OpcionesEvento/OpcionesEvento";
import { useState } from "react";


export const EventosDias = ({eventosDia, setNewDate, setOpcion }) => { 
    const [vistaEvento, setVistaEvento] = useState(false);
    const [dataEvento, setDataEvento] = useState({});
    const [dataEventos, setDataEventos] = useState([]);
    const [opcionVista, setOpcionVista] = useState();
    const [fechaEventos, setFechaEventos] = useState();

    // const HandlerToDay = (fecha) => () => {
    //     setNewDate(fecha) 
    //     setOpcion(1)
    // }

    const HandlerEventView = (evento) => () =>  {
        setDataEvento(evento) 
        setOpcionVista(1)
        setVistaEvento(true);       
    }

    const HandlerMultiEventView = (eventos, fecha) => () =>  {        
        setOpcionVista(3) 
        setFechaEventos(fecha)
        setDataEventos(eventos)
        setVistaEvento(true);       
    }
    

    var listaHoras = CrearBloquesDeHoras(eventosDia)

    return (
            listaHoras.map((item, index) => {
                
                return (
                    item.eventos.length === 0 ? 
                    <Grid id="BoxContenedorHorasDiaListaSinEventos" key={index} height={item.ocupados * 48 + "px"} >
                        {null}
                    </Grid>
                    :
                    <Grid id="BoxContenedorHorasDiaListaConEventos" key={index} height={item.ocupados * 48 + "px"} >
                        {
                            item.eventos.length === 0 ?
                            null
                            :
                            item.eventos.length > 2 ?
                            <Box key={index} id="BoxMultipleEventosDia" >
                                <Button id="BtnBoxMultipleEventos" 
                                fullWidth sx={{padding: "0px", textTransform:"none"}} 
                                onClick={HandlerMultiEventView(item.eventos, item.eventos[0].fechaVisita)}>
                                    <Box id="BoxTituloMultiple">
                                        <Typography id="txtInfoEventoTitulo">
                                            {
                                                "Multiples Eventos" 
                                            }
                                        </Typography>
                                    </Box>
                                    <Box id="BoxTituloMultiple">
                                        <Typography id="txtInfoEvento">
                                            {
                                                item.eventos.length === 3 ?
                                                "3 Eventos"
                                                :
                                                "+3 Eventos"
                                            }
                                        </Typography>
                                    </Box>
                                </Button>
                            </Box>
                            :
                            //aqui lo que se debe hacer es modificar para que se muestren los dos eventos o motrar evento multiple
                            <Box key={index} id="BoxDataEventoDiarioVistaDia" height={item.ocupados * 48 + "px"} sx={{ background: item.eventos[0].estado === false ? "#94B1C0": item.eventos[0].idTipoVisita === 0 ? "#154E6B" : item.eventos[0].idTipoVisita === 1 ? "#1F7098": "#699FBA"}} >
                               <Button id="BtnNombreEventoDataMultiple" 
                               fullWidth  
                               sx={{padding: "0px", textTransform:"none"}} 
                               onClick={item.eventos.length === 1 ? HandlerEventView(item.eventos[0]) :  HandlerMultiEventView(item.eventos, item.eventos[0].fechaVisita)}>
                                    {
                                        item.eventos.length === 1 ?
                                        <Typography id="txtNombreEvento">
                                        {
                                            item.eventos[0].nombreEvento  //.split(" ")[0] + " " +item.nombreEvento.split(" ")[1].substring(0,3) + "..."
                                        }
                                        </Typography>
                                        :
                                        <Typography id="txtNombreEvento">
                                        {
                                            "2 eventos"
                                        }
                                        </Typography>
                                    }
                               </Button>
                               
                            </Box>
                        }
                        <HandlerOpcionesDetalleEventos 
                        open={vistaEvento}
                        setOpen={setVistaEvento} 
                        eventoData={dataEvento}
                        opcion={opcionVista}
                        fechaSeleccionada={fechaEventos}
                        listaEventos={dataEventos}
                        />
                    </Grid>
                );
            })
    );
}

const CrearBloquesDeHoras = (listaEventos) => {  
    let eventosDia = listaEventos.sort(e => e.fechaVisita)
    var bloquesOcupados = []
    
    
    eventosDia.forEach(elementoEvento => {
        var horaInicio = parseInt(dayjs(elementoEvento.fechaVisita).format("HH"))
        var horaTermino = parseInt(dayjs(elementoEvento.fechaTermino).format("HH"))
        var minTermino = parseInt(dayjs(elementoEvento.fechaTermino).format("mm"))
        
        if(bloquesOcupados.length > 0 )
        {
            var ocupado = bloquesOcupados.find(b => (horaInicio >= b.inicio && horaTermino <= b.fin) && horaTermino !== 23)// 
            
            
            if(ocupado !== undefined)
            {
                ocupado.eventos.push(elementoEvento)
                
            }
            else
            {
                var otroOcupado = bloquesOcupados.find(b => (horaInicio >= b.inicio && horaInicio <= b.fin) && horaTermino >= b.fin)
                
                if(otroOcupado !== undefined){
                    otroOcupado.fin = minTermino > 0 ? horaTermino + 1  : horaTermino
                    otroOcupado.eventos.push(elementoEvento)
                }
                else{
                    var nuevoBloque = {
                        id: horaInicio,
                        inicio: horaInicio,
                        fin: minTermino > 0 ? horaTermino + 1  : horaTermino,
                        eventos: []
                    } 
                    
                    nuevoBloque.eventos.push(elementoEvento)
                    bloquesOcupados.push(nuevoBloque)
                }
            }
        }
        else{
            var bloqueInicial = {
                id: horaInicio,
                inicio: horaInicio,
                fin: minTermino > 0 ? horaTermino + 1 : horaTermino,
                eventos: []
            }

            bloqueInicial.eventos.push(elementoEvento)
            bloquesOcupados.push(bloqueInicial)
        }

    });
    var listaFinalHorasOcupadasLibres = CrearDataDia(bloquesOcupados)
    return listaFinalHorasOcupadasLibres;
}


const CrearDataDia = (eventosOrdenados) => {

    var listaHorasDia = [];
    var valorFin = 23
    
    
    for (let index = 0; index <= valorFin; index++) {
        
        var existe = eventosOrdenados.find(e => e.id === index);
        if(existe)
        {               
            var horasOcupadas = {
                id: uuidv4(),
                ocupados: existe.fin - existe.inicio,
                eventos: existe.eventos
            }
            listaHorasDia.push(horasOcupadas)
            var valorAumento = existe.fin -1
            
            index = valorAumento 

        }
        else{
            var horasLibres = {
                id: uuidv4(),
                ocupados: 1,
                eventos: []
            }
            listaHorasDia.push(horasLibres);            
        }


    }
    
    return listaHorasDia
}
