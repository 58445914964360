import * as Yup from 'yup';
import { cleanRut, validateRut } from '../../../Helper/ValidarRut';

export const ValidationSchema = Yup.object({
  nombres: Yup.string()
    .required('* Campo requerido'),

  apellidos: Yup.string()
    .required('* Campo requerido'),

  rut: Yup.string()
    .nullable()
    .test('rut-required', 'Debes ingresar un RUT o un Pasaporte', function(value) {
      const { pasaporte } = this.parent;
      // Si el pasaporte está vacío y el RUT también lo está, es un error
      if (!value && (!pasaporte || pasaporte.trim() === '')) {
        return false; // Marcamos como error
      }
      return true; // Si hay un RUT, no hay problema
    })
    .test('rut-valid', 'RUT inválido', function(value) {
      // Solo validamos si el RUT no está vacío
      if (value && value.trim() !== '') {
        // Limpiamos y validamos el RUT
        const isValidRut = (validateRut(cleanRut(value))); // Validamos el RUT 
        if (!isValidRut) {
          return false; // Si no es válido, marcamos como error
        }
      }
      return true; // Si el RUT es válido o está vacío, la validación pasa
    }),

  pasaporte: Yup.string()
    .nullable()
    .test('pasaporte-required', 'Debes ingresar un RUT o un Pasaporte', function(value) {
      const { rut } = this.parent;
      // Si el RUT está vacío y el pasaporte también lo está, es un error
      if (!value && (!rut || rut.trim() === '')) {
        return false; // Marcamos como error
      }
      return true; // Si hay un pasaporte, no hay problema
    }),

  correo: Yup.string()
    .email('formato: ejemplo@dominio.cl')
    .required('* Campo requerido'),

  patente: Yup.string(),
});




