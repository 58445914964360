import { Box, FormControl, FormHelperText, IconButton, MenuItem, Modal, Select, Typography } from "@mui/material";
import { CerrarModalNuevoUsuario, ClearIconTextField } from "../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import { useFormik } from "formik";
import { EsquemaValidacionEditarTarjeta } from "./Validacion";
import { useManejoTarjetas } from "../../../Hooks/Tarjetas/useManejoTarjeta";
import { useSelector } from "react-redux";
import { useState } from "react";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
  
const style = {
    width: "1024px",
    height: "680px",
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
    };

const TipoTarjeta = [
    {
        id: 0, tipo: "Tipo de tarjeta"
    }
    ,
    {
        id: 1, tipo: "RFID"
    }
    ,
    {
        id: 2, tipo: "TAG"
    }
]

  
export const EditarTarjeta = ({ open, handlerOpen, setRows, dataToEdit, rows }) => {
    const data = useSelector((action) => action.user.authenticated)
    const {loading, setLoading, response, EditarTarjetaRequest} = useManejoTarjetas();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [ConfirmationDialog, confirm ]= useConfirm();


    const EditarRow = () => {

        const tipoT = TipoTarjeta.find(t => t.id === formik.values.idTipoTarjeta);

        var rowEdited = {
            idTarjeta: dataToEdit.idTarjeta,
            codigo: formik.values.codigo,
            idTipoTarjeta: tipoT.id,
            tipoTarjeta: tipoT.tipo,
            asignada : dataToEdit.asignada,
            estado :  dataToEdit.estado,
        }
        setRows(rows.map((row) => (row.idTarjeta === rowEdited.idTarjeta ? rowEdited : row)))
    }

    const formik = useFormik({
        initialValues: {idTipoTarjeta: dataToEdit.idTipoTarjeta ?? 0, codigo: dataToEdit.codigo ??""},
        validationSchema:EsquemaValidacionEditarTarjeta,
        enableReinitialize: true,
        onSubmit: async () => {
            var response = await confirm();
            if(response === true) {
                setLoading(true);
                setOpenLoading(true);
                await EditarTarjetaRequest(dataToEdit.idTarjeta,formik.values, data.idEmpresa)
                .then(
                    (resolve) => {
                        EditarRow();
                    }
                    ,
                    (reject) => {
                        console.log(reject)
                    }
                )
            }
            else{
                console.log("rechaza la modificacion")
            }
            
        },
        onReset: () => {
            
             
        },
    });

    const handleClose = () => {
        handlerOpen(false);
        formik.resetForm();
    }
  
    return (
        <Modal id="modalNuevaTarjeta" open={open} onClose={handleClose}>
            <Box id="BoxNuevaTarjeta" sx={{ ...style }}>
                <Box id="CabeceraNuevaTarjeta">
                    <IconButton onClick={handleClose}>
                        <CerrarModalNuevoUsuario />
                    </IconButton>
                </Box>
                <Box id="ContenidoModalNuevaTarjeta">
                    <Box id="BoxContenidoInternoModalNuevaTarjeta">
                        <Box id="BoxCabeceraContenidoModalNuevaTarjeta">
                            <Box id="BoxTitutloModalNuevaTarjeta">
                                <Typography id="TituloNuevaTarjeta">{"Editar tarjeta"}</Typography>
                            </Box>
                            <Box id="BoxSubtituloModalNuevaTarjeta">
                                <Typography id="SubtituloNuevaTarjeta">
                                {
                                    "Todos los campos son obligatorios para completar el registro."
                                }
                                </Typography>
                            </Box>
                        </Box>
                        <Box id="BoxContenedorDataNuevaTarjeta">
                            <Box id="BoxTituloFormularioNuevaTarjeta"> 
                                <Box id="BoxTituloNuevaTarjeta">
                                    <Typography id="TxtTituloInformacionPersonal">
                                        {"Información de tarjeta"}
                                    </Typography>
                                </Box>
                                <Box id="BoxFormularioNuevaTarjeta" component={"form"} onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                                    <Box id="BoxFormularioTipoTarjetaNueva">
                                        <FormControl fullWidth >
                                            <Select
                                            fullWidth
                                            id={"idTipoTarjeta"}
                                            name={"idTipoTarjeta"}
                                            required
                                            sx={{height:"56px", borderBottom:"2px solid #8A8A8A", backgroundColor: "rgb(248, 248, 248)"}}
                                            variant="filled"
                                            value={formik.values.idTipoTarjeta}
                                            onChange={formik.handleChange}
                                            disableUnderline={true}
                                            >
                                            {
                                                TipoTarjeta.map((item, index) => {
                                                return (
                                                    <MenuItem
                                                    disableRipple
                                                    key={index}
                                                    value={item.id}
                                                    >
                                                        <Typography>{item.tipo}</Typography>
                                                    </MenuItem>
                                                );
                                                })
                                            }
                                            </Select>
                                            <FormHelperText>
                                                {
                                                    formik.errors.idTipoTarjeta
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                    <Box id="BoxFormularioCodigoNuevaTarjeta">
                                        <TextFieldTres
                                            id={"codigo"}
                                            label={"Código"}
                                            placeholder={"Código"}
                                            icon={
                                                <ClearIconTextField id="IconoLimpiarTextFieldNuevoUsuario" />
                                            }
                                            name={"codigo"}
                                            value={formik.values.codigo}
                                            handlerChange={formik.handleChange}
                                            handlerClick={() => {
                                            formik.setFieldValue("codigo", "");
                                            }}
                                            helper={formik.errors.codigo}
                                            disabled={false}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box id="BotoncesAccionModalNuevaTarjeta">
                                <Box id="BoxBotonNuevaTarjeta">
                                    <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                                </Box>
                                <Box id="BoxBotonNuevaTarjeta">
                                    <BotonTipo2
                                    textoBoton={"Actualizar"}
                                    event={formik.submitForm}
                                    disabled={loading}
                                    />

                                <LoadingMasRespuestaCinco
                                    open={openLoading} 
                                    setOpen={setOpenLoading} 
                                    msj={"La información de la tarjeta está siendo actualizada. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
                                    id={"RespuestaGuardarEditarTarjeta"} 
                                    tituloLoading={"Actualizando datos..."}
                                    loading={loading} 
                                    respuesta={response} 
                                    icono={
                                        response === 200 ?
                                        <IconoExito id="IconoRespuestaServidor" />
                                        :
                                        <IconoFallo id="IconoRespuestaServidor" />
                                    }
                                    textoBoton={
                                        response === 200 ? 
                                        "Aceptar"
                                        :
                                        "Reintentar"
                                    }
                                    titulo={response === 200 ? "Actualización exitosa":"Error al actualizar"}
                                    mensajeRespuesta={response === 200 ? 
                                        "La información de la tarjeta se ha actualizado correctamente"
                                        :
                                        "Ha ocurrido un error y no se ha podido actualizar la información."}
                                    />
                                    <ConfirmationDialog
                                    icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
                                    titulo={"¿Actualizar datos?"}
                                    mensaje={"Estás a punto de actualizar la información de la tarjeta. ¿Deseas continuar?"}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
  };
  