import axios from "axios";
import { useState } from "react";
import { ActivarRemoto, CrearActuador, DesactivarActuador, DesactivarRemoto, EditarActuador, ReactivarActuador } from "../../Service/API/Api";

export default function useActuadores(){
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();

    const CrearNuevo = (values) =>{
        return new Promise((resolve, reject) => {
            const nuevoActuador= {
                "nombre": values.nombreActuador,
                "mac": values.mac,
                "serial": values.serial,
                "ip": values.ip,
                "puerto": values.puerto,
                "clave": values.clave,
                "zonaId": values.idZona,
                "tipoActorId": values.idTipoActuador,
                "sentidoId": values.idSentido
            }

            axios.post(CrearActuador, nuevoActuador)
            .then(
                (response) => {
                    resolve(response.data);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
        })
    }


    const EditarExistente = (id, values) =>{
        return new Promise((resolve, reject) => {
            const editarActor = {
                "idActor": id,
                "nombre": values.nombreActuador,
                "mac": values.mac,
                "serial": values.serial,
                "ip": values.ip,
                "puerto": values.puerto,
                "clave": values.clave === "" ? null : values.clave,
                "zonaId": values.idZona,
                "tipoActorId": values.idTipoActuador,
                "sentidoId": values.idSentido,
              }

            axios.put(EditarActuador, editarActor)
            .then(
                (response) => {
                    resolve(true);
                    setLoading(false);
                    setResponse(response.status)
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            )
        })

    }

    const ModificarEstadoActuador = (idActuador, estadoActual) =>{
        return new Promise((resolve, reject) => {
            axios.patch(estadoActual === true ? DesactivarActuador + idActuador : ReactivarActuador + idActuador)
            .then(
                (response) => {
                    resolve(true);
                    setLoading(false);
                    setResponse(response.status)
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            )
        })
    }

    const ModificarRemotoActuador = (idActuador, estadoActual) =>{
        return new Promise((resolve, reject) => {
            axios.patch(estadoActual === true ? DesactivarRemoto + idActuador : ActivarRemoto + idActuador)
            .then(
                (response) => {
                    resolve(true);
                    setLoading(false);
                    setResponse(response.status)
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            )
        })

    }

    return{
        loading,
        setLoading,
        response,
        CrearNuevo,
        ModificarEstadoActuador,
        ModificarRemotoActuador,
        EditarExistente
    }
}