import React, { useEffect, useState } from 'react';
import { Modal, Card, IconButton, Box, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import "./CardImagen.css";
import useGestionCamaras from '../../../Hooks/GestionCamaras/useGestionCamaras';
import { ReproductorComponent } from '../../Camaras/Live';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const CardImagen = ({ 
  open, 
  onClose, 
  botonSeleccionado, 
}) => {

  const { data: dataCamaras } = useGestionCamaras();
  const [camaraSeleccionada, setCamaraSeleccionada] = useState({});

  //Variables para manejar el tamaño de la pantalla de las camaras en inicio
  const widthPantallaNormal = "550px";
  const heightPantallaNormal = "307px";
  const widthPantallaExpandida = "790px";
  const heightPantallaExpandida = "443px";
  const [pantallaExpandida, setPantallaExpandida] = useState(false);
  const [anchoPantalla, setAnchoPantalla] = useState(widthPantallaNormal);
  const [altoPantalla, setAltoPantalla] = useState(heightPantallaNormal);
  const [imagenCargada, setImagenCargada] = useState(true);

  useEffect(() => {
    if(pantallaExpandida){
      setAnchoPantalla(widthPantallaExpandida);
      setAltoPantalla(heightPantallaExpandida);
    } else {
      setAnchoPantalla(widthPantallaNormal);
      setAltoPantalla(heightPantallaNormal);
    }
  }, [pantallaExpandida])
  

  useEffect(() => {

    if(dataCamaras.length > 0 && botonSeleccionado !== null && botonSeleccionado !== undefined && botonSeleccionado !== ""){

      const camara = dataCamaras.find(camara => camara.idActor === botonSeleccionado.idActor );
      setCamaraSeleccionada(camara);
    }
  }, [dataCamaras, botonSeleccionado])
  
  
  return (
    <Modal open={open} onClose={onClose} slotProps={{backdrop: {sx: { background: "rgba(0, 0, 0, 0.7)"}}}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          outline: 0, // Evitar el borde de Modal
        }}
      >
        <Card 
          id="CardBox" 
          sx={{backgroundColor: "white", width: anchoPantalla, height: altoPantalla}}
          >
            <Box id='HeaderCard'>
                <IconButton
                    onClick={()=>{
                      setPantallaExpandida(!pantallaExpandida);
                    }}
                    sx={{fontSize: "35px", color: imagenCargada? "black" : "white"}}
                >
                  {pantallaExpandida? <FullscreenExitIcon sx={{ fontSize: "inherit" }}/> : <FullscreenIcon sx={{ fontSize: "inherit" }}/>}
                </IconButton>

                <IconButton
                    onClick={onClose}
                    sx={{fontSize: "35px", color: imagenCargada? "black" : "white"}}
                >
                    <CloseIcon sx={{ fontSize: "inherit" }}/>
                </IconButton>

            </Box>
            {
              camaraSeleccionada !== undefined && camaraSeleccionada !== null ? 
                <Grid id="BoxContenedorLive">
                  <ReproductorComponent camData={camaraSeleccionada} setImagenCargada={setImagenCargada} />
                </Grid>
                :
                <Typography>
                  {
                      "No se han encontrado camaras..."
                  }
                </Typography>
            }
        </Card>
      </Box>
    </Modal>
  );
};

export default CardImagen;





























































export const LiveStreamCamaras = () => {
  console.log("LiveStreamCamaras")
  const { data } = useGestionCamaras();
  const [camaras, setCamaras ] = useState([]);

  useEffect(() => {
      setCamaras(data);
      console.log("LiveStreamCamaras, data ha cambiado y es: ",data);
  }, [data])
  
  return(
      <Box id="BoxLiveCamaras">
          <Box id="BoxLayoutCamarasLiveStream">
              <Grid id="GridCamarasLiveStream" container  >
              {
                  camaras.length > 0 ?
                  camaras.map((cam, index) => {

                      return (
                          <Grid item key={index} id="BoxContenedorLive">
                              <ReproductorComponent camData={cam} />
                          </Grid>
                      );
                  })
                  :
                  <Typography>
                      {
                          "No se han encontrado camaras..."
                      }
                  </Typography>
              }
              </Grid>
          </Box>
      </Box>
  );
}














































































// const CardImagen = ({ open, onClose }) => {
//   return (
//     <Modal open={open} onClose={onClose}>
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '100vh',
//           outline: 0, // Evitar el borde de Modal
//         }}
//       >
//         <Card id="CardBox">
//             <Box id='HeaderCard'>
//                 <IconButton
//                     onClick={onClose}
//                 >
//                     <CloseIcon/>
//                 </IconButton>
//             </Box>
//             <CardMedia
//                 component="img"
//                 height="300"
//                 image="https://ucateci.edu.do/images/imagenes/SeccionConstruccion.jpg"
//                 alt="Imagen vacía"
//             />
//         </Card>
//       </Box>
//     </Modal>
//   );
// };

// export default CardImagen;



































































// import React from 'react';
// import { Card, CardMedia, CardContent, Typography, Collapse } from '@mui/material';

// const CardImagen = ({ open }) => {
//   return (
//     <Card sx={{ maxWidth: 345 }}>
//       <CardMedia
//         component="img"
//         height="140"
//         image="https://previews.123rf.com/images/paylessimages/paylessimages1503/paylessimages150308779/42524204-imagen-vac%C3%ADa.jpg"
//         alt="Imagen Vacía"
//       />
//       <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//           Título de la Tarjeta
//         </Typography>
//         <Collapse in={open}>
//           <Typography variant="body2" color="text.secondary">
//             Descripción breve del contenido que se encuentra en esta tarjeta. Aquí puedes poner más detalles que solo se muestran cuando la tarjeta está abierta.
//           </Typography>
//         </Collapse>
//       </CardContent>
//     </Card>
//   );
// };

// export default CardImagen;
