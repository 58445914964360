import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { LayoutCrearNuevo } from "../../../../Box/CrearNuevoMenuGestionUsuario/LayoutCrearNuevo";
import "./Horario.css"
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import { SeleccionFecha } from "../../../../DatePicker/DatePicker";
import { useFormik } from "formik";
import { ValidationEditarAsignacion } from "./Validation";
import { LoadingMasRespuestaCinco } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";
import { useState } from "react";
import useAsignaciones from "../../../../../Hooks/Asignaciones/useAsignaciones";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../../../Usuarios/UsuariosIcon";
import { IconoExito, IconoFallo } from "../../../RespuestaServidor/RespuestaServidorIcon";


export const EditarAsignacion = ({setOpen, asignaciones, setRows, user, horariosAsignar, asignacionToEdit}) =>{
    const {loading, setLoading, response, EditarAsignacion} = useAsignaciones();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [ConfirmationDialog, confirm ]= useConfirm();

    const handleClose = () => {
        setOpen(false);
    }

    const EditarRow = async (values) => {
        let rowToEdit = asignaciones.find(h => h.idAsignacion === asignacionToEdit.idAsignacion);
        rowToEdit.idHorario = values.idHorario;
        rowToEdit.inicio = values.inicioEditar;
        rowToEdit.termino = values.terminoEditar;
        setRows(asignaciones.map((row) => (row.idAsignacion === rowToEdit.idAsignacion ? rowToEdit : row)))

        setTimeout(() => {
            handleClose();
            setOpenLoading(false);
        }, 3000);
    }




    const formik = useFormik({
        initialValues: {idHorario:asignacionToEdit.idHorario, inicioEditar: asignacionToEdit.inicio, terminoEditar:asignacionToEdit.termino},
        enableReinitialize:true,
        validationSchema:ValidationEditarAsignacion,
        onSubmit: async () =>{
            let response = await confirm();
            if(response === true){
                setLoading(true);
                setOpenLoading(true);
                await EditarAsignacion(formik.values, asignacionToEdit.idAsignacion, user)
                .then(
                    (resolve) => {
                        EditarRow(formik.values, asignacionToEdit.idAsignacion, user);
                    }
                    ,
                    (reject) => {
                        console.log(reject);
                    }
                )
            }
            else
            {
                console.log("sin cambios no se acepta realizar modificacion")
            }
        }
    })

    return(
        <LayoutCrearNuevo titulo={"Editar Horario"}>
            <Box id="BoxLayoutAsignarHorarioGU">
                <Box id="BoxTituloSeleccionAsignacionHorarioGU">
                    <Box id="BoxTituloAsignacionHorarioGU">
                        <Typography id="TxtTituloAsignacionHorarioGU">
                            {
                                "Asignación de horario"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxSeleccionAsignacionHorarioGU" component={"form"} onSubmit={formik.handleSubmit}>
                        <Box id="BoxSelectHorarioAsignacionGU">
                            <FormControl fullWidth>
                                <InputLabel id="LblHorario">
                                    {
                                        "Horarios Disponibles"
                                    }
                                </InputLabel>
                                <Select 
                                fullWidth 
                                variant="outlined"
                                name="idHorario"
                                labelId="LblHorario"
                                label="Horarios Disponibles"
                                value={formik.values.idHorario}
                                onChange={formik.handleChange}
                                MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        maxHeight: '280px',
                                        overflowY: 'auto',
                                        width: '220px',
                          
                                        // Estilos para WebKit (Chrome, Safari, Edge) en el Paper (menú desplegable)
                                        '&::-webkit-scrollbar': {
                                          width: '8.024px !important',
                                          background: 'transparent !important',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                          background: 'transparent !important',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                          background: '#C9C9C9',
                                          borderRadius: '100px',
                                        },
                                      },
                                    },
                                }}
                                >
                                {
                                    horariosAsignar.map((item, index) => {
                                    return (
                                        <MenuItem disableRipple key={index} value={item.idHorario} id="MiZonaGU" >
                                            <Box id="BoxZonaGU">
                                                <Typography>
                                                    {
                                                        item.nombreHorario
                                                    }
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                        );
                                    })
                                }      
                                </Select>
                                <FormHelperText>
                                    {
                                        formik.errors.idHorario
                                    }
                                </FormHelperText>
                            </FormControl>
                        </Box>
                        <Box id="BoxFechaInicioTerminoAsignacionHorarioGU">
                            <Box id="BoxFechaInicioAsignacionHorarioGU">
                                <FormControl fullWidth>
                                    <SeleccionFecha 
                                    valueSelected={formik.values.inicioEditar}
                                    handleDate={(value)=> {formik.setFieldValue("inicioEditar", value); console.log(value)}}
                                    labelName={"Fecha de Inicio"}
                                    name={"inicioEditar"}
                                    variante={"out"}
                                    />
                                    <FormHelperText>
                                        {
                                            formik.errors.inicioEditar
                                        }
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                            <Box id="BoxFechaTerminoAsignacionHorarioGU">
                                <FormControl fullWidth>
                                    <SeleccionFecha 
                                    valueSelected={formik.values.terminoEditar}
                                    handleDate={(value)=> formik.setFieldValue("terminoEditar", value)}
                                    labelName={"Fecha de Termino"}
                                    name={"terminoEditar"}
                                    variante={"out"}
                                    />
                                    <FormHelperText>
                                        {
                                            formik.errors.terminoEditar
                                        }
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxAccionesAsignarHorarioGU">
                    <Box id="BoxBtnCancelarAsignarHorarioGU">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                    </Box>
                    <Box id="BoxBtnAsignarAsignarHorarioGU">
                        <BotonTipo2 textoBoton={"Asignar"} event={formik.submitForm} />
                        <ConfirmationDialog
                        icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
                        titulo={"¿Actualizar?"}
                        mensaje={"Estás a punto de actualizar el horario asignado. ¿Deseas continuar?"}
                        />
                        <LoadingMasRespuestaCinco
                        open={openLoading}
                        icono={response === 200 ?
                            <IconoExito id="IconoRespuestaServidor" />
                            :
                            <IconoFallo id="IconoRespuestaServidor" />
                        }
                        textoBoton={response === 200 ?
                            "Aceptar" 
                            :
                            "Reintentar"
                        }
                        setOpen={setOpenLoading}
                        msj={"La información está siendo actualizada. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                        id={"LoadingAgregaListaNegraGU"}
                        tituloLoading={"Actualizando Horario..."}
                        loading={loading}
                        titulo={response === 200 ? 
                            "Actualización exitosa"
                            :
                            "Error al actualizar"
                        }
                        mensajeRespuesta={response === 200 ? 
                            "La información del usuario se ha actualizado correctamente. Ahora puedes gestionar sus permisos o añadir un nuevo usuario."
                            :
                            "Ha ocurrido un error y no se ha podido actualizar la información. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                        }
                        />
                    </Box>
                </Box>
            </Box>
        </LayoutCrearNuevo>
    );
}