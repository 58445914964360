import { useEffect, useRef, useState } from "react";
import { Box, Button, Popover, Typography } from "@mui/material";
import "./CargaMasivaComponent.css"
import * as XLSX from 'xlsx';
import { useSelector } from "react-redux";
import useConfirm from "../../Hooks/ConfirmDialog/useConfirmDialog";
import usePerfil from "../../Hooks/OpcionesPerfil/usePerfil";
import { IconoExito, IconoFallo } from "../Modal/RespuestaServidor/RespuestaServidorIcon";
import { TextFieldSeleccionarFotoPerfil } from "../TextField/TextFieldUno";
import { LoadingMasRespuestaCinco } from "../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoAlerta } from "../Usuarios/UsuariosIcon";
import { generarMensajeColumnasRepetidas, verificarColumnasRepetidas } from "./funcionesCargaMasivaComponent";

//Carga Masiva es un componente tipo Popover que tiene como objetivo dar la opcion para importar ciertos datos
// desde un archivo Excel, lo que se conoce como carga masiva.
// Al ser un componente universal este tiene las siguientes props para adaptarse dependiendo la situacion
const CargaMasivaComponent = ({ 
    anchorEl, 
    onClose, 

    //Funcion que se ejecutará para solicitarle al servidor el archivo con el formato ideal del Excel
    handleDescargarFormatoIdeal,

    //Funcion que se ejecutará para importar el archivo excel, o sea le manda al servidor los datos a importar luego de extraerlos del Excel de entrada
    importarArchivoExcel,

    //Esto es solo un String para indicar en el confirm dialog si se trata de una carga de "tarjetas", "ubicaciones", etc.
    tipoCargaMasiva,

    //Funcion que se ejecutará para validar los datos y su formato en el archivo Excel. Retorna un booleano con la respuesta.
    funcionValidadoraExcel,
    
    //Si es true, se ejecutará la recarga de la pagina en caso de que se haya realizado con éxito importación de los datos.
    recargarPagina = true,

    actualizarTabla,
}) => {    

  const dataSelector = useSelector(action => action.user.authenticated);

  const [selectedFile, setSelectedFile] = useState(null);

  //Esto es para manejar el boton de importar, en caso de que no se haya seleccionado un archivo este debe estar deshabilitado.
  const [botonImportarDeshabilitado, setBotonImportarDeshabilitado] = useState(false);
  useEffect(() => {
    if(selectedFile === null || selectedFile === undefined){
      setBotonImportarDeshabilitado(true);
    }
    else{
      setBotonImportarDeshabilitado(false);
    }
  }, [selectedFile])
  
  const fileInputRef = useRef(null);
  const open = Boolean(anchorEl);
  const { loading, setLoading } = usePerfil();
  const [openLoading, setOpenLoading] = useState(false);

  // Estados y variables para el manejo del dialog
  const [dialogText, setDialogText] = useState({titulo: '', mensaje: ''});
  const [ConfirmationDialog, confirm ]= useConfirm();


  //Información que irá escrita en el LoadingMasRespuestaCinco
  const [textoLoadingMasRespuestaCinco, setTextoLoadingMasRespuestaCinco] = useState({
    msj: "Importando datos, por favor espere...",
    tituloLoading: "Importando...",
    textoBoton: "Aceptar",
    titulo: "Actualización exitosa",
    mensajeRespuesta: "La imagen de perfil se ha actualizado correctamente.",
    icono: <IconoExito id="IconoRespuestaServidor" />
  })

  const handleImportarArchivo = async () => {

    setDialogText({
        titulo: `¿Importar ${tipoCargaMasiva}?`,
        mensaje: `Estás a punto de importar ${tipoCargaMasiva.toLowerCase()} ¿Deseas continuar?`
    });

    const responseConfirm = await confirm();
    if (responseConfirm === true) {
        setOpenLoading(true);
        setLoading(true);

        const file = selectedFile;
        
        // Verificar que se haya seleccionado un archivo
        if (file === null) {
            const nuevaRespuesta = {
                ...textoLoadingMasRespuestaCinco,
                textoBoton: "Reintentar",
                titulo: "Error al subir el archivo",
                mensajeRespuesta: "Ha ocurrido un error y no se ha podido importar los datos. Debe seleccionar un archivo.",
                icono: <IconoFallo id="IconoRespuestaServidor" />,
            };
            setTextoLoadingMasRespuestaCinco(nuevaRespuesta);
            setLoading(false);
        } else {
            // Obtener el nombre del archivo
            const fileName = file.name;

            // Obtener la extensión del archivo
            const fileExtension = fileName.split('.').pop().toLowerCase();

            // Comprobar si el archivo tiene una extensión válida para Excel
            if (fileExtension !== 'xls' && fileExtension !== 'xlsx') {
                const nuevaRespuesta = {
                    ...textoLoadingMasRespuestaCinco,
                    textoBoton: "Reintentar",
                    titulo: "Error al subir el archivo",
                    mensajeRespuesta: "Ha ocurrido un error y no se ha podido importar los datos. Debe seleccionar un archivo con formato Excel",
                    icono: <IconoFallo id="IconoRespuestaServidor" />,
                };
                setTextoLoadingMasRespuestaCinco(nuevaRespuesta);
                setLoading(false);
            } else {
                // Leer el archivo utilizando FileReader
                const reader = new FileReader();

                reader.onload = async (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];

                    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                        defval: "",
                    });

                    setSelectedFile(file);

                    //Se obtiene la información del archivo en formato JSON para trabajar con ella
                    const dataArchivo = jsonData;

                    // Se verifica si existen ciertas columnas repetidas en el archivo

                    let [hayRepetidos, columnasRepetidas] = [false, []];
                    if(tipoCargaMasiva.toLowerCase() === "visitantes"){
                      [hayRepetidos, columnasRepetidas] = verificarColumnasRepetidas(dataArchivo, ["RUT", "PATENTE"]);
                    }

                    else if(tipoCargaMasiva.toLowerCase() === "tarjetas"){
                      [hayRepetidos, columnasRepetidas] = verificarColumnasRepetidas(dataArchivo, ["Codigo"]);
                    }

                    else if(tipoCargaMasiva.toLowerCase() === "zonas"){
                      [hayRepetidos, columnasRepetidas] = verificarColumnasRepetidas(dataArchivo, ["Nombre"]);
                    }

                    else if(tipoCargaMasiva.toLowerCase() === "ubicaciones"){
                      [hayRepetidos, columnasRepetidas] = verificarColumnasRepetidas(dataArchivo, []);
                    }
                
                    //Si existen repetidos, no se puede importar el archivo, por lo que se le indica al usuario que la operación falló.
                    if (hayRepetidos) {
                        const nuevaRespuesta = {
                            ...textoLoadingMasRespuestaCinco,
                            textoBoton: "Reintentar",
                            titulo: "Error al subir el archivo",
                            mensajeRespuesta: `Ha ocurrido un error y no se ha podido importar los datos. Se han detectado la(s) siguiente(s) columna(s) con información repetida(s): ${generarMensajeColumnasRepetidas(columnasRepetidas)}`,
                            icono: <IconoFallo id="IconoRespuestaServidor" />,
                        };
                        setTextoLoadingMasRespuestaCinco(nuevaRespuesta);
                        setLoading(false);
                        return;
                    }

                    const [respuesta, mensajeError] = funcionValidadoraExcel(dataArchivo) || [false, ""];
                    if (respuesta) {
                    // if (false) {
                 
                      try {
                          await importarArchivoExcel(dataSelector.idEmpresa, file);
                          const nuevaRespuesta = {
                              ...textoLoadingMasRespuestaCinco,
                              textoBoton: "Aceptar",
                              titulo: "Archivo importado",
                              mensajeRespuesta: "Los datos del archivo se han cargado con éxito.",
                              icono: <IconoExito id="IconoRespuestaServidor" />,
                          };
                          setTextoLoadingMasRespuestaCinco(nuevaRespuesta);
                          setLoading(false);
                          if(recargarPagina){
                            setTimeout(() => {
                              window.location.reload();
                            }, 2000);
                          }
                          if(tipoCargaMasiva.toLowerCase() === "visitantes"){
                            actualizarTabla();
                          }
                      } catch (error) {
                          console.error("Error al importar el archivo");
                          const nuevaRespuesta = {
                              ...textoLoadingMasRespuestaCinco,
                              textoBoton: "Reintentar",
                              titulo: "Error al subir el archivo",
                              mensajeRespuesta: "Ha ocurrido un error al importar el archivo. Por favor, inténtelo nuevamente. Si el problema persiste, contáctanos para asistencia.",
                              icono: <IconoFallo id="IconoRespuestaServidor" />,
                          };
                          setTextoLoadingMasRespuestaCinco(nuevaRespuesta);
                          setLoading(false);
                      }
                  }
                   
                    else {
                        const nuevaRespuesta = {
                            ...textoLoadingMasRespuestaCinco,
                            textoBoton: "Reintentar",
                            titulo: "Error al subir el archivo",
                            mensajeRespuesta: `Ha ocurrido un error y no se ha podido importar los datos: ${mensajeError} `,
                            icono: <IconoFallo id="IconoRespuestaServidor" />,
                        };
                        setTextoLoadingMasRespuestaCinco(nuevaRespuesta);
                        setLoading(false);
                    }
                };

                reader.onerror = (e) => {
                    console.error("Error al leer el archivo");
                    const nuevaRespuesta = {
                        ...textoLoadingMasRespuestaCinco,
                        textoBoton: "Reintentar",
                        titulo: "Error al leer el archivo",
                        mensajeRespuesta: "Ha ocurrido un error y no se ha podido leer el archivo seleccionado. Por favor, inténtelo nuevamente. Si el problema persiste, contáctanos para asistencia.",
                        icono: <IconoFallo id="IconoRespuestaServidor" />,
                    };
                    setTextoLoadingMasRespuestaCinco(nuevaRespuesta);
                    setLoading(false);
                };

                reader.readAsArrayBuffer(file);
            }
        }
    }
};


  // Manejar la selección del archivo
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      slotProps={{ paper: { sx: { borderRadius: '10px' } } }}
    >
      <Box id="BoxContainerCM">
        <Typography sx={{marginBottom: '10px'}} variant="h5">{`Importar archivo de ${tipoCargaMasiva.toLowerCase()}`}</Typography>
        <Box id="BoxSubirArchivoCM">
          {/* Mostrar el nombre del archivo o mensaje por defecto */}
          <Box id="TextoArchivoSeleccionadoCM">
            <TextFieldSeleccionarFotoPerfil disabled={true} value={selectedFile ? selectedFile.name : "Ningún archivo seleccionado"}></TextFieldSeleccionarFotoPerfil>
            
          </Box>

          {/* Input oculto para seleccionar el archivo */}
          <input
            type="file"
            accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={handleFileSelect}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          
          <Button
            id="BotonSeleccionarArchivoCM"
            onClick={() => fileInputRef.current.click()}
          >
            Seleccionar
          </Button>
        </Box>

      <Typography id='ParrafoFormatoIdeal'>
        Por favor, asegúrese de que el archivo siga el formato correcto.{' '}
        <Typography
          component="span"
          onClick={handleDescargarFormatoIdeal}
          sx={{
            color: '#007bff',
            textDecoration: 'underline',
            cursor: 'pointer',
            fontWeight: 'bold',
            fontSize: '14px'
          }}
        >
          Descargar formato ideal
        </Typography>
      </Typography>

        <Button sx={{backgroundColor: botonImportarDeshabilitado? "gray" : "#175676"}} disabled={botonImportarDeshabilitado} id="BotonSubirArchivoCM" onClick={handleImportarArchivo}>
          Importar
        </Button>

        <LoadingMasRespuestaCinco
            open={openLoading} 
            setOpen={setOpenLoading} 
            msj={textoLoadingMasRespuestaCinco.msj} 
            id={"RespuestaGuardarEditarUsuario"} 
            tituloLoading={textoLoadingMasRespuestaCinco.tituloLoading}
            loading={loading} 
            icono={textoLoadingMasRespuestaCinco.icono}
            textoBoton={textoLoadingMasRespuestaCinco.textoBoton}
            titulo={textoLoadingMasRespuestaCinco.titulo}
            mensajeRespuesta={textoLoadingMasRespuestaCinco.mensajeRespuesta}
        />

        <ConfirmationDialog
        icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
        titulo={dialogText.titulo}
        mensaje={dialogText.mensaje}
        />

      </Box>
    </Popover>
  );
};

export default CargaMasivaComponent;