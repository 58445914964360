import { Box, Checkbox, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "./MenuFiltroTiempoReal.css"
import { CerrarPerfilIcon } from '../../Profile/IconProfile'
import TooltipTipoUno from '../../ToolTip/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { actualizarFiltroTiempoReal } from '../../../store/misSlice';

export const MenuFiltroTiempoReal = ({ anchorEl, handleClose, filtrosActivados, actuadores }) => {

    //Estado controlado por Redux para filtrar los accesos en tiempo real
    const filtroTiempoReal = useSelector((state) => state.filtroTiempoReal.listadoFiltros);
    // console.log("🚀 ~ MenuFiltroTiempoReal ~ filtroTiempoReal:", filtroTiempoReal);
    const dispatch = useDispatch();

    const [actuadoresFiltrables, setActuadoresFiltrables] = useState();
    
    useEffect(() => {
      setActuadoresFiltrables(
        actuadores
            .filter(actuador => {
                // Filtrar solo aquellos actuadores que tienen la opción de filtrarse, como puertas, portones, y algunos lectores.               
                const filtroActuador = filtroTiempoReal?.find(filtro => filtro.idActor === actuador.idActor);
                return filtroActuador !== undefined && filtroActuador !== null;
            })
            .map(actuador => {
                 // Ahora que ya solo se tienen los actuadores que cumplen la condición, se pueden mapear.
                //La idea aqui, para no trabajar con varios arreglos, es que al listado de actuadores que pueden filtrarse, se le agrega la información sobre si este filtro se encuentra activado o no.
                const filtroActuador = filtroTiempoReal?.find(filtro => filtro.idActor === actuador.idActor);
                return {
                    ...actuador, 
                    filtroActivado: filtroActuador.filtroActivado 
                };
            })
      )
    }, [filtroTiempoReal, actuadores])

    const handleCheckboxChange = (idActor) =>{
        const filtroTiempoRealActualizado = filtroTiempoReal.map(filtro => {

            // Cuando se cambia el estado de un checkbox, se busca el filtro que corresponde al actuador y se cambia el estado del filtro
            if(filtro.idActor === idActor){
                return {
                   ...filtro, 
                    filtroActivado:!filtro.filtroActivado
                };
            }
            else{
                return filtro;
            }
        })
        dispatch(actualizarFiltroTiempoReal(filtroTiempoRealActualizado));
    }


  return (
    <Box>
        <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }} 
        sx={{
            padding: "0px", margin: "0px", "& .MuiList-root": {
                padding: "0px", margin: "0px" 
            }
        }}
        >   
            <Box id="ContainerMenu">
                <Box id="Header">
                    <Typography id="TxtTituloFiltroTiempoReal" >
                       {
                            "Filtro en tiempo real"
                       }
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CerrarPerfilIcon id="CerrarMenuIcon"/>
                    </IconButton>
                </Box>
                <Box id="BoxMenuItem">
                    {actuadoresFiltrables && actuadoresFiltrables.length > 0 && actuadoresFiltrables.map((actuador) => (
                        <Box id="BoxItemFiltro" key={actuador.idActor}>
                            <TooltipTipoUno titulo={`${actuador.tipoActor}: ${actuador.nombre}`}>
                                <MenuItem id="ItemFiltro" onClick={() => handleCheckboxChange(actuador.idActor)} value={actuador.nombre}>
                                    <Checkbox sx={{ '&.Mui-checked': { color: "#CC261A" }}} checked={actuador.filtroActivado} />
                                    <Typography id="TxtTipoFiltroNombreFiltroAplicado">{`${actuador.tipoActor}: ${actuador.nombre}`}</Typography>
                                </MenuItem>
                            </TooltipTipoUno>
                        </Box>
                    ))}
                </Box>
                <Divider orientation='horizontal' flexItem/>
                <Box id="BoxTotalFiltrosAplicados">
                    <Typography>
                        {
                            filtrosActivados.length + " filtros aplicados"
                        }
                    </Typography>
                </Box>
            </Box>
        </Menu>
    </Box>
  )
}
