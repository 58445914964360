import { Box } from "@mui/material"
import { IconoConfiguracion } from "../../GestionActuadores/IconosGestionActuadores";

export const BotonConfiguracion = () => {
  return (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '2px',
            alignItems: 'center',
            gap: '10px',
            width: '32px',
            height: '32px',
            borderRadius: '25px',
            background: '#FFF',
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
            '& .icono-configuracion':{
            fill: '#5f6368',
            },
            '& .icono-configuracion .fondo-icono-configuracion':{
            fill: 'white',
            },
            '&:hover':  {
            backgroundColor: '#E8EEF1',
            '.icono-configuracion': {
                fill: '#175676',
            },
            },
            '&:active':  {
            backgroundColor: '#1F7098',
            '.icono-configuracion': {
                fill: 'white',
            },
            }
        }}
        >
        <IconoConfiguracion/>
    </Box>
  )
}

export default BotonConfiguracion;
