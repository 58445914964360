import { useEffect, useState } from "react";
import useTiempoReal from "../../Hooks/TiempoReal/useTiempoReal";
import { Box, Button, Divider, IconButton, Modal, Typography } from "@mui/material";
import "./TiempoReal.css";
import { AbrirTiempoRealIcon, CerrarTiempoRealIcon, ExitoIconTable, FalloIconTable, TiempoRealIcon } from "./TiempoRealIcons";
import MostrarDetalleTR from "./DetalleTiempoReal/DetalleTiempoReal";
import dayjs from "dayjs";
import { PopOverTipoTarjetaDetalleEventos } from "../Popover/PopoverTipoUno";
import { useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuFiltroTiempoReal } from "./MenuFiltroTiempoReal/MenuFiltroTiempoReal";
import useGetActuadores from "../../Hooks/Actuadores/useGetActuadores";

const TiempoReal = ({ trOpen, trWidth, trHeight, handleTiempoReal }) => {
    const { dataTR, ObtenerTR } = useTiempoReal();
    const [anchorEl, setAnchorEl] = useState(null);
    const [dataToShow, setDataToShow] = useState({});
    const openDetalle = Boolean(anchorEl)

    //Estado controlado por Redux para filtrar los accesos en tiempo real
    const filtroTiempoReal = useSelector((state) => state.filtroTiempoReal.listadoFiltros);

    //Se obtienen los actuadores para que con el uso de filtroTiempoReal se puedan obtener un listado de los actuadores que tengan el filtro activado
    // para almacenarlo en filtrosActivados y enviarselos al conmponente MenuFiltroTiempoReal
    const data = useSelector(action => action.user.authenticated);
    const { actuadores } = useGetActuadores(data.idEmpresa);
    const [filtrosActivados, setFiltrosActivados] = useState([]);
    useEffect(() => {
        const filtrosActivos = filtroTiempoReal?.filter(item => item.filtroActivado === true);

        const actuadoresFiltrados = actuadores?.filter(actuador => 
            filtrosActivos?.some(filtro => filtro.idActor === actuador.idActor)
        );

        setFiltrosActivados(actuadoresFiltrados);
    }, [filtroTiempoReal, actuadores])
    
    ///// Funciones y estados para manejar el Menu que se abre al presionar los 3 puntos del modal de Tiempo Real ///////////
    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const handleClickMenu = (event) => {
        setAnchorElMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };

    const [dataFiltrada, setDataFiltrada] = useState({});
    //La idea de este UseEffect es que cada vez que se llame al servidor para obtener la data en tiempo real
    //Se haga un filtrado de esta dataTR, de modo de dejar solo aquellos actuadores que tengan activado su filtro.
    //Esto de acuerdo a la informacion del filtroTiempoReal que contiene el listado de todos los actuadores, su tipo, y si tienen su filtro activado.
    //filtroTiempoReal es manejado por Redux.
    useEffect(() => {
        if(filtrosActivados.length > 0) {
            // Filtrar los actuadores activados
            const actoresActivados = filtroTiempoReal
            .filter(filtro => filtro.filtroActivado)
            .map(filtro => filtro.idActor);
    
            // Filtrar los datos de dataTR que coincidan con actuadores activados
            const dataFiltrada = dataTR.filter(item => 
                actoresActivados.includes(item.idActuador)
            );      
            setDataFiltrada(dataFiltrada);
        } else {
            setDataFiltrada(dataTR);
        }
      }, [dataTR, filtroTiempoReal, filtrosActivados]);


    useEffect(() => {
        const timer = setTimeout(() => {
            ObtenerTR();
        }, 5000);

        return () => clearTimeout(timer);
    }, [ObtenerTR])
   
     
    const handleOpenDetalle = (e, item) => {
        setDataToShow(item)
        setAnchorEl(e.currentTarget)
    }

    return (
        trOpen ?
            <Modal id="ModalTiempoReal" slotProps={{backdrop: {sx:{backgroundColor: 'transparent'}}}} onClose={handleTiempoReal} open={trOpen} >
                <Box id="ModalTiempoReal" sx={{ width: `${trWidth}px`, height: `${trHeight}px` }} >
                    <Box id="CabeceraTR">
                        <Button onClick={handleTiempoReal} fullWidth id="BotonCerrarTR" >
                            <CerrarTiempoRealIcon id="IconoCierreTR" />
                            <TiempoRealIcon id="IconoTR" />
                            <Typography id="TextoBotonTRAbierto">
                                {
                                    "Accesos en tiempo real"
                                }
                            </Typography>
                        </Button>
                        
                        <IconButton 
                            aria-label="delete" 
                            size="large" 
                            onClick={handleClickMenu}
                        >
                            <MoreVertIcon size={"large"} />
                        </IconButton> 
                    </Box>
                    <Divider />
                    <Box id="BoxTablaTR">
                        <table id="tablaTR">
                            <tbody id="bodyTR">                               
                                {dataFiltrada.map((item, index) => {
                                    let nombre = item.nombrePersona === "-" ? "No encontrado" : item.nombrePersona
                                    return (
                                        <tr onClick={(e) => { handleOpenDetalle(e, item) }} id="TiempoRealRow" key={index}  >
                                            <td width="15%"  id="HoraRegistroAcceso" align="center">
                                                {
                                                    dayjs(item.fecha).format("HH:mm")
                                                }
                                            </td>
                                            <td width="10%" id="RespuestaAcceso" align="center">
                                                {
                                                    item.acceso ?
                                                        <ExitoIconTable id="iconoRespueta" />
                                                        :
                                                        <FalloIconTable id="iconoRespueta" />
                                                }
                                            </td>
                                            <td width="55%"  id="NombreUsuarioFinal" align="left">
                                                {
                                                    nombre
                                                }
                                            </td>
                                            <td width="20%" id="SentidoRegistro" style={{ color: item.sentido === "Entrada" ? "#338333" : "#CC261A" }} >
                                                {
                                                    item.sentido
                                                }
                                            </td>
                                        </tr>

                                    );

                                })}
                            </tbody>
                        </table>
                    </Box>
                    <PopOverTipoTarjetaDetalleEventos
                        open={openDetalle}
                        anchorEl={anchorEl}
                        setOpen={setAnchorEl}
                        children={<MostrarDetalleTR setOpen={setAnchorEl}  item={dataToShow} />}
                    />
                    <MenuFiltroTiempoReal
                        anchorEl={anchorElMenu} 
                        handleClose={handleCloseMenu} 
                        filtrosActivados={filtrosActivados}
                        actuadores={actuadores}
                    />
                </Box>
            </Modal>
            :
            <Button id="BotonMostrarTiempoReal" onClick={handleTiempoReal} sx={{ width: `${trWidth}px`, height: `${trHeight}px` }} startIcon={<TiempoRealIcon id="IconoRelojTRCerrado" />} endIcon={<AbrirTiempoRealIcon id="flechaAbrirTR" />}>
                <Typography id="TextoBotonTR">
                    {
                        "Accesos en tiempo real"
                    }
                </Typography>
            </Button>
    );
}

export default TiempoReal;