import axios from "axios";
import { useEffect, useState } from "react";
import { UsuariosNoEnListaNegra } from "../../Service/API/Api";


export default function useNoEnListaNegra()
{
    const [listaUsuarios, setListaUsuarios] = useState([]);

    useEffect(() => {
        axios.get(UsuariosNoEnListaNegra)
        .then(
            (response) =>
            {
                var listaUsuariosFormada = []
                listaUsuariosFormada.push({idPersona:-1, nombreRut:"Usuarios", rut: null, pasaporte: null})
                response.data.forEach(element => {
                    var persona = { idPersona: element.idPersona, nombreRut: element.nombreRut, rut: element.rut, pasaporte: element.pasaporte}
                    listaUsuariosFormada.push(persona)
                });
                setListaUsuarios(listaUsuariosFormada);
            }
        )
        .catch(
            (response) => {
                console.error("Error al obtener el listado de usuarios que no están en lista negra")
            }
        );
    }, [])


    return {
        listaUsuarios
    }
}
