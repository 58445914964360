import { Box } from "@mui/material";
import "./CuerpoAgenda.css";
import { CuerpoDia } from "./CuerpoVistaDia/CuerpoDia";
import { CuerpoAgendaVistaSemana } from "./CuerpoSemana/CuerpoSemana";
import { CuerpoMes } from "./CuerpoMes/CuerpoMes";
import { CuerpoAnio } from "./CuerpoAnio/CuerpoAnio";

export const CuerpoAgenda = ({opcion, setOpcion, fechaActual, addDay, restDay, addWeek, restWeek, setNewDate}) => {
    
    return (
        <Box id="BoxCuerpoAgenda">
            {
                opcion === 1 ?
                <CuerpoDia fechaActual={fechaActual} addDay={addDay} restDay={restDay} />
                :
                opcion === 2 ?
                <CuerpoAgendaVistaSemana fechaActual={fechaActual} addWeek={addWeek} restWeek={restWeek} setNewDate={setNewDate} setOpcion={setOpcion} />
                :
                opcion === 3 ?
                <CuerpoMes fechaActual={fechaActual} setOpcion={setOpcion} setNewDate={setNewDate} />
                :
                <CuerpoAnio fechaActual={fechaActual} setOpcion={setOpcion} setNewDate={setNewDate} />
            }
        </Box>
    );
}