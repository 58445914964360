import { Box, Popover, Typography } from "@mui/material";
import "./AgregarTareaActuador.css"
import { useEffect, useRef, useState } from "react";
import SelectMui from "../../../../SelectMui/SelectMui";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import SelectMultipleCheckmarks from "../../../../SelectMultipleCheckmarks/SelectMultipleCheckmarks";
import useGetActuadores from "../../../../../Hooks/Actuadores/useGetActuadores";
import AutocompleteSimple from "../../../../AutocompleteSimple/AutocompleteSimple";
import { SnackBarTipoUno } from "../../../../snackBar/SnackBar";
import { filtrarActuadoresDisponibles } from "../FuncionesConfiguracionActuador";

const AgregarTareaActuador = ({anchorEl, onClose, setTareasAsignadas, actuadorSeleccionado, crearTareaActor, usuarios, idsActuadoresDisponibles, usuariosPermitidosParaNotificar}) => {
    const data = useSelector(action => action.user.authenticated);
    const open = Boolean(anchorEl);
    const { actuadores } = useGetActuadores(data.idEmpresa);

    const {idTareaActivarDispositivo, idTareaNotificar} = useSelector(
        (state) => state.idsTiposTareas
      );
    
    // Usamos useRef para almacenar los valores de idTareaActivarDispositivo e idTareaNotificar
    const idTareaActivarDispositivoRef = useRef(idTareaActivarDispositivo);
    const idTareaNotificarRef = useRef(idTareaNotificar);
    
    const [actuadoresDisponibles, setActuadoresDisponibles] = useState([]);
    
    useEffect(() => {
        setActuadoresDisponibles(filtrarActuadoresDisponibles(actuadores, idsActuadoresDisponibles));
    }, [idsActuadoresDisponibles, actuadores]);

    const tipoTareas = useSelector((state) => state.tiposTareas.listadoTiposTareas);

    const [listadoCorreosDestino, setListadoCorreosDestino] = useState([]);
    const prevListadoCorreosDestino = useRef(listadoCorreosDestino); // useRef para almacenar el valor anterior de listadoCorreosDestino

    //Variables para manejar el snackbar
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState();

    const formik = useFormik({
        initialValues: {
        tipoTareaSeleccionada: "",
        idActuadorTarea: null,
        correosDestino: [],
        },
        validate: (values) => {
            const errors = {};
            
            // Si la tarea seleccionada es 'Notificar' y no hay correosDestino, mostrar un error
            if (values.tipoTareaSeleccionada === idTareaNotificarRef.current && values.correosDestino.length === 0) {
                errors.correosDestino = "Debe seleccionar al menos un correo destino.";
            }

            else if(values.tipoTareaSeleccionada === idTareaActivarDispositivoRef.current && values.idActuadorTarea === null){
                errors.correosDestino = "Debe seleccionar un actuador.";
            }
            
            return errors;
        },
        onSubmit: (values) => {

            let cuerpoPeticion;
            let tareaAsignada;

            // Si la tarea seleccionada fue Activar Actuador
            if(values.tipoTareaSeleccionada === idTareaActivarDispositivoRef.current)
            {
                if(values.idActuadorTarea === null || values.accionSeleccionada === null){
                    console.log("Error: Se gestionará esto después");
                }
                else{

                    cuerpoPeticion = {
                        actorId: actuadorSeleccionado.idActor,
                        tipoTareaId: values.tipoTareaSeleccionada,
                        detalleTarea: [values.idActuadorTarea],
                    }

                    crearTareaActor(cuerpoPeticion)
                    .then((idActorTarea) => {
                        tareaAsignada = {
                            idActorTareas: idActorTarea,
                            idTipoTarea: values.tipoTareaSeleccionada,
                            tipoTarea: tipoTareas.find(tipoTarea => tipoTarea.idTipoTarea === values.tipoTareaSeleccionada).tarea,
                            detalleNotificacion: [],
                            detalleAccion: {
                                idActor: values.idActuadorTarea,
                                nombre: actuadores.find(actuador => actuador.idActor === values.idActuadorTarea).nombre,
                                tipoActor: actuadores.find(actuador => actuador.idActor === values.idActuadorTarea).tipoActor
                            },
                            detalleTareas: String(values.idActuadorTarea)

                        }
                        // Actualizar el estado tareasAsignadas
                        setTareasAsignadas(prevTareas => [...prevTareas, tareaAsignada]);

                        setSnackbarSeverity("success");
                        setSnackMsg("Tarea creada con éxito");
                        setSnackbar(true);
                    })
                    .catch((error) => {
                        // Manejar el error si la promesa es rechazada
                        console.error('Error creando tarea');
                        setSnackbarSeverity("error")
                        setSnackMsg("Error creando tarea. Intente nuevamente.")
                        setSnackbar(true);
                    });
                }
            }


            // Si la tarea seleccionada fue Enviar Correo para notificar
            else if(values.tipoTareaSeleccionada === idTareaNotificarRef.current)
            {
                if(values.correosDestino === null || values.correosDestino.length === 0 ){
                    console.log("Error: Se enviará un correo después");
                }
                else{
                    cuerpoPeticion = {
                        actorId: actuadorSeleccionado.idActor,
                        tipoTareaId: values.tipoTareaSeleccionada,
                        detalleTarea: values.correosDestino,
                    }

                    crearTareaActor(cuerpoPeticion)
                    .then((idActorTarea) => {
                        tareaAsignada = {
                            idActorTareas: idActorTarea,
                            idTipoTarea: values.tipoTareaSeleccionada,
                            tipoTarea: tipoTareas.find(tipoTarea => tipoTarea.idTipoTarea === values.tipoTareaSeleccionada).tarea,
                            detalleAccion: {},
                            detalleTareas: values.correosDestino.join(";"),
                            detalleNotificacion: values.correosDestino.map(id => {

                                // Buscar el usuario con 'idPersona' igual al valor del arreglo de enteros
                                const usuario = usuarios.find(u => u.idPersona === id);
                            
                                // Si encontramos al usuario, devolvemos un objeto con 'idPersona' y 'correo'
                                if (usuario) {
                                    return {
                                        idPersona: usuario.idPersona,
                                        correo: usuario.correo
                                    };
                                }
                                
                                // Si no se encuentra el usuario, podemos retornar null o un objeto vacío
                                return null;
                            }).filter(item => item !== null), // Se filtran los valores nulos si no encontramos el usuario
                        }
                        
                        // Actualizar el estado tareasAsignadas
                        setTareasAsignadas(prevTareas => [...prevTareas, tareaAsignada]);

                        setSnackbarSeverity("success");
                        setSnackMsg("Tarea creada con éxito");
                        setSnackbar(true);
                    })
                    .catch((error) => {
                        // Manejar el error si la promesa es rechazada
                        console.error('Error creando tarea');
                        setSnackbarSeverity("error")
                        setSnackMsg("Error creando tarea. Intente nuevamente.")
                        setSnackbar(true);
                    });
                }
            }
            // Si la tarea seleccionada es diferente a ninguna
            else
            {
                console.log("Error: La tarea seleccionada no es válida.");
                setSnackbarSeverity("error")
                setSnackMsg("Error: La tarea seleccionada no es válida.")
                setSnackbar(true);
            }
        formik.resetForm();
        setListadoCorreosDestino([]);
        },
    });

    useEffect(() => {
        // Solo actualizamos Formik si listadoCorreosDestino ha cambiado
        if (prevListadoCorreosDestino.current !== listadoCorreosDestino) {
            formik.setFieldValue('correosDestino', listadoCorreosDestino);
            prevListadoCorreosDestino.current = listadoCorreosDestino; // Actualizamos el valor previo
        }
    }, [listadoCorreosDestino, formik]);

    return (
        <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            slotProps={{paper:
                {sx:{
                    borderRadius: '16px'
                }}
            }}
        >
            <Box id="ContainerAgregarTareaActuador">
                <Box id="BoxTituloAgregarTareaActuador">
                    <Typography id="TxtTituloAgregarTareaActuador">
                        {
                            `Agregar nueva tarea `
                        }
                    </Typography>
                </Box>

                <Box id="BoxCuerpoAgregarTareaActuador">
                    <SelectMui
                        label = "Tipo de tarea"
                        name="tipoTareaSeleccionada"
                        listadoElementos={tipoTareas || []}
                        keyListadoElementos={"idTipoTarea"}
                        atributoMostrable={"tarea"}
                        handleChange = {formik.handleChange}
                        elementoSeleccionado = {formik.values.tipoTareaSeleccionada}
                        atributoValue={"idTipoTarea"}
                    />

                    {/* Si el tipo de tarea escogido es activar actuador */}
                    {formik.values.tipoTareaSeleccionada === idTareaActivarDispositivoRef.current &&
                        <Box id="BoxGestionarActuador">
                            <Box sx={{width: '100%'}}>
                                <AutocompleteSimple
                                    listadoElementos={actuadoresDisponibles}
                                    label={"Escoger actuador"}
                                    onSelectionChange={(newValue) => {
                                        formik.setFieldValue("idActuadorTarea", newValue ? newValue["idActor"] : "" );
                                    }}
                                    keyListadoElementos={"idActor"}
                                    mostrarElemento={(option) => `${option["tipoActor"]} - ${option["nombre"]}`}  // Personalizamos cómo se muestra la etiqueta
                                />
                                {formik.errors.correosDestino && (
                                    <Typography color="error" variant="body2">
                                        {formik.errors.correosDestino} {/* Mostramos el mensaje de error */}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    }


                    {/* Si el tipo de tarea escogido es enviar correo */}
                    {formik.values.tipoTareaSeleccionada === idTareaNotificarRef.current &&

                        <Box id="BoxEnviarCorreo">
                            <SelectMultipleCheckmarks
                                width={'100%'}
                                label="Seleccionar correo destino"
                                listadoElementos={usuariosPermitidosParaNotificar || []}
                                keyListadoElementos={"idPersona"}
                                atributoMostrable={"correo"}
                                atributoValue={"idPersona"}
                                selectedValues={listadoCorreosDestino}
                                setSelectedValues={setListadoCorreosDestino}
                            />
                            {formik.errors.correosDestino && (
                                <Typography color="error" variant="body2">
                                    {formik.errors.correosDestino} {/* Mostramos el mensaje de error */}
                                </Typography>
                            )}
                        </Box>
                    }
                    <SnackBarTipoUno 
                        open={snackbar}
                        handler={handleCloseSnackbar}
                        duration={6000}
                        severity={snackbarSeverity}
                        msg={snackMsg}
                    />
                </Box>{/* Fin BoxCuerpoAgregarTareaActuador */}
                <Box id="BoxBotonEnviarTareaActuador">
                    <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} />
                </Box>
            </Box>
        </Popover>
    )
}

export default AgregarTareaActuador;