import axios from "axios";

const { useState } = require("react");
const { TiempoRealEmpresa } = require("../../Service/API/Api");

function useTiempoReal(){
    const [dataTR, setDataTR] = useState([]);

    async function ObtenerTR()
    {
        await axios.get(TiempoRealEmpresa)
        .then((response) => {
            setDataTR(response.data);
        }).catch((error) => {
            console.log("Error al obtener la información de tiempo real");
        });
    }
    
    

    return {
        dataTR, ObtenerTR
    }
}

export default useTiempoReal;