import { Box, CardMedia, FormControl, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ModalLoadingSimple } from "../Modal/ModalLoding/ModalLoading";
import lcp2 from "./../../assets/imagenes/CP2.png";
import { BotonConLoadingEvent } from "../Botones/BotonesTabla/Botones";
import TooltipTipoUno from "../ToolTip/Tooltip";
import { FechaIconVerificarEvento, HoraIconVerificarEvento, UbicacionIconVerificarEvento } from "./IconosVerificarEvento";
import useVisitas from "../../Hooks/Eventos/useVisitas";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { cleanRut, validateRut } from "../../Helper/ValidarRut";
import { SnackBarTipoUno } from "../snackBar/SnackBar";
import { LoadingMasRespuestaCinco } from "../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../Modal/RespuestaServidor/RespuestaServidorIcon";
import "./ConfirmarAsistencia/ConfirmarAsistencia.css";

const ObtenerMesActualTexto = (id) =>{
    const date = new Date();
    date.setMonth(id);

    var mes = date.toLocaleString('es-ES', {month: 'long'})
    return mes;
}


export const ValidaryConfirmar = ({setOpcion, setInfoEventoUsuario, setInfoEvento}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [evento, setEvento] = useState(null);
    const [openLoading, setOpenLoading] = useState(false);
    const [dataEvento, setDataEvento] = useState({});
    // const [tipoIdentificador, setTipoIdentificador] = useState(1);


    const [tipoIdentificador, setTipoIdentificador] = useState(1);
    const handleChangeTipoIdentificador = (event) => {
        setTipoIdentificador(event.target.value);
    }

    const [openModal, setOpenModal] = useState(false);
    const buscar = useRef();
    const {BuscarEvento, loading, BuscarInvitado, setLoading, response } = useVisitas();
    const [isValid, setIsValid] = useState(null);
    const [snackMsg, setSnackMsg] = useState({severity: '', mensaje: ''});
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(() => {
        if(searchParams.size > 0){
            setEvento(atob(searchParams.get('idVisita')))
            searchParams.delete('idVisita');
            setSearchParams(searchParams);
            setTimeout(() => {
                ActivarBoton()
            }, 1000);
        }
    },[searchParams, setSearchParams]);
    

    const ActivarBoton = () => {
        buscar.current.click()
    }

    const handleClickSearchEvento = async () => {
        setOpenModal(true);
        await BuscarEvento(evento)
        .then(
            
            (resolve) => {
                setDataEvento(resolve);
                setIsValid(true)
                setOpenModal(false);
            }
            ,
            (reject) => { 
                setDataEvento({});
                setIsValid(false)
                setOpenModal(false);

            }
        )
    } 

    let nombre = dataEvento.nombreUbicacion === null || dataEvento.nombreUbicacion === undefined ? "Sin información": dataEvento.nombreUbicacion
    let numeracion = dataEvento.numeracion === null || dataEvento.numeracion === undefined ? "Sin información": dataEvento.numeracion
    let piso = dataEvento.pisoUbicacion === null || dataEvento.pisoUbicacion === undefined ? "Sin información": dataEvento.pisoUbicacion

    let destinoData = 
        (dataEvento.nombreUbicacion === null || dataEvento.nombreUbicacion === undefined) && (dataEvento.numeracion === null || dataEvento.numeracion === undefined) && (dataEvento.pisoUbicacion === null || dataEvento.pisoUbicacion === undefined) ? 
            "Sin información."
        :
            nombre + ", N°" + numeracion + ", Piso: " + piso + "."

    let direccionEmpresa = dataEvento.direccionEmpresa;

    // const handleIdentificador = (val) => () => {
    //     setTipoIdentificador(val)
    // }
    
    const ValidationIdentifier = Yup.object({
        identificador: Yup.string()
        .test("min", tipoIdentificador === 1 ? "Rut inválido" : tipoIdentificador === 2 ? "Información no válida" : "El número movil debe tener 9 digitos incluyendo el 9" , function(value){
            var reg = /^\d+$/;
            if(tipoIdentificador === 1){
                return (validateRut(cleanRut(value)));
            }
            if(tipoIdentificador === 3){
                if(value.length > 9)
                {
                    return false;
                }
                else{
                    return true;
                }
            }
            else{
                if(value.match(reg)){
                    return true;
                }
                else{
                    return false;
                }
            }
        })
        .required("Este campo es obligatorio")        
    })

    const formik = useFormik({
        initialValues: {identificador: ''},
        enableReinitialize:true,
        validationSchema: ValidationIdentifier,
        onSubmit: async () => {
            if(dataEvento.estado === false){
                setSnackMsg({severity: 'error', mensaje: "Este evento está finalizado, confirma tu invitación"})
                setSnackbar(true)
            }
            else{
                setOpenLoading(true);
                setLoading(true);
                await BuscarInvitado(formik.values.identificador, tipoIdentificador)
                .then(
                    (resolve) => {
                        setInfoEventoUsuario(resolve)
                        setInfoEvento(evento)
                        setOpcion(2);
                    }
                    ,
                    (reject) =>{
                        setSnackMsg({severity: 'error', mensaje: "Invitado no encontrado..."})
                        setSnackbar(true)
                    }
                )
            }
        }
    })
    

    return(
        <Box id="BoxContenedorConfirmarAsistencia">
            <input ref={buscar} onClick={handleClickSearchEvento} style={{display:"none"}} />
            <Box id="BoxImagenMasContenidoConfirmarAsistencia">
                <Box id="BoxContenedorImagenConfirmacionAsistencia">
                    <CardMedia id="ImagenLogoCP2ConfirmacionAsistencia" component="img" src={lcp2} />
                </Box>
                <Box id="BoxInformacionEventoConfirmarAsistencia">
                    <Box id="BoxContenedorInformacionEventoIdentificadorConfirmarAsistencia">
                        <Box id="BoxTitulosConfirmacionAsistenciaEvento">
                            <Box id="BoxContenedorTituloSaludoConfirmacionEvento">
                                <Typography id="TxtTituloConfirmacionEvento">
                                    {
                                        "¡Hola!"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxContenedorSubtituloSaludoConfirmacionEvento">
                                <Typography id="TxtSubtituloConfirmacionEvento">
                                    {
                                        "Confirma tu asistencia al evento"
                                    }
                                </Typography >
                            </Box>
                            <Box id="BoxContenedorNombreEventoSaludoConfirmacionEvento">
                                <TooltipTipoUno titulo={"NombreEvento"}>
                                    <Typography id="TxtNombreEventoConfirmacionEvento">
                                        {
                                            dataEvento.nombreEvento ?? "Sin informacion"
                                        }
                                    </Typography>
                                </TooltipTipoUno>
                            </Box>
                        </Box>
                        <Box id="BoxDataEventoNotaIdentificadorConfirmacionAsistencia">
                            <Box id="BoxContenedorInformacionEventoConfirmarAsistencia">
                                <Box id="BoxInformacionEventoMasIconoConfirmarAsistencia">
                                    <FechaIconVerificarEvento id="iconoConfirmarAsistencia" /> 
                                    <Typography id="TextoInformacionEventoConfirmarAsistencia">
                                        {
                                            "Fecha: " + dayjs(dataEvento.fechaVisita).format("DD") + " de " + ObtenerMesActualTexto(dayjs(dataEvento.fechaVisita).month()) + " del " + dayjs(dataEvento.fechaVisita).format("YYYY")
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInformacionEventoMasIconoConfirmarAsistencia">
                                    <HoraIconVerificarEvento id="iconoConfirmarAsistencia" /> 
                                    <Typography id="TextoInformacionEventoConfirmarAsistencia">
                                        {
                                            "Hora: De " + dayjs(dataEvento.fechaVisita).format("HH:mm") + " a " + dayjs(dataEvento.fechaTermino).format("HH:mm") + " horas."
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInformacionEventoMasIconoConfirmarAsistencia">
                                    <UbicacionIconVerificarEvento id="iconoConfirmarAsistencia" /> 
                                    <TooltipTipoUno titulo={null} ubicacion={"bottom"}>
                                        <Typography id="TextoInformacionEventoConfirmarAsistencia">
                                            {
                                                "Destino: " + destinoData
                                            }
                                        </Typography>
                                    </TooltipTipoUno>
                                </Box>
                                <Box id="BoxInformacionEventoMasIconoConfirmarAsistencia">
                                    <Box id="iconoQrEvento">
                                    </Box>
                                    <TooltipTipoUno titulo={null} ubicacion={"bottom"}>
                                        <Typography id="TextoInformacionEventoConfirmarAsistencia">
                                            {
                                                direccionEmpresa === undefined || direccionEmpresa === null ? 
                                                 "Dirección: Sin información."
                                                :
                                                 "Dirección: " + direccionEmpresa + "."
                                            }
                                        </Typography>
                                    </TooltipTipoUno>                                    
                                </Box>
                            </Box>
                            <Box  id="BoxNotaEventoConfirmarAsistencia">
                                <Typography id="TxtNotaEventoConfirmarAsistencia">
                                    {"Por favor, escoje tu identificador y completa la información para validar tu asistencia al evento."}
                                </Typography>
                            </Box>
                            <Box id="BoxInputIdentificadorQRVisita" component={"form"} onSubmit={formik.handleSubmit}>
                            <FormControl hiddenLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={tipoIdentificador}
                                    label="Tipo"
                                    onChange={handleChangeTipoIdentificador}
                                    input={<OutlinedInput />}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    displayEmpty
                                    MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            backgroundColor: '#175676'
                                          },
                                        },
                                    }}
                                    sx={{
                                        width: '120px',
                                        fontSize: '15px',
                                        height: '100%',
                                        backgroundColor: '#175676',  // color fondo para el Select
                                        color: 'white',  // color de letras para el Select
                                        '& .MuiSelect-icon': {
                                            color: 'white',  // color del icono de dropdown
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#175676',  //color del borde
                                        },
                                        '& .MuiSelect-root': {
                                            backgroundColor: '#175676',  // color fondo para todo el contenedor
                                        },
                                        '& .MuiMenu-paper': {
                                            backgroundColor: '#175676',  // color fondo para el menú desplegable de opciones
                                            color: 'white',  // Color para el texto de las opciones
                                        }
                                    }}
                                >
                                    <MenuItem 
                                        value={1} 
                                        sx={{
                                            backgroundColor: '#175676', 
                                            color: 'white', 
                                            '&:hover': { 
                                                backgroundColor: '#0066cc'  // Cambiar fondo al hacer hover
                                            }
                                        }}
                                    >
                                        Rut
                                    </MenuItem>
                                    <MenuItem 
                                        value={2} 
                                        sx={{
                                            backgroundColor: '#175676', 
                                            color: 'white', 
                                            '&:hover': { 
                                                backgroundColor: '#0066cc'  // Cambiar fondo al hacer hover
                                            }
                                        }}
                                    >
                                        Pasaporte
                                    </MenuItem>
                                    <MenuItem 
                                        value={3} 
                                        sx={{
                                            backgroundColor: '#175676', 
                                            color: 'white', 
                                            '&:hover': { 
                                                backgroundColor: '#0066cc'  // Cambiar fondo al hacer hover
                                            }
                                        }}
                                    >
                                        Teléfono
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                sx={{
                                    borderRadius: "8px",
                                    width: "70%",
                                }}
                                id="identificador"
                                name={"identificador"}
                                hiddenLabel={true}
                                variant='filled'                                    
                                placeholder={"Información de identificación"}
                                inputProps={
                                    {
                                        style: {
                                            paddingLeft: "15px",
                                        }
                                    }
                                }
                                value={formik.values.identificador}
                                onChange={formik.handleChange}
                                InputProps={
                                    {
                                        style: {
                                            paddingLeft: "0px",
                                        }
                                        ,
                                        disableUnderline: true
                                    }
                                }
                                helperText={formik.errors.identificador}
                            />
                        </Box>
                            {/* <Box id="BoxIdentificacionConfirmarAsistencia" component={"form"} onSubmit={formik.handleSubmit}>
                                <TextField fullWidth
                                    sx={{
                                        borderRadius: "8px", height: "56px"
                                    }}
                                    id={"identificador"}
                                    name={"identificador"}
                                    hiddenLabel={true}
                                    variant='filled'                                    
                                    placeholder={"Información de identificacion"}
                                    inputProps={
                                        {
                                            style: {
                                                paddingLeft: "15px",
                                            }
                                        }
                                    }
                                    value={formik.values.identificador}
                                    onChange={formik.handleChange}
                                    InputProps={
                                        {
                                            style: {
                                                paddingLeft: "0px",
                                            }
                                            ,
                                            disableUnderline: true
                                            ,
                                            startAdornment: tipoIdentificador === 1 ? 
                                            <IconButton id="IconButtonIdentificador" onClick={handleIdentificador(2)}>
                                                <Typography id="TextoIdentificadorQRVisita">
                                                    {
                                                        "Rut"
                                                    }
                                                </Typography>
                                            </IconButton> 
                                            : 
                                            tipoIdentificador === 2 ? 
                                            <IconButton id="IconButtonIdentificador" onClick={handleIdentificador(3)}>
                                                <Typography id="TextoIdentificadorQRVisita">
                                                    {
                                                        "Pasaporte"
                                                    }
                                                </Typography>
                                            </IconButton>
                                            :
                                            <IconButton id="IconButtonIdentificador" onClick={handleIdentificador(1)}>
                                                <Typography id="TextoIdentificadorQRVisita">
                                                    {
                                                        "Teléfono"
                                                    }
                                                </Typography>
                                            </IconButton>
                                        }
                                    }
                                    helperText={formik.errors.identificador}
                                />
                            </Box> */}
                        </Box>
                    </Box>
                    <Box id="BoxAccionesConfirmarAsistencia">
                        {
                            isValid === true  && dataEvento.estado === true ? 
                            <BotonConLoadingEvent disabled={dataEvento.estado ? false: true} textoBoton={"Buscar Invitación"} event={formik.submitForm} loadingStatus={loading} />
                            :
                            <Typography id="TextoVentanaObteniendoDataQRVisitaRespuestaServidor" >
                                {
                                    dataEvento.estado === false ?
                                    "Este evento se encuentra finalizado, confirme su invitación"
                                    :
                                    "Alerta! No ha proporcionado información del evento"
                                }
                            </Typography>
                        }
                    </Box>
                </Box>
            </Box>
            <Box>
                {
                    loading === true ?
                    "Validando invitación, agradecemos tu espera..."
                    :
                    null
                }
            </Box>
            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackMsg.severity}
            msg={snackMsg.mensaje}
            />
            <LoadingMasRespuestaCinco
                open={openLoading} 
                setOpen={setOpenLoading} 
                msj={"La información del invitado está siendo verificada. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
                id={"RespuestaValidarInvitadoAEvento"} 
                tituloLoading={"Buscando al invitado..."}
                loading={loading} 
                respuesta={response} 
                icono={
                    response === 200 ?
                    <IconoExito id="IconoRespuestaServidor" />
                    :
                    <IconoFallo id="IconoRespuestaServidor" />
                }
                textoBoton={
                    response === 200 ? 
                    "Aceptar"
                    :
                    "Reintentar"
                }
                titulo={response === 200 ? "Invitado encontrado":"Invitado no encontrado"}
                mensajeRespuesta={response === 200 ? 
                    "Se ha encontrado la información del invitado. Recuerda completar la información con tus datos para ingresar al lugar del evento."
                    :
                    "Ha ocurrido un error y no se ha podido verificar tu información.  Por favor verifique los datos ingresados o utilice otro metodo de identificación"}
                />
            <ModalLoadingSimple open={openModal} setOpen={setOpenModal} msj={"Validando evento..."} />
        </Box>
    );
}