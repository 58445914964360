import { Box, FormControl, FormHelperText, Typography } from "@mui/material";
import { useFormik } from "formik";
import "./VerMiembros.css"
import usePersonaEnUbicacion from "../../../Hooks/PersonaUbicacion/usePersonaEnUbicacion";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { useState } from "react";
import { LoadingMasRespuestaCuatro } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import { EsquemaNuevo } from "./Validation";
import AutocompleteSimple from "../../AutocompleteSimple/AutocompleteSimple";


export const NuevoMiembro = ({setRows, ubicacion}) => {
    const {usuariosSinUbicacion, AgregarUsuarioAUbicacion, loadingReq, setLoadingReq, response} = usePersonaEnUbicacion();
    
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [confirmMessage, setConfirmMessage] = useState({titulo: "", mensaje: ""});
    const [ConfirmationDialog, confirm ]= useConfirm();

    const AgregarRow = (nuevaId) => {
        var userFinded = usuariosSinUbicacion.find(u => u.idUsuario === formik.values.idPersonaMiembro)

        setRows((oldRows) => [
            
            ...oldRows,
            {
                idUsuario: formik.values.idPersonaMiembro,
                nombre: formik.values.nombrePersona,
                idPersonaUbicacion: nuevaId,
                tipoUsuario: userFinded.tipoUsuario,
                estado: userFinded.estado,
                isNew: true,
            },
        ]);
    }

    const handleAgregarOtro = () => {
        formik.resetForm();
    }

    const formik = useFormik(
        {
            initialValues: {
                idUbicacion: ubicacion,
                idPersonaMiembro: -1,
                nombrePersona : "Usuarios"
            },
            validationSchema: EsquemaNuevo,
            enableReinitialize: true,
            onSubmit: async() => {
                setConfirmMessage({titulo: "¿Agregar?", mensaje: "Estas a punto de agregar un nuevo usuario a la ubicacion ¿Continuar?"})
                var response = await confirm();
                if(response)
                {
                    setLoadingReq(true);
                    setOpenLoading(true);
                    await AgregarUsuarioAUbicacion(formik.values)
                    .then(
                        (resolve) => {
                            console.log("nueva id", resolve);

                            AgregarRow(resolve);
                        },
                        (reject) => {
                            console.log("Error en nueva asociacion");
                        }
                    )
                }
                else{
                    console.log("Rechaza la nueva asociacion");
                }
            }
        }
    )

    return(
        <Box id="BoxFormNuevoMiembro">
            <Box id="BoxCabeceraNuevoMiembro">
                <Typography id="TxtCabeceraNuevoMiembro">
                    {
                        "Agregar usuario"
                    }
                </Typography>
            </Box>
            <Box id="BoxSeleccionadorNuevoUsuarioUbicacion">
                <Box id="BoxAutocompleteUsuarios">
                    <FormControl fullWidth>
                        <AutocompleteSimple
                            disablePortal={true}
                            freeSolo = {true}
                            listadoElementos={usuariosSinUbicacion}
                            label="Usuarios"
                            onSelectionChange={(newValue) => {
                                if(newValue === null)
                                {
                                    formik.setFieldValue("idPersonaMiembro", -2)                                           
                                }
                                else{
                                    formik.setFieldValue("idPersonaMiembro", newValue.idUsuario)
                                    formik.setFieldValue("nombrePersona", newValue.nombre)
                                }
                            }}
                            keyListadoElementos="idUsuario"
                            mostrarElemento={(option) => option["nombre"] || ''}
                            elementoSeleccionado={usuariosSinUbicacion.find(usuario => formik.values.idPersonaMiembro === usuario.idUsuario) || null}
                        />
                        <FormHelperText>
                            {
                                formik.errors.idPersonaMiembro
                            }
                        </FormHelperText>                                   
                    </FormControl>
                </Box>
                <Box id="BoxBotonAgregar">
                    <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} />
                    
                </Box>
            </Box>
            <LoadingMasRespuestaCuatro
            id={"LoadingMasRespuestaDosNuevoActuador"}
            open={openLoading}
            setOpen={setOpenLoading}
            msj={
                "Se está agregando un usuario a la ubicacion. Este proceso puede tomar un momento. Agradecemos tu paciencia."
            }
            tituloLoading={"Agregando usuario..."}
            loading={loadingReq}
            respuesta={response}
            titulo={
                response === 201 ? 
                "Usuario agregado"
                : 
                "Usuario no agregado"
            }
            mensajeRespuesta={
                response === 201
                ? "El Usuario ha sido agregado exitosamente a la ubicacion. Ahora puedes agregar otros miembros a este destino"
                : response === 403 ?
                    "No se ha podido generar la solicitud de creacion, ya que no cuenta con el perfil requerido, contacte al administrador del sistema"
                    :
                    "No se ha podido agregar el usuario debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia"
            }
            txtBotonUno={"Agregar otro"}
            eventBotonUno={handleAgregarOtro}
            />
            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
            titulo={confirmMessage.titulo}
            mensaje={confirmMessage.mensaje}
            />
        </Box> 
    );
}