import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import "./ManejadorOpciones.css"
import { LoadingMasRespuestaCuatro } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import { useFormik } from "formik";
import { IconoBorrarCampoGU } from "../../Modal/MenuGestionUsuario/MenuGestionUsuarioIcon";
import { InitialValuesNE } from "./InitialValues";
import { useState } from "react";
import { EsquemaNuevaPatente } from "./ValidationSchema";
import useGetEstacionamientos from "../../../Hooks/Estacionamientos/useGetEstacionamientos";
import { useSelector } from "react-redux";
import AutocompleteSimple from "../../AutocompleteSimple/AutocompleteSimple";

export const NuevoEstacionamiento = ({setOpen, setRows}) =>{
    const zonasFormadas = useSelector((state) => state.zonasFormadasRegistradas.listadoZonasFormadas);
    const {setLoading, loading, response, CreateEstacionamiento} = useGetEstacionamientos();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const handlerClose = () => {
        formik.resetForm();
        setOpen(false);
    }

    const AddNewRow = (nuevaId) => {
        var findedZona = zonasFormadas.find(z => z.id === formik.values.idZonaEstacionamiento);
        
        var nuevoEstacionamiento = {
            idEstacionamiento: nuevaId,
            idPersona: null,
            idVisita: null,
            estado: true,
            fechaAsignacion: null,
            fechaTermino: null,
            idPersonaEstacionamiento: null,
            nombreZona: findedZona.tipo,
            idZona: findedZona.id,
            numero: formik.values.nombreEstacionamiento,
            pasaporte: null,
            rut: null,
            nombreCompleto: null,
            isNew: true
        }
        setRows((oldRows) => [
            
            ...oldRows,
            nuevoEstacionamiento,
        ]);
    }

    const formik = useFormik({
        initialValues:InitialValuesNE,
        enableReinitialize: true,
        validationSchema: EsquemaNuevaPatente,
        onSubmit: async ()=> {
            setLoading(true);
            setOpenLoading(true);
            await CreateEstacionamiento(formik.values)
            .then(
                (resolve) => {
                    AddNewRow(resolve)
                }
                ,
                (reject) => {
                    console.log("fallo la creacion");
                }
            )
        }
    })

    const handleAgregarOtro = () => {
        formik.resetForm();
        setOpenLoading(false);
    }

    return (
        <Box id="BoxContenedorNuevoEstacionamiento">
            <Box id="BoxTituloSubTituloNuevoEstacionamiento">
                <Box id="BoxTituloOpcionGestionEstacionamientos">
                    <Typography id="TxtTituloGestionEstacionamiento">
                        {
                            "Agregar estacionamiento"
                        }
                    </Typography>
                </Box>
                <Box id="BoxSubTituloOpcionGestionEstacionamientos">
                    <Typography id="TxtSubTituloGestionEstacionamiento">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxInformacionNuevoEstacionamiento">
                <Box id="BoxContenidoInformacionEstacionamiento" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxContenidoZonaNombreEstacionamiento">
                        <Box id="BoxTituloNuevoEstacionamientoFormato">
                            <Typography id="TxtOpcionTituloFormulario">
                                {
                                    "Información del estacionamiento"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxFormularioNuevoEstacionamiento">
                            <Box id="BoxContenedorOpcionesFormularioNuevoEstacionamiento">
                                <TextFieldTres
                                id="TFNuevoEstacionamientoNombre"
                                label={"Nombre Estacionamiento"}
                                placeholder={"Nombre del estacionamiento"}
                                icon={<IconoBorrarCampoGU id="IconoEnEstacionamiento" />}
                                helper={formik.errors.nombreEstacionamiento}
                                value={formik.values.nombreEstacionamiento}
                                name={"nombreEstacionamiento"}
                                handlerChange={formik.handleChange}
                                handlerClick={() => formik.setFieldValue("nombreEstacionamiento", "")}
                                disabled={false}
                                />
                            </Box>
                            <Box id="BoxContenedorOpcionesFormularioNuevoEstacionamiento">
                                <FormControl fullWidth>
                                    <AutocompleteSimple
                                        disablePortal={true}
                                        freeSolo = {true}
                                        listadoElementos={zonasFormadas}
                                        label="Zonas"
                                        onSelectionChange={(newValue) => {
                                            formik.setFieldValue('idZonaEstacionamiento', newValue ? newValue["id"] : -2);
                                            formik.setFieldValue('nombreZona', newValue ? newValue["tipo"] : '');
                                        }}
                                        keyListadoElementos="id"
                                        mostrarElemento={(option) => option["tipo"] || ''}
                                        elementoSeleccionado={zonasFormadas.find(zonaFormada => formik.values.idZonaEstacionamiento === zonaFormada.id) || null}
                                    />
                                    <FormHelperText>
                                        {
                                            formik.errors.idZonaEstacionamiento
                                        }
                                    </FormHelperText>                                   
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>                   
                </Box>
                <Box id="BotoncesAccionModalNuevoUsuario">
                    <Box id="BoxBotones">
                        <Box id="BoxBotonNuevoUsuario">
                            <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                        </Box>
                        <Box id="BoxBotonNuevoUsuario">
                        <BotonTipo2
                            textoBoton={"Agregar"}
                            event={formik.submitForm}
                            disabled={loading}
                        />
                        <LoadingMasRespuestaCuatro
                            id={"LoadingMasRespuestaDosNuevoUsuario"}
                            open={openLoading}
                            setOpen={setOpenLoading}
                            msj={
                            "Se está agregando el nuevo usuario al sistema. Este proceso puede tomar un momento. Agradecemos tu paciencia"
                            }
                            tituloLoading={"Agregando usuario..."}
                            loading={loading}
                            respuesta={response}
                            titulo={
                                response === 201
                                ? "Agregado exitosamente"
                                : "Ha Fallado"
                            }
                            mensajeRespuesta={
                                response === 201
                                ? "Nuevo estacionamiento agregado exitósamente, ahora puede asignar un usuario o invitado al estacionamiento."
                                : "La creación del estacionamiento ha fallado, ocurrió un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                            }
                            txtBotonUno={"Agregar otro estacionamiento"}
                            eventBotonUno={handleAgregarOtro}
                        />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}