import axios from "axios";
import { ExportarReporte } from "../../Service/API/Api";
import { useState } from "react";

function useReporteDownload(){
    const [loadingDescarga, setLoadingDescarga] = useState(null);
    const [respuesta, setRespuesta] = useState(null);

    async function DescargarArchivoReporte(reporteOpciones){
        return new Promise((resolve, reject) => {
            axios.request({
                method: "POST",
                url: ExportarReporte,
                data: reporteOpciones,
                responseType: "blob",
            })
            .then((response) => {
                let filename = "";
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
                var disposition = response.headers['content-disposition']
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);                    
                }
                else
                {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);
    
                    if (filename) {
                        var a = document.createElement("a");
                        if (typeof a.download === 'undefined') {
                            window.location.href = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location.href = downloadUrl;
                    }
                    resolve(true);
                    setRespuesta(response.status)
                    setLoadingDescarga(false);
                    setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); 
                }
            })
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setRespuesta(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setRespuesta("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setRespuesta("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoadingDescarga(false);
                }
            );
        })
       
    }

    return {
        loadingDescarga,
        setLoadingDescarga,
        DescargarArchivoReporte,
        respuesta
    }
}


export default useReporteDownload;