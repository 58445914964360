import { useState } from "react";
import { CambioContrasena, CambioCorreo, CargaImagenPerfil, CompletarCambioCorreo, EditarUsuario } from "../../Service/API/Api";
import axios from "axios";

function usePerfil() {
    const [loading, setLoading] = useState(null);
    const [respuesta, setRespuesta] = useState(null);
    const [respuestaCC, setRespuestaCC] = useState(null); 
    const [respuestaCorreo, setRespuestaCorreo] = useState(null); 
    const [respuestaFotoPerfil, setRespuestaFotoPerfil] = useState(null);
    const [respuestaCambioCorreo, setRespuestaCambioCorreo] = useState(null); 

   

    const ActualizarFotoPerfil = (idPersona, imagen) => {
        const formData = new FormData();
        formData.append('IdPersona', idPersona);
        formData.append('ImagenPerfil', imagen );
        axios.post(CargaImagenPerfil, formData)
          .then((response) => {
            setRespuestaFotoPerfil(response);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      };
    

    const GuardarRespuesta = (res) => 
    {
        setLoading(false);
        setRespuesta(res.status);
    }

    const ActualizarDataUsuario = (valores, idPersona) => {
        var data = {
            "idPersona": idPersona,
            "rut": valores.rut,
            "pasaporte": valores.pasaporte,
            "nombres": valores.nombres,
            "apellidos": valores.apellidos,
            "telefono": valores.telefono
          }

        axios.put(EditarUsuario, data)
        .then((data) => {GuardarRespuesta(data, idPersona)})
        .catch(
            (error) => { 
                // Asegurarse de que error.response existe antes de acceder a error.response.status
                if (error.response) {
                    setRespuesta(error.response.status); // Código de estado si el servidor respondió
                } else if (error.request) {
                    // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                    setRespuesta("Error de red o servidor inaccesible. Contáctanos para más información.");
                } else {
                    // Otro error que no está relacionado con la respuesta ni la solicitud
                    setRespuesta("Error desconocido. Contáctanos para más información.");
                }
                setLoading(false);
            }
        )
    }

    const ActualizarPassWordUsuario = (valores) => {
        var data = {
            "correo": valores.correoEditarPass,
            "contrasenaActual":  valores.passActual,
            "contrasenaNueva":  valores.passNuevaAgregar
        }

        axios.put(CambioContrasena, data)
        .then((data) => {RespuestaActualizar(data)})
        .catch(
            (error) => { 
                // Asegurarse de que error.response existe antes de acceder a error.response.status
                if (error.response) {
                    setRespuestaCC(error.response.status); // Código de estado si el servidor respondió
                } else if (error.request) {
                    // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                    setRespuestaCC("Error de red o servidor inaccesible. Contáctanos para más información.");
                } else {
                    // Otro error que no está relacionado con la respuesta ni la solicitud
                    setRespuestaCC("Error desconocido. Contáctanos para más información.");
                }
                setLoading(false);
            }
        )
    }

    const RespuestaActualizar = (data) => {
        setRespuestaCC(data.status);
        setLoading(false);
    }

    const CrearCambioCorreo = (correoActual, correoNuevo) =>{
        const data = {
            correoActual: correoActual,
            nuevoCorreo: correoNuevo
        };


        axios.post(CambioCorreo, data)
        .then((data) => {GuardarRespuestaCorreo(data)})
        .catch((error) => { console.error("Error al realizar la operación con el correo")})
    }

    const GuardarRespuestaCorreo = (data) => {
        setRespuestaCorreo(data.status);
        setLoading(false);
    };

    const CambiarCorreo = (correoActual, correoNuevo, idEmpresa, token) =>{

        const dataToChange = {
            "correoActual": correoActual,
            "nuevoCorreo": correoNuevo.toUpperCase(),
            "idEmpresa": idEmpresa,
            "token": token
        };
        // console.log(dataToChange);

        axios.put(CompletarCambioCorreo, dataToChange)
        .then((data) => {GuardarRespuestaCambioCorreo(data)})
        .catch((error) => { console.error("Error al cambiar el correo")})
    }

    const GuardarRespuestaCambioCorreo = (data) => {
        setRespuestaCambioCorreo(data.status);
        setLoading(false);
    };



    return {
        respuesta,
        ActualizarDataUsuario,
        loading,
        setLoading,
        respuestaCC,
        ActualizarPassWordUsuario,
        CrearCambioCorreo,
        respuestaCorreo,
        CambiarCorreo,
        respuestaCambioCorreo,
        ActualizarFotoPerfil,
        respuestaFotoPerfil,
    }
}

export default usePerfil;