import { useEffect, useState } from 'react'
import { EliminarRegistroHistorial, Historial } from '../../Service/API/Api';
import axios from 'axios';

function useNotificaciones () {
    const [loading, setLoading] = useState(true);
    const [notificaciones, setNotificaciones] = useState([]);
    const [error, setError] = useState('false');
    const [response, setResponse] = useState();

    const saveData = (data) =>{
        setNotificaciones(data)
        setLoading(false)
    }

    useEffect(() => {
        if (loading){
          async function getData () {
              await axios.get(Historial)
              .then((response)=>{
                saveData(response.data)})
              .catch( (response)=>(setError("Error al obtener la información de notificaciones")))
              } 
          getData();
        }
    }, [loading])

    const EliminarRegistro = (id) => {
      	return new Promise((resolve, reject) => {
			axios.delete(EliminarRegistroHistorial + id)
			.then(
				(response) => {
					resolve(true);
					setResponse(response.status)
					setLoading(false);
				}

			)
			.catch(
				(error) => {
          // Asegurarse de que error.response existe antes de acceder a error.response.status
          if (error.response) {
              setResponse(error.response.status); // Código de estado si el servidor respondió
          } else if (error.request) {
              // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
              setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
          } else {
              // Otro error que no está relacionado con la respuesta ni la solicitud
              setResponse("Error desconocido. Contáctanos para más información.");
          }

          reject(false);
          setLoading(false);
				}
			)
      	})
    }

    return {loading, 
        setLoading, 
        notificaciones, 
        error, 
        response,
		    EliminarRegistro
      };
}

export default useNotificaciones;
