import { useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import "./RegistroPatentes.css";
import { IconoAlerta, IconoEditarUsuario } from "../../../../Usuarios/UsuariosIcon";
import { SwitchTablas } from "../../../../Switch/switch";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import usePatentes from "../../../../../Hooks/Patentes/usePatentes";
import { Box } from "@mui/material";
import { SnackBarTipoUno } from "../../../../snackBar/SnackBar";
import DataGridMui from "../../../../DataGrid/DataGridMui";

export const PatentesTablaGU = ({dataPatente, setRows, openEdit, setEdit, setDigitos}) =>{
    const {ActivarDesactivarPatente} = usePatentes();
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [isTrue, setIsTrue] = useState();
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);

    const HandleEstadoPatente = (id, estado) => async () => {
        const rowToEdit = dataPatente.find((row) => row.idPatente === id);
        if(rowToEdit.estadoPatente === true){
            setIsTrue(true);
        }
        else{
            setIsTrue(false);
        }
        let response = await confirm();
        if(response === true){
            await ActivarDesactivarPatente(id, estado)
            .then
            (
                (result) => {
                    rowToEdit.estadoPatente = !rowToEdit.estadoPatente;
                    setRows(dataPatente.map((row) => (row.idPatente === rowToEdit.idPatente ? rowToEdit : row)));
                    setSnackbarSeverity("success")
                    setSnackMsg(!rowToEdit.estadoPatente === true ? "Patente desactivada exitosamente!":"Patente activada exitosamente!")
                    setSnackbar(true);
                }
                ,
                (error) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al guardar, reintente nuevamente")
                    setSnackbar(true);  
                }
            )
        }
        else
        {
            console.log("no se modifica")
        }
    }

    const HandleEditar = (id, patente) => async () => {
        openEdit(true);
        setDigitos(patente);
        setEdit(id);
    }

    const columnNames = [
        {
            field: 'idPatente', headerName: 'id', type: 'string', width: 150, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'patente', headerName: 'Patente', type: 'string', flex: 0.5, minWidth: 500, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'editar', headerName: 'Editar', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id, row}) => {
                return [
                    <GridActionsCellItem
                      icon={<IconoEditarUsuario  id='iconoEnTablaUsuarios'/>}
                      label="Editar"
                      onClick={HandleEditar(id, row.patente)}
                    />,
                  ];
            }
        }
        ,
        {
            field: 'estadoPatente', headerName: 'Estado', type: 'bool', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false,
            renderCell: ({id, row}) => 
            (
                <SwitchTablas 
                id="switchTablaPase" 
                value={row.estadoPatente}
                disabled={false}
                handler={HandleEstadoPatente(id, row.estadoPatente)} 
                />
            )
        },

    ];


    return(
        <Box>

        <DataGridMui
            id="DataGridPatentesGU"
            getRowId={(row) => row.idPatente} //reeemplazar luego por el id definido idMetodos y agregar snackbar
            columns={columnNames}
            rows={dataPatente}
            style={{}}
            checkboxSelection={false}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            rowSelection={true}
            disableRowSelectionOnClick={true}
        />

        <ConfirmationDialog
        icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
        titulo={isTrue ? "¿Desactivar Patente?" : "¿Activar Patente?"}
        mensaje={
        isTrue ? 
            "Estás a punto de desactivar esta patente. Esto restringirá sus accesos. ¿Deseas continuar?" 
            : 
            "Estás a punto de reactivar esta patente. Una vez activado, el usuario tendrá acceso según sus permisos asignados. ¿Deseas continuar?" 
        }
        />
        <SnackBarTipoUno 
        open={snackbar}
        handler={handleCloseSnackbar}
        duration={6000}
        severity={snackbarSeverity}
        msg={snackMsg}
        />
        </Box>
    );
}