import { SvgIcon } from "@mui/material";

export function CerrarMenu(props){
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                <path className="iconAbirMenu" d="M9 1L1 9L9 17" stroke="#626262" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function AbrirMenu(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                <path className="iconAbirMenu" d="M1 17L9 9L1 1" stroke="#626262" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function Inicio(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 22" fill="none">
                <path className="addIcon" d="M6.5 21C7.05228 21 7.5 20.5523 7.5 20V15C7.5 14.4477 7.94772 14 8.5 14H11.5C12.0523 14 12.5 14.4477 12.5 15V20C12.5 20.5523 12.9477 21 13.5 21H18C18.5523 21 19 20.5523 19 20V10.1725C19 9.90239 18.8907 9.64377 18.6971 9.45549L10.6971 1.67771C10.309 1.30037 9.69104 1.30038 9.30292 1.67772L1.30292 9.45549C1.10926 9.64377 1 9.90239 1 10.1725V20C1 20.5523 1.44772 21 2 21H6.5Z" stroke="currentColor" strokeWidth="1.5"/>
            </svg>
        </SvgIcon>
    );
}


export function ListaNegraIcon(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" alignmentBaseline="middle" fill="currentColor" stroke="currentColor" overflow="hidden" viewBox="0 0 1024 1024" version="1.1" >
                <path d="M777.216 556.032c-127.488 0-230.4 102.912-230.4 230.4s102.912 230.4 230.4 230.4 230.4-102.912 230.4-230.4-102.912-230.4-230.4-230.4z m-171.52 230.4c0-94.72 76.8-172.032 171.52-172.032 37.888 0 74.752 12.8 104.448 36.352l-249.344 227.328c-17.408-27.648-26.624-59.392-26.624-91.648z m171.52 171.52c-37.888 0-74.752-12.8-104.448-36.352l249.344-227.328c16.896 26.624 26.624 57.856 26.624 91.648 0.512 95.232-76.288 172.032-171.52 172.032z"  />
                <path d="M587.264 594.944c2.56 1.024 5.632 1.536 8.704 1.536 14.848 8.192 33.28 2.56 41.472-12.288 8.192-14.848 2.56-33.28-12.288-41.472-4.096-2.048-9.216-3.584-13.824-3.584l0.512-1.024c-1.024-0.512-2.048-0.512-3.072-1.024h-0.512c-7.68-2.56-14.848-5.12-22.528-7.68 86.016-48.64 144.384-139.776 144.384-245.76C727.552 126.976 598.528 1.536 441.856 4.096c-153.6 2.56-277.504 125.952-279.552 279.552 0 109.056 62.464 202.752 152.576 250.368-167.424 58.88-281.6 214.528-287.744 391.68h0.512c0 1.024-0.512 1.536-0.512 2.048 0 16.896 13.824 30.72 30.72 30.72 21.504 0 37.376-13.824 37.376-30.72 0-1.024-0.512-1.536-0.512-2.048h0.512c7.68-197.12 163.328-348.16 361.984-348.16 45.056 0.512 89.088 2.56 130.048 17.408zM444.928 504.832c-121.856 0-220.672-98.816-220.672-220.672s98.816-220.672 220.672-220.672S665.6 162.304 665.6 284.16s-98.304 220.672-220.672 220.672z"  />
            </svg>
        </SvgIcon>
    );
}

export function Configuracion(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path className="addIcon" d="M11 3H13C13.5523 3 14 3.44772 14 4V4.56879C14 4.99659 14.2871 5.36825 14.6822 5.53228C15.0775 5.69638 15.5377 5.63384 15.8403 5.33123L16.2426 4.92891C16.6331 4.53838 17.2663 4.53838 17.6568 4.92891L19.071 6.34312C19.4616 6.73365 19.4615 7.36681 19.071 7.75734L18.6688 8.1596C18.3661 8.46223 18.3036 8.92247 18.4677 9.31774C18.6317 9.71287 19.0034 10 19.4313 10L20 10C20.5523 10 21 10.4477 21 11V13C21 13.5523 20.5523 14 20 14H19.4312C19.0034 14 18.6318 14.2871 18.4677 14.6822C18.3036 15.0775 18.3661 15.5377 18.6688 15.8403L19.071 16.2426C19.4616 16.6331 19.4616 17.2663 19.071 17.6568L17.6568 19.071C17.2663 19.4616 16.6331 19.4616 16.2426 19.071L15.8403 18.6688C15.5377 18.3661 15.0775 18.3036 14.6822 18.4677C14.2871 18.6318 14 19.0034 14 19.4312V20C14 20.5523 13.5523 21 13 21H11C10.4477 21 10 20.5523 10 20V19.4313C10 19.0034 9.71287 18.6317 9.31774 18.4677C8.92247 18.3036 8.46223 18.3661 8.1596 18.6688L7.75732 19.071C7.36679 19.4616 6.73363 19.4616 6.34311 19.071L4.92889 17.6568C4.53837 17.2663 4.53837 16.6331 4.92889 16.2426L5.33123 15.8403C5.63384 15.5377 5.69638 15.0775 5.53228 14.6822C5.36825 14.2871 4.99659 14 4.56879 14H4C3.44772 14 3 13.5523 3 13V11C3 10.4477 3.44772 10 4 10L4.56877 10C4.99658 10 5.36825 9.71288 5.53229 9.31776C5.6964 8.9225 5.63386 8.46229 5.33123 8.15966L4.92891 7.75734C4.53838 7.36681 4.53838 6.73365 4.92891 6.34313L6.34312 4.92891C6.73365 4.53839 7.36681 4.53839 7.75734 4.92891L8.15966 5.33123C8.46228 5.63386 8.9225 5.6964 9.31776 5.53229C9.71288 5.36825 10 4.99658 10 4.56876V4C10 3.44772 10.4477 3 11 3Z" stroke="currentColor" strokeWidth="1.5"/>
                <path className="addIcon" d="M14.5 12C14.5 13.5 13.5 14.5 12 14.5C10.5 14.5 9.5 13.5 9.5 12C9.5 10.5 10.5 9.5 12 9.5C13.5 9.5 14.5 10.5 14.5 12Z" stroke="currentColor" strokeWidth="1.5"/>
            </svg>
        </SvgIcon>
    );
}

export function CerrarSesion(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="none">
                <path className="iconCerrarSesion" d="M6.625 1H3.25C2.00736 1 1 2.00736 1 3.25V16.75C1 17.9926 2.00736 19 3.25 19H6.625M14.5 5.5L19 10M19 10L14.5 14.5M19 10L5.5 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function CalendarioPrueba(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M34 16.2222H2M34 16.2222V30.4444C34 32.4081 32.2091 34 30 34H6C3.79086 34 2 32.4081 2 30.4444V9.11111C2 7.14743 3.79086 5.55556 6 5.55556H30C32.2091 5.55556 34 7.14743 34 9.11111V16.2222ZM24 2V9.11111M12 2V9.11111" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function CamarasLeftBar(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 5C5.63636 5 2 12 2 12C2 12 5.63636 19 12 19C18.3636 19 22 12 22 12C22 12 18.3636 5 12 5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function ActuadoresLeftBar(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M1 1V7M1 7H7M1 7C3.32744 4.91141 5.48287 2.54676 8.74532 2.08779C10.6777 1.81593 12.6461 2.17941 14.3539 3.12343C16.0617 4.06746 17.4164 5.54091 18.2139 7.32177M19 19V13M19 13H13M19 13C16.6725 15.0886 14.5171 17.4532 11.2547 17.9122C9.32229 18.1841 7.35391 17.8206 5.6461 16.8766C3.93829 15.9325 2.58356 14.4591 1.78604 12.6782" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}