import { Box, Popover, TextField, Typography } from "@mui/material"
import { useFormik } from "formik";
import { useState } from "react";
import { InitialValues } from "../CabeceraTabla/InitialValues";
import { ValidationSchema } from "../CabeceraTabla/ValidationSchema";
import "./AgregarInvitado.css";
import { BotonTipo1 } from "../../../Botones/BotonesTabla/Botones";
import { SnackBarTipoUno } from "../../../snackBar/SnackBar";
import SelectInvitadosEventoRecurrente from "../SelectInvitadosEventoRecurrente/SelectInvitadosEventoRecurrente";

export const AgregarInvitado = ({ anchorEl, onClose, idVisita, setRows, guardarInvitado, esEventoRecurrente, listaEventosRecurrentes, actualizar }) => {
    const open = Boolean(anchorEl);
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);

    const [eventosNuevoInvitado, setEventosNuevoInvitado] = useState([]);

    const formik = useFormik(
        {
            initialValues: InitialValues,
            validationSchema: ValidationSchema,
            onSubmit: async () => {
                //Si es un evento recurrente, se agrega el invitado a los dias seleccionados
                if (eventosNuevoInvitado !== undefined && eventosNuevoInvitado !== null && eventosNuevoInvitado.length > 0) {
                    for (const evento of eventosNuevoInvitado) {
                        const idVisita = evento.idVisita;
                        await guardarInvitado(formik.values, idVisita)
                            .then(() => {
                                setSnackbarSeverity("success");
                                setSnackMsg("Invitado agregado exitosamente");
                                setSnackbar(true);
                            })
                            .catch((error) => {
                                setSnackbarSeverity("error")
                                setSnackMsg("No pudo ser agregado, reintente!")
                                setSnackbar(true);
                            });
                    }
                    actualizar();
                    setEventosNuevoInvitado([]);
                }
                //Si no, se agrega al evento seleccionado
                else{
                    await guardarInvitado(formik.values, idVisita)
                    .then(
                        (resolve) => {
                            setRows((oldRows) => [...oldRows, 
                                {
                                    idVisitante: resolve.idVisitante,
                                    idVisitaVisitante:resolve.idVisitaVisitante,
                                    nombres:formik.values.nombres,
                                    apellidos:formik.values.apellidos,
                                    rut:formik.values.rut,
                                    pasaporte:formik.values.pasaporte,
                                    correo:formik.values.correo,
                                    patente: formik.values.patente === "" ? "-" : formik.values.patente,
                                    estadoAceptacion: "PENDIENTE",
                                    estadoEnvioCorreo: false,
                                    isNew: false 
                                }
                            ]);
                            setSnackbarSeverity("success")
                            setSnackMsg("Invitado agregado exitosamente")
                            setSnackbar(true);
                            handleOtro();
                            
                        }
                        ,
                        (reject) => {
                            setSnackbarSeverity("error")
                            setSnackMsg("No pudo ser agregado, reintente!")
                            setSnackbar(true);
                        }
                    );

                }
                handleOtro();
            }
    });

    const handleOtro = () => {
        formik.resetForm();
    }

    return (
        <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >   
            <Box id="AgregarInvitadoContainer">
                <Typography id="TituloAgregarInvitado">Nuevo invitado</Typography>
                <Box component={'form'} onSubmit={formik.handleSubmit} onReset={formik.handleReset} id="AgregarInvitadoForm">
                    <Box id="BoxInvitadoNombreApellido">
                        <Box id="BoxTextFieldNuevoUsuarioNombre">
                            <TextField variant="filled" 
                            label="Nombres"
                            value={formik.values.nombres}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} 
                            id="Nombres" name="nombres" 
                            placeholder="Nombres"
                            fullWidth
                            helperText=
                            {
                                formik.errors.nombres ?
                                <span id="ErrorSpan">{formik.errors.nombres}</span>
                                :
                                null
                            } 
                            />
                        </Box>
                        <Box id="BoxTextFieldNuevoUsuarioApellido">
                            <TextField variant="filled" 
                            label={"Apellidos"} 
                            id="Apellidos" name="apellidos" 
                            placeholder="Apellidos" 
                            fullWidth
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} 
                            value={formik.values.apellidos}
                            helperText=
                            {
                                formik.errors.apellidos ?
                                <span id="ErrorSpan">{formik.errors.apellidos}</span>
                                :
                                null
                            }
                            />
                        </Box>
                    </Box>
                    <Box id="BoxTextFieldNuevoUsuarioRut">
                        <TextField variant="filled" 
                        label={"Rut"} 
                        id="Rut" name="rut" 
                        placeholder="11111111-1" 
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                        value={formik.values.rut}
                        helperText=
                        {
                            formik.errors.rut ?
                            <span id="ErrorSpan">{formik.errors.rut}</span>
                            :
                            null
                        }
                        />
                    </Box>
                    <Box id="BoxTextFieldNuevoUsuarioRut">
                        <TextField variant="filled" 
                        label={"Pasaporte"} 
                        id="Pasaporte" name="pasaporte" 
                        placeholder="000000000000" 
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                        value={formik.values.pasaporte}
                        helperText=
                        {
                            formik.errors.pasaporte ?
                            <span id="ErrorSpan">{formik.errors.pasaporte}</span>
                            :
                            null
                        }
                        />
                    </Box>
                    <Box id="BoxTextFieldNuevoUsuarioCorreo">
                        <TextField variant="filled" 
                        label={"Correo"} 
                        id="Correo" name="correo" 
                        placeholder="dominio@dominio.cl" 
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                        value={formik.values.correo}
                        helperText=
                        {
                            formik.errors.correo ?
                            <span id="ErrorSpan">{formik.errors.correo}</span>
                            :
                            null
                        }
                        />
                    </Box>
                    <Box id="BoxTextFieldNuevoUsuarioPatente">
                        <TextField variant="filled" 
                        label={"Patente"} 
                        id="Patente" name="patente" 
                        placeholder="DLAU00" 
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                        value={formik.values.patente}
                        helperText=
                        {
                            formik.errors.patente ?
                            <span id="ErrorSpan">{formik.errors.patente}</span>
                            :
                            null
                        }
                        />
                    </Box>
                    {esEventoRecurrente && <SelectInvitadosEventoRecurrente
                        eventosNuevoInvitado={eventosNuevoInvitado}
                        setEventosNuevoInvitado={(entrada)=>setEventosNuevoInvitado(entrada)}
                        esEventoRecurrente={esEventoRecurrente}
                        listaEventosRecurrentes={listaEventosRecurrentes}
                    />}
                </Box>
                <Box id="BoxBotonAgregarInvitado">
                    <BotonTipo1 textoBoton={"Agregar"} event={formik.submitForm} />
                </Box> 
            </Box>
            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />
        </Popover>
    )
}