import { useState, useRef } from "react";
import { Box, Button, Popover, Typography } from "@mui/material";
import "./UpdateProfilePhoto.css";
import { IconoAlerta } from "../../../../Usuarios/UsuariosIcon";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import usePerfil from "../../../../../Hooks/OpcionesPerfil/usePerfil";
import { TextFieldSeleccionarFotoPerfil } from "../../../../TextField/TextFieldUno";
import { LoadingMasRespuestaCinco } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../../RespuestaServidor/RespuestaServidorIcon";
import { SnackBarTipoUno } from "../../../../snackBar/SnackBar";

const UpdateProfilePhoto = ({ anchorEl, onClose, idPersona }) => {

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const open = Boolean(anchorEl);
  const { ActualizarFotoPerfil, loading, setLoading, respuestaFotoPerfil } = usePerfil();
  const [openLoading, setOpenLoading] = useState(false);

  //Variables para manejar el snackbar
  const [snackbar, setSnackbar] = useState(false);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState();

  // Estados y variables para el manejo del dialog
  const [dialogText, setDialogText] = useState({titulo: '', mensaje: ''});
  const [ConfirmationDialog, confirm ]= useConfirm();
  const handleUpdateProfilePhoto = async () => {

    if(selectedFile) {
      setDialogText(
        {
            titulo: "¿Actualizar Imagen?",
            mensaje: "Estás a punto de actualizar tu imagen de perfil ¿Deseas continuar?"
        }
      );

      const responseConfirm = await confirm(); 
      if(responseConfirm === true) {
          setLoading(true);
          setOpenLoading(true);
          ActualizarFotoPerfil(idPersona, selectedFile); 

          // Limpiar selección después de la subida
          setSelectedFile(null);
      } 
    } 
    
    else {
      //Se muestra un mensaje con el snackbar indicando que debe subir un archivo primero
      setSnackbarSeverity("error")
      setSnackMsg("Error: Debe seleccionar un archivo primero.");
      setSnackbar(true);
    }
  }

  // Manejar la selección del archivo
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      slotProps={{ paper: { sx: { borderRadius: '10px' } } }}
    >
      <Box id="BoxContainer">
        <Typography variant="h5">Actualizar foto de perfil</Typography>
        <Box id="BoxSubirArchivo">
          {/* Mostrar el nombre del archivo o mensaje por defecto */}
          <Box id="TextoArchivoSeleccionado">
            <TextFieldSeleccionarFotoPerfil disabled={true} value={selectedFile ? selectedFile.name : "Ningún archivo seleccionado"}></TextFieldSeleccionarFotoPerfil>
          </Box>

          {/* Input oculto para seleccionar el archivo */}
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={handleFileSelect}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          
          <Button
            id="BotonSeleccionarArchivo"
            onClick={() => fileInputRef.current.click()}
          >
            Seleccionar
          </Button>
        </Box>

        <Button id="BotonSubirArchivo" onClick={handleUpdateProfilePhoto}>
          Actualizar
        </Button>

        <LoadingMasRespuestaCinco
            open={openLoading} 
            setOpen={setOpenLoading} 
            msj={"La imagen de perfil está siendo actualizada. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
            id={"RespuestaGuardarEditarUsuario"} 
            tituloLoading={"Actualizando imagen..."}
            loading={loading} 
            icono={
                respuestaFotoPerfil === 200 ?
                <IconoExito id="IconoRespuestaServidor" />
                :
                <IconoFallo id="IconoRespuestaServidor" />
            }
            textoBoton={
                respuestaFotoPerfil === 200 ? 
                "Aceptar"
                :
                "Reintentar"
            }
            titulo={respuestaFotoPerfil === 200 ? "Actualización exitosa":"Error al actualizar"}
            mensajeRespuesta={respuestaFotoPerfil === 200 ? 
                "La imagen de perfil se ha actualizado correctamente."
                :
                "Ha ocurrido un error y no se ha podido actualizar la imagen de perfil. Por favor, vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
          />

        <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
        />

        <ConfirmationDialog
        icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
        titulo={dialogText.titulo}
        mensaje={dialogText.mensaje}
        />

      </Box>
    </Popover>
  );
};

export default UpdateProfilePhoto;
