import { Autocomplete, Box, Button, FormControl, FormHelperText, IconButton, TextField, Typography } from "@mui/material";
import "./EditarUsuario.css";
import { useFormik } from "formik";
import { CerrarModalNuevoUsuario, ClearIconTextField } from "../NuevoUsuario/NuevoUsuarioIcons";
import { EsquemaEdicion } from "./EsquemaEdicion";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { useState } from "react";
import ImgCambioCorreo from "../../../assets/imagenes/CambioCorreo.png"
import { useNavigate } from "react-router-dom";
import { IconoAlerta } from "../UsuariosIcon";
import { IconoEditarCorreo } from "../../Modal/Perfil/DataUsuario/DataUsuarioIcon";
import useUsuarios from "../../../Hooks/Usuarios/useUsuarios";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import useConfirmDos from "../../../Hooks/ConfirmDialog/useConfirmDialogDos";
import { useSelector } from "react-redux";
import { TextFieldUno, TextFieldUnoEditarCorreo } from "../../TextField/TextFieldUno";
import { SnackBarTipoUno } from "../../snackBar/SnackBar";


const style = {
    width: "1144px",
    height: "604px",
    display: "inline-flex",
    position: 'absolute',
    flexDirection: "column",
    aligItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};



export const EditarUsuario = ({handlerOpen, userData, rows, setRows}) => {
    const data = useSelector(action => action.user.authenticated)
    const tiposUsuarios = useSelector(action => action.tiposUsuarios.listadoTiposUsuarios);
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const {EditarExistente, loadingRequest, setLoadingRequest, requestResponse } = useUsuarios();
    const navigate = useNavigate();
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [ConfirmationDialogDos, confirmDos]= useConfirmDos();
    const [snackMsg, setSnackMsg] = useState({severity: "", message: ""});
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    
    const formik = useFormik(
        {
            initialValues: {
                nombres:  userData.nombres,
                apellidos:  userData.apellidos,
                rut:  userData.rut,
                pasaporte:  userData.pasaporte,
                idTipoPersona: userData.idTipoPersona,
                tipoPersona: userData.tipo,
                correo:  userData.correo,
                telefono:  userData.telefono,                
            },
            enableReinitialize:true,
            validationSchema: EsquemaEdicion,
            onSubmit: async () => {
                if((formik.values.rut === null || formik.values.rut === "") 
                    && 
                (formik.values.pasaporte === null || formik.values.pasaporte === ""))
                {
                    setSnackMsg({severity: "error", message:"Debe completar el campo de identificacion (Rut o Pasaporte)"});
                    setSnackbar(true);
                }
                else{
                    let response = await confirm();
                    if(response){
                        setLoadingRequest(true);
                        setOpenLoading(true);
                        EditarExistente(formik.values, userData.idPersona)
                        .then(
                            (result) => {
                                let tipoUsuario = tiposUsuarios.find((t)=>t.idTipoPersona === formik.values.idTipoPersona);
                                userData.nombres = formik.values.nombres;
                                userData.apellidos = formik.values.apellidos;
                                userData.rut = formik.values.rut;
                                userData.pasaporte = formik.values.pasaporte;
                                userData.idTipoPersona = formik.values.idTipoPersona;
                                userData.correo = formik.values.correo;
                                userData.telefono = formik.values.telefono;
                                userData.tipo = tipoUsuario.tipo;
                                
                                setRows(rows.map((row) => (row.idPersona === userData.idPersona ? userData : row)))

                            },
                            (error) => {
                                setRows(rows.filter((row) => row.idPersona !== userData.idPersona));
                            }
                        )
                    }
                    else{
                        console.log(("ha rechazado el cambio"))
                    }
                }
            }
        }
    );

    const handlerClose = () => {
        handlerOpen(false);
        formik.resetForm();
    };
    
    const EditCorreo = async () => {
        let response = await confirmDos();
        if(response === true) {
            navigate("/cambiarEmail", { replace: true, state: { correoActual: userData.correo}})
        }
        else{
            console.log("Rechaza el cambio");
        }
    }
    return (
            <Box id="BoxNuevoUsuario" sx={{...style}}>
                <Box id="CabeceraEditarUsuario">
                    <IconButton onClick={handlerClose}>
                        <CerrarModalNuevoUsuario />
                    </IconButton>
                </Box>
                <Box id="ContenidoModalEditarUsuario">
                    <Box id="BoxTitutloModalEditarUsuario">
                        <Typography id="TituloEditarUsuario">
                            {
                                "Editar Usuario"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxSubtituloModalEditarUsuario">
                        <Typography id="SubtituloEditarUsuario">
                            {
                                "Todos los campos son obligatorios."
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxFormEditarUsuario" component={"form"} onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <Box id="BoxInformacionPersonalEditar">
                            <Box id="TituloInformacionPersonalEditar">
                                <Typography id="TxtTituloInformacionPersonalEditar">
                                    {
                                       "Información personal"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxFormularioInformacionPersonalEditar">
                                <Box id="BoxNombreApellidoEditar">
                                    <TextFieldUno
                                    id={"NombresNuevoUsuario"}
                                    label={"Nombres"}
                                    placeholder={"Nombres"}
                                    icon={<ClearIconTextField id="IconoLimpiarEnEditarUsuario" />} 
                                    helper={formik.errors.nombres}
                                    value={formik.values.nombres}
                                    name={"nombres"}
                                    handlerChange={formik.handleChange}
                                    formHandle={() => {formik.setFieldValue("nombres", "")}}
                                    disabled={false}
                                    />
                                    <TextFieldUno 
                                    id={"ApelllidosNuevoUsuario"}
                                    label={"Apellidos"}
                                    placeholder={"Apellidos"}
                                    icon={<ClearIconTextField id="IconoLimpiarEnEditarUsuario" />} 
                                    helper={formik.errors.apellidos}
                                    value={formik.values.apellidos}
                                    name={"apellidos"}
                                    handlerChange={formik.handleChange}
                                    formHandle={() => {formik.setFieldValue("apellidos", "")}}
                                    disabled={false}
                                    />
                                </Box>
                                <Box id="BoxRutTipoEditar">
                                    <Box id="BoxIdentificadoresEditarUsuario">
                                        <Box id="BoxIdentificadorRutEditarUsaurio">
                                            <TextFieldUno 
                                            id={"RutNuevoUsuario"}
                                            label={"Rut"}
                                            placeholder={"Rut"}
                                            icon={<ClearIconTextField id="IconoLimpiarEnEditarUsuario" />} 
                                            helper={formik.errors.rut}
                                            value={formik.values.rut}
                                            name={"rut"}
                                            handlerChange={formik.handleChange}
                                            formHandle={() => {formik.setFieldValue("rut", "")}}
                                            disabled={false}
                                            />
                                        </Box>
                                        <Box id="BoxIdentificadorPasaporteEditarUsaurio">
                                            <TextFieldUno
                                            id={"PasaporteEditarUsuario"}
                                            label={"Pasaporte"}
                                            placeholder={"Pasaporte"}
                                            icon={<ClearIconTextField id="IconoLimpiarEnEditarUsuario" />} 
                                            helper={formik.errors.pasaporte}
                                            value={formik.values.pasaporte || ""}
                                            name={"pasaporte"}
                                            handlerChange={formik.handleChange}
                                            formHandle={() => {formik.setFieldValue("pasaporte", "")}}
                                            disabled={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box id="BoxSelectTipoPersona">
                                        <FormControl fullWidth>
                                            <Autocomplete                                   
                                            disablePortal
                                            options={tiposUsuarios}
                                            fullWidth  
                                            sx={{ "& .MuiFilledInput-input":{ height: "20px", borderBottom: "0px"}, 
                                                    "& .MuiOutlinedInput-input":{ height: "20px", borderBottom: "0px"}}}
                                            id="idTipoPersona"
                                            componentname="idTipoPersona"
                                            freeSolo={true}                                        
                                            getOptionKey={(o) => o.idTipoPersona}
                                            isOptionEqualToValue={(o, v) => o.tipo === v}
                                            onChange={(e, v) => {
                                                if(v === null)
                                                {
                                                    formik.setFieldValue("idTipoPersona", -1) 
                                                    formik.setFieldValue("tipoPersona", "Seleccione")                                           
                                                                                            
                                                }
                                                else
                                                {
                                                    formik.setFieldValue("idTipoPersona", v.idTipoPersona)
                                                    formik.setFieldValue("tipoPersona", v.tipo)
                                                }
                                            }}
                                            getOptionLabel={(option) =>  option.tipo ?? option}
                                            inputValue={formik.values.tipoPersona}
                                            value={formik.values.tipoPersona}
                                            onInputChange={(e,v) => formik.setFieldValue("tipoPersona", v)}   
                                            renderInput={(params) => 
                                            <TextField
                                            InputLabelProps={{shrink: true}}
                                            {...params}
                                            sx={{ height: "100%"}}
                                            label="Tipo de Usuario"
                                            />
                                            }
                                            renderOption={(props, option) => {
                                                const { key, ...optionProps } = props;
                                                return (
                                                    <Box
                                                    key={key}
                                                    component="li"
                                                    {...optionProps}>
                                                    {option.tipo}
                                                    </Box>
                                                );
                                            }}
                                            />
                                            <FormHelperText>
                                            {
                                                formik.errors.idTipoPersona
                                            }
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box id="BoxInformacionContactoEditar">
                            <Box id="TituloInformacionContactoEditar">
                                <Typography id="TxtTituloInformacionContactoEditar">
                                    {
                                        "Información de contacto"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxFormularioInformacionContactoEditar">
                                <Box id="BoxCorreoEditarBoton">
                                    <TextFieldUnoEditarCorreo
                                    id={"CorreoNuevoUsuario"}
                                    label={"Correo"}
                                    placeholder={"Correo"}
                                    icon={null} 
                                    helper={formik.errors.correo}
                                    value={formik.values.correo}
                                    name={"correo"}
                                    handlerChange={formik.handleChange}
                                    formHandle={null} 
                                    disabled={true}
                                    />
                                    <Box id="BoxBotonEditarcorreoEditarUsuario">
                                        <Button id="BotonEditarCorreoEditarUsuario"
                                        onClick={EditCorreo}
                                        startIcon={
                                        <IconoEditarCorreo  id="IconoEditarCorreoEditarUsuario"/>}
                                        >
                                            Editar Correo
                                        </Button>
                                    </Box>
                                </Box>
                                <Box id="BoxTelefonoNuevo">
                                    <TextFieldUno 
                                    id={"TelefonoNuevoUsuario"}
                                    label={"Telefono"}
                                    placeholder={"Telefono"}
                                    icon={<ClearIconTextField id="IconoLimpiarEnEditarUsuario" />} 
                                    helper={formik.errors.telefono}
                                    value={formik.values.telefono}
                                    name={"telefono"}
                                    handlerChange={formik.handleChange}
                                    formHandle={() => {formik.setFieldValue("telefono", "")}} 
                                    disabled={false}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BotoncesAccionModalNuevoUsuarioEditar">
                        <Box id="BoxBotonesEditar">
                            <Box id="BoxBotonNuevoUsuario">
                                <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                            </Box>
                            <Box id="BoxBotonNuevoUsuario">
                                <BotonTipo2 textoBoton={"Actualizar"} event={formik.submitForm} disabled={loadingRequest}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <ConfirmationDialogDos
                img={ImgCambioCorreo}
                titulo={"Confirmar cambio"}
                mensaje={"estas seguro(a) que quieres cambiar este correo electrónico"}
                usuario={data.usuario.split(' ')[0]}
                />
                <LoadingMasRespuestaCinco
                open={openLoading} 
                setOpen={setOpenLoading} 
                msj={"La información del usuario está siendo actualizada. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
                id={"RespuestaGuardarEditarUsuario"} 
                tituloLoading={"Actualizando datos..."}
                loading={loadingRequest} 
                respuesta={requestResponse} 
                icono={
                    requestResponse === 200 ?
                    <IconoExito id="IconoRespuestaServidor" />
                    :
                    <IconoFallo id="IconoRespuestaServidor" />
                }
                textoBoton={
                    requestResponse === 200 ? 
                    "Aceptar"
                    :
                   "Reintentar"
                }
                titulo={requestResponse === 200 ? "Actualización exitosa":"Error al actualizar"}
                mensajeRespuesta={requestResponse === 200 ? 
                    "La información del usuario se ha actualizado correctamente. Ahora puedes gestionar sus permisos o añadir un nuevo usuario"
                    :
                    "Ha ocurrido un error y no se ha podido actualizar la información del usuario. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
                />
                <ConfirmationDialog
                icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
                titulo={"¿Actualizar datos?"}
                mensaje={"Estás a punto de actualizar la información del usuario. ¿Deseas continuar?"}
                />
                <SnackBarTipoUno
                open={snackbar}
                handler={handleCloseSnackbar}
                duration={6000}
                severity={snackMsg.severity}
                msg={snackMsg.message}
                />
        </Box>
    );
}