import axios from "axios";
import { useEffect, useState } from "react";
import { BuscarListaNegraEmpresa, CrearListaNegra, EliminarDeListaNegra } from "../../Service/API/Api";

export default function useListaNegra(){
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState();
    const [listaNegra, setListaNegra] = useState([]);

    useEffect(() => {
        axios.get(BuscarListaNegraEmpresa)
            .then(
                (response) => {
                    setListaNegra(response.data);
                    setLoading(false);

                }
            )
            .catch(
                (error) => {
                    console.log("fallo al cargar lista negra");
                    setLoading(false);

                }
            )
	}, [])

    const AgregarLN = (rutAListaNegra, pasaporteAListaNegra, creador, motivo, empresa) =>{
        return new Promise((resolve, reject) => {
            const agregarListaNegra = {
                "rutPersona": rutAListaNegra,
                "pasaporte": pasaporteAListaNegra,
                "idPersona": creador,
                "motivoIngreso": motivo,
                "idEmpresa": empresa
            }
            axios.post(CrearListaNegra, agregarListaNegra)
            .then(
                (response) => {
                    resolve(response.data);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            )
        })   
    }

    const EliminarEnLN = (idListaNegra) =>{
        return new Promise((resolve, reject) => {
            
            axios.delete(EliminarDeListaNegra + idListaNegra)
            .then(
                (response) => {
                    resolve(response.data);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {

                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                        reject(error.response);
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                        reject("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                        reject("Error desconocido. Contáctanos para más información.");
                    }
        
                    setLoading(false);
                }
            )
        })   
    }

    return{
        loading,
        setLoading,
        response,
        AgregarLN,
        EliminarEnLN,
        listaNegra
    }
}