import axios from "axios";
import { useEffect, useState } from "react";
import { HuellasUsuario } from "../../Service/API/Api";

export default function useHuellasUsuario(idPersona)
{
    const [huellas, setHuellas] = useState([]);

    useEffect(() => {
        axios.get(HuellasUsuario + idPersona)
        .then(
            (response) => {
                setHuellas(response.data);
            }
        )
        .catch(
            (error) => {
                console.error("Error al obtener información de huellas de usuario");
            }
        )
    }, [idPersona])

    return {
        huellas
    }
    
}