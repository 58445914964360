import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import "./SelectFechaEvento.css";
import { useState } from 'react';
import dayjs from 'dayjs';
import { ListItemText } from '@mui/material';

export default function SelectFechaEvento({listaObjetos, label, atributoMostrable, keyItem, setFechaSeleccionada}) {
  const [valor, setValor] = useState('');

  const nombreDias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

  const handleChange = (event) => {
    setValor(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          id="select-eventos-recurrentes"
          value={valor}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{height: '65px'}}
        >

            <MenuItem onClick={()=>setFechaSeleccionada(null)} value="">
                <em style={{ minHeight: '46px', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontStyle: 'italic', margin: 'auto' }}>{label}</em>
            </MenuItem>
            {listaObjetos.map(objeto=>(
                <MenuItem 
                onClick={()=>setFechaSeleccionada(objeto)} 
                value={objeto[atributoMostrable]} 
                key={objeto[keyItem]}
                >
                  <ListItemText 
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',}}
                    primary={nombreDias[dayjs(objeto[atributoMostrable]).day()]} 
                    secondary={`${dayjs(objeto[atributoMostrable]).format('DD/MM/YYYY HH:mm')} hrs.`}
                  />
                </MenuItem>
                
            ))}
        </Select>
      </FormControl>
    </div>
  );
}