import { Box, Card, CardContent, CardMedia, CircularProgress, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.css";
import CP2 from "../../assets/imagenes/CP2.png"
import { MostrarIcon, OcultarIcon } from "../Login/LoginIcons";
import {InitialValues} from "./InitialValues";
import {ValidationSchema} from "./ValidationSchema";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { LoadingMasRespuestaCinco } from "../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../Modal/RespuestaServidor/RespuestaServidorIcon";
import useRecuperarContrasena from "../../Hooks/Recuperar/useCambiarContrasena";
import { SnackBarTipoUno } from "../snackBar/SnackBar";

const RecuperarContrasena = () => {
    const {response, loading, Recuperar, setLoading} = useRecuperarContrasena();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [correo, setCorreo] = useState(null);
    const [token, setToken] = useState(null);
    const [show, setShowPassword] = useState();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [show2, setShowPassword2] = useState();
    const handleClickPass = () => setShowPassword((show) => !show)
    const handleClickConf = () => setShowPassword2((show2) => !show2)
    const [snackMsg, setSnackMsg] = useState({severity: "", message: ""});
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    

    const formik = useFormik(
        {
        initialValues: InitialValues,
        validationSchema: ValidationSchema,
        onSubmit: async () => 
            {
                if(token !== null && correo !== null){
                    setOpenLoading(true);
                    setLoading(true);
                    await Recuperar(token, correo, formik.values.confirmacion)
                    .then(
                        (resolve) => {
                            setTimeout(() => {
                                navigate("/login", {replace: true});                    
                            }, 5000);
                        }
                        ,
                        (reject) => {
                            setSnackMsg({severity: "error", message:"Ocurrio un error en la creacion, reintenta o contacta a un administrador"});
                            setSnackbar(true);
                        }
                    )
                }
                else{
                    setSnackMsg({severity: "error", message:"Error de validacion"});
                    setSnackbar(true);
                }                
            }
        }
    );
   

    useEffect(() => {

        if(searchParams.size > 0){
            
            setCorreo(searchParams.get('correo'));
            var decode = decodeURI(searchParams.get('token'));
            var tEncode = encodeURI(decode);
            setToken(tEncode);
            
            searchParams.delete('token');
            searchParams.delete('correo');

            setSearchParams(searchParams);
        }
    },[correo, token, searchParams, setSearchParams]);
    


    return (
        <>
            <Box id="RecuperarLayout">
                <Card id="CardRecuperar">
                    <CardContent id="CardContenidoRecuperar">
                        <Box id="BoxImagen">
                            <CardMedia id="imagen" image={CP2}></CardMedia>
                        </Box>
                        <Box id="BoxTitulo">
                            <Typography id="TextoTitulo">
                                {
                                    "Restablecer contraseña"
                                }
                            </Typography>
                            <Typography id="ComentarioRecuperacion">
                                {
                                    "Por favor ingrese su nueva contraseña y después confirmela."
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxConstrasenas" component="form" onSubmit={formik.handleSubmit}>
                            <Box id="BoxContrasena">
                                <TextField variant="filled"
                                inputProps={{
                                    style: {
                                        color: "#333",
                                        fontFamily: "inherit",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        letterSpacing: "0.5px",
                                        }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: "#626262",
                                        fontFamily: "inherit",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        fontStyle: "normal",
                                        lineHeight: "24px",
                                        letterSpacing: "0.5px",
                                        }
                                }}
                                size="small"
                                required fullWidth id='password'
                                label='Contraseña' name='password'
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete='current-password'
                                type={show ? 'text' : 'password'}
                                inputMode="password"
                                InputProps={{ 
                                    style: { backgroundColor: "#f8f8f8"},
                                    endAdornment: 
                                        <InputAdornment position='end'>
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={handleClickPass}>
                                                {show ? <OcultarIcon /> : <MostrarIcon  />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                helperText= 
                                    {
                                        formik.errors.password ?
                                        <span id="errorSpan">{formik.errors.password}</span>
                                        :
                                        null
                                    }
                                >                            
                                </TextField>
                            </Box>
                            <Box id="BoxConfirmacion">
                                <TextField variant="filled"
                                inputProps={{
                                    style: {
                                        color: "#333",
                                        fontFamily: "inherit",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        letterSpacing: "0.5px",
                                        }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: "#626262",
                                        fontFamily: "inherit",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        fontStyle: "normal",
                                        lineHeight: "24px",
                                        letterSpacing: "0.5px",
                                        }
                                }}
                                size="small"
                                required fullWidth id='confirmacion'
                                label='Confirmar Contraseña' name='confirmacion'
                                value={formik.values.confirmacion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete='current-password'
                                type={show2 ? 'text' : 'password'}
                                inputMode="password"
                                InputProps={{ 
                                    style: { backgroundColor: "#f8f8f8"},
                                    endAdornment: 
                                        <InputAdornment position='end'>
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={handleClickConf}>
                                                {show2 ? <OcultarIcon /> : <MostrarIcon  />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                helperText= 
                                    {
                                        formik.errors.password ?
                                        <span id="errorSpan">{formik.errors.confirmacion}</span>
                                        :
                                        null
                                    }
                                >                            
                                </TextField>
                            </Box>
                            <Box id="BoxBoton">
                                <LoadingButton type="submit" sx={{height:"48px"}}
                                fullWidth id="ButtonCambiar"
                                loading={loading}  
                                loadingIndicator={<CircularProgress style={{color:"white"}} size={25} />} >
                                    {
                                    loading ? 
                                    null
                                    :
                                    <Typography id="TextoBoton">
                                        {"Restablecer Contraseña"}
                                    </Typography>
                                    }
                                </LoadingButton>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <LoadingMasRespuestaCinco
            open={openLoading} 
            setOpen={setOpenLoading} 
            msj={"La información del usuario está siendo actualizada. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
            id={"RespuestaGuardarEditarUsuario"} 
            tituloLoading={"Actualizando datos..."}
            loading={loading} 
            icono={
                response === 200 ?
                <IconoExito id="IconoRespuestaServidor" />
                :
                <IconoFallo id="IconoRespuestaServidor" />
            }
            textoBoton={
                response === 200 ? 
                "Aceptar"
                :
                "Reintentar"
            }
            titulo={response === 200 ? "¡Cambio con éxito!":"¡Error al Cambiar!"}
            mensajeRespuesta={response === 200 ? 
                "Su contraseña ha sido cambiada! ahora Puede iniciar sesión con su nueva contraseña"
                :
                "Ha ocurrido un error y no se ha podido actualizar la contraseña, reintente por favor o contacte al administrador del sistema"}
            />
            <SnackBarTipoUno
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackMsg.severity}
            msg={snackMsg.message}
            />
        </>
    );
}

export default RecuperarContrasena;