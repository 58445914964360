import { Box, CardMedia, Typography } from "@mui/material";
import "./InvitacionRechazadaPage.css";

import CP2IMG from "./../../../assets/imagenes/CP2.png";

export const InvitacionRechazadaPage = () => {
  return(
    <Box id="ContainerInvitacionRechazada">
        <Box id="BoxCabeceraInvitacionRechazada">
            <Box id="BoxContenedorIMGCP2InvitacionRechazada">
                <CardMedia 
                    id="CMImagenCP2" 
                    src={CP2IMG} 
                    component="img"
                    sx={{
                        objectFit: 'contain', // Esta propiedad hace que la imagen se ajuste al contenedor sin distorsionarse. La imagen será redimensionada para que encaje completamente dentro del contenedor, manteniendo sus proporciones originales.
                    }}
                />
            </Box>
            <Box id="BoxTituloInvitacionRechazada">
                <Typography id="TxtTituloInvitacionRechazada">
                    {
                        "¡Invitación Rechazada!"
                    }
                </Typography>
            </Box>
        </Box>
        <Box id="BoxTituloMensajeInvitacionRechazada">
            <Typography id="TxtMensajeInvitacionRechazada">
                {
                    "Su invitación al evento ha sido rechazada con éxito. Puede cerrar esta página."
                }
            </Typography>
        </Box>
    </Box>
  );
}
