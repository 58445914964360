import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";

/* Componente Select de MaterialUI con opción multiple perzonalizado para ser re utilizado en otras partes. */
/* Props Entrada: 

- label: Este será el label que tendrá el Select a la hora de cargarse la primera vez.

- listadoElementos: arreglo de objetos que será lo que se va a recorrer para mostrar los items del select.

- keyListadoElementos: atributo del arreglo de objetos que funcionará como key o id para recorrerlo y mostrar las opciones.

- atributoMostrable: atributo del objeto que se mostrará en el Select.

- atributoValue: atributo del objeto que se usará como valor del Select una vez se selecciona una opción. Puede ser igual al atributo mostrable como no, eso depende del objetivo de la persona que utilice el componente.

- selectedValues: arreglo de valores seleccionados en el Select. Este es el estado inicial del componente. La idea es que este sea un estado creado por el componente Padre que invoca a este elemento Select. De esta manera se gestiona por fuera los elementos seleccionados.

- setSelectedValues: función que se llamará cuando se selecciona o deselecciones un elemento en el Select. Este es el estado que se actualizará por fuera del componente.

*/

const SelectMultipleCheckmarks = ({
  width = 320,
  label,
  listadoElementos,
  keyListadoElementos,
  atributoMostrable,
  atributoValue,
  selectedValues,
  setSelectedValues
}) => {

  // Función que maneja la selección y desmarcado de los elementos
  const handleSelectChange = (event) => {
    const value = event.target.value; // Los valores seleccionados desde el Select

    // Actualizamos el estado con los nuevos valores seleccionados
    setSelectedValues(value);
  };

  // Función que maneja la lógica de agregar o quitar valores al estado `selectedValues`
  // const handleCheckboxChange = (value) => {
  //   setSelectedValues((prevSelectedValues) => {
  //     if (prevSelectedValues.includes(value)) {
  //       // Si el valor ya está en el arreglo, lo eliminamos (checkbox desmarcado)
  //       return prevSelectedValues.filter((item) => item !== value);
  //     } else {
  //       // Si el valor no está en el arreglo, lo agregamos (checkbox marcado)
  //       return [...prevSelectedValues, value];
  //     }
  //   });
  // };

  return (
    <Box sx={{ width: width }}>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
            multiple
            value={selectedValues} // El valor de los elementos seleccionados
            label={label}
            onChange={handleSelectChange} // Llama a la función `handleSelectChange` al cambiar
            renderValue={(selected) => 
                { 
                    //Se obtienen aquellos elementos del listadoElementos que se encuentran seleccionados
                    const filteredList = listadoElementos.filter(item =>
                        selected.includes(item[atributoValue])
                    );

                    //Se muestran los elementos seleccionados de acuerdo a la prop atributoMostrable
                    return filteredList.map(item => item[atributoMostrable]).join(', ');
                }
            }
        >
          {listadoElementos.map((item) => (
            <MenuItem key={item[keyListadoElementos]} value={item[atributoValue]}>
              <Checkbox 
                checked={selectedValues.includes(item[atributoValue])} // Verifica si el ítem está seleccionado
                // onChange={() => handleCheckboxChange(item[atributoValue])} // Maneja el cambio de selección/desmarcado
              />
              <ListItemText primary={item[atributoMostrable]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectMultipleCheckmarks;
