import axios from "axios";
import { useEffect, useState } from "react"
import { TarjetasDisponibles } from "../../Service/API/Api";

export function useTarjetas(){
    const [tarjetas, setTarjetas] = useState([]);


    useEffect(() => {
        axios.get(TarjetasDisponibles)
        .then(
            (response) => {
                var lista = []
                var item0 = {
                    idTarjeta: -1,
                    codigo: "Tarjetas",
                    idTipoTarjeta: null
                }
                lista.push(item0)
                response.data.forEach(element => {
                    var item = {
                        idTarjeta: element.idTarjeta,
                        codigo: element.codigo,
                        idTipoTarjeta: element.idTipoTarjeta
                    }
                    lista.push(item)
                });
                setTarjetas(lista);
            }
        )
        .catch(
            (error) => {
                console.error("Error al obtener la información de las tarjetas");
            }
        )
    }, [])
    

    return{
        tarjetas
    }
}