import { useState, useEffect } from "react";
import axios from "axios";
import { TiposTareas } from "../../Service/API/Api";

export default function useTipoTarea(cargarDatos = true) {
    const [tipoTareas, setTipoTareas] = useState([]);

    useEffect(() => {
        if (cargarDatos) {
            axios.get(TiposTareas) // Cambia esta URL por la de tu API
                .then((response) => {
                    // Guarda directamente la respuesta en el estado sin modificaciones
                    setTipoTareas(response.data);
                })
                .catch((error) => {
                    console.log("Error al cargar los tipos de tarea:");
                });
        }
    }, [cargarDatos]);

    return {
        tipoTareas
    };
}
