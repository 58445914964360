import { Box, CardMedia, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { LayoutCrearNuevo } from "../../../../Box/CrearNuevoMenuGestionUsuario/LayoutCrearNuevo";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import FacialIMG from "../../../../../assets/imagenes/Facial.jpg"
import { useFormik } from "formik";
import { ValidationNuevoFacial } from "./Validation";
import useFacial from "../../../../../Hooks/Facial/useFacial";
import { useState } from "react";
import { LoadingMasRespuestaCinco } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../../RespuestaServidor/RespuestaServidorIcon";

export const NuevoFacial = ({setOpen, setRows, actuadores, user}) => {
    const { loading, setLoading, response, CrearNuevoFacial } = useFacial();
    const [openLoading, setOpenLoading] = useState(Boolean(false));

    const handleClose = () =>{
        setOpen(false);
    }

    const AgregarRow = (values) =>{
        setRows((oldRows) => [...oldRows, 
            {
                idFacial: values.idFacial, 
                codigo: values.codigo,
            }
        ]);
        setTimeout(() => {
            handleClose();
            setOpenLoading(false);
        }, 3000);
    }

    const formik = useFormik({
        initialValues:{lector: ''},
        validationSchema: ValidationNuevoFacial,
        onSubmit:
        async () => {
            setLoading(true);
            setOpenLoading(true);
            await CrearNuevoFacial(formik.values.lector, user)
            .then(
                (resolve) => {
                    AgregarRow(resolve)
                },
                (reject) => {
                    console.log(reject);
                }
            )
        }
    })

    return(
        <LayoutCrearNuevo titulo={"Agregar registro facial"} >
            <Box id="BoxLayoutAgregaFacialGU">
                <Box id="BoxImagenSelectRegistroFacialGU">
                    <Box id="BoxImagenRegistroFacialGU">
                        <CardMedia id="ImgRegistroFacialGU" component={"img"} src={FacialIMG} />
                    </Box>
                    <Box id="BoxSelectBotonRegistroFacialGU">
                        <Box id="BoxSelectRegistroFacialGU" component={"form"} onSubmit={formik.handleSubmit}>
                            <FormControl fullWidth>
                                <InputLabel id="LblLectorasFaciales">
                                    {
                                        "Lectoras Faciales"
                                    }
                                </InputLabel>
                                <Select 
                                fullWidth 
                                variant="outlined"
                                name="lector"
                                labelId="LblLectorasFaciales"
                                label="Lectoras Faciales"
                                value={formik.values.lector}
                                onChange={formik.handleChange}
                                MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        maxHeight: '280px',
                                        overflowY: 'auto',
                                        width: '220px',
                          
                                        // Estilos para WebKit (Chrome, Safari, Edge) en el Paper (menú desplegable)
                                        '&::-webkit-scrollbar': {
                                          width: '8.024px !important',
                                          background: 'transparent !important',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                          background: 'transparent !important',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                          background: '#C9C9C9',
                                          borderRadius: '100px',
                                        },
                                      },
                                    },
                                }}
                                >
                                {
                                    actuadores.map((item, index) => {
                                    return (
                                        <MenuItem disableRipple key={index} value={item.idActor} id="MiLectorGU" >
                                            <Box id="BoxLectorGU">
                                                <Typography>
                                                    {
                                                        item.nombreActor
                                                    }
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                        );
                                    })
                                }  
                                </Select>
                                <FormHelperText>
                                    {
                                        formik.errors.lector
                                    }
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Box>  
                </Box>
                <Box id="BoxAccionesNuevoFacialGU">
                    <Box id="BoxBtnCancelarNuevoFacialGU">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                    </Box>
                    <Box id="BoxBtnAgregarNuevoFacialGU">
                        <BotonTipo2 textoBoton={"Capturar y Guardar"} event={formik.handleSubmit} />
                        <LoadingMasRespuestaCinco
                        id={"LoadingMasRespuestaCincoNuevoFacial"}
                        open={openLoading}
                        icono={
                            response === 201 ?
                            <IconoExito id="IconoRespuestaServidor" />
                            :
                            <IconoFallo id="IconoRespuestaServidor" />
                        }
                        textoBoton={response === 201 ?
                            "Aceptar" 
                            :
                            "Reintentar"
                        }
                        setOpen={setOpenLoading}
                        msj={"Se está agregando el registro facial al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                        tituloLoading={"Agregando facial..."}
                        loading={loading}
                        titulo={response === 201 ? "Registro agregado":"Registro no agregado"}
                        mensajeRespuesta={response === 201 ? 
                            "El nuevo registro facial ha sido agregado exitosamente"
                            :
                            "No se ha podido agregar el nuevo registro facial debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia"}
                        />
                    </Box>
                </Box>
            </Box>
        </LayoutCrearNuevo>
    );
}