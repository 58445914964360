import axios from "axios";
import { useEffect, useState } from "react"
import { BuscarUsuario } from "../../Service/API/Api";

function usePerfilUsuario(idPersona){
    const [user, setUser] = useState(null);

    useEffect(() => {
        axios.get(BuscarUsuario + idPersona)
            .then(
                (response) =>
                setUser(response.data)
            )
            .catch(err => console.log("Error al obtener datos del usuario"));
        
    }, [idPersona])

    return{
        user
    }
}

export default usePerfilUsuario;