import dayjs from 'dayjs';

//Función que evalúa si la ultima conexión reportada de un mismo dispositivo fue el dia de hoy. 
// Ojo, que se refiere al  dia de hoy, no si el ultimo reporte fue durante las últimas 24 horas.

//Esto quiere decir que si la ultima conexión fue un martes a las 23:59 pm y hoy es miercoles a las 00:20 am, retornará falso.
export const dispositivoReportadoHoy = (fechaUltimaConexión) => {
    const fechaHoy = dayjs(); // Fecha actual
    const ultimaConexion = dayjs(fechaUltimaConexión); // Última conexión
    
    // Comparamos solo el día (año, mes y día) de ambas fechas
    return fechaHoy.isSame(ultimaConexion, 'day'); // 'day' ignora la hora
};

