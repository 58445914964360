import { SwitchTablas } from "../../../../Switch/switch";
import "./Pases.css";
import usePase from "../../../../../Hooks/Pase/usePase";
import { Box } from "@mui/material";
import { SnackBarTipoUno } from "../../../../snackBar/SnackBar";
import { IconoAlerta } from "../../../../Usuarios/UsuariosIcon";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import { useState } from "react";
import DataGridMui from "../../../../DataGrid/DataGridMui";

export const PasesTabla = ({rows, setRows}) =>{
    const {DesactivarActivarPase} = usePase();
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [isTrue, setIsTrue] = useState();
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);

    const HandleEstado = (id) => async () => {
        const rowToEdit = rows.find((row) => row.idPase === id);
        setIsTrue(rowToEdit.estado === true ? true:false);
        let responseConfirm = await confirm();
        if(responseConfirm === true){
            await DesactivarActivarPase(rowToEdit.estado, id)
            .then(
                (result) => {
                    rowToEdit.estado = !rowToEdit.estado
                    setRows(rows.map((row) => (row.idPase === id ? rowToEdit : row)));
                    setSnackbarSeverity("success")
                    setSnackMsg(isTrue ? "Pase Activado exitosamente!" : "Pase desactivado exitosamente!")
                    setSnackbar(true);
                }
                ,
                (error) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al guardar, reintente nuevamente")
                    setSnackbar(true); 
                }
            )
        }
        else
        {
            console.log("rechaza el cambio")
        }
    }

    const columnNames = [
        {
            field: 'tipoTarjeta', headerName: 'Método', type: 'string', width: 220, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'patenteDigitos', headerName: 'Patente', type: 'string', valueFormatter: (params) => params?.value === null ? "-":params?.value, flex:0.5, minWidth: 220, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'codigoTarjeta', headerName: 'Código', width: 220, type: 'string',  headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'estado', headerName: 'Estado', type: 'bool', width: 220, headerAlign: 'center', align:'center', sortable: false, editable: false,
            renderCell: ({id, row}) => 
            (
                <SwitchTablas 
                id="switchTablaPase" 
                value={row.estado}
                disabled={false}
                handler={HandleEstado(id)} 
                />
            )
        },

    ];


    return(
        <Box>

            <DataGridMui
            id="DataGridMetodosIdentificacion"
            getRowId={(row) => row.idPase} 
            columns={columnNames}
            rows={rows}
            checkboxSelection={false}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            rowSelection={true}
            disableRowSelectionOnClick={true}
            />

            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={5000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />
            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={isTrue ? "¿Desactivar pase?" : "¿Activar pase?"}
            mensaje={
                isTrue ? 
                    "Estás desactivando este pase de acceso, el usuario no podra ingresar utilizando este metodo. ¿Deseas continuar?" 
                    : 
                    "Estás activando este pase de acceso, el usuario podra acceder utilizando este metodo. ¿Deseas continuar?" 
            }
            />
        </Box>
    );
}