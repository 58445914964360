import axios from "axios";
import { useEffect, useState } from "react"
import { CargaMasivaUbicaciones, CrearUbicacion, EditarUbicacion, UbicacionesEnEmpresa, UbicacionesFormatoImporte } from "../../Service/API/Api";

export default function useUbicacionesGestion() {
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();
    const [ubicaciones, setUbicaciones] = useState([]);
    

    useEffect(() => {
        setLoading(true);
        axios.get(UbicacionesEnEmpresa)
        .then(
            (response) => {
                setUbicaciones(response.data)
                setLoading(false);
            }
        ).catch(
            (error) => {
                console.log("Error al obtener las ubicaciones");
                setLoading(false);
            }
        )
    }, [])

    const GuardarUbicacion = (values) => {
        return new Promise(async (resolve, reject) => {
            let nuevaUbicacion = {
                "idZona": values.idZona,
                "idTipoUbicacion": values.idTipoUbicacion,
                "nombre": values.nombreUbicacion,
                "piso": values.piso,
                "numeracion": values.numeracion
            }
            await axios.post(CrearUbicacion, nuevaUbicacion)    
            .then(
                (response) =>{
                    resolve(response.data);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }

    const EditarUbicacionExistente = (idUbicacion, values) => {
        return new Promise(async (resolve, reject) => {
            let nuevaUbicacion = {
                "idUbicacion": idUbicacion,
                "idZona": values.idZona,
                "idTipoUbicacion": values.idTipoUbicacion,
                "nombre": values.nombreUbicacion,
                "piso": values.piso,
                "numeracion": values.numeracion
            }
            await axios.put(EditarUbicacion, nuevaUbicacion)    
            .then(
                (response) =>{
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }

    async function DescargarFormatoIdealUbicaciones(){
        return new Promise((resolve, reject) => {
            axios.request({
                method: "GET",
                url: UbicacionesFormatoImporte,
                responseType: "blob",
            })
            .then((response) => {
                let filename = "";
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
                var disposition = response.headers['content-disposition']
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);                    
                }
                else
                {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);
    
                    if (filename) {
                        var a = document.createElement("a");
                        if (typeof a.download === 'undefined') {
                            window.location.href = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location.href = downloadUrl;
                    }
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false);
                    setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); 
                }
            })
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            );
        })
    }

    async function ImportarArchivoExcelUbicaciones(idEmpresa, archivo) {
        const formData = new FormData();
        formData.append('ubicaciones', archivo);
        formData.append('idEmpresa', idEmpresa);
    
        try {
            const response = await axios.post(CargaMasivaUbicaciones, formData);
            setResponse(response.status);
            setLoading(false);
            return response;
        } catch (error) {
            throw error;
        }
    }
   
    

    return {
        ubicaciones,
        loading,
        setLoading,
        response,
        GuardarUbicacion,
        EditarUbicacionExistente,
        DescargarFormatoIdealUbicaciones,
        ImportarArchivoExcelUbicaciones,
    }
}