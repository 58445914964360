import { Box } from "@mui/material"
import { PapeleroIconA } from "../../GestionUbicaciones/IconosGestionUbicaciones"

export const BotonEliminar = () => {
  return (
    <Box
        id="iconoEditarBox"
        sx={{
            display: 'flex',
            padding: '6px',
            alignItems: 'flex-start',
            gap: '10px',
            borderRadius: '25px',
            background: '#FFF',
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
            '& .icono-papelero .fondo-icono-papelero':{
            fill: 'white',
            },
            '& .icono-papelero .trazo-icono-papelero':{
            stroke: '#626262',
            strokeWidth: 1.5,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            },
            '&:hover':  {
            backgroundColor: '#FAE9E8',
            '.icono-papelero .fondo-icono-papelero': {
                fill: '#FAE9E8',
            },
            '.icono-papelero .trazo-icono-papelero': {
                stroke: 'red',
            },
            },
            '&:active':  {
            backgroundColor: 'red',
            '.icono-papelero .fondo-icono-papelero': {
                fill: 'red',
            },
            '.icono-papelero .trazo-icono-papelero': {
                stroke: 'white',
            },
            }
        }}
        >
        <PapeleroIconA id='iconoEditar'/> 
</Box>
  )
}

export default BotonEliminar;
