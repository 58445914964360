import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CabeceraTablasGestion from '../CabeceraTablasGestion/CabecerasTablasGestion';
import { FiltroSelect, FiltroSelectTres } from '../CabeceraTablasGestion/FiltrosCabecera/FiltrosCabecera';
import { GridActionsCellItem } from '@mui/x-data-grid';
import TooltipTipoUno from '../ToolTip/Tooltip';
import { IconoGestionUbicaciones, UsuariosIconA } from './IconosGestionUbicaciones';
import useUbicacionesGestion from '../../Hooks/Ubicaciones/useUbicacionesGestion';
import { IconoEditarUsuario } from '../Usuarios/UsuariosIcon';
import { ModalOpcionesGestionUbicacion } from './OpcionesUbicaciones/ManejadorOpcionesUbicacion';
import "./GestionUbicaciones.css";
import { LoadingMasRespuestaCinco } from '../Modal/LoadingMasRespuesta/LoadingMasRespuesta';
import { IconoExito, IconoFallo } from '../Modal/RespuestaServidor/RespuestaServidorIcon';
import CargaMasivaComponent from '../CargaMasivaComponent/CargaMasivaComponent';
import { validarArchivoExcelUbicaciones } from '../CargaMasivaComponent/funcionesCargaMasivaComponent';
import useZonas from '../../Hooks/Zona/useZonas';
import { useSelector } from 'react-redux';
import DataGridMui from '../DataGrid/DataGridMui';

const GestionUbicaciones = () => {
    const { ubicaciones, loading, setLoading, response, DescargarFormatoIdealUbicaciones, ImportarArchivoExcelUbicaciones} = useUbicacionesGestion();
    const tiposUbicaciones = useSelector(action => action.tiposUbicaciones.listadoTiposUbicaciones);
    const zonasFormadas = useSelector((state) => state.zonasFormadasRegistradas.listadoZonasFormadas);
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [openModalGestionUbicacionr, setOpenModalGestionUbicacion] = useState(Boolean(false));
    const [opcionSeleccionada, setOpcionSeleccionada] = useState();
    const [dataEditar, setDataEditar] = useState();
    const [tipoUbicacion, setTipoUbicacion] = useState(-1);
    const [opcionZona, setOpcionZona] = useState(0);
    const [idBusqueda, setIdBusqueda] = useState({});

    //Esto es necesario para las funciones que comprueban la existencia del id zona
    // y el id de tipo de ubicacion que se encuentran en el archivo Excel
    const {zonas} = useZonas();

    //Estado para manejar el LoadingMasRespuestaCinco
    const [openLoading, setOpenLoading] = useState(Boolean(false)); 

    //variables y funciones relacionadas con el manejo del popover para importar el archivo de ubicaciones
    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const handleDescargarFormatoIdeal = async () => {
        setLoading(true);
        setOpenLoading(true);
        try {
            await DescargarFormatoIdealUbicaciones();
        } catch (error) {
            console.error("Error al descargar formato ideal del archivo ubicaciones");
        }
    }

    useEffect(() => {
        setRows(ubicaciones);
    }, [ubicaciones])

    const handleZona = (e) => {
        setOpcionZona(e.target.value);
        if(e.target.value === 0) {
            setRows(ubicaciones);
        }
        else
        {
            const listaFiltrada = ubicaciones.filter((row) => row.idZona === e.target.value);
            setRows(listaFiltrada);
        }
        
    }

    const handleNueva = ()=> {
        setOpenModalGestionUbicacion(true);
        setOpcionSeleccionada(1);
    }

    const openEditar = (row) => () => {
        setOpenModalGestionUbicacion(true);
        setDataEditar(row);
        setOpcionSeleccionada(2);
    }

    const handleTipoUbicacion = (e) => {
        setTipoUbicacion(e.target.value);
        if(e.target.value === -1) {
            setRows(ubicaciones);
        }
        else
        {
            const listaFiltrada = ubicaciones.filter((row) => row.idTipoUbicacion === e.target.value);
            setRows(listaFiltrada);
        }
    }

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = ubicaciones.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field]);
            });
          });
        setRows(filteredRows);
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value)
    }

    const clearSearch = () => {
        requestSearch('')
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handlerVistaMiembros = (id) => () => {
        setOpenModalGestionUbicacion(true);
        setIdBusqueda(id);
        setOpcionSeleccionada(3);
    }

    const columns = [
        {   field: 'idUbicacion', headerName: 'ID', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'nombreZona', headerName: 'Ubicada en', flex: 0.5, minWidth: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'tipoUbicacion', headerName: 'T. Ubicacion', type: 'string',  width: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'nombre', headerName: 'N. Ubicacion', type: 'string',  flex: 0.5, minWidth: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'piso', headerName: 'Piso', type: 'string', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'numeracion', headerName: 'Numero', type: 'string',  flex: 0.5, minWidth: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'Usuarios', headerName: 'Miembros', type: 'actions',  width: 134, headerAlign: 'center', align:'center', sortable: false, editable: false, cellClassName: 'actions',
            getActions:({id, row})=>{
                return [<GridActionsCellItem
                    icon={<Box
                        id="iconoEditarBox"
                        sx={{
                          display: 'flex',
                          padding: '6px',
                          alignItems: 'flex-start',
                          gap: '10px',
                          borderRadius: '25px',
                          background: '#FFF',
                          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                          '& .usuarios-icon-a .fondo-usuarios-icon-a':{
                            fill: 'white',
                          },
                          '& .usuarios-icon-a .trazo-usuarios-icon-a':{
                            stroke: '#626262',
                            strokeWidth: 1.5,
                            strokeLinecap: 'round',
                            strokeLinejoin: 'round',
                          },
                          '&:hover':  {
                            backgroundColor: '#E8EEF1',
                            '.usuarios-icon-a .fondo-usuarios-icon-a': {
                              fill: '#E8EEF1',
                            },
                            '.usuarios-icon-a .trazo-usuarios-icon-a': {
                              stroke: '#175676',
                            },
                          },
                          '&:active':  {
                            backgroundColor: '#1F7098',
                            '.usuarios-icon-a .fondo-usuarios-icon-a': {
                              fill: '#1F7098',
                            },
                            '.usuarios-icon-a .trazo-usuarios-icon-a': {
                              stroke: 'white',
                            },
                          }
                        }}
                      >
                        <UsuariosIconA id='iconoEditar'/> 
                    </Box>}
                    label="Miembros"
                    onClick={handlerVistaMiembros(id)}
                />]
            }
        },
        {   field: 'editar', headerName: 'Editar', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id, row}) => {
                return [
                    <TooltipTipoUno titulo={"Editar"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<IconoEditarUsuario  id='iconoEnTablaUsuarios'/>}
                        label="Editar"
                        onClick={openEditar(row)}
                        />
                    </TooltipTipoUno>
                ];
            }
        }
    ];

    return (
    <Box id="BoxContenedorTablaActuadores">
        <CabeceraTablasGestion 
            icono1={<IconoGestionUbicaciones />}
            titulo={"Ubicaciones"}
            rows={rows}
            setRows={setRows}
            agregarNuevoIcon={true}
            handlerAN={handleNueva} //agregar handle nuevo
            cargaMasivaIcon={true}
            handlerCM={handlePopoverOpen}
            descargarIcon={null}
            handlerDownload={null}
            onChange={handleSearch}
            value={searchText}
            clearSearch={clearSearch}
            >
                <Box id="BoxSelectContainerCabeceras">
                    <FiltroSelectTres id="FiltroSelectTiposActores" 
                    nombre={"Tipos"} 
                    listaOpciones={tiposUbicaciones}
                    value={tipoUbicacion}
                    handler={handleTipoUbicacion}
                    />
                    <FiltroSelect id="FiltroSelectZonasEmpresa" 
                    nombre={"Zonas"} 
                    listaOpciones={zonasFormadas}
                    value={opcionZona}
                    handler={handleZona}
                    />
                </Box>
            </CabeceraTablasGestion>

            <DataGridMui
            id='TablaHorarios'
            getRowId={(row) => row.idUbicacion}
            columns={columns} 
            rows={rows}
            checkboxSelection={false}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            rowSelection = {true}
            loading={loading}
            disableRowSelectionOnClick = {true}
            />

            <ModalOpcionesGestionUbicacion
            open={openModalGestionUbicacionr}
            setOpen={setOpenModalGestionUbicacion}
            setRows={setRows}
            dataEditar={dataEditar}
            rows={rows}
            opcion={opcionSeleccionada}
            ubicacion={idBusqueda}
            /> 

            <LoadingMasRespuestaCinco
                open={openLoading} 
                setOpen={setOpenLoading} 
                msj={"El reporte se esta descargando. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."} 
                id={"RespuestaDescargarReporteExcel"} 
                tituloLoading={"Descargando reporte..."}
                loading={loading} 
                respuesta={response} 
                icono={
                    response === 200 ?
                    <IconoExito id="IconoRespuestaServidor" />
                    :
                    <IconoFallo id="IconoRespuestaServidor" />
                }
                textoBoton={
                    response === 200 ? 
                    "Aceptar"
                    :
                    "Reintentar"
                }
                titulo={response === 200 ? "Descarga exitosa":"Error al Descargar"}
                mensajeRespuesta={response === 200 ? 
                    "El reporte se ha descargado correctamente"
                    :
                    "Ha ocurrido un error y no se ha podido descargar el reporte. Por favor, verifica tu conexión y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
            />

            <CargaMasivaComponent
                anchorEl={anchorEl} 
                onClose={handlePopoverClose}
                handleDescargarFormatoIdeal={handleDescargarFormatoIdeal}
                importarArchivoExcel={ImportarArchivoExcelUbicaciones}
                tipoCargaMasiva={"ubicaciones"}
                funcionValidadoraExcel={(entrada)=>validarArchivoExcelUbicaciones(entrada, zonas, tiposUbicaciones)}
            />           
    </Box>
  );
}


export default GestionUbicaciones;