import { Autocomplete, Box, Button, FormControl, FormHelperText,TextField, Typography } from "@mui/material";
import "./EditarInformacion.css";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import { TextFieldUno } from "../../../../TextField/TextFieldUno";
import { useState } from "react";
import { useFormik } from "formik";
import { Validation } from "./validation";
import { IconoBorrarCampoGU } from "../../MenuGestionUsuarioIcon";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../../../Usuarios/UsuariosIcon";
import useUsuarios from "../../../../../Hooks/Usuarios/useUsuarios";
import { LoadingMasRespuestaCinco } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoEditarCorreo } from "../../../Perfil/DataUsuario/DataUsuarioIcon";
import { useNavigate } from "react-router-dom";
import ImgCambioCorreo from "../../../../../assets/imagenes/CambioCorreo.png"
import useConfirmDos from "../../../../../Hooks/ConfirmDialog/useConfirmDialogDos";
import { useSelector } from "react-redux";
import { IconoExito, IconoFallo } from "../../../RespuestaServidor/RespuestaServidorIcon";
import { SnackBarTipoUno } from "../../../../snackBar/SnackBar";

export const EditarInformacion = ({ userData, setRows, rows, handleOpen}) => {
    const data = useSelector(action => action.user.authenticated);
    const [edit, setEdit] = useState(false);
    const [ConfirmationDialog, confirm ]= useConfirm();
    const {EditarExistente, loadingRequest, setLoadingRequest, requestResponse } = useUsuarios();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [ConfirmationDialogDos, confirmDos]= useConfirmDos();
    const navigate = useNavigate();
    const tiposUsuarios = useSelector(action => action.tiposUsuarios.listadoTiposUsuarios);
    const [snackMsg, setSnackMsg] = useState({severity: "", message: ""});
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);


    const handleClose = () =>{
        handleOpen(false);
    }
    const handleEdit = () =>{
        setEdit(true);
    }

    
    const EditCorreo = async () => {
        let response = await confirmDos();
        if(response === true){
            navigate("/cambiarEmail", { replace: true, state: { correoActual: formik.values.correo}})
        }
        else{
            console.log("rechaza cambio");
        }
    }

    const formik =  useFormik({
        initialValues:{
            nombres: userData.nombres,
            apellidos: userData.apellidos,
            rut: userData.rut ?? "-",
            pasaporte: userData.pasaporte ?? "-",
            correo: userData.correo,
            telefono: userData.telefono,
            idTipoPersona: userData.idTipoPersona ?? -1,
            tipoPersona: userData.tipo ?? "Seleccione"
        },
        validationSchema:Validation,
        onSubmit: async () =>{
            
            if((formik.values.rut === "-" || formik.values.rut === "") && (formik.values.pasaporte === "-" || formik.values.pasaporte === ""))
            {
                setSnackMsg({severity: "error", message:"Debe completar el campo de identificacion (Rut o Pasaporte)"});
                setSnackbar(true);
            }
            else
            {
                let response = await confirm();
                if(response === true)
                {
                    let tipoUsuario = tiposUsuarios.find((t)=>t.idTipoPersona === formik.values.idTipoPersona);
                    setLoadingRequest(true);
                    setOpenLoading(true);
                    EditarExistente(formik.values, userData.idPersona)
                    .then(
                        (result) => {
                            userData.nombres = formik.values.nombres;
                            userData.apellidos = formik.values.apellidos;
                            userData.rut = formik.values.rut;
                            userData.pasaporte = formik.values.pasaporte;
                            userData.idTipoPersona = formik.values.idTipoPersona;
                            userData.correo = formik.values.correo;
                            userData.telefono = formik.values.telefono;
                            userData.tipo = tipoUsuario.tipo;
                            setRows(rows.map((row) => (row.idPersona === userData.idPersona ? userData : row)))

                        },
                        (error) => {
                            console.log("fallo modificacion");
                        }
                    )
                }
                else
                {
                    console.log("rechaza la modificacion")
                }
            }
        }
    })

    return (
        <Box id="BoxOpcionEditarUsuario">
            <Box id="BoxtTituloEditarGU">
                <Typography id="TxtTituloEditarGU">
                    {
                        "Información del Usuario"
                    }
                </Typography>
            </Box>
            <Box id="BoxContenidoEditarGU">
                <Box id="BoxDataPersonalContactoGU" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxInformacionPersonaGUEditar">
                        <Box id="BoxTituloInformacionPersonalGUEditar">
                            <Typography id="TxtTituloInfoPerGUEditar">
                                {
                                    "Información personal"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxTextFieldDatosUsuarios">
                            <Box id="BoxDataUsuarioNomGUEditar">
                                <Box id="BoxTextfieldNombresGU">
                                    <TextFieldUno 
                                    id="NombresUsuarioGUEditar"
                                    label={"Nombres"}
                                    placeholder={"Nombres"}
                                    name={"nombres"}
                                    helper={formik.errors.nombres}
                                    icon={edit ? <IconoBorrarCampoGU id="IconoEnTFUno" /> : null}
                                    disabled={ edit ? false:true}
                                    formHandle={() => formik.setFieldValue("nombres", "")}
                                    value={formik.values.nombres}
                                    handlerChange={formik.handleChange}
                                    focus={false}
                                    />
                                </Box>
                                <Box id="BoxTextfieldNombresGU">
                                    <TextFieldUno 
                                    id="ApellidosUsuarioGUEditar"
                                    label={"Apellidos"}
                                    placeholder={"Apellidos"}
                                    name={"apellidos"}
                                    helper={formik.errors.apellidos}
                                    icon={edit ? <IconoBorrarCampoGU id="IconoEnTFUno"/> : null}
                                    disabled={ edit ? false:true}
                                    formHandle={() => formik.setFieldValue("apellidos", "")}
                                    value={formik.values.apellidos}
                                    handlerChange={formik.handleChange}
                                    focus={false}
                                    />
                                </Box>
                            </Box>
                            <Box id="BoxDataUsuarioRutTipoGUEditar">
                                <Box id="BoxIdentificadoresInformacionUsuario">
                                    <Box id="BoxIdentificadorRutInformacionUsuario">
                                        <TextFieldUno 
                                        id="RutIdentificadorInformacionUsuario"
                                        label={"Rut"}
                                        placeholder={"Rut"}
                                        name={"rut"}
                                        helper={formik.errors.rut}
                                        icon={edit ? <IconoBorrarCampoGU id="IconoEnTFUno"/> : null}
                                        disabled={ edit ? false:true}
                                        formHandle={() => formik.setFieldValue("rut", "")}
                                        value={formik.values.rut}
                                        handlerChange={formik.handleChange}
                                        focus={false}
                                        />
                                    </Box>
                                    <Box id="BoxIdentificadorPasaporteInformacionUsuario">
                                        <TextFieldUno 
                                        id="PasaporteIdentificadorInformacionUsuario"
                                        label={"Pasaporte"}
                                        placeholder={"Pasaporte"}
                                        name={"pasaporte"}
                                        helper={formik.errors.pasaporte}
                                        icon={edit ? <IconoBorrarCampoGU id="IconoEnTFUno"/> : null}
                                        disabled={ edit ? false:true}
                                        formHandle={() => formik.setFieldValue("pasaporte", "")}
                                        value={formik.values.pasaporte}
                                        handlerChange={formik.handleChange}
                                        focus={false}
                                        />
                                    </Box>
                                </Box>
                                <Box id="BoxTextfieldNombresGU">
                                    <FormControl fullWidth sx={{height:"56px"}}>
                                        <Autocomplete                                   
                                        disablePortal
                                        disabled={ edit ? false:true}
                                        options={tiposUsuarios}
                                        fullWidth  
                                        sx={{ "& .MuiFilledInput-input":{ height: "20px", borderBottom: "0px"}, 
                                                "& .MuiOutlinedInput-input":{ height: "20px", borderBottom: "0px"}}}
                                        id="idTipoPersona"
                                        componentname="idTipoPersona"
                                        freeSolo={true}                                        
                                        getOptionKey={(o) => o.idTipoPersona}
                                        isOptionEqualToValue={(o, v) => o.tipo === v}
                                        onChange={(e, v) => {
                                            if(v === null)
                                            {
                                                formik.setFieldValue("idTipoPersona", -1) 
                                                formik.setFieldValue("tipoPersona", "Seleccione")                                           
                                                                                         
                                            }
                                            else
                                            {
                                                formik.setFieldValue("idTipoPersona", v.idTipoPersona)
                                                formik.setFieldValue("tipoPersona", v.tipo)
                                            }
                                        }}
                                        getOptionLabel={(option) =>  option.tipo ?? option}
                                        inputValue={formik.values.tipoPersona}
                                        value={formik.values.tipoPersona}
                                        onInputChange={(e,v) => formik.setFieldValue("tipoPersona", v)}   
                                        renderInput={(params) => 
                                        <TextField
                                        InputLabelProps={{shrink: true}}
                                        {...params}
                                        sx={{ height: "100%"}}
                                        label="Tipo de Usuario"
                                        />
                                        }
                                        renderOption={(props, option) => {
                                            const { key, ...optionProps } = props;
                                            return (
                                                <Box
                                                key={key}
                                                component="li"
                                                {...optionProps}>
                                                {option.tipo}
                                                </Box>
                                            );
                                        }}
                                        />
                                         <FormHelperText>
                                        {
                                            formik.errors.idTipoPersona
                                        }
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxInformacionContactoGUEditar">
                        <Box id="BoxTextoTituloContactoGUEditar">
                            <Typography id="TxtTituloInfoPerGUEditar">
                                {
                                    "Información de contacto"
                                }
                            </Typography>  
                        </Box>
                        <Box id="BoxDataContactoUsuarioGUEditar">
                            <Box id="BoxTextfieldNombresGU">
                                <TextFieldUno 
                                id="CorreoUsuarioGUEditar"
                                label={"Correo"}
                                placeholder={"Correo"}
                                name={"correo"}
                                helper={formik.errors.correo}
                                icon={null}
                                disabled={true}
                                formHandle={() => formik.setFieldValue("correo", "")}
                                value={formik.values.correo}
                                handlerChange={formik.handleChange}
                                focus={false}
                                />
                                <Box id="BoxBotonEditarcorreoEditarUsuarioInformacion">
                                    <Button id="BotonEditarCorreoEditarUsuario"
                                    onClick={EditCorreo}
                                    startIcon={
                                    <IconoEditarCorreo  id="IconoEditarCorreoEditarUsuario"/>}
                                    >
                                        Editar Correo
                                    </Button>
                                </Box>
                            </Box>
                            <Box id="BoxTextfieldNombresGU">
                                <TextFieldUno 
                                id="TelefonoUsuarioGUEditar"
                                label={"Telefono"}
                                placeholder={"Telefono"}
                                name={"telefono"}
                                helper={formik.errors.telefono}
                                icon={edit ? <IconoBorrarCampoGU id="IconoEnTFUno" /> : null}
                                disabled={ edit ? false:true}
                                formHandle={() => formik.setFieldValue("telefono", "")}
                                value={formik.values.telefono}
                                handlerChange={formik.handleChange}
                                focus={false}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxAccionesPersonalContactoGU">
                    <Box id="BoxBotonAccionEditarUsuarioGU">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                    </Box>
                    <Box id="BoxBotonAccionEditarUsuarioGU">
                        <BotonTipo2 textoBoton={edit ? "Actualizar":"Editar"} event={edit ? formik.handleSubmit : handleEdit}/>
                    </Box>
                </Box>
            </Box>

            <ConfirmationDialogDos
            img={ImgCambioCorreo}
            titulo={"Confirmar cambio"}
            mensaje={"estas seguro(a) que quieres cambiar este correo electrónico"}
            usuario={data.usuario.split(' ')[0]}
            />
            <LoadingMasRespuestaCinco
            open={openLoading} 
            setOpen={setOpenLoading} 
            msj={"La información del usuario está siendo actualizada. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
            id={"RespuestaGuardarEditarUsuario"} 
            tituloLoading={"Actualizando datos..."}
            loading={loadingRequest} 
            respuesta={requestResponse} 
            icono={
                requestResponse === 200 ?
                <IconoExito id="IconoRespuestaServidor" />
                :
                <IconoFallo id="IconoRespuestaServidor" />
            }
            textoBoton={
                requestResponse === 200 ? 
                "Aceptar"
                :
                "Reintentar"
            }
            titulo={requestResponse === 200 ? "Actualización exitosa":"Error al actualizar"}
            mensajeRespuesta={requestResponse === 200 ? 
                "La información del usuario se ha actualizado correctamente. Ahora puedes gestionar sus permisos o añadir un nuevo usuario"
                :
                "Ha ocurrido un error y no se ha podido actualizar la información del usuario. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
            />
            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={"¿Actualizar datos?"}
            mensaje={"Estás a punto de actualizar la información del usuario. ¿Deseas continuar?"}
            />

            <SnackBarTipoUno
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackMsg.severity}
            msg={snackMsg.message}
            />
        </Box>
    );
}
