import axios from "axios";
import { useState } from "react";
import {AsignarHorario, DesactivarAsignacion, EditarAsignacionUsuario, ReactivarAsignacion } from "../../Service/API/Api";
import dayjs from "dayjs";

export default function useAsignaciones(){
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();

    const CrearNuevaAsignacion = (values, idPersona) =>{
        return new Promise((resolve, reject) => {
            
            const nuevaASignacion = {
                "fechaInicio": dayjs(values.inicio).toISOString(),
                "fechaTermino":  dayjs(values.termino).toISOString(),
                "idPersona": idPersona,
                "idHorario": values.idHorario
            }
           
            console.log(nuevaASignacion)
            axios.post(AsignarHorario, nuevaASignacion)
            .then(
                (response) => {
                    resolve(response.data);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                        reject(error.response.status);
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                        reject("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                        reject("Error desconocido. Contáctanos para más información.")
                    }
                    setLoading(false);
                }
            )
        })

    }

    const DeshabilitarActivarAsignacion = (idAsignacion, estado) =>{
        return new Promise((resolve, reject) => {
            
            axios.patch(estado === true ? DesactivarAsignacion + idAsignacion : ReactivarAsignacion + idAsignacion)
            .then(
                (response) => {
                    resolve(true);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
        })

    }


    const EditarAsignacion = (values, idAsignacion, usuario) =>{
        return new Promise((resolve, reject) => {
            const diaInicio = dayjs(values.inicioEditar).format("DD")
            const mesInicio = dayjs(values.inicioEditar).format("MM")
            const anioInicio = dayjs(values.inicioEditar).format("YYYY")

            const diaTermino = dayjs(values.terminoEditar).format("DD")
            const mesTermino = dayjs(values.terminoEditar).format("MM")
            const anioTermino = dayjs(values.terminoEditar).format("YYYY")
            
            const asignacion ={
                "idAsignacion": idAsignacion,
                "fechaInicio": dayjs(new Date(anioInicio, mesInicio -1, diaInicio, 0, 0, 0, 0)), //formato new date (DD, MM, YYYY, HH, mm, ss, ssss) esperado 17/05/2024 00:00:00
                "fechaTermino": dayjs(new Date(anioTermino, mesTermino -1, diaTermino, 0, 0, 0, 0)),//formato new date (DD, MM, YYYY, HH, mm, ss, ssss) esperado 17/05/2025 00:00:00
                "idPersona": usuario,
                "idHorario": values.idHorario
            }
           
            axios.put(EditarAsignacionUsuario, asignacion)
            .then(
                (response) => {
                    resolve(true);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
        })

    }

    return{
        loading,
        setLoading,
        response,
        CrearNuevaAsignacion,
        DeshabilitarActivarAsignacion,
        EditarAsignacion
    }
}