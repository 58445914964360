import { useEffect, useState } from "react";
import axios from "axios";
import { CargaMasivaZona, CrearZona, DesactivarAPBZona, DesactivarZona, EditarZona, ReactivarAPBZona, ReActivarZona, ZonasEmpresa, ZonasFormatoImporte } from "../../Service/API/Api";

export default function useZonas(){
    const [zonas, setZonas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState();
    const [reload, setReload] = useState(true);

    useEffect(() => {
        axios.get(ZonasEmpresa)
        .then(
            (response) => {
                setZonas(response.data)
                setLoading(false)
            }
        )
        .catch(
            (error) => {
                console.log("Error al obtener la información de las zonas");      
                setLoading(false)          
            }
        )

        return () => setReload(false);

    }, [reload])
    
    const HandleEstadoZona = (id, estadoActual) => {

        return new Promise(async (resolve, reject) => {
            axios.patch(estadoActual ? DesactivarZona + id : ReActivarZona + id)
            .then(
                (response) => {
                    resolve(true)
                    setLoading(false)
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setLoading(false)
                }
            )
        });
    }

    const HandleAPBZona = (id, estadoActual) => {
        return new Promise(async (resolve, reject) => {
            axios.patch(estadoActual ? DesactivarAPBZona + id : ReactivarAPBZona + id)
            .then(
                (response) => {
                    resolve(true)
                    setLoading(false)
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setLoading(false)
                }
            )
        });
    }


    const HandleAgregarZona = (values, idEmpresa) => {
        return new Promise(async (resolve, reject) => {
            var nueva = {
                "nombre": values.nombreZona,
                "antiPassBack": values.apb,
                "idEmpresa": idEmpresa
              }

            axios.post(CrearZona, nueva)
            .then(
                (response) => {
                    resolve(response.data)
                    setResponse(response.status)
                    setLoading(false)
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            )
        });
    }

    const HandleEditarZona = (values, idEmpresa, idZona) => {
        return new Promise(async (resolve, reject) => {
            var toEdit = {
                "idZona": idZona,
                "nombre": values.nombreZona,
                "antiPassBack": values.antiPassBack,
                "idEmpresa": idEmpresa
            }

            axios.put(EditarZona, toEdit)
            .then(
                (response) => {
                    resolve(true)
                    setResponse(response.status)
                    setLoading(false)
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            )
        });
    }

    async function DescargarFormatoIdealZonas(){
        return new Promise((resolve, reject) => {
            axios.request({
                method: "GET",
                url: ZonasFormatoImporte,
                responseType: "blob",
            })
            .then((response) => {
                let filename = "";
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
                var disposition = response.headers['content-disposition']
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);                    
                }
                else
                {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);
    
                    if (filename) {
                        var a = document.createElement("a");
                        if (typeof a.download === 'undefined') {
                            window.location.href = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location.href = downloadUrl;
                    }
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false);
                    setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); 
                }
            })
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            );
        })
    }

    async function ImportarArchivoExcelZonas(idEmpresa, archivo) {
        const formData = new FormData();
        formData.append('zonas', archivo);
        formData.append('idEmpresa', idEmpresa);
    
        try {
            const response = await axios.post(CargaMasivaZona, formData);
            setResponse(response.status);
            setLoading(false);
            return response;
        } catch (error) {
            throw error;
        }
    }
    
    
    return {
        zonas,
        loading,
        setLoading,
        response,
        setReload,
        HandleEstadoZona,
        HandleAPBZona,
        HandleAgregarZona,
        HandleEditarZona,
        DescargarFormatoIdealZonas,
        ImportarArchivoExcelZonas
    }
}