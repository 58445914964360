import { IconButton } from "@mui/material";
import { IconoBarrera, IconoCamara, IconoCamaraPatente, IconoGOS, IconoLectorFacial, IconoLectorHuella, IconoLuz, IconoPinPass, IconoPorton, IconoPuerta, IconoQR, IconoRFID, IconoSensorMagnetico, IconoSensorProximidad, IconoTAG, IconoTotemOCR, IconoVentana } from "./IconosParaBotones";
import TooltipTipoUno from "../ToolTip/Tooltip";

export const ObtenerIconoAsociado = (idTipoActor) => {
    var ObtenerIcono = '';

    if (idTipoActor === 1) {
        ObtenerIcono = <IconoCamara id="IconoEnMapa" />;
    }
    //Ahora Camara Patente
    if (idTipoActor === 2) {
        ObtenerIcono = <IconoCamaraPatente id="IconoEnMapa"/>
    }
    //Ahora es totemOCR
    if (idTipoActor === 3) {
        ObtenerIcono = <IconoTotemOCR id="IconoEnMapa" />;
    }
    //Ahora es Totem QR
    if (idTipoActor === 4) {
        ObtenerIcono = <IconoQR id="IconoEnMapa" />;
    }
    //Ahora es totem huella
    if (idTipoActor === 5) {
        ObtenerIcono = <IconoLectorHuella id="IconoEnMapa" />;
    }
    //Ahora es sensor proximidad
    if (idTipoActor === 6) {
        ObtenerIcono = <IconoSensorProximidad id="IconoEnMapa" />;
    }
    //Ahora es sensor magnetico
    if (idTipoActor === 7) {
        ObtenerIcono = <IconoSensorMagnetico id="IconoEnMapa" />;
    }
    //Ahora es TAG
    if (idTipoActor === 8) {
        ObtenerIcono = <IconoTAG id="IconoEnMapa" />;
    }
    //Ahora es RFID
    if (idTipoActor === 9) {
        ObtenerIcono = <IconoRFID id="IconoEnMapa" />;
    }
    //Ahora es Torniquete OCR (LOGO PENDIENTE)
    if (idTipoActor === 10) {
        ObtenerIcono = null;
    }
    //Ahora es Torniquete QR (LOGO PENDIENTE)
    if (idTipoActor === 11) {
        ObtenerIcono = null;
    }
    //Ahora es Torniquete Huella (LOGO PENDIENTE)
    if (idTipoActor === 12) {
        ObtenerIcono = null;
    }
    //Ahora es GOS
    if (idTipoActor === 13) {
        ObtenerIcono = <IconoGOS id="IconoEnMapa" />;
    }
    //Ahora es Cámara Facial
    if (idTipoActor === 14) {
        ObtenerIcono = <IconoLectorFacial id="IconoEnMapa" />;
    }
    //Ahora es PinPass
    if (idTipoActor === 15) {
        ObtenerIcono = <IconoPinPass id="IconoEnMapa" />; 
    }
    //Ahora es Porton
    if (idTipoActor === 16) {
        ObtenerIcono = <IconoPorton id="IconoEnMapa" />;
    }
    //Ahora es Puerta
    if (idTipoActor === 17) {
        ObtenerIcono = <IconoPuerta id="IconoEnMapa" />; 
    }
    //Ahora es Alarma (LOGO PENDIENTE)
    if (idTipoActor === 18) {
        ObtenerIcono = null;
    }
    //Ahora es Luz
    if (idTipoActor === 19) {
        ObtenerIcono = <IconoLuz id="IconoEnMapa" />;
    }
    //Ahora es Ventana
    if (idTipoActor === 20) {
        ObtenerIcono = <IconoVentana id="IconoEnMapa" />;
    }

    if (idTipoActor === 21) {
        ObtenerIcono = <IconoBarrera id="IconoEnMapa" />;
    }

    if (idTipoActor === 22) {
        ObtenerIcono = null;
    }

    return (
        ObtenerIcono
    );
}


export const BotonesConSusFormatos = ({ dataBotones, handlePopoverOpen, handleChangeBotonSeleccionado }) => {
    return dataBotones.map((item, index) => {
        let iconoAsociado = ObtenerIconoAsociado(item.idTipoActor);
        return (
            <TooltipTipoUno key={index} titulo={item.tipoActor + " - " + item.nombreActor} ubicacion={"bottom"} >
                <IconButton
                    id="PruebaButton"
                    onClick={(event) => {
                        handlePopoverOpen(event);
                        handleChangeBotonSeleccionado(item);
                    }}
                    sx={{
                        position: "absolute",
                        marginLeft: `${item.marginLeft}px`,
                        marginTop: `${item.marginTop}px`
                    }} >
                    {iconoAsociado}
                </IconButton>
            </TooltipTipoUno>

        );
    });
}

