import axios from "axios";
import { IconoDuranteElDia, IconoEvento, IconoRecurrente } from "./TiposEventosIcon";
const { useState, useEffect } = require("react");
const { TiposVisitasEmpresa } = require("../../Service/API/Api");

function useTiposEventos(cargarDatos = true) {
    const [tiposVisitas, setTiposVisitas] = useState([]);
    useEffect(() => {
        if(cargarDatos) {
            axios.get(TiposVisitasEmpresa)
            .then(
                (response) => {
                    var lista= [];
                    var valor0 = {
                        idTipoVisita:-1,
                        tipoVisita: "Seleccione",
                        icono: null
                    }
                    lista.push(valor0);

                    response.data.forEach(element => {
                        var item = {
                            idTipoVisita: element.idTipoVisita,
                            tipoVisita: element.tipo,
                            icono: element.idTipoVisita === 1 ? <IconoDuranteElDia id="iconoTipoVisita"  /> : element.idTipoVisita === 2 ? <IconoEvento id="iconoTipoVisita" /> : <IconoRecurrente id="iconoTipoVisita"  />
                        }
                        lista.push(item);
                    });

                    setTiposVisitas(lista)
                }
            )

        }
    }, [cargarDatos])
    

    return { 
        tiposVisitas
    };
}

export default useTiposEventos;
