import { Autocomplete, Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

// /* Componente Autocomplete básico de MaterialUI perzonalizado para ser re utilizado en otras partes. */
// /* Props Entrada: 

// - listadoElementos: arreglo de objetos que será lo que se va a recorrer para mostrar los items del select.

// - label: Este será el label que tendrá el Select a la hora de cargarse la primera vez.

// - keyListadoElementos: atributo del arreglo de objetos que funcionará como key o id para recorrerlo y mostrar las opciones.

// - mostrarElemento: Esta es una funcion que retorna lo que será mostrado del objeto en cada elemento seleccionable.
//     Se escribe como funcion en caso de querer mostrar mas de un atributo del objeto. 
//     Por ejemplo, si se quiere mostrar el nombre y apellido de cada persona, se podría escribir la prop como:

//     mostrarElemento={(option) => `${option["nombres"]} ${option["apellidos"]}`}

//     Donde 'option' representa un objeto del arreglo de objetos importado en la prop 'listadoElementos'


//     En caso de solo querer mostrar solo un atributo, como los nombres por ejemplo, sería de esta manera.
//     mostrarElemento={(option) => option["nombres"]}


// - onSelectionChange: Función para manejar la lógica cuando cambia la selección.

// - elementoSeleccionado (Opcional) = Este es el estado donde se guardará el objeto que representa el elemento seleccionado en el select del autocomplete.
      //Esto es necesario solo en caso de que se guarde el elemento seleccionado en un estado desde componente padre, en caso de utilizar formik este atributo no se debe usar.









// Finalmente, aquí se tiene un ejemplo de como utilizar este componente desde un componente Padre en los 2 casos hipotéticos:

// export const elementoPadreEjemplo = () => {
//   const usuariosNombreRut = [
//   {
//     "idPersona": 0,
//     "nombreRut": "Pedro Perez/17.786.044-1",
//     "rut": "17.786.044-1",
//     "pasaporte": null
//   },
//   {
//     "idPersona": 1,
//     "nombreRut": "Desarrollo Automatismos/11.111.111-1",
//     "rut": "11.111.111-1",
//     "pasaporte": "17398509O"
//   }
// ];



    //Caso 1: Gestion de la informacion con formik.
    //En caso de utilizar un formik para gestionar la información, se hace de esta manera.
    
    // const formik = useFormik({
    //         initialValues: {
    //           idPersona: -1, 
    //           textoPersona: null,
    //         },
    //         validate: (values) => {
    //           /* resto del codigo */
    //         },
    //         onSubmit: (values) => {
    //           formik.resetForm();
    //         },
    // });
    // return (
    //           <Box sx={{ width: "85%" }}>
    //             <AutocompleteSimple
    //               listadoElementos={usuariosNombreRut}
    //               label="Seleccionar persona"
    //               onSelectionChange={(newValue) => {
    //                 formik.setFieldValue('idPersona', newValue ? newValue.idPersona : '');
    //                 formik.setFieldValue('textoPersona', newValue ? newValue.nombreRut : '');
    //               }}
    //               keyListadoElementos="idPersona"
    //               mostrarElemento={(option) => option["nombreRut"] || ''}
    //             />
    //           </Box>
    //         )




    //Caso 2: Gestion de la información desde un estado en componente padre.
    //Aqui la idea es guardar el objeto seleccionado en un estado desde el componente padre.
    
    // const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null);
    // return (
    //       <Box sx={{ width: "85%" }}>
    //         <AutocompleteSimple
    //           listadoElementos={usuariosNombreRut}
    //           label="Seleccionar persona"
    //           onSelectionChange={(newValue) => {
    //             setUsuarioSeleccionado(newValue);
    //           }}
    //               elementoSeleccionado={usuarioSeleccionado}
    //           keyListadoElementos="idPersona"
    //           mostrarElemento={(option) => option["nombreRut"] || ''}
    //         />
    //       </Box>
    //     )
// }

const AutocompleteSimple = ({
  listadoElementos,
  keyListadoElementos,
  label,
  mostrarElemento,
  onSelectionChange, // Función para manejar la lógica cuando cambia la selección
  elementoSeleccionado = null, // Valor inicial externo

  disabled = false,
  freeSolo = false,
  disablePortal = false,
  fullWidth = true,
  tipoInput = 0 // Usamos un número, por ejemplo 0 para el tipo por defecto y 1 para el que no tiene rectangulo envolvente
}) => {
  const [localElementoSeleccionado, setLocalElementoSeleccionado] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const seleccionado = elementoSeleccionado || localElementoSeleccionado;

    if (seleccionado && typeof seleccionado === 'object') {
      setInputValue(mostrarElemento(seleccionado) || '');
    } else {
      setInputValue('');
    }
  }, [elementoSeleccionado, mostrarElemento, localElementoSeleccionado]);

  const handleChange = (newValue) => {
    // Llamar a la función proporcionada para manejar el cambio
    if (onSelectionChange) {
      onSelectionChange(newValue || null);
    }

    // Actualizar el estado local solo si no se maneja externamente
    if (!elementoSeleccionado) {
      setLocalElementoSeleccionado(newValue || null);
    }
  };

  // Lógica para elegir el renderizado del TextField
  const renderTextField = (params) => {
    // Si tipoInput es 1, mostramos el TextField con sin rectangulo envolvente, solo una linea abajo
    if (tipoInput === 1) {
      return (
        <TextField
          variant="standard"
          InputLabelProps={{ shrink: true }}
          {...params}
          label={label} // Usamos el label proporcionado como prop
        />
      );
    }
    // Si tipoInput es 0, mostramos el TextField por defecto
    return <TextField {...params} label={label} />;
  }

  return (
    <Autocomplete
      disabled={disabled}
      freeSolo={freeSolo}
      disablePortal={disablePortal}
      fullWidth = {fullWidth}

      value={elementoSeleccionado || localElementoSeleccionado}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        handleChange(newValue);
        // console.log("OnChange, event es: ", event, "newValue: ", newValue);
      }}
      onInputChange={(event, newValue) => {
        setInputValue(newValue);
        // console.log("onInputChange, event es: ", event, "y newValue: ", newValue);
      }}
      options={listadoElementos}
      getOptionKey={(option) => `${option[keyListadoElementos]}`}
      getOptionLabel={(option) => mostrarElemento(option) || ''}
      renderInput={renderTextField}
      ListboxProps={{
        sx: {
          '&::-webkit-scrollbar': {
            width: '8.024px !important',
            background: 'transparent !important',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent !important',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#C9C9C9',
            borderRadius: '100px',
          },
        },
      }}

      ListboxComponent={(props) => (
        <Box
            {...props}
            sx={{
            maxHeight: '200px !important',
            overflowY: 'auto',
            }}
        />
      )}
    />
  );
};

export default AutocompleteSimple;
