import { Box, IconButton, Modal} from "@mui/material"
import { IconoCerrarModalInvitados} from "./InvitarIcons";
import "./Invitar.css";
import { TablaInvitadosEvento } from "../../Tablas/ModeloTablaInvitados/ModeloTablaInvitados";


const style = {
    display: "flex",
    width: "1470px",
    height: "700px",
    position: 'absolute',
    flexDirection: "column",
    justifyContent: "center",
    aligItems: 'center',
    top: '50%',
    left: '50%',    
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)",
  };

export const ModalInvitar = ({ openInvitacion, setOpenInvitacion, idVisita}) => {
    
    const handleClose = () =>{
        setOpenInvitacion(false);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.stopPropagation();
        }
    };

    return(
        <Modal id="ModalEnviarInvitaciones" open={openInvitacion} onClose={handleClose} onKeyDown={handleKeyDown}>
            <Box id="BoxDelModalEnviarInvitacion" sx={{...style}}>
                <Box id="BoxBotonCierreModalInvitados">
                    <IconButton onClick={handleClose} id="IconoBotonCierreInvitaciones">
                        <IconoCerrarModalInvitados id="IconoCerrarModalInvitaciones" />
                    </IconButton>
                </Box>
                <TablaInvitadosEvento 
                idVisita={idVisita}
                /> 
            </Box>
        </Modal>
    );
   
}