// Función que filtra los actuadores disponibles según las IDs proporcionadas
export const filtrarActuadoresDisponibles = (actuadores, ids) => {
    // Si el arreglo de IDs está vacío, se retorna el arreglo completo
    if (ids.length === 0) {
        return actuadores;
    }

    // Filtramos el arreglo según las IDs proporcionadas
    return actuadores.filter(actuador => ids.includes(actuador.idTipoActor));
};

  // Función que filtra los usuarios permitidos para notificar según los IDs proporcionados
export const filtrarUsuariosPermitidosParaNotificar = (usuarios, idsTiposUsuariosPermitidos) => {
    let usuariosPermitidos = [];

    // Iterar sobre cada tipo de usuario permitido
    idsTiposUsuariosPermitidos.forEach(idTipoUsuarioPermitido => {
      // Filtrar todos los usuarios que coinciden con el idTipoUsuarioPermitido
      const usuariosFiltrados = usuarios.filter(usuario => usuario.idTipoPersona === idTipoUsuarioPermitido);
      
      // Si hay usuarios que coinciden, agregarlos al array usuariosPermitidos
      usuariosPermitidos = [...usuariosPermitidos, ...usuariosFiltrados];
    });
  
    return usuariosPermitidos;
  };