import axios from "axios";
import { useEffect, useState } from "react"
import { BuscarPatentePersonaAsignacion } from "../../Service/API/Api";


export default function usePatentesUsuarioAsignaciones(idPersona){
    const [patentesActivas, setPatentesActivas] = useState([]);

    useEffect(() => {
        axios.get(BuscarPatentePersonaAsignacion + idPersona)
        .then((response) => {
            var lista = []
            var el0 = {
                idPatente: -1,
                patente: "Patentes",
                estadoPatente: true
            }
            lista.push(el0);
            response.data.forEach(element => {
                if(element.estadoPatente === true){
                    var data = {
                        idPatente:element.idPatente,
                        patente:element.patente,
                        estadoPatente: element.estadoPatente
                    }
                    lista.push(data)
                }
            });
            setPatentesActivas(lista)
        })
        .catch((error) =>{
            console.error("Error al obtener la información de patentes activas")
        });
    }, [idPersona])
    
    

    return{
        patentesActivas
    }
}