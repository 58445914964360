import axios from "axios";
import { useState } from "react";
import { EliminarHuella } from "../../Service/API/Api";

export default function useHuellas(){
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();

    const CrearNueva = (values, idPersona) =>{
        return new Promise((resolve, reject) => {
            // const nuevaHuella = {
            //     idDedo: values.dedo,
            //     idActor: values.lector,
            //     idPersona: idPersona
            // }
            setTimeout(() => {
                resolve({idHuella:1, registro: 1, codigo:"AJKDASDADASDKKDK"});
                setLoading(false);
                setResponse(201);//response.status
            }, 5000);
            // axios.post("CrearHuellaEnDispo", nuevaHuella)
            // .then(
            //     (response) => {
            //         resolve(response.data);
            //         setLoading(false);
            //         setResponse(response.status);
            //     }
            // )
            // .catch(
            //     (error) => {
            //         reject(error.response);
            //         setLoading(false);
            //         setResponse(response.status);
            //     }
            // )
        }) 
    }

    const EliminarRegistro = (idHuella) =>{
        return new Promise((resolve, reject) => {
           
            axios.delete(EliminarHuella + idHuella)
            .then(
                (response) => {
                    resolve(true);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
        }) 
    }

    return{
        loading,
        setLoading,
        response,
        CrearNueva,
        EliminarRegistro
    }
}