import axios from 'axios';
import { useEffect, useState } from 'react';
import { MisCamaras } from '../../Service/API/Api';

function useGestionCamaras ()  {
    const [data, setData] = useState([])

    useEffect(() => {
        axios.get(MisCamaras)
            .then((response)=>{
                setData(response.data);

            })
            .catch( 
                (error)=> {
                    console.error("Error al obtener información de cámaras")
                }
            )
      }, [])

    return {
        data
    };

}

export default useGestionCamaras;
