import axios from "axios";
import { useEffect, useState } from "react";
import { ActivarAccesoAPPMovil, BuscarUsuario, BuscarUsuariosEmpresa, CargaMasivaUsuarios, CrearAdmin, CrearSA, CrearUsuario, DesactivarAccesoAPPMovil, DesactivarUsuario, EditarUsuarioTabla, ExportarUsuariosEmpresa, ReactivarUsuario, ReporteUsuariosFormato } from "../../Service/API/Api";


function useUsuarios()
{
    const [loading, setLoading] = useState(true);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [requestResponse, setRequestResponse] = useState();
    const [usuarios, setUsuarios] = useState([]);
    const [usuario, setUsuario] = useState({});
    const [error, setError] = useState();  
    
    useEffect(() => {
        async function Obtener() {
            setLoading(true);
            setError(null); // Limpiar el error antes de la nueva solicitud

            try {
                const response = await axios.get(BuscarUsuariosEmpresa);
                setUsuarios(response.data);
                setLoading(false);
            } catch (error) {
                if (error.response) {
                    // Error en la respuesta del servidor (404, 500, etc.)
                    console.error("Error en la respuesta del servidor:", error.response.data);
                    setError("Error en la respuesta del servidor.");
                } else if (error.request) {
                    // No se recibió respuesta del servidor
                    console.error("No se pudo conectar con el servidor.");
                    setError("No se pudo conectar con el servidor.");
                } else {
                    // Error en la configuración de la solicitud
                    console.error("Error desconocido:", error.message);
                    setError("Error desconocido.");
                }
                setLoading(false);
            }
        }

        Obtener();
    }, []);

    

    const Actualizar = () =>{
        setLoading(true);
    }

    async function DescargarArchivoUsuarios(){
        return new Promise((resolve, reject) => {
            axios.request({
                method: "GET",
                url: ExportarUsuariosEmpresa,
                responseType: "blob",
            })
            .then((response) => {
                let filename = "";
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
                var disposition = response.headers['content-disposition']
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);                    
                }
                else
                {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);
    
                    if (filename) {
                        var a = document.createElement("a");
                        if (typeof a.download === 'undefined') {
                            window.location.href = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location.href = downloadUrl;
                    }
                    resolve(true);
                    setRequestResponse(response.status)
                    setLoading(false);
                    setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); 
                }
            })
            .catch (
                (error) =>{

                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setRequestResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setRequestResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setRequestResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            );
        })
    }

    async function ImportarArchivoExcelUsuarios(idEmpresa, archivo) {
        const formData = new FormData();
        formData.append('usuarios', archivo);
        formData.append('idEmpresa', idEmpresa);
    
        try {
            const response = await axios.post(CargaMasivaUsuarios, formData);
            setRequestResponse(response.status);
            setLoading(false);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async function DescargarFormatoIdealUsuarios(){
        return new Promise((resolve, reject) => {
            axios.request({
                method: "GET",
                url: ReporteUsuariosFormato,
                responseType: "blob",
            })
            .then((response) => {
                let filename = "";
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
                var disposition = response.headers['content-disposition']
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);                    
                }
                else
                {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);
    
                    if (filename) {
                        var a = document.createElement("a");
                        if (typeof a.download === 'undefined') {
                            window.location.href = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location.href = downloadUrl;
                    }
                    resolve(true);
                    setRequestResponse(response.status)
                    setLoading(false);
                    setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); 
                }
            })
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setRequestResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setRequestResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setRequestResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            );
        })
    }

    async function ActivarDesactivarUsuario (id, value){
        return new Promise(async (resolve, reject) => {
            await axios.patch(value ? DesactivarUsuario + id : ReactivarUsuario + id)
            .then(
                (response) =>{
                    resolve(true);
                }
               
            )
            .catch (
                (error) =>{
                    reject(false);
                }
            );
            
        });
    }

    function ActivarDesactivarAccesoAPPMovil (id, estadoActual){
        return new Promise(async (resolve, reject) => {
            await axios.patch(estadoActual ? DesactivarAccesoAPPMovil + id : ActivarAccesoAPPMovil + id)
            .then(
                (response) =>{
                    resolve(true);
                }
               
            )
            .catch (
                (error) =>{
                    reject(false);
                }
            );
            
        });
    }

    function CrearNuevo(values, empresa){
        return new Promise(async (resolve, reject) => {
            let nuevo = {
                "rut": values.rut === null || values.rut === "" ? null : values.rut,
                "pasaporte": values.pasaporte === null || values.pasaporte === "" ? null : values.pasaporte,
                "nombres": values.nombres,
                "apellidos": values.apellidos,
                "telefono": values.telefono,
                "correo": values.correo,
                "idTipoPersona": values.idTipoPersona,
                "idEmpresa": empresa
            }
            if(values.idTipoPersona > 2 ){
                await axios.post(CrearUsuario, nuevo)
                .then(
                    (response) =>{
                        setRequestResponse(response.status);
                        setLoadingRequest(false);
                        resolve(response.data);
                    }
                   
                )
                .catch (
                    (error) =>{

                        // Asegurarse de que error.response existe antes de acceder a error.response.status
                        if (error.response) {
                            setRequestResponse(error.response.status); // Código de estado si el servidor respondió
                        } else if (error.request) {
                            // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                            setRequestResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                        } else {
                            // Otro error que no está relacionado con la respuesta ni la solicitud
                            setRequestResponse("Error desconocido. Contáctanos para más información.");
                        }
                        reject(error);
                        setLoadingRequest(false);
                    }
                );
            }
            else
            {///este esamos probando
                if(values.idTipoPersona === 1){
                    await axios.post(CrearSA, nuevo)
                    .then(
                        (response) =>{
                            setRequestResponse(response.status);
                            setLoadingRequest(false); 
                            resolve(response.data);            
                        }
                    )
                    .catch (
                        (error) =>{
                            // Asegurarse de que error.response existe antes de acceder a error.response.status
                            if (error.response) {
                                setRequestResponse(error.response.status); // Código de estado si el servidor respondió
                            } else if (error.request) {
                                // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                                setRequestResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                            } else {
                                // Otro error que no está relacionado con la respuesta ni la solicitud
                                setRequestResponse("Error desconocido. Contáctanos para más información.");
                            }
                            reject(false);
                            setLoadingRequest(false);
                        }
                    );
                }
                if(values.idTipoPersona === 2){
                    await axios.post(CrearAdmin, nuevo)
                    .then(
                        (response) =>{
                            resolve(response.data);
                            setRequestResponse(response.status);
                            setLoadingRequest(false);
                        }
                    )
                    .catch (
                        (error) =>{
                            // Asegurarse de que error.response existe antes de acceder a error.response.status
                            if (error.response) {
                                setRequestResponse(error.response.status); // Código de estado si el servidor respondió
                            } else if (error.request) {
                                // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                                setRequestResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                            } else {
                                // Otro error que no está relacionado con la respuesta ni la solicitud
                                setRequestResponse("Error desconocido. Contáctanos para más información.");
                            }
                            reject(false);
                            setLoadingRequest(false);
                        }
                    );
                }
            }
        });
    }

    function EditarExistente(values, idPersona){
        return new Promise(async (resolve, reject) => {
            let toEdit = {
                "idPersona": idPersona,
                "rut": values.rut,
                "pasaporte": values.pasaporte,
                "nombres": values.nombres,
                "apellidos": values.apellidos,
                "telefono": values.telefono,
                "idTipoUsuario": values.idTipoPersona,
            }
            await axios.put(EditarUsuarioTabla, toEdit )
            .then(
                (response) =>{
                    resolve(true);
                    setRequestResponse(response.status);
                    setLoadingRequest(false);
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setRequestResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setRequestResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setRequestResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoadingRequest(false);
                }
            );
            
        });
    }

    async function BuscarUsuarioExistente(idPersona){
        await axios.get(BuscarUsuario + idPersona )
            .then(
                (response) =>{
                    setUsuario(response.data)
                }
            )
            .catch (
                (error) =>{
                    console.error("falló la busqueda");
                }
        );
    }

    



    return {
        usuarios,
        setLoading,
        setUsuarios,
        error,
        loading,
        Actualizar,
        ActivarDesactivarUsuario,
        CrearNuevo,
        setLoadingRequest,
        loadingRequest,
        requestResponse,
        EditarExistente,
        ActivarDesactivarAccesoAPPMovil,
        BuscarUsuarioExistente,
        usuario,
        DescargarArchivoUsuarios,
        DescargarFormatoIdealUsuarios,
        ImportarArchivoExcelUsuarios,
    }
}

export default useUsuarios;