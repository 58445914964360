import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { EventoAgendadoIcon, TresPuntosIcos } from "../../../../Calendario/CalendarioIcons";
import { CerrarModalNuevoUsuario } from "../../../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import { useState } from "react";
import TooltipTipoUno from "../../../../ToolTip/Tooltip";
import useInvitadosEvento from "../../../../../Hooks/Eventos/useInvitadosEvento";
import { ListaInvitados } from "./InvitadosDetalle";

import { MenuOpcionesEvento } from "./MenuOpcionesEvento";
import { MenuTipoUno } from "../../../../Menu/MenuTipoUno";
import { EventoCorto } from "../../../EventoAgenda/EventoEnAgenda";



export const DetalleEventoAgendado = ({eventoData, setOpen}) => {
    const [showInvitados, setShowInvitados] = useState(false);
    const {invitados} = useInvitadosEvento(eventoData.idVisita)
    const [anchorElM, setAnchorElM] = useState(false);
    
    const openM = Boolean(anchorElM)

    const HandleOpenOpciones = (e) => {
        setAnchorElM(e.currentTarget)
    }

    const HandlerClose = () => {
        setOpen(false)
    }
    

    
    const HandlerOpenInvitados = () => {
        setShowInvitados(!showInvitados)
    }

    return (
    <Box id="BoxLayoutEventoView" height={showInvitados ? "622px": "390px"}>
        <Box id="BoxCabeceraTituloEventoView">
            <Box id="BoxContedorIconosFechaEventoView">
                <Box id="BoxIconoCalendario">
                    <EventoAgendadoIcon />
                </Box>
                <Box id="BoxIconoFechaActualEventoView">
                    <Typography id="TxtFechaFormateadaSinEventosView">
                        {
                            "Evento agendado"
                        }
                    </Typography>
                </Box>
                <Box id="BoxIconosAccionEventoView">
                    <Box>
                        <IconButton onClick={(e) => HandleOpenOpciones(e)}>
                            <TresPuntosIcos id="IconButtonNoEventosView" />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton onClick={HandlerClose}>
                            <CerrarModalNuevoUsuario id="IconButtonNoEventosView" />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Box>
        <Divider orientation="horizontal" flexItem />
        <Box id="BoxContenidoEventoView" height={showInvitados ? "535px":"303px"}>
            <Box id="BoxContenidoEventoDetalle" >
                <EventoCorto
                eventoData={eventoData}
                />
                <Box id="BoxContenidoDetalleCreadorEvento">
                    <Box id="BoxDetalleCreacionInvitacionEventosView">
                        <Box id="BoxInformacionCreacionEventoView">
                            <Box id="BoxTxtTituloCreacionEventoView">
                                <Typography id="TxtTituloCreacionEventoView">
                                    {
                                        "Agendo:"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTxtCreadorEventoView">
                                <TooltipTipoUno titulo={eventoData.nombres}>
                                    <Typography id="TxtCreadorEventoView">
                                        {
                                            eventoData.nombres
                                        }
                                    </Typography>
                                </TooltipTipoUno>                                
                            </Box>
                        </Box>
                        <Box id="BoxInformacionCreacionEventoView">
                            <Box id="BoxTxtTituloCreacionEventoView">
                                <Typography id="TxtTituloCreacionEventoView">
                                    {
                                        "Rut:"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTxtCreadorEventoView">
                                <TooltipTipoUno titulo={eventoData.rut ?? "No informado"}>
                                    <Typography id="TxtCreadorEventoView">
                                        {
                                            eventoData.rut ?? "-"
                                        }
                                    </Typography>
                                </TooltipTipoUno>                                
                            </Box>
                        </Box>
                        <Box id="BoxInformacionCreacionEventoView">
                            <Box id="BoxTxtTituloCreacionEventoView">
                                <Typography id="TxtTituloCreacionEventoView">
                                    {
                                        "Pasaporte:"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTxtCreadorEventoView">
                                <TooltipTipoUno titulo={eventoData.Pasaporte ?? "No informado"}>
                                    <Typography id="TxtCreadorEventoView">
                                        {
                                            eventoData.Pasaporte ?? "-"
                                        }
                                    </Typography>
                                </TooltipTipoUno>                                
                            </Box>
                        </Box>
                        <Box id="BoxInformacionCreacionEventoView">
                            <Box id="BoxTxtTituloCreacionEventoView">
                                <Typography id="TxtTituloCreacionEventoView">
                                    {
                                        "Invitados:"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTxtCreadorEventoView">
                                <TooltipTipoUno titulo={invitados.length === 0 ? "sin invitado": null}>
                                    <Typography id="TxtCreadorEventoView">
                                        {
                                            invitados.length
                                        }
                                    </Typography>
                                </TooltipTipoUno>                                
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxBtnMostrarInvitados">
                    <Button onClick={HandlerOpenInvitados} id="BtnVerInvitadosEventosView">
                        <Typography id="TxtVerInvitadosEventosView">
                            {
                                "Ver invitados"
                            }
                        </Typography>
                    </Button>
                </Box>
            </Box>
            {
                showInvitados ?
                <Box id="BoxContenidoInvitados" >
                    <Box id="BoxCabeceraListaInvitados">
                        <Box id="BoxCabeceraNombreInvitado">
                            <Typography id="LblTituloNombreInvitado">
                                {
                                    "Nombre invitado"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxCabeceraIdentificadorInvitado">
                            <Typography id="LblTituloIdentificadorInvitado">
                                {
                                    "Rut/Pasaporte"
                                }
                            </Typography>
                        </Box>
                    </Box>
                    {
                        invitados.length === 0 ? 
                        <Typography id="TxtNombreApellidoInvitadoEventoView">
                            {
                                "Sin invitados registrados"
                            }
                        </Typography>
                        :
                        <ListaInvitados listaInvitados={invitados} />
                    }
                </Box>
                :
                null
            }
        </Box>
        <MenuTipoUno
        open={openM}
        setOpen={setAnchorElM}
        anchorEl={anchorElM} 
        children={
        <MenuOpcionesEvento 
            idVisita={eventoData.idVisita} 
            estadoEvento={eventoData.estado}
            dataEvento={eventoData} />} />
    </Box>
    );
}