import { DataGrid } from "@mui/x-data-grid";

const DataGridMui = ({
    id = "",
    style = { width:'100%', height:"100%"},
    sx = {
            '& .MuiDataGrid-columnHeaderTitle': {
            color:'#175676',
        },
            '& .MuiDataGrid-columnHeader': {
            borderBottom: '2px solid #94B1C0',
        },
        '& .MuiDataGrid-row':{
            borderBottom:'1px solid #94B1C0'
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            width: "7.024px",
            background: "transparent"
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
            background: "transparent"
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
            background: "#C9C9C9",
            borderRadius: "100px"
        }
    },
    getRowId,
    columns,
    rows,
    initialState = {
        pagination: {
            paginationModel: {
            page: 0,
            pageSize: 10,
            },
        },
    },
    checkboxSelection = false,
    disableColumnFilter = true,
    disableColumnMenu = true,
    disableColumnSelector = true,
    disableDensitySelector = true,
    disableVirtualization = true,
    showCellVerticalBorder = false,
    showColumnVerticalBorder = false,
    hideFooterSelectedRowCount = true,
    processRowUpdate = null,
    rowSelection = true,
    loading = null,
    slotProps={
        pagination: {
            labelRowsPerPage: "Filas por página",
        }
    },
    disableRowSelectionOnClick = true,
    pageSizeOptions = [10, 20],
}) => {
  return (
    <DataGrid id={id}
    style={style}
    sx={sx}
    getRowId={getRowId}
    columns={columns} 
    rows={rows}
    initialState={initialState}
    checkboxSelection={checkboxSelection}
    disableColumnFilter={disableColumnFilter}
    disableColumnMenu={disableColumnMenu}
    disableColumnSelector={disableColumnSelector}
    disableDensitySelector={disableDensitySelector}
    disableVirtualization={disableVirtualization}
    showCellVerticalBorder={showCellVerticalBorder}
    showColumnVerticalBorder={showColumnVerticalBorder}
    hideFooterSelectedRowCount={hideFooterSelectedRowCount}
    rowSelection = {rowSelection}
    loading={loading}
    slotProps={slotProps}
    disableRowSelectionOnClick = {disableRowSelectionOnClick}
    pageSizeOptions={pageSizeOptions}
    processRowUpdate={processRowUpdate}
    />
  )
}

export default DataGridMui;