import axios from "axios";
import dayjs from "dayjs";
const { useState } = require("react");
const { CrearVisita, EditarVisita } = require("../../Service/API/Api");

function useNuevoEvento(){
    const [isLoading, setIsLoading] = useState();
    const [respuesta, setRespuesta] = useState([]);
    const [response, setResponse] = useState();
    
    const GuardarEventoNuevo = async (data, usuario) =>{
        return new Promise(async (resolve, reject) => {
            const tipoEvento = data.idTipoEvento;
            const fechaUno = dayjs(data.fechaUno).format('MM/DD/YYYY');
            const fechaDos = data.fechaDos.format('MM/DD/YYYY');
            const desde =  dayjs(data.desde).format("HH:mm");
            const hasta = dayjs(data.hasta).format("HH:mm");
            const nombre = data.nombreEvento;
            const idPersona = usuario;
            const idTipoVisita = tipoEvento;
            const idUbicacion = data.idDestino;
            const diasRecurrencia = [];
            
            if(data.lunes === true){
                diasRecurrencia.push(0)
            }
            if(data.martes === true){
                diasRecurrencia.push(1)
            }
            if(data.miercoles === true){
                diasRecurrencia.push(2)
            }
            if(data.jueves === true){
                diasRecurrencia.push(3)
            }
            if(data.viernes === true){
                diasRecurrencia.push(4)
            }
            if(data.sabado === true){
                diasRecurrencia.push(5)
            }
            if(data.domingo === true){
                diasRecurrencia.push(6)
            }
    
            const visita = { 
                fechaVisita: null, 
                fechaTermino: null,  
                horaInicio: null, 
                horaTermino: null,  
                nombreEvento: null,   
                idPersona: null, 
                idTipoVisita: null,  
                idUbicacion: null,  
                diasRecurrencia: null
            };
    
            if(tipoEvento === 0) 
            {
                visita.fechaVisita = fechaUno;
                visita.fechaTermino = fechaUno;
                visita.horaInicio = desde;
                visita.horaTermino = hasta;
                visita.nombreEvento = nombre;
                visita.idPersona = idPersona;
                visita.idTipoVisita = idTipoVisita;
                visita.idUbicacion = idUbicacion;
                visita.diasRecurrencia = null;
               
            }
            if(tipoEvento === 1) 
            {
                visita.fechaVisita = fechaUno;
                visita.fechaTermino = fechaDos;
                visita.horaInicio = desde;
                visita.horaTermino = hasta;
                visita.nombreEvento = nombre;
                visita.idPersona = idPersona;
                visita.idTipoVisita = idTipoVisita;
                visita.idUbicacion = idUbicacion;
                visita.diasRecurrencia = null;
    
            }
            if(tipoEvento === 2) 
            {
                visita.fechaVisita = fechaUno;
                visita.fechaTermino = fechaDos;
                visita.horaInicio = desde;
                visita.horaTermino = hasta;
                visita.nombreEvento = nombre;
                visita.idPersona = idPersona;
                visita.idTipoVisita = idTipoVisita;
                visita.idUbicacion = idUbicacion;
                visita.diasRecurrencia = diasRecurrencia;
    
            }
    
            await axios.post(CrearVisita, visita)
            .then(
                (response) => {
                    resolve(response.data)
                    setRespuesta(response.status)
                    setIsLoading(false);
                }
            ).catch((error) => {
                // Asegurarse de que error.response existe antes de acceder a error.response.status
                if (error.response) {
                    setRespuesta(error.response.status); // Código de estado si el servidor respondió
                } else if (error.request) {
                    // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                    setRespuesta("Error de red o servidor inaccesible. Contáctanos para más información.");
                } else {
                    // Otro error que no está relacionado con la respuesta ni la solicitud
                    setRespuesta("Error desconocido. Contáctanos para más información.");
                }
                reject(false);
                setIsLoading(false);
            });
        });
    }

    const EditarEvento = async (data, usuario, idVisita) =>{
        return new Promise(async (resolve, reject) => {
            const tipoEvento = data.idTipoEvento;
            const fechaUno = dayjs(data.fechaUno).format('MM/DD/YYYY');
            const fechaDos = data.fechaDos.format('MM/DD/YYYY');
            const desde =  dayjs(data.desde).format("HH:mm");
            const hasta = dayjs(data.hasta).format("HH:mm");
            const nombre = data.nombreEvento;
            const idPersona = usuario;
            const idTipoVisita = tipoEvento;
            const idUbicacion = data.idDestino;
            const diasRecurrencia = [];
            
            if(data.lunes === true){
                diasRecurrencia.push(0)
            }
            if(data.martes === true){
                diasRecurrencia.push(1)
            }
            if(data.miercoles === true){
                diasRecurrencia.push(2)
            }
            if(data.jueves === true){
                diasRecurrencia.push(3)
            }
            if(data.viernes === true){
                diasRecurrencia.push(4)
            }
            if(data.sabado === true){
                diasRecurrencia.push(5)
            }
            if(data.domingo === true){
                diasRecurrencia.push(6)
            }
    
            const visita = { 
                idVisita: idVisita,
                fechaVisita: null, 
                fechaTermino: null,  
                horaInicio: null, 
                horaTermino: null,  
                nombreEvento: null,   
                idPersona: null, 
                idTipoVisita: null,  
                idUbicacion: null,  
                diasRecurrencia: null
            };
    
            if(tipoEvento === 0) 
            {
                visita.fechaVisita = fechaUno;
                visita.fechaTermino = fechaUno;
                visita.horaInicio = desde;
                visita.horaTermino = hasta;
                visita.nombreEvento = nombre;
                visita.idPersona = idPersona;
                visita.idTipoVisita = idTipoVisita;
                visita.idUbicacion = idUbicacion;
                visita.diasRecurrencia = null;
               
            }
            if(tipoEvento === 1) 
            {
                visita.fechaVisita = fechaUno;
                visita.fechaTermino = fechaDos;
                visita.horaInicio = desde;
                visita.horaTermino = hasta;
                visita.nombreEvento = nombre;
                visita.idPersona = idPersona;
                visita.idTipoVisita = idTipoVisita;
                visita.idUbicacion = idUbicacion;
                visita.diasRecurrencia = null;
    
            }
            if(tipoEvento === 2) 
            {
                visita.fechaVisita = fechaUno;
                visita.fechaTermino = fechaDos;
                visita.horaInicio = desde;
                visita.horaTermino = hasta;
                visita.nombreEvento = nombre;
                visita.idPersona = idPersona;
                visita.idTipoVisita = idTipoVisita;
                visita.idUbicacion = idUbicacion;
                visita.diasRecurrencia = diasRecurrencia;
    
            }
    
            await axios.put(EditarVisita, visita)
            .then(
                (response) => {
                    resolve(true)
                    setIsLoading(false);
                    setResponse(response.status)
                }
            ).catch(
                (error) => {

                // Asegurarse de que error.response existe antes de acceder a error.response.status
                if (error.response) {
                    setResponse(error.response.status); // Código de estado si el servidor respondió
                } else if (error.request) {
                    // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                    setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                } else {
                    // Otro error que no está relacionado con la respuesta ni la solicitud
                    setResponse("Error desconocido. Contáctanos para más información.");
                }
                reject(false);
                setIsLoading(false);
            }
        );
        });
    }


    return {
        isLoading,
        setIsLoading,
        GuardarEventoNuevo,
        respuesta,
        EditarEvento,
        response

    }
}

export default useNuevoEvento;