import axios from "axios";
import { useState } from "react";
import { CrearPase, DesactivarPase, EditarPase, EliminarPase, ReActivarPase } from "../../Service/API/Api";

export default function usePase(){
    const [loading, setLoading] = useState(null);
    const [response, setResponse] = useState(null);


    function CrearNuevo(metodo, persona, patente, tarjeta){
        return new Promise(async (resolve, reject) => {
            let createPaseTag = {
                "idPersona": persona,
                "idTarjeta": tarjeta,
                "idPatente": patente
            }

            let createPaseRfid = {
                "idPersona": persona,
                "idTarjeta": tarjeta,
                "idPatente": null
            }

            await axios.post(CrearPase, metodo === 1 ? createPaseRfid :  createPaseTag )
            .then(
                (response) =>{
                    resolve(response.data);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }

    function DesactivarActivarPase(metodo, idPase){
        return new Promise(async (resolve, reject) => {
            await axios.patch(metodo === true ? DesactivarPase + idPase : ReActivarPase + idPase )
            .then(
                (response) =>{
                    resolve(true);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                }
            );
            
        });
    }

    const EliminacionDelPase = (idPase) => {
        return new Promise(async (resolve, reject) => {
            await axios.delete(EliminarPase + idPase )
            .then(
                (response) =>{
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false)
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }

    //completar actualizacion
    const ActualizarPase = (idPase, idPersona, idPatente, idTarjeta) => {
        return new Promise(async (resolve, reject) => {
            var toEdit = {
                "idPase": idPase,
                "idPersona": idPersona,
                "idTarjeta": idTarjeta,
                "idPatente": idPatente === "" ? null : idPatente
              }
            await axios.put(EditarPase, toEdit)
            .then(
                (response) =>{
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false)
                }
            )
            .catch (
                (error) =>{
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
        
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }


    return {
        loading, 
        setLoading,
        CrearNuevo,
        response,
        DesactivarActivarPase,
        EliminacionDelPase,
        ActualizarPase
    }
}