import { useState } from "react";
import { SwitchTablas } from "../../../../Switch/switch";
import "./Horario.css";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { IconoAlerta, IconoEditarUsuario } from "../../../../Usuarios/UsuariosIcon";
import useAsignaciones from "../../../../../Hooks/Asignaciones/useAsignaciones";
import { Box } from "@mui/material";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import { SnackBarTipoUno } from "../../../../snackBar/SnackBar";
import dayjs from "dayjs";
import DataGridMui from "../../../../DataGrid/DataGridMui";

export const HorariosTablaGU = ({horarios, setRows, editar, setAsignacionToEdit}) =>{
    const {DeshabilitarActivarAsignacion} = useAsignaciones();
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [isTrue, setIsTrue] = useState();
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    
    const HandleEstado = (id) => async () => {
        const rowToEdit = horarios.find((row) => row.idAsignacion === id);
        setIsTrue(rowToEdit.estado === true ? true:false);
        let responseConfirm = await confirm();
        if(responseConfirm === true){
            await DeshabilitarActivarAsignacion(id, rowToEdit.estado)
            .then(
                (result) => {
                    rowToEdit.estado = !rowToEdit.estado
                    setRows(horarios.map((row) => (row.idAsignacion === id ? rowToEdit : row)));
                    setSnackbarSeverity("success")
                    setSnackMsg(isTrue ? "Horario Asignado exitosamente!":"Horario desactivado exitosamente!")
                    setSnackbar(true);
                }
                ,
                (error) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al guardar, reintente nuevamente")
                    setSnackbar(true); 
                }
            )
        }
        else
        {
            console.log("rechaza el cambio")
        }
    }
    

    const handleEdit = (id, inicio, termino, idHorario) => async () => {

        const AsignacionToEdit = {
            idAsignacion: id,
            idHorario: idHorario,
            inicio: new Date(inicio),
            termino:  new Date(termino),
        }
        setAsignacionToEdit(AsignacionToEdit);
        editar(true);
    }


    const columnNames = [
        {
            field: 'idHorario', headerName: 'Id', type: 'int', width: 50, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'nombreHorario', headerName: 'Horario', type: 'string', width: 190, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'inicio', headerName: 'Fecha de Inicio', type: 'Date', valueFormatter: params => dayjs(params?.value).format("DD/MM/YYYY 00:00:00"), width: 221, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'termino', headerName: 'Fecha de Termino', type: 'Date', valueFormatter: params => dayjs(params?.value).format("DD/MM/YYYY 23:59:59"), width: 221, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'estado', headerName: 'Estado Horario', type: 'bool', width: 120, headerAlign: 'center', align:'center', sortable: false, editable: false,
            renderCell: ({id, row}) => 
            (
                <SwitchTablas 
                id="switchTablaHorario" 
                value={row.estado}
                disabled={false}
                handler={HandleEstado(id)} 
                />
            )
        },
        {
            field: 'editar', headerName: 'Editar', type: 'actions', width: 90, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id, row}) => {
                return [
                    <GridActionsCellItem
                      icon={<IconoEditarUsuario  id='iconoEnTablaUsuarios'/>}
                      label="Editar"
                      onClick={handleEdit(id, row.inicio, row.termino, row.idHorario)}
                    />,
                  ];
            }
        },

    ];


    return(
        <Box>
            <DataGridMui
                style={{}}
                id="DataGridHorariosGU"
                getRowId={(row) => row.idAsignacion} //reeemplazar luego por el id definido idMetodos y agregar snackbar
                columns={columnNames}
                rows={horarios}
                sx={{
                    width:"894px",
                    height:"424px",
                        '& .MuiDataGrid-columnHeaderTitle': {
                        color:'#175676',
                        },
                            '& .MuiDataGrid-columnHeader': {
                            borderBottom: '2px solid #94B1C0',
                        },
                        '& .MuiDataGrid-row':{
                            borderBottom:'1px solid #94B1C0'
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                            width: "7.024px",
                            background: "transparent"
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                            background: "transparent"
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                            background: "#C9C9C9",
                            borderRadius: "100px"
                        }
                    }}
                checkboxSelection={false}
                disableColumnFilter={true}
                disableColumnMenu={true}
                disableColumnSelector={true}
                disableDensitySelector={true}
                disableVirtualization={true}
                showCellVerticalBorder={false}
                showColumnVerticalBorder={false}
                hideFooterSelectedRowCount={true}
                rowSelection={true}
                disableRowSelectionOnClick={true}
            />

            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />
            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={isTrue ? "¿Desactivar horario?" : "¿Activar horario?"}
            mensaje={
                isTrue ? 
                    "Estás desactivando este horario en el usuario. ¿Deseas continuar?" 
                    : 
                    "Estás activando este horario en el usuario. ¿Deseas continuar?" 
            }
            />
        </Box>
        
    );
}