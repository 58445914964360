import axios from "axios";

const { useState } = require("react");
const { VisitasEmpresaPeriodo, DesactivarVisita } = require("../../Service/API/Api");

function useEventos(){
    const [dataEventos, setDataEventos] = useState([]);
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();


    const ObtenerHE = async () => {
        const date = new Date(Date.now());
        const fecha = date.toISOString();
        const periodo = {
            "fecha": fecha,
        }
        await axios.post(VisitasEmpresaPeriodo, periodo)
        .then((response) => {
            setDataEventos(response.data);
        }).catch((error) => {
            console.log("Error al obtener HE: ", error);
        });
    }

    
    async function DesactivarEvento(id) {
        return new Promise(async (resolve, reject) =>{
            await axios.patch(DesactivarVisita + id)
            .then((response)=>{
                    resolve(true);
                    setResponse(response.status)
                    setLoading(false);
                }
            )
            .catch((error)=>{
                // Asegurarse de que error.response existe antes de acceder a error.response.status
                if (error.response) {
                    setResponse(error.response.status); // Código de estado si el servidor respondió
                } else if (error.request) {
                    // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                    setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                } else {
                    // Otro error que no está relacionado con la respuesta ni la solicitud
                    setResponse("Error desconocido. Contáctanos para más información.");
                }
                reject(false);
                setLoading(false);
                
            })
        })
    }


    return {
        dataEventos,
        ObtenerHE,
        DesactivarEvento,
        loading,
        setLoading,
        response
    }
}

export default useEventos;