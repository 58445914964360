import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import useGetAll from "../../../Hooks/Usuarios/useGetAll";
import usePatentes from "../../../Hooks/Patentes/usePatentes";
import { useState } from "react";
import "./OpcionesPatente.css";
import { useFormik } from "formik";
import { TextFieldTresPuntoDos } from "../../TextField/TextFieldTres";
import { IconoBorrarCampoGU } from "../../Modal/MenuGestionUsuario/MenuGestionUsuarioIcon";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { EsquemaNuevaPatente } from "./ValidationScheme";
import AutocompleteSimple from "../../AutocompleteSimple/AutocompleteSimple";

export const EditarPatenteGestion = ({setOpen, setRows, rows, dataPatente}) => {
    const {loading, response, EditarPatente, setLoading} = usePatentes();
    const [openLoading, setOpenLoading] = useState(false);
    const {listaUsuarios} = useGetAll(); 

    const handlerClose = () => {
        setOpen(false);
    }
    

    const formik = useFormik({
        initialValues: { 
            patente: dataPatente.patenteDigitos, 
            idPersona: dataPatente.idPersona, 
            nombreCompleto: dataPatente.rut === null ? 
                dataPatente.nombreCompleto + "/" + dataPatente.pasaporte 
                :
                dataPatente.nombreCompleto + "/" + dataPatente.rut
            },
        enableReinitialize: true,
        validationSchema: EsquemaNuevaPatente,
        onSubmit: async () => {
            setLoading(true)
            setOpenLoading(true);
            await EditarPatente(formik.values.patente, formik.values.idPersona, dataPatente.idPatente)
            .then(
                (resolve) => {
                    var findedRow = rows.find(p => p.idPatente === dataPatente.idPatente)
                    var usuario = listaUsuarios.find(u => u.idPersona === formik.values.idPersona)
                    findedRow.idPersona =  formik.values.idPersona;
                    findedRow.nombreCompleto = formik.values.nombreCompleto.split("/")[0];
                    findedRow.pasaporte = usuario.pasaporte;
                    findedRow.patenteDigitos = formik.values.patente;
                    findedRow.rut = usuario.rut;
                    setRows(rows.map((row) => (row.idPatente === findedRow.idPatente ? findedRow : row)))
                },
                (reject) => {
                    console.log("fallo la creacion");
                }
            )
        }
    })

     //teoria del filtro, si aumentan mis caracteres, busco en patentes, 
    return(
        <Box id="BoxAgregarNuevaPatente">
            <Box id="BoxTituloSubtituloOpcionNuevaPatente">
                <Box id="BoxTituloOpcionNuevaPatente">
                    <Typography id="TxtTituloOpcionNuevaPatente">
                        {
                            "Editar patente"
                        }
                    </Typography>
                </Box>
                <Box id="BoxSubTituloOpcionNuevaPatente">
                    <Typography id="TxtSubTituloOpcionNuevaPatente">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxFormularioOpcionNuevaPatente" >
                <Box id="BoxContenidoFormularioNuevaPatente" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxInformacionPatenteNuevaPatente">
                        <Box id="BoxTituloInformacionPatenteNuevaPatente">
                            <Typography id="TxtTitulosFormulariosNuevaPatente">
                                {
                                    "Inf. de la Patente"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxTextFieldFormularioNuevaPatente">
                            <TextFieldTresPuntoDos
                            id="patente"
                            label={"Patente"}
                            placeholder={"Patente"}
                            icon={<IconoBorrarCampoGU />}
                            helper={formik.errors.patente}
                            value={formik.values.patente.toLocaleUpperCase()}
                            name={"patente"}
                            handlerChange={formik.handleChange}
                            handlerClick={() => formik.setFieldValue("patente", "")}
                            disabled={false}
                            />
                        </Box>
                    </Box>
                    <Box id="BoxSegundaInformacionPatenteNuevaPatente">
                        <Box id="BoxTituloSegundaInformacionNuevaPatente">
                            <Typography id="TxtTitulosFormulariosNuevaPatente">
                                {
                                    "Inf. del Usuario"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxTextFieldUsuarioFormularioNuevaPatente">
                            <FormControl fullWidth>
                                <AutocompleteSimple
                                    disablePortal={true}
                                    freeSolo = {true}
                                    listadoElementos={listaUsuarios}
                                    label="Usuarios"
                                    onSelectionChange={(newValue) => {
                                        if(newValue === null)
                                        {
                                            formik.setFieldValue("idPersona", -2)                                           
                                        }
                                        else{
                                            formik.setFieldValue("idPersona", newValue.idPersona)
                                        }
                                    }}
                                    keyListadoElementos="idPersona"
                                    mostrarElemento={(option) => option["nombreRut"] || ''}
                                    elementoSeleccionado={listaUsuarios.find(usuario => formik.values.idPersona === usuario.idPersona) || null}
                                />
                                <FormHelperText>
                                    {
                                        formik.errors.idPersona
                                    }
                                </FormHelperText>                         
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxHandlerFormularioNuevaPatente">
                    <Box id="BoxAccionNuevaPatente">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                    </Box>
                    <Box id="BoxAccionNuevaPatente">
                        <BotonTipo2 textoBoton={"Actualizar"} event={formik.submitForm} />
                            <LoadingMasRespuestaCinco
                                id={"LoadingMasRespuestaDosEditarHorario"}
                                open={openLoading}
                                setOpen={setOpenLoading}
                                msj={
                                "Se está actualizando la patente. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."
                                }
                                tituloLoading={"Actualizando..."}
                                loading={loading}
                                titulo={
                                    response === 200 ? 
                                    "Patente actualizada"
                                    : 
                                    "Patente no actualizada"
                                }
                                icono={
                                    response === 200 ?
                                    <IconoExito id="IconoRespuestaServidor" />
                                    :
                                    <IconoFallo id="IconoRespuestaServidor" />
                                }
                                mensajeRespuesta={
                                    response === 200
                                    ? "La patente ha sido actualizada exitosamente en el sistema."
                                    : response === 403 ?
                                        "No se ha podido generar la solicitud de actualizacion, ya que no cuenta con el perfil requerido, contacte al administrador del sistema"
                                        :
                                        "No se ha podido actualizar la patente debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia"
                                }
                                textoBoton={response === 200 ?
                                    "Aceptar"
                                    :
                                    "Reintentar"
                                }
                            />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}