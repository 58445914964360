import { Box, CardMedia, Typography } from "@mui/material";
import "./ConfirmarAsistencia.css";
import { useFormik } from "formik";
import logocp2 from "./../../../assets/imagenes/CP2.png";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { TextFieldUnoPuntoDos } from "../../TextField/TextFieldUno";
import { ClearIconTextField } from "../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useState } from "react";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import useInvitado from "../../../Hooks/Visitante/useInvitados";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import { SnackBarTipoUno } from "../../snackBar/SnackBar";
import { useNavigate } from "react-router-dom";
import { ValidationSchema } from "./ValidationSchema";

export const ConfirmarAsistencia = ({inforEventoUsuario, infoEvento}) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [openLoading, setOpenLoading] = useState(false);
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [snackMsg, setSnackMsg] = useState({severity: "", message: ""});
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [mensajeConfirm, setMensajeConfim ]= useState({titulo: "", mensaje:""});
    const navigate = useNavigate();
    const {loading, setLoading, ActualizarEstado, ActualizarInformacionInvitado, response} = useInvitado()

    const formik = useFormik({
        initialValues: {
            nombres: inforEventoUsuario.nombres ?? "-", 
            apellidos: inforEventoUsuario.apellidos ?? "-", 
            rut: inforEventoUsuario.rut ?? "-", 
            pasaporte: inforEventoUsuario.pasaporte ?? "-",  
            telefono: inforEventoUsuario.telefono ?? "-", 
            correo: inforEventoUsuario.correo ?? "-", 
            patente: inforEventoUsuario.patente ?? "-", 
        },
        enableReinitialize: true,
        validationSchema: ValidationSchema,
        onSubmit: async () => {
            setLoading(true)
            if(isDisabled === false){
                await ActualizarInformacionInvitado(formik.values, inforEventoUsuario.idVisitante)
                .then(
                    (resolve) => {
                        setSnackMsg({severity:"success", message: "Informacion actualizada exitosamente."})
                        setSnackbar(true);
                        setIsDisabled(true);
                    }
                    ,
                    (reject) => {
                        setSnackMsg({severity:"error", message: "ocurrio un error al rechazar el evento, reintente nuevamente"})
                        setSnackbar(true);
                        setIsDisabled(false);
                    }
                )
            }
        }
    });

    const RechazarInvitacion = async () => {
        setMensajeConfim({ titulo: "Rechazar invitacion", mensaje: "estas rechanzado la invitacion al evento ¿continuar?"})
        var res = await confirm();
        if(res === true){          
            setLoading(true);  
            setOpenLoading(true);
            ActualizarEstado(inforEventoUsuario.idVisitante , "RECHAZADO",  infoEvento)
            .then
                (
                (resolve) => 
                {    
                    setLoading(true);
                    setTimeout(() => {
                        navigate("/Visita/Rechazada", { replace:true });
                    }, 5000);
                }
                ,
                (reject) => 
                {
                    setOpenLoading(false);
                }
            )
        }
        
    }

    const HandleIsDisabled = () => {
        setIsDisabled(false);
    }

    const AceptarInvitacion = async () => {
        setMensajeConfim({ titulo: "Aceptar invitación", mensaje: "Estás aceptando la invitación al evento ¿continuar?"})
        var response = await confirm();
        if(response){
            setLoading(true);
            setOpenLoading(true);
            ActualizarEstado(inforEventoUsuario.idVisitante , "ACEPTADO",  infoEvento)
            .then
            (
                (resolve) => {
                    setLoading(true);
                    setTimeout(() => {
                        navigate("/Visita/Confirmada", { replace:true });
                    }, 3000);
                    }
                ,
                (reject) => {
                    setOpenLoading(false);                   
                }
            )
        }
    }

    return(
        <Box id="BoxContenedorImagenFormInvitado">
            <Box id="BoxImagenCp2FomularioInvitado">
                <CardMedia component={"img"} src={logocp2} id="LogoCp2" />
            </Box>
            <Box id="BoxContenidoFormularioInvitado">
                <Box id="BoxFormularioInvitado">
                    <Box id="BoxTituloSubtituloFormularioInvitado">
                        <Box id="BoxTituloFormularioInvitado">
                            <Typography id="txtTituloFormularioInvitado">
                                {
                                    "Completa tu registro"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxSubtituloFormularioInvitado">
                            <Typography id="txtSubtituloFormularioInvitado">
                                {
                                    "verifica tus datos y confirma tu asistencia al evento"
                                }
                            </Typography>
                        </Box>
                    </Box>
                    <Box id="BoxCuerpoFormularioInvitado" component={"form"} onSubmit={formik.handleSubmit}>
                        <Box id="BoxContenedorTFTipoUno">
                            <TextFieldUnoPuntoDos
                            id={"NombresInvitado"}
                            label={"Nombres"}
                            placeholder={"Nombres"}
                            icon={ isDisabled === true ? <EditRoundedIcon id="IconoLimpiarEnEditarUsuario" /> : <ClearIconTextField id="IconoLimpiarEnEditarUsuario" />}
                            helper={formik.errors.nombres}
                            value={formik.values.nombres}
                            name={"nombres"}
                            handlerChange={formik.handleChange}
                            formHandle={isDisabled ===  true ? HandleIsDisabled:() => {formik.setFieldValue("nombres", "")}}
                            disabled={isDisabled}
                            />
                        </Box>
                        <Box id="BoxContenedorTFTipoUno">
                            <TextFieldUnoPuntoDos
                            id={"ApellidosInvitado"}
                            label={"Apellidos"}
                            placeholder={"Apellidos"}
                            icon={ isDisabled === true ? <EditRoundedIcon id="IconoLimpiarEnEditarUsuario" /> : <ClearIconTextField id="IconoLimpiarEnEditarUsuario" />}
                            helper={formik.errors.apellidos}
                            value={formik.values.apellidos}
                            name={"apellidos"}
                            handlerChange={formik.handleChange}
                            formHandle={isDisabled ===  true ? HandleIsDisabled:() => {formik.setFieldValue("apellidos", "")}}
                            disabled={isDisabled}
                            />
                        </Box>
                        <Box id="BoxContenedorTFTipoUno">
                            <TextFieldUnoPuntoDos
                            id={"RutInvitado"}
                            label={"Rut"}
                            placeholder={"Rut"}
                            icon={ isDisabled === true ? <EditRoundedIcon id="IconoLimpiarEnEditarUsuario" /> : <ClearIconTextField id="IconoLimpiarEnEditarUsuario" />}
                            helper={formik.errors.rut}
                            value={formik.values.rut}
                            name={"rut"}
                            handlerChange={formik.handleChange}
                            formHandle={isDisabled ===  true ? HandleIsDisabled:() => {formik.setFieldValue("rut", "")}}
                            disabled={isDisabled}
                            />
                        </Box>
                        <Box id="BoxContenedorTFTipoUno">
                            <TextFieldUnoPuntoDos
                            id={"PasaporteInvitado"}
                            label={"Pasaporte"}
                            placeholder={"Pasaporte"}
                            icon={ isDisabled === true ? <EditRoundedIcon id="IconoLimpiarEnEditarUsuario" /> : <ClearIconTextField id="IconoLimpiarEnEditarUsuario" />}
                            helper={formik.errors.pasaporte}
                            value={formik.values.pasaporte}
                            name={"pasaporte"}
                            handlerChange={formik.handleChange}
                            formHandle={isDisabled ===  true ? HandleIsDisabled:() => {formik.setFieldValue("pasaporte", "")}}
                            disabled={isDisabled}
                            />
                        </Box>
                        <Box id="BoxContenedorTFTipoUno">
                            <TextFieldUnoPuntoDos
                            id={"TelefonoInvitado"}
                            label={"Telefono"}
                            placeholder={"Telefono"}
                            icon={ isDisabled === true ? <EditRoundedIcon id="IconoLimpiarEnEditarUsuario" /> : <ClearIconTextField id="IconoLimpiarEnEditarUsuario" />}
                            helper={formik.errors.telefono}
                            value={formik.values.telefono}
                            name={"telefono"}
                            handlerChange={formik.handleChange}
                            formHandle={isDisabled ===  true ? HandleIsDisabled:() => {formik.setFieldValue("telefono", "")}}
                            disabled={isDisabled}
                            />
                        </Box>
                        <Box id="BoxContenedorTFTipoUno">
                            <TextFieldUnoPuntoDos
                            id={"CorreoInvitado"}
                            label={"Correo"}
                            placeholder={"Correo"}
                            icon={ isDisabled === true ? <EditRoundedIcon id="IconoLimpiarEnEditarUsuario" /> : <ClearIconTextField id="IconoLimpiarEnEditarUsuario" />}
                            helper={formik.errors.correo}
                            value={formik.values.correo}
                            name={"correo"}
                            handlerChange={formik.handleChange}
                            formHandle={isDisabled ===  true ? HandleIsDisabled:() => {formik.setFieldValue("correo", "")}}
                            disabled={isDisabled}
                            />
                        </Box>
                        <Box id="BoxContenedorTFTipoUno">
                            <TextFieldUnoPuntoDos
                            id={"PatenteInvitado"}
                            label={"Patente (Opcional)"}
                            placeholder={"Patente (Opcional)"}
                            icon={ isDisabled === true ? <EditRoundedIcon id="IconoLimpiarEnEditarUsuario" /> : <ClearIconTextField id="IconoLimpiarEnEditarUsuario" />} 
                            helper={formik.errors.patente}
                            value={formik.values.patente}
                            name={"patente"}
                            handlerChange={formik.handleChange}
                            formHandle={isDisabled ===  true ? HandleIsDisabled:() => {formik.setFieldValue("patente", "")}}
                            disabled={isDisabled}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxBotonesAccionFormularioInvitado">
                    <Box id="BoxBtnFormularioInvitado">
                        <BotonTipo2 textoBoton={"Rechazar"} disabled={false} event={RechazarInvitacion}/>
                    </Box>
                    <Box id="BoxBtnFormularioInvitado">
                        <BotonTipo2 textoBoton={isDisabled ? "Confirmar" :"Actualizar"} event={isDisabled ? AceptarInvitacion:formik.submitForm} disabled={false} />                        
                    </Box>
                </Box>

                <LoadingMasRespuestaCinco
                open={openLoading} 
                setOpen={setOpenLoading} 
                msj={"Estamos guardando tu respuesta. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
                id={"RespuestaGuardarEditarUsuario"} 
                tituloLoading={"Guardando respuesta"}
                loading={loading} 
                respuesta={loading} 
                icono={
                    response === 200 ?
                    <IconoExito id="IconoRespuestaServidor" />
                    :
                    <IconoFallo id="IconoRespuestaServidor" />
                }
                textoBoton={
                    response === 200 ? 
                    "Aceptar"
                    :
                    "Reintentar"
                }
                titulo={response === 200 ? "Registro exitoso":"Error al registrar"}
                mensajeRespuesta={response === 200 ? 
                    "La información del invitado se ha actualizado correctamente, volviendo al inicio..."
                    :
                    "Ha ocurrido un error y no se ha podido registrar su respuesta. Por favor, vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
                />

                <ConfirmationDialog
                icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
                titulo={mensajeConfirm.titulo}
                mensaje={mensajeConfirm.mensaje}
                />
                <SnackBarTipoUno
                open={snackbar}
                handler={handleCloseSnackbar}
                duration={6000}
                severity={snackMsg.severity}
                msg={snackMsg.message}
                />
            </Box>
        </Box>
    );
}