import { Box, IconButton, Typography } from "@mui/material";
import "./ConfiguracionActuador.css";
import { GridActionsCellItem } from "@mui/x-data-grid";
import TooltipTipoUno from "../../../ToolTip/Tooltip";
import { IconoAgregarNuevaRow } from "../../../CabeceraTablasGestion/IconosCabecera";
import { useEffect, useState } from "react";
import AgregarTareaActuador from "./AgregarTareaActuador/AgregarTareaActuador";
import BotonEliminar from "../../../Botones/BotonEliminar/BotonEliminar";
import { ComentarioIcono } from "../../../Reportes/ReportesIcons";
import VerDetallesTarea from "./VerDetallesTarea/VerDetallesTarea";
import useActorTarea from "../../../../Hooks/useActorTarea/useActorTarea";
import useUsuarios from "../../../../Hooks/Usuarios/useUsuarios";
import "./FuncionesConfiguracionActuador";
import { filtrarUsuariosPermitidosParaNotificar } from "./FuncionesConfiguracionActuador";
import DataGridMui from "../../../DataGrid/DataGridMui";

const ConfiguracionActuador = ({actorData}) => {

    const {usuarios} = useUsuarios();
    
    const usuariosPermitidosParaNotificar = filtrarUsuariosPermitidosParaNotificar(usuarios, [2]);

    //Ids de los actuadores que estarán disponibles para gestionar.
    const idsActuadoresDisponibles = [16, 17, 18, 19, 20];

    const [tareasAsignadas, setTareasAsignadas] = useState([]);

    const {tareasActor, crearTareaActor, eliminarTareaActor, loading: loadingTareasActor, setLoading: setLoadingTareasActor, actualizarTareaActor, response: responseTareaActor} = useActorTarea(actorData.idActor);
    useEffect(() => {
      setTareasAsignadas(tareasActor);
    }, [tareasActor])

    //variables y funciones relacionadas con el manejo del popover
    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    //variables y funciones relacionadas con el manejo del modal con los detalles de la tarea
    const [openModalVerDetallesTarea, setOpenModalVerDetallesTarea] = useState(false);
    const [tareaSeleccionada, setTareaSeleccionada] = useState({});
    const handleOpenModalVerDetallesTarea = (tarea) => {
        setTareaSeleccionada(tarea);
        setOpenModalVerDetallesTarea(true);
    };
    const handleCloseVerDetallesTarea = () => {
        setOpenModalVerDetallesTarea(false);
    };

    const columns = [
        { field: 'idActorTareas', headerName: 'ID', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'tipoTarea', headerName: 'Tipo de tarea', flex: 0.5, minWidth: 150, headerAlign: 'center', align:'center', sortable: false, editable: false},        
        { field: 'opciones', headerName: 'Ver más', type:'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false, cellClassName: 'actions', 
            getActions:({row})=>{
                return [
                    <TooltipTipoUno titulo={"Detalles de la tarea"} ubicacion={"right-start"}>
                        <GridActionsCellItem
                        icon={<ComentarioIcono  id='iconoEnTablaUsuarios'/>}
                        label="Detalles de la tarea"
                        onClick={()=>handleOpenModalVerDetallesTarea(row)}
                        />
                    </TooltipTipoUno>
                    ]
            }
        },
        {
            field: 'eliminar', headerName: 'Eliminar', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id, row}) => {
                return [
                    <TooltipTipoUno titulo={"Eliminar tarea"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        label="Eliminar"
                        onClick={()=>handleEliminarTarea(id)}
                        icon={<BotonEliminar/>}
                        />
                    </TooltipTipoUno>
                ];
            }
        }
    ];


    const handleEliminarTarea = (idTarea) => {
        eliminarTareaActor(idTarea)
        .then(() => {
            const tareasActualizadas = tareasAsignadas.filter(tarea => tarea.idActorTareas !== idTarea);
            setTareasAsignadas(tareasActualizadas);
        })
        .catch((error) => {
            console.log("Error al eliminar tarea")
        })
    }

    return (
        <Box id="ContainerConfiguracionActuador">
            <Box id="BoxTituloConfiguracionActuador">
                <Typography id="TxtTituloConfiguracionActuador">
                    {
                        `Configurar tareas de actuador `
                    }
                </Typography>
            </Box>

            <Box id="BoxTituloTareasConfiguracionActuador">
                <Typography id="TxtTituloTareasConfiguracionActuador">
                    {
                        ` ${actorData.tipoActor} - ${actorData.nombre}`
                    }
                </Typography>
                <TooltipTipoUno titulo={"Agregar nuevo"} >
                    <IconButton onClick={handlePopoverOpen} id="btnIconoAccionCabecera">
                        <IconoAgregarNuevaRow id="iconoCabecera"/>
                    </IconButton>
                </TooltipTipoUno>

                <AgregarTareaActuador
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    setTareasAsignadas={setTareasAsignadas}
                    actuadorSeleccionado = {actorData}
                    crearTareaActor = {crearTareaActor}
                    usuarios={usuarios}
                    idsActuadoresDisponibles={idsActuadoresDisponibles}
                    usuariosPermitidosParaNotificar={usuariosPermitidosParaNotificar}
                />

                <VerDetallesTarea
                    open={openModalVerDetallesTarea}
                    onClose={handleCloseVerDetallesTarea}
                    tarea={tareaSeleccionada}
                    usuarios={usuarios}
                    actuadorSeleccionado = {actorData}
                    setTareasAsignadas={setTareasAsignadas}
                    actualizarTareaActor = {actualizarTareaActor}
                    loading = {loadingTareasActor}
                    setLoading = {setLoadingTareasActor}
                    response = {responseTareaActor}
                    idsActuadoresDisponibles={idsActuadoresDisponibles}
                    usuariosPermitidosParaNotificar={usuariosPermitidosParaNotificar}
                />
                
            </Box>

            <DataGridMui
            getRowId={(row) => row.idActorTareas}
            columns={columns}
            rows={tareasAsignadas}
            loading={loadingTareasActor}
            checkboxSelection={false}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            rowSelection = {true}
            disableRowSelectionOnClick = {true}
            />
        </Box>
    )
}

export default ConfiguracionActuador;
