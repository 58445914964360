import { useEffect, useRef, useState } from "react";
import "./ModeloTablaInvitados.css";
import { GridActionsCellItem} from "@mui/x-data-grid";
import { IconoEditar, IconoEliminarInvitado} from "./ModeloTablaIcons";
import { Box } from "@mui/material";
import IMGConfirmacionIndividual from "../../../assets/imagenes/ImgMCESingle.png";
import { BotonTipo1 } from "../../Botones/BotonesTabla/Botones";
import CabeceraInvitados from "./CabeceraTabla/CabeceraInvitados";
import ModalEnvioInvitados from "../../Modal/EnvioConLoading/ModalEnvioInvitados";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import { IconoOpcionesUsuarios } from "../../Usuarios/UsuariosIcon";
import TooltipTipoUno from "../../ToolTip/Tooltip";
import useInvitados from "../../../Hooks/Eventos/useInvitados";
import useConfirmDos from "../../../Hooks/ConfirmDialog/useConfirmDialogDos";
import { useSelector } from "react-redux";
import { SnackBarTipoUno } from "../../snackBar/SnackBar";
import useVisitante from "../../../Hooks/Visitante/useVisitante";
import { MenuTipoUno } from "../../Menu/MenuTipoUno";
import { MenuCorreoInvitado } from "../../Box/MenuCorreoInvitado/MenuCorreoInvitado";
import useInvitadosEvento from "../../../Hooks/Eventos/useInvitadosEvento";
import useEventosData from "../../../Hooks/Agenda/useEventosData";
import EditarInvitado from "./EditarInvitado/EditarInvitado";
import DataGridMui from "../../DataGrid/DataGridMui";

export const TablaInvitadosEvento = ({idVisita }) => {
    const {setLoading, loading, respuesta, EnviarInvitacionesMasivas, EliminarInvitado} = useInvitados();
    const [rows, setRows] = useState([]);
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [selectedRows, setSelectedRows] =  useState([]);
    const [ConfirmationDialogDos, confirmDos]= useConfirmDos();
    const data = useSelector(action => action.user.authenticated)
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const { EditarVisitanteData, loading: loadingUseVisitante, setLoading: setLoadingVisitante } = useVisitante();
    const [anchorEl, setAnchorEl] = useState(null);
    const [infoEnviarCorreo, setInfoEnviarCorreo] = useState({idVisita: null, idVisitante: null});

    //Variables y estados para manejar el modal de editar invitado
    const [openEditarInvitado, setOpenEditarInvitado] = useState(false);    
    const handleOpenEditarInvitado = (id, row) => {
        setInvitadoSeleccionado(row);
        setOpenEditarInvitado(true);        
    };
    const handleCloseEditarInvitado = () => {
        setOpenEditarInvitado(false);
    };
    const [invitadoSeleccionado, setInvitadoSeleccionado] = useState({});

    const { BuscarEventosRecurrentes } = useEventosData();
    const BuscarEventosRecurrentesRef = useRef(BuscarEventosRecurrentes);

    //  Debido a que useInvitadosEvento solicita al servidor los invitados cada vez que cambia su entrada, es que 
    //  se deja esta entrada como un estado de react para que pueda ir cambiando en caso de ser un evento recurrente.
    //  Comienza en -1 para, en principio, no mostrar datos en la tabla.
    const [idVisitaSeleccionada, setIdVisitaSeleccionada] = useState(-1);
    const {invitados, setReload, loadingRow, setLoadingRow} = useInvitadosEvento(idVisitaSeleccionada);

    //En caso de ser un evento recurrente, la respuesta a esto y el listado de eventos se guardan en estos estados.
    const [listaEventosRecurrentes, setListaEventosRecurrentes] = useState([]);
    const [esEventoRecurrente, setEsEventoRecurrente] = useState(false);

    //Este useEffect solo se ejecuta una vez para evaluar si el evento presionado es recurrente o no
    useEffect(() => {
        // Se obtiene el listado de eventos recurrentes de acuerdo al idVisita original
        BuscarEventosRecurrentesRef.current(idVisita)
        .then((listaEventos) => {
            const eventoRecurrente = (listaEventos?.length > 0);

            //En caso de ser un evento recurrente, se guarda la informacion en los estados correspondientes.
            if(eventoRecurrente){
                setEsEventoRecurrente(eventoRecurrente);
                setListaEventosRecurrentes(listaEventos);
            }
            //En caso de no serlo, se restaura el valor de idVisitaSeleccionada al idVisita original, para mostrar los invitados de ese evento.
            else{
                setIdVisitaSeleccionada(idVisita);
            }
        })
        .catch((err) => {
            console.error('Error al buscar los eventos recurrentes');
        })
    }, [idVisita])

    // De esta manera, cada vez que se cambie el valor de idVisitaSeleccionada, se realizará  una nueva petición al servidor
    // con los invitados a ese evento, por lo tanto al cambiar el valor de invitados por esta peticion, se debe modificar rows para actualizar la información de la tabla.
    useEffect(() => {
        setRows(invitados);
    }, [invitados])

    const handleActualizar = () => {
        setLoadingRow(true)
        setReload(true);
    }

    const HandleDelete = (id) => async () => {
        let response = await confirmDos();
        if(response === true)
            {
                EliminarInvitado(id)
                .then(
                    (resolve) => {
                        setRows(rows.filter((row) => row.idVisitaVisitante !== id ));
                        setSnackbarSeverity("success")
                        setSnackMsg("Invitado eliminado exitosamente!")
                        setSnackbar(true);
                    }
                    ,
                    (reject) => {
                        setSnackbarSeverity("error")
                        setSnackMsg("error en la eliminacion, reintente!")
                        setSnackbar(true);
                    }
                )
            }
        else
            {
                console.log("usuario rechaza la eliminacion");
            }
    };
    const handleEnviar = () => {
      	setLoading(true);
      	setOpenLoading(true)
      	EnviarInvitacionesMasivas(idVisita, rows)
        .then(
            (resolve) => {
                setSnackbarSeverity("success")
                setSnackMsg("Invitaciones enviadas exitosamente!")
                setSnackbar(true);
            }
            ,
            (reject) => {
                setSnackbarSeverity("error")
                setSnackMsg("fallo el envio, reintente!")
                setSnackbar(true);
            }
        )
    };

    const open = Boolean(anchorEl);
    
    const handleMenuCorreo = (e, row) =>{
        setInfoEnviarCorreo({
            idVisita: idVisitaSeleccionada,
            idVisitante: row.idVisitante
        })
        setAnchorEl(e.currentTarget);
    }
    const colName = [
        {
            field: 'nombres', headerName: 'Nombres', type: 'string', width: 130, headerAlign: 'center', align:'center', sortable: false, editable: false
        },
        {
            field: 'apellidos', headerName: 'Apellidos', type: 'string', width: 140, headerAlign: 'center', align:'center', sortable: false, editable: false
        },
        {
            field: 'rut', headerName: 'Rut', width: 150, type: 'string',  valueFormatter: (params) => params?.value === null ? "-":params?.value, headerAlign: 'center', align:'center', sortable: false, editable: false
        },
        {
            field: 'pasaporte', headerName: 'Pasaporte', width: 190, type: 'string',  valueFormatter: (params) => params?.value === null ? "-":params?.value, headerAlign: 'center', align:'center', sortable: false, editable: false
        },
        {
            field: 'correo', headerName: 'Correo', type: 'string', width: 220, headerAlign: 'center', align:'center', sortable: false, editable: false
        },
        {
            field: 'patente', headerName: 'Patente', type: 'string', valueFormatter: (params) => params?.value === null ? "-":params?.value, width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'estadoAceptacion', headerName: 'Invitacion', type: 'string', width: 140, headerAlign:'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'editar', headerName: 'Editar', type: 'actions', width: 80, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', 
            getActions: ({ id, row }) => {
                    return [
                      <TooltipTipoUno titulo={"Editar invitado"} ubicacion={"right-start"}>
                        <GridActionsCellItem
                          icon={<IconoEditar  id='iconoEnTabla' />}
                          label="Editar"
                          onClick={()=>handleOpenEditarInvitado(id, row)}
                        />
                      </TooltipTipoUno>,
                    ];
                // }
            }
        },
        {
            field: 'eliminar', headerName: 'Eliminar', type: 'actions', headerAlign: 'center', width: 80, align:'center', sortable: false, cellClassName: 'actions',
            getActions: ({ id }) => {
                  return [
                    <TooltipTipoUno titulo={"Eliminar invitado del evento"} ubicacion={"right-start"}>
                      <GridActionsCellItem
                        icon={<IconoEliminarInvitado  id='iconoEnTabla'/>}
                        label="Eliminar"
                        onClick={HandleDelete(id)}
                      />
                    </TooltipTipoUno>,
                  ];
            //   }
            }
        }
        ,
        {
            field: 'opciones', headerName: 'Opciones', type: 'actions', headerAlign: 'center', width: 90, align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({row}) => {
              return [
                <TooltipTipoUno titulo={"Gestionar Correos"} ubicacion={"right-start"}>
                    <GridActionsCellItem
                    icon={<IconoOpcionesUsuarios  id='iconoEnTablaUsuarios'/>}
                    label="Opciones"
                    onClick = {(e) => handleMenuCorreo(e, row)}
                    />
                </TooltipTipoUno>
              ];
            }
        },

    ];

    return (
      <Box>
        <Box id="BoxConTenidoInvitaciones">

            <Box id="BoxManejoNuevoInvitado">
                <CabeceraInvitados 
                setRows={setRows} 
                selectedRows={selectedRows} 
                idVisita={idVisitaSeleccionada}
                setIdVisita={setIdVisitaSeleccionada}
                actualizar={handleActualizar}
                listaEventosRecurrentes = {listaEventosRecurrentes}
                esEventoRecurrente = {esEventoRecurrente}
                invitados={invitados}
                />
                <Box id="BoxListaInvitados">

                    <DataGridMui
                    id="TablaInvitados"
                    getRowId={(row) => row.idVisitaVisitante}
                    columns={colName}
                    rows={rows}
                    loading={loadingRow}
                    style={{ width:'inherit'}}
                    checkboxSelection={false}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    disableVirtualization={true}
                    showCellVerticalBorder={false}
                    showColumnVerticalBorder={false}
                    hideFooterSelectedRowCount={true}
                    rowSelection={true}
                    disableRowSelectionOnClick={true}
                    />

                </Box>
            </Box>
        </Box>
        <Box id="BoxBotonEnviarInvitacion">
          <Box id="BoxPosicionBotonEnviar" >
            <BotonTipo1 textoBoton={"Enviar"} event={handleEnviar} />
          </Box> 
        </Box>

        <ConfirmationDialogDos
        img={IMGConfirmacionIndividual}
        titulo={"Eliminar invitado"}
        mensaje={"estas seguro(a) de eliminar a este invitado de la lista"}
        usuario={data.usuario.split(' ')[0]}
        />

        <SnackBarTipoUno
        open={snackbar}
        handler={handleCloseSnackbar}
        duration={6000}
        severity={snackbarSeverity}
        msg={snackMsg}
        />

        <ModalEnvioInvitados 
        open={openLoading} 
        setOpen={setOpenLoading} 
        msj={"Enviando tu invitación! Este proceso puede tomar unos minutos. Agradecemos tu espera"} 
        id={"ModalEnvioInvitados"} 
        loading={loading}
        respuestaMsj={respuesta === 200 ? "Su invitación ha sido enviada con éxito a su lista de invitados":"Error al enviar invitación! Intente nuevamente más tarde"}
        respuestTitulo={respuesta === 200 ? "Invitación enviada":"Error al enviar"}
            icono={respuesta === 200 ?
          <IconoExito id="IconoRespuestaServidor" />
          :
          <IconoFallo id="IconoRespuestaServidor" />
        }
        textoBoton={respuesta === 200 ?
          "Aceptar"
          :
          "Reintentar"
        }
        />
        <MenuTipoUno
        open={open}
        anchorEl={anchorEl}
        setOpen={setAnchorEl}
        children={<MenuCorreoInvitado infoEnviarCorreo={infoEnviarCorreo} />}
        />

        <EditarInvitado
        open={openEditarInvitado} 
        handleClose={handleCloseEditarInvitado}
        invitadoSeleccionado={invitadoSeleccionado}
        editarVisitante={EditarVisitanteData}
        loading={loadingUseVisitante}
        setLoading={setLoadingVisitante}
        setRows={setRows}
        />
      </Box>
    );
}