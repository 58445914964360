import { useState } from 'react';
import axios from 'axios';
import { RecuperarContrasena } from '../../Service/API/Api'


function useRecuperarContrasena() {
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();

    const Recuperar = async (token, correo, pass) => {
        return new Promise(async (resolve, reject) => {
            const recuperar = {
                correo: correo,
                nuevaContrasena: pass,
                token: token
            };
            await axios.post(RecuperarContrasena, recuperar)
            .then(
                (response) => {
                    resolve(true)
                    setResponse(response.status)
                    setLoading(false)
                }
            )
            .catch(
                (error) => {
                    // Asegurarse de que error.response existe antes de acceder a error.response.status
                    if (error.response) {
                        setResponse(error.response.status); // Código de estado si el servidor respondió
                    } else if (error.request) {
                        // En caso de que no haya respuesta, puede ser por un problema de red o de servidor
                        setResponse("Error de red o servidor inaccesible. Contáctanos para más información.");
                    } else {
                        // Otro error que no está relacionado con la respuesta ni la solicitud
                        setResponse("Error desconocido. Contáctanos para más información.");
                    }
                    reject(false);
                    setLoading(false);
                }
            )
            
        });
        
        
    }

    return {
        loading,
        setLoading,
        Recuperar,
        response
    }
};

export default useRecuperarContrasena;