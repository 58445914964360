import { Modal, Box, Typography, IconButton, TextField } from '@mui/material';
import "./EditarInvitado.css";
import { IconCerrarAccesos } from '../../../GestionAccesos/IconsGestionAccesos';
import { useFormik } from 'formik';
import { ValidationSchema } from '../CabeceraTabla/ValidationSchema';
import { useEffect, useRef, useState } from 'react';
import { BotonTipo1 } from '../../../Botones/BotonesTabla/Botones';
import useConfirm from '../../../../Hooks/ConfirmDialog/useConfirmDialog';
import { IconoExito, IconoFallo } from '../../../Modal/RespuestaServidor/RespuestaServidorIcon';
import { LoadingMasRespuestaCinco } from '../../../Modal/LoadingMasRespuesta/LoadingMasRespuesta';
import { IconoAlerta } from '../../../Usuarios/UsuariosIcon';

const EditarInvitado = ({ open, handleClose, invitadoSeleccionado, editarVisitante, loading, setLoading, setRows }) => {
    const [respuestaEditarInvitado, setRespuestaEditarInvitado] = useState();

    const formik = useFormik(
        {
            initialValues: {
                idVisitante: "",
                nombres: "",
                apellidos: "",
                rut: "",
                pasaporte: "",
                correo: "",
                patente: ""
            },
            validationSchema: ValidationSchema,
            onSubmit: async () => {
                setDialogText(
                    {
                        titulo: "¿Actualizar invitado?",
                        mensaje: "Estás a punto de actualizar la información de este invitado ¿Deseas continuar?"
                    }
                );
                var response = await confirm();
                if(response){
                    setLoading(true);
                    setOpenLoading(true);
                    await editarVisitante(formik.values)
                    .then(() => {
                        const invitadoActualizado = formik.values;
                        setRows((prevRows) => {
                            // Actualizamos el arreglo, reemplazando el objeto con el mismo idVisitante
                            return prevRows.map((row) => {
                                // Si el idVisitante es igual, combinamos los datos
                                if (row.idVisitante === invitadoActualizado.idVisitante) {
                                    // Devuelves el objeto actualizado, combinando las propiedades
                                    return { ...row, ...invitadoActualizado };
                                }
                                // Si no es el invitado a actualizar, mantenemos el objeto tal como está
                                return row;
                            });
                        });
                        setRespuestaEditarInvitado(true);
                        // formik.resetForm();
                    })
                    .catch(() => {
                        console.log("error al editar visitante");
                        setRespuestaEditarInvitado(false);
                    });
                }
                // formik.resetForm();
            }
    });

    const formikRef = useRef(formik);

    useEffect(() => {
        formikRef.current.setValues({
            idVisitante: invitadoSeleccionado.idVisitante || "",
            nombres: invitadoSeleccionado.nombres || "",
            apellidos: invitadoSeleccionado.apellidos || "",
            rut: invitadoSeleccionado.rut || "",
            pasaporte: invitadoSeleccionado.pasaporte || "",
            correo: invitadoSeleccionado.correo || "",
            patente: invitadoSeleccionado.patente || "",
        });
    }, [invitadoSeleccionado, open]);

    // Estados y variables para el manejo del dialog
    const [dialogText, setDialogText] = useState({titulo: '', mensaje: ''});
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [openLoading, setOpenLoading] = useState(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >

        <Box id="ContainerEditarInvitado">
            <Box id="ContenidoEditarInvitado">
                <Box id="CabeceraEditarInvitado">
                    <Typography id="TituloCabeceraInvitado" variant="h5" component="h2">
                        Editar invitado
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <IconCerrarAccesos sx={{fontSize: "15px"}}/>
                    </IconButton>
                </Box>

                <Box id="CuerpoEditarInvitado">
                    <Box id="NombreApellidosEditarEvento">
                        <TextField 
                            variant="filled" 
                            label="Nombres"
                            value={formik.values.nombres}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} 
                            id="Nombres" name="nombres" 
                            placeholder="Nombres"
                            fullWidth
                            helperText=
                            {
                                formik.errors.nombres ?
                                <span id="ErrorSpan">{formik.errors.nombres}</span>
                                :
                                null
                            } 
                        />
                        <TextField 
                            variant="filled" 
                            label={"Apellidos"} 
                            id="Apellidos" name="apellidos" 
                            placeholder="Apellidos" 
                            fullWidth
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} 
                            value={formik.values.apellidos}
                            helperText=
                            {
                                formik.errors.apellidos ?
                                <span id="ErrorSpan">{formik.errors.apellidos}</span>
                                :
                                null
                            }
                        />
                    </Box>
                    <TextField variant="filled" 
                        label={"Rut"} 
                        id="Rut" name="rut" 
                        placeholder="11111111-1" 
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                        value={formik.values.rut}
                        helperText=
                        {
                            formik.errors.rut ?
                            <span id="ErrorSpan">{formik.errors.rut}</span>
                            :
                            null
                        }
                    />
                    <TextField variant="filled" 
                        label={"Pasaporte"} 
                        id="Pasaporte" name="pasaporte" 
                        placeholder="000000000000" 
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                        value={formik.values.pasaporte}
                        helperText=
                        {
                            formik.errors.pasaporte ?
                            <span id="ErrorSpan">{formik.errors.pasaporte}</span>
                            :
                            null
                        }
                    />
                    <TextField variant="filled" 
                        label={"Correo"} 
                        id="Correo" name="correo" 
                        placeholder="dominio@dominio.cl" 
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                        value={formik.values.correo}
                        helperText=
                        {
                            formik.errors.correo ?
                            <span id="ErrorSpan">{formik.errors.correo}</span>
                            :
                            null
                        }
                    />
                    <TextField variant="filled" 
                        label={"Patente"} 
                        id="Patente" name="patente" 
                        placeholder="DLAU00" 
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                        value={formik.values.patente}
                        helperText=
                        {
                            formik.errors.patente ?
                            <span id="ErrorSpan">{formik.errors.patente}</span>
                            :
                            null
                        }
                    />
                </Box>{/*fin box cuerpoEditarInvitado*/}
                <Box id="BoxBotonEditarInvitado">
                    <BotonTipo1 textoBoton={"Actualizar"} event={formik.submitForm} />
                </Box> 

                <ConfirmationDialog
                icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
                titulo={dialogText.titulo}
                mensaje={dialogText.mensaje}
                />

                <LoadingMasRespuestaCinco
                open={openLoading} 
                setOpen={setOpenLoading} 
                msj={"La información del estacionamiento está siendo actualizada. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
                id={"RespuestaGuardarEditarEstacionamiento"} 
                tituloLoading={"Actualizando datos..."}
                loading={loading} 
                respuesta={respuestaEditarInvitado} 
                icono={
                    respuestaEditarInvitado ?
                    <IconoExito id="IconoRespuestaServidor" />
                    :
                    <IconoFallo id="IconoRespuestaServidor" />
                }
                textoBoton={
                    respuestaEditarInvitado ? 
                    "Aceptar"
                    :
                    "Reintentar"
                }
                titulo={respuestaEditarInvitado ? "Actualización exitosa":"Error al actualizar"}
                mensajeRespuesta={respuestaEditarInvitado ? 
                    "La información se ha actualizado correctamente"
                    :
                    "Ha ocurrido un error y no se ha podido actualizar la información"}
                />
            </Box>
        </Box>

    </Modal>
  );
};
export default EditarInvitado;
