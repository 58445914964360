import axios from "axios";
import { useEffect, useState } from "react";
import { CrearTarea, EditarTarea, EliminarTarea, ObtenerTareasActor } from "../../Service/API/Api";

export default function useActorTarea(idActor) {
    const [tareasActor, setTareasActor] = useState([]);
    const [response, setResponse] = useState();
    const [loading, setLoading] = useState()

    useEffect(() => {
        setLoading(true); // Inicia el estado de carga
        axios.get(ObtenerTareasActor + idActor) // Cambia esta URL por la de tu API
            .then((response) => {
                // Guarda directamente la respuesta en el estado sin modificaciones
                setTareasActor(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("Error al cargar los tipos de tarea:");
                setLoading(false);
            });
    }, [idActor]);

    const crearTareaActor = (tarea) => {
        return new Promise(async (resolve, reject) => {
            try {
                // Realizamos la solicitud POST con Axios
                const response = await axios.post(CrearTarea, tarea);
                
                // Procesamos la respuesta de manera directa
                setResponse(response.status);
                setLoading(false);
    
                // Resolvemos la promesa con los datos obtenidos
                resolve(response.data);  // Resolvemos la promesa con la respuesta
    
            } catch (error) {
                // Capturamos cualquier error y manejamos la respuesta de error
                setResponse(error.response ? error.response.status : 'Error desconocido');
                setLoading(false);
    
                // Rechazamos la promesa con false o con el error
                reject(error);  // Rechazamos la promesa si ocurre un error
            }
        });
    };


    const actualizarTareaActor = (tarea) => {
        return new Promise(async (resolve, reject) => {
            try {
                // Realizamos la solicitud PUT con Axios
                const response = await axios.put(EditarTarea, tarea);
                
                // Procesamos la respuesta de manera directa
                setResponse(response.status);
                setLoading(false);
    
                // Resolvemos la promesa con los datos obtenidos
                resolve(response.data);  // Resolvemos la promesa con la respuesta
    
            } catch (error) {
                // Capturamos cualquier error y manejamos la respuesta de error
                setResponse(error.response ? error.response.status : 'Error desconocido');
                setLoading(false);
    
                // Rechazamos la promesa con false o con el error
                reject(error);  // Rechazamos la promesa si ocurre un error
            }
        });
    };




    const eliminarTareaActor = (idTareaActor) => {
        return new Promise(async (resolve, reject) => {
            try {
                // Realizamos la solicitud POST con Axios
                const response = await axios.delete(EliminarTarea + idTareaActor);
                
                // Procesamos la respuesta de manera directa
                setResponse(response.status);
                setLoading(false);
    
                // Resolvemos la promesa
                resolve(response.data);  // Resolvemos la promesa con la respuesta
    
            } catch (error) {
                // Capturamos cualquier error y manejamos la respuesta de error
                setResponse(error.response ? error.response.status : 'Error desconocido');
                setLoading(false);
    
                // Rechazamos la promesa con false o con el error
                reject(error);  // Rechazamos la promesa si ocurre un error
            }
        });
    };
    

    return {
        tareasActor,
        crearTareaActor,
        eliminarTareaActor,
        response,
        setResponse,
        loading,
        setLoading,
        actualizarTareaActor
    };
}