import axios from "axios";
import { useEffect, useState } from "react";
import { HorariosEmpresa } from "../../Service/API/Api";

export default function useHorarios()
{
    const [horarios, setHorarios] = useState([]);

    useEffect(() => {
        axios.get(HorariosEmpresa)
        .then(
            (response) => {
                setHorarios(response.data)                
            }
        )
        .catch(
            (error) => {
                console.error("Error al obtener información de horarios");
            }
        )
    }, [])

    return {
        horarios
    }
    
}