import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import "./Pases.css";
import { LayoutCrearNuevo } from "../../../../Box/CrearNuevoMenuGestionUsuario/LayoutCrearNuevo";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import { InitialNuevoPase } from "./Initial";
import { ValidationNuevoPase } from "./Validation";
import usePase from "../../../../../Hooks/Pase/usePase";
import { useState } from "react";
import { LoadingMasRespuestaCuatro } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";

const tiposTarjeta = [
    {id:0, tipo:"Metodos de Acceso"}
    ,
    {id:1, tipo:"RFID"}
    ,
    {id:2, tipo:"TAG"}
]



export const NuevoPase = ({handleOpen, setRows, patentes, tarjetas, user}) => {
    const {loading, setLoading, CrearNuevo, response} = usePase();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [selectTipo, setSelectTipo] = useState([]);

    const handleClose = () => {
        handleOpen(false);
    }


    const handleSelectTipo = (tipoSeleccionado) => {
        formik.setFieldValue("idMetodoAcceso", tipoSeleccionado);
        if(tipoSeleccionado === 0){
            setSelectTipo([]);
            formik.setFieldValue("idTarjeta", 0);
            formik.setFieldValue("idPatente", 0);
        }
        if(tipoSeleccionado === 1){
            const data = tarjetas.filter((t) => t.idTipoTarjeta === 1);
            setSelectTipo(data);
            const primerValor = data.length === 0 ? 0 : data[0].idTarjeta;
            formik.setFieldValue("idTarjeta", primerValor);
            formik.setFieldValue("idPatente", 0);
        }
        if(tipoSeleccionado === 2)
        {
            const data = tarjetas.filter((t) => t.idTipoTarjeta === 2);
            const patente = patentes.length === 0 ? 0 : patentes[0].idPatente;
            setSelectTipo(data);
            const primerValor = data.length === 0 ? 0 : data[0].idTarjeta;
            formik.setFieldValue("idTarjeta", primerValor);
            formik.setFieldValue("idPatente", patente);
        }
    }

   
    

    
    const handleOtro = () =>{
        //formik.setFieldValue("idMetodoAcceso", 0);
        //formik.setFieldValue("idPatente", formik.initialValues.idPatente);
        setSelectTipo([]);
        //formik.setFieldValue("idTarjeta", 0);
        formik.resetForm();
        setOpenLoading(false);
    }

    const AgregarRow = async (values, nuevaId) => {
        let tipoTarjetaAgregar = tiposTarjeta.find((t) => t.id === values.idMetodoAcceso);
        let tarjetaAgregar = tarjetas.find((t) => t.idTarjeta === values.idTarjeta);
        let patenteAgregar = values.idMetodoAcceso === 1 ? null : patentes.find((p) => p.idPatente === values.idPatente);
        
        setRows((oldRows) => [...oldRows, 
            {
                idPase: nuevaId, 
                tipoTarjeta: tipoTarjetaAgregar.tipo,
                patenteDigitos: patenteAgregar === null ? "-": patenteAgregar.patente, 
                codigoTarjeta: tarjetaAgregar.codigo,
                estado: true,
                isNew: true 
            }
        ]);
    }

    const formik = useFormik({
        initialValues: InitialNuevoPase,
        validationSchema:ValidationNuevoPase,
        onSubmit: async () => {
            setLoading(true);
            setOpenLoading(true);
            await CrearNuevo(formik.values.idMetodoAcceso, user, formik.values.idPatente, formik.values.idTarjeta)
            .then(
                (result) => {
                    AgregarRow(formik.values, result);
                },
                (error) => {
                    console.log(error);
                }
            )
        }
    })

    return (
        <LayoutCrearNuevo
        titulo={"Agregar nuevo TAG/RFID"}
        >
            <Box id="BoxLayoutFormAgregarNuevoPaseGU">
                <Box id="BoxFormAgregarNuevoPaseGU">
                    <Box id="BoxTituloFormAgregarNuevoPaseGU">
                        <Typography id="TxtTituloFormAgregarNuevoPaseGU">
                            {
                                "Nuevo TAG/RFID"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxDataToCreateAgregarNuevoPaseGU" component={"form"} onSubmit={formik.handleSubmit}>
                        <Box id="BoxDataToCreateTipoMetodoAgregarNuevoPaseGU">
                            <Box id="BoxTipoAccesoAgregarNuevoGU">
                            <FormControl fullWidth>
                                    <InputLabel id="LblMetodoAcceso">
                                    {
                                        "Metodo de Acceso"
                                    }
                                    </InputLabel>
                                    <Select 
                                    fullWidth 
                                    name="idMetodoAcceso"
                                    variant="outlined"
                                    labelId="LblMetodoAcceso"
                                    label="Método de Acceso"
                                    value={formik.values.idMetodoAcceso}
                                    onChange={(e) => handleSelectTipo(e.target.value)}
                                    MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            maxHeight: '250px',
                                            overflowY: 'auto',
                                            width: '220px',
                              
                                            // Estilos para WebKit (Chrome, Safari, Edge) en el Paper (menú desplegable)
                                            '&::-webkit-scrollbar': {
                                              width: '8.024px !important',
                                              background: 'transparent !important',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                              background: 'transparent !important',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                              background: '#C9C9C9',
                                              borderRadius: '100px',
                                            },
                                          },
                                        },
                                    }}
                                    >
                                    {
                                        tiposTarjeta.length > 0 ?
                                        tiposTarjeta.map((item, index) => {
                                            return (
                                                <MenuItem disableRipple key={index} value={item.id} id="MITipoTarjetaGU" >
                                                    <Box id="BoxTipoTarjetaGU">
                                                        <Typography>
                                                            {
                                                                item.tipo
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                             );
                                        })
                                        :
                                        <MenuItem disableRipple key={0} value={0} id="MITipoTarjetaGU" >
                                            <Box id="BoxTipoTarjetaGU">
                                                <Typography>
                                                    {
                                                        "Obteniendo..."
                                                    }
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                    }
                                    </Select>
                                    <FormHelperText>
                                        {
                                            formik.errors.idMetodoAcceso
                                        }
                                    </FormHelperText>
                                </FormControl>
                                
                            </Box>
                            <Box id="BoxTipoMetodoAgregarNuevoGU">
                                <TextField
                                fullWidth
                                readOnly
                                name="tipoAcceso"
                                label="Tipo de Acceso"
                                value={formik.values.idMetodoAcceso === 0 ?
                                    "Seleccione un método de acceso"
                                    :
                                        formik.values.idMetodoAcceso === 1 ?
                                        "Acceso peatonal"
                                        :
                                        "Acceso vehicular"
                                }
                                />
                            </Box>
                        </Box>
                        <Box id="BoxDataToCreatePatanteTarjetaAgregarNuevoPaseGU">
                            <Box id="BoxPatenteAgregarNuevoGU">
                                <FormControl fullWidth>
                                    <InputLabel id="LblPatentes">
                                    {
                                        "Patentes"
                                    }
                                    </InputLabel>
                                    <Select 
                                    fullWidth 
                                    disabled={formik.values.idMetodoAcceso === 2 ? false : true}
                                    variant="outlined"
                                    labelId="LblPatentes"
                                    label="Patentes"
                                    name="idPatente"
                                    value={formik.values.idPatente}
                                    onChange={formik.handleChange}
                                    MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            maxHeight: '250px',
                                            overflowY: 'auto',
                                            width: '220px',
                              
                                            // Estilos para WebKit (Chrome, Safari, Edge) en el Paper (menú desplegable)
                                            '&::-webkit-scrollbar': {
                                              width: '8.024px !important',
                                              background: 'transparent !important',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                              background: 'transparent !important',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                              background: '#C9C9C9',
                                              borderRadius: '100px',
                                            },
                                          },
                                        },
                                    }}
                                    >
                                    {
                                        
                                        formik.values.idMetodoAcceso === 0 ?
                                        <MenuItem disableRipple key={0} value={0} id="MIPatentesGU" >
                                            <Box id="BoxPatenteGU">
                                                <Typography>
                                                    {
                                                        "seleccione un metodo de acceso"
                                                    }
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                        :
                                        formik.values.idMetodoAcceso === 1 ?
                                        <MenuItem disableRipple key={0} value={0} id="MIPatentesGU" >
                                            <Box id="BoxPatenteGU">
                                                <Typography>
                                                    {
                                                        "metodo no permite asociar patentes"
                                                    }
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                        :
                                        patentes.length === 0 ?
                                        <MenuItem disableRipple key={0} value={0} id="MIPatentesGU" >
                                            <Box id="BoxPatenteGU">
                                                <Typography>
                                                    {
                                                        "no hay registradas"
                                                    }
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                        :
                                        patentes.map((item, index) => {
                                            return (
                                                <MenuItem disableRipple key={index} value={item.idPatente} id="MIPatentesGU" >
                                                    <Box id="BoxPatenteGU">
                                                        <Typography>
                                                            {
                                                                item.patente
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                    </Select>
                                    <FormHelperText>
                                        {
                                            formik.errors.idPatente
                                        }
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                            <Box id="BoxTarjetaAgregarNuevoGU">
                                <FormControl fullWidth>
                                    <InputLabel id="LblNumeroTarjeta">
                                    {
                                        "Tarjetas disponibles"
                                    }
                                    </InputLabel>
                                    <Select 
                                    fullWidth 
                                    variant="outlined"
                                    labelId="LblNumeroTarjeta"
                                    label="Tarjetas disponibles"
                                    name="idTarjeta"
                                    value={formik.values.idTarjeta}
                                    onChange={formik.handleChange}
                                    MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            maxHeight: '400px',
                                            overflowY: 'auto',
                                            width: '220px',
                              
                                            // Estilos para WebKit (Chrome, Safari, Edge) en el Paper (menú desplegable)
                                            '&::-webkit-scrollbar': {
                                              width: '8.024px !important',
                                              background: 'transparent !important',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                              background: 'transparent !important',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                              background: '#C9C9C9',
                                              borderRadius: '100px',
                                            },
                                          },
                                        },
                                    }}
                                    >
                                    {
                                        formik.values.idMetodoAcceso === 0 ?
                                            <MenuItem disableRipple key={0} value={0} id="MIPatentesGU" >
                                                <Box id="BoxPatenteGU">
                                                    <Typography>
                                                        {
                                                            "seleccione un metodo de acceso"
                                                        }
                                                    </Typography>
                                                </Box>
                                            </MenuItem>
                                            :
                                            selectTipo.length === 0 ?
                                                <MenuItem disableRipple key={0} value={0} id="MIPatentesGU" >
                                                    <Box id="BoxPatenteGU">
                                                        <Typography>
                                                            {
                                                                "No hay disponibles para es metodo"
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                                :
                                                selectTipo.map((item, index) => {
                                                    return (
                                                        <MenuItem disableRipple key={index} value={item.idTarjeta} id="MIPatentesGU" >
                                                            <Box id="BoxPatenteGU">
                                                                <Typography>
                                                                    {
                                                                        item.codigo
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </MenuItem>
                                                    );
                                                })
                                        
                                    }
                                    </Select>
                                    <FormHelperText>
                                        {
                                            formik.errors.idTarjeta
                                        }
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </Box>
                        
                    </Box>
                </Box>
                <Box id="BoxAccionesAgregarNuevoPaseGU">
                    <Box id="BoxBtnCancelarAgregarNuevoPaseGU">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                    </Box>
                    <Box id="BoxBtnAgregarAgregarNuevoPaseGU">
                        <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} />
                        
                        <LoadingMasRespuestaCuatro
                        id={"LoadingMasRespuestaDosNuevoPase"}
                        open={openLoading}
                        setOpen={setOpenLoading}
                        msj={"Se está agregando un método de identificación al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                        tituloLoading={"Registrando...."}
                        loading={loading}
                        respuesta={response}
                        titulo={response === 201 ? "Método agregado":"Método no agregado"}
                        mensajeRespuesta={response === 201 ? "El nuevo método de identificación ha sido agregado exitosamente. Ahora puedes gestionar los accesos o agregar otro método.":"No se ha podido agregar el nuevo método debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
                        txtBotonUno={"Agregar nuevo método"}
                        eventBotonUno={handleOtro}
                        />
                    </Box>
                </Box>
            </Box>
        </LayoutCrearNuevo>
    );
}

