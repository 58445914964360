import { useEffect, useState,  } from "react"
import useVisitas from "../../Hooks/Eventos/useVisitas";
import { Box, CardMedia, CircularProgress, Typography } from "@mui/material";
import lcp2 from "../../assets/imagenes/CP2.png"
import "./GenerarQREvento.css";
import QRCode from "react-qr-code";

export const HandlerQRDinamico = ({infoEvento}) => {
    const {GenerarQRInformacion} = useVisitas();
    const [valorQR, setValorQR] = useState(null);
    
    useEffect(() => {       
        
        let timer = setTimeout(() => {
            GenerarQRInformacion(infoEvento.idVisitaVisitante)
            .then(
                (resolve) => setValorQR(resolve),
                (reject) => setValorQR(reject),
            );
        }, 10000);

        return () => clearTimeout(timer);
    }, [infoEvento, GenerarQRInformacion])
    

    return(
        <Box id="BoxImagenInformacionQR">
            <Box id="BoxContenedorImagenCP2QRVisita">
                <CardMedia id="ImagenLogoCP2QRVisita" component="img" src={lcp2} />
            </Box>
            <Box id="BoxInformacionyQRAcceso">
                <Box id="BoxTituloQRAcceso">
                    <Typography id="TextoTituloQRAcceso">
                        {
                            "Tu código QR de acceso"
                        }
                    </Typography>
                </Box>
                <Box id="BoxContenidoParaQRAcceso">
                    <Box id="BoxInfoAyudaQRAcceso">
                        <Typography id="TextoAyudaQRAcceso">
                            {
                                "Escanea este código en el lector ubicado en la entrada del recinto. Asegúrate de subir el brillo de tu teléfono y mantener el código QR estable para facilitar la lectura."
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxQRAccesoInvitadoVisible">
                        {
                            valorQR === null ?
                            <CircularProgress size={96} style={{color:"#175676"}} />
                            :
                            valorQR === false ?
                            <Typography id="TextoInfoSoloUnQRAcceso">
                                {
                                    "Falló la creación del QR, confirme su informacion"
                                }
                            </Typography>
                            :
                            <QRCode 
                            value={valorQR} 
                            size={300}
                            bgColor="#FFF"
                            fgColor="#000000"
                            style={{ border: "1px solid black"}}
                            level="L"
                            />
                            
                        }
                    </Box>
                    <Box id="BoxInfoSoloUnQRAcceso">
                        <Typography id="TextoInfoSoloUnQRAcceso">
                            {
                                "Este QR sólo es válido para el ingreso de un usuario"
                            }
                        </Typography>
                        <Typography id="TextoInfoSoloUnQRAcceso">
                            {
                                "Este QR cambia cada minuto, recuerda que no se puede compartir"
                            }
                        </Typography>
                    </Box>
                </Box>
            </Box> 
        </Box>
    );
}