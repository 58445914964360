import axios from "axios";
import { useEffect, useState } from "react";
import { BuscarActuadoresEmpresa } from "../../Service/API/Api";

export default function useGetActuadores(idEmpresa) {
    const [actuadores, setActuadores] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        axios.get(BuscarActuadoresEmpresa + idEmpresa)
        .then((response) => {
            setActuadores(response.data);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        })
    }, [idEmpresa]);

    return {
        actuadores,
        loading,
        setLoading
    };
}
