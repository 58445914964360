import { Box, IconButton, Modal, Typography } from "@mui/material";
import "./VerDetallesTarea.css"
import { IconCerrarAccesos } from "../../../../GestionAccesos/IconsGestionAccesos";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import useGetActuadores from "../../../../../Hooks/Actuadores/useGetActuadores";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import SelectMultipleCheckmarks from "../../../../SelectMultipleCheckmarks/SelectMultipleCheckmarks";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../../../Usuarios/UsuariosIcon";
import { LoadingMasRespuestaCinco } from "../../../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../../../Modal/RespuestaServidor/RespuestaServidorIcon";
import AutocompleteSimple from "../../../../AutocompleteSimple/AutocompleteSimple";
import TextFieldReadOnlyUno from "../../../../TextField/TextFieldReadOnlyUno";
import { filtrarActuadoresDisponibles } from "../FuncionesConfiguracionActuador";

const VerDetallesTarea = ({ 
  open, 
  onClose, 
  tarea, 
  usuarios, 
  actuadorSeleccionado, 
  setTareasAsignadas, 
  actualizarTareaActor, 
  loading, 
  setLoading, 
  response,
  idsActuadoresDisponibles,
  usuariosPermitidosParaNotificar,
}) => {

  const {idTareaActivarDispositivo, idTareaNotificar} = useSelector(
    (state) => state.idsTiposTareas
  );

  // Usamos useRef para almacenar los valores de idTareaActivarDispositivo e idTareaNotificar
  const idTareaActivarDispositivoRef = useRef(idTareaActivarDispositivo);
  const idTareaNotificarRef = useRef(idTareaNotificar);
    // useEffect(() => {
    //   console.log("La tarea es: ", tarea);
    // }, [tarea])

    // useEffect(() => {
    //   console.log("response: ", response);
    // }, [response])

    // useEffect(() => {
    //   console.log("usuarios es: ", usuarios)
    // }, [usuarios])

  const data = useSelector(action => action.user.authenticated);
  const { actuadores } = useGetActuadores(data.idEmpresa);

  const [actuadoresDisponibles, setActuadoresDisponibles] = useState();

  // Función que filtra los actuadores disponibles según las IDs proporcionadas
  useEffect(() => {
    setActuadoresDisponibles(filtrarActuadoresDisponibles(actuadores, idsActuadoresDisponibles));
}, [idsActuadoresDisponibles, actuadores]);
  
  //Listado donde se guardarán las ids de los correos a notificar en caso de tratarse de una tarea de tipo "Notificar"
  const [idsCorreos, setIdsCorreos] = useState([]);
  
  //Dispositivo que será activado en caso de tratarse de una tarea de tipo "Activar dispositivo"
  const [dispositivoSeleccionado, setDispositivoSeleccionado] = useState("");
  useEffect(() => {
    console.log("dispositivo seleccionado: ", dispositivoSeleccionado)
  }, [dispositivoSeleccionado])

  useEffect(() => {

    //En caso de ser una tarea tipo "Notificar", esta accion toma el atributo detalleTareas y lo transforma en un arreglo de entero para finalmente guardarlo en idsCorreos
    // Ejemplo: "23;45;56" => [23,45,56]
    if(tarea.idTipoTarea === idTareaNotificarRef.current){
      const listado = tarea.detalleTareas.split(';').map(Number);
      setIdsCorreos(listado);
    }

    //En caso de ser una tarea tipo "Activar dispositivo", esta accion toma el atributo detalleTareas y lo guarda en dispositivoSeleccionado
    else if(tarea.idTipoTarea === idTareaActivarDispositivoRef.current){

      // Con esto se permite que para tareas del tipo "Activar dispositivo", en caso de que se cambie una opcion de dispositivo y no se presione el boton "Actualizar" y luego el usuario
      // cierre el modal, se evita que al abrirlo de nuevo aparezca esta informacion cambiada. Con esto al abrir nuevamente el componente los valores se resetean a los originales.

        const dispositivo = actuadores.find(a => a.idActor === parseInt(tarea.detalleTareas));
        setDispositivoSeleccionado(dispositivo);

    }
  }, [tarea, actuadores, open])


  // //Funcion que se ejecuta al cambiar el dispositivo seleccionado en el elementoo Select en caso de tratarse de una tarea de tipo "Activar dispositivo"
  // const handleChangeDispositivoSeleccionado = (event) => {
  //   const actuador = actuadores.find(actuador => actuador.idActor === event.target.value);
  //   setDispositivoSeleccionado(actuador);
  // }

  const handleActualizarTarea = async () => {

    setDialogText(
      {
          titulo: "¿Actualizar Tarea?",
          mensaje: "Estás a punto de actualizar la tarea asignada ¿Deseas continuar?"
      }
    );

    const responseConfirm = await confirm(); 

    if(responseConfirm === true) {
      setOpenLoading(true);
      setLoading(true);

      // console.log("response confirm, tarea es: ", tarea);
      
        //Si el tipo de tarea es activar actuador
      if(tarea.idTipoTarea === idTareaActivarDispositivoRef.current && dispositivoSeleccionado !== null && dispositivoSeleccionado !== undefined){

        const cuerpoPeticion = {
          idActorTarea: tarea.idActorTareas,
          actorId: actuadorSeleccionado.idActor,
          tipoTareaId: tarea.idTipoTarea,
          detalleTarea: [dispositivoSeleccionado.idActor]
        }

        actualizarTareaActor(cuerpoPeticion)
        .then(() => {
            const tareaAsignada = {
                idActorTareas: tarea.idActorTareas,
                idTipoTarea: tarea.idTipoTarea,
                tipoTarea: tarea.tipoTarea,
                detalleNotificacion: [],
                detalleAccion: {
                    idActor: dispositivoSeleccionado.idActor,
                    nombre: actuadores.find(actuador => actuador.idActor === dispositivoSeleccionado.idActor).nombre,
                    tipoActor: actuadores.find(actuador => actuador.idActor === dispositivoSeleccionado.idActor).tipoActor
                },
                detalleTareas: String(dispositivoSeleccionado.idActor)

            }
            // Actualizar el estado tareasAsignadas
            setTareasAsignadas(prevTareas => {
              const tareaExistenteIndex = prevTareas.findIndex(tarea => tarea.idActorTareas === tareaAsignada.idActorTareas);
            
              if (tareaExistenteIndex !== -1) {
                // Si la tarea existe, reemplázala
                return prevTareas.map((tarea, index) =>
                  index === tareaExistenteIndex ? tareaAsignada : tarea
                );
              } else {
                // Si no existe, agrega la nueva tarea
                return [...prevTareas, tareaAsignada];
              }
            });
        })
        .catch((error) => {
            // Manejar el error si la promesa es rechazada
            console.error('Error actualizando tarea');
        });

      }//fin if activar actuador

      //Si el tipo de tarea es notificar
      else if (tarea.idTipoTarea === idTareaNotificarRef.current && idsCorreos.length > 0){

        const cuerpoPeticion = {
          idActorTarea: tarea.idActorTareas,
          actorId: actuadorSeleccionado.idActor,
          tipoTareaId: tarea.idTipoTarea,
          detalleTarea: idsCorreos
        }

        actualizarTareaActor(cuerpoPeticion)
        .then(() => {
            const tareaAsignada = {
                idActorTareas: tarea.idActorTareas,
                idTipoTarea: tarea.idTipoTarea,
                tipoTarea: tarea.tipoTarea,
                detalleAccion: {},
                detalleNotificacion: idsCorreos.map(id => {

                  // Buscar el usuario con 'idPersona' igual al valor del arreglo de enteros
                  const usuario = usuarios.find(u => u.idPersona === id);
              
                  // Si encontramos al usuario, devolvemos un objeto con 'idPersona' y 'correo'
                  if (usuario) {
                      return {
                          idPersona: usuario.idPersona,
                          correo: usuario.correo
                      };
                  }
                  
                  // Si no se encuentra el usuario, podemos retornar null o un objeto vacío
                  return null;
              }).filter(item => item !== null),
                detalleTareas: idsCorreos.join(";")
            }
            
            // Actualizar el estado tareasAsignadas
            setTareasAsignadas(prevTareas => {
              const tareaExistenteIndex = prevTareas.findIndex(tarea => tarea.idActorTareas === tareaAsignada.idActorTareas);
            
              if (tareaExistenteIndex !== -1) {
                // Si la tarea existe, reemplázala
                return prevTareas.map((tarea, index) =>
                  index === tareaExistenteIndex ? tareaAsignada : tarea
                );
              } else {
                // Si no existe, agrega la nueva tarea
                return [...prevTareas, tareaAsignada];
              }
            });
            
        })
        .catch((error) => {
            // Manejar el error si la promesa es rechazada
            console.error('Error actualizando tarea');
        });
      }//fin else if

      else{
        console.log("Error: La tarea seleccionada no es válida");
        setLoading(false);
      }
    } //fin if responseConfirm === true
    
  }

  // Estados y variables para el manejo del dialog
  const [dialogText, setDialogText] = useState({titulo: '', mensaje: ''});
  const [ConfirmationDialog, confirm ]= useConfirm();
  const [openLoading, setOpenLoading] = useState(false);


    return (
        <Modal
          open={open}
          onClose={onClose}
        >
          <Box id="ContainerModalVerDetallesTarea">
            <Box id="CabeceraModalVerDetallesTarea">
                <Typography 
                  variant="h5" 
                  component="h2"
                  sx={{
                    // borderBottom: '1px solid #B1B1B1', // Agrega un borde debajo
                    paddingBottom: '5px',
                  }}
                >
                  {`Tarea - ${tarea.tipoTarea}`}
                </Typography>
                <IconButton onClick={onClose}>
                    <IconCerrarAccesos sx={{fontSize: "15px"}}/>
                </IconButton>
            </Box>


            <Box id="CuerpoModalVerDetallesTarea">

                  {/* En caso de tratarse de una tarea de tipo 'Activar Dispositivo' */}
                  {tarea.idTipoTarea === idTareaActivarDispositivoRef.current && 
                    <Box id="BoxContenidoInfoTarea">
                      <Box className="BoxItemInfoTarea">
                        <Typography 
                          className="TituloInfoTarea"
                          variant="h5"
                          sx={{
                            fontWeight: 'normal',
                          }}
                          >
                          {`Dispositivo seleccionado`}
                        </Typography>
                      
                        <Box sx={{ width: "85%" }}>
                          <AutocompleteSimple
                            listadoElementos={actuadoresDisponibles}
                            label={"Escoger actuador"}
                            onSelectionChange={(nuevoElemento) => setDispositivoSeleccionado(nuevoElemento)}
                            elementoSeleccionado={dispositivoSeleccionado}
                            keyListadoElementos={"idActor"}
                            mostrarElemento={(option) => `${option["tipoActor"]} - ${option["nombre"]}` || undefined}  // Personalizamos cómo se muestra la etiqueta
                          />
                        </Box>
                      </Box>
                      <Box className="BoxItemInfoTarea">
                        <Typography 
                          className="TituloInfoTarea"
                          variant="h5"
                          sx={{
                            fontWeight: 'normal',
                          }}
                          >
                          {`Detalles dispositivo`}
                        </Typography>
                        <Box id="BoxInfoDispositivo">
                          <Box className={"BoxItemInfoDispositivo"}>
                            <TextFieldReadOnlyUno
                              label={"Nombre"}
                              value={dispositivoSeleccionado?.nombre || "Sin datos"}  // Si dispositivoSeleccionado es null o undefined, se asigna ""
                            />

                            <TextFieldReadOnlyUno
                              label={"Tipo"}
                              value={dispositivoSeleccionado?.tipoActor || "Sin datos"}  // Si dispositivoSeleccionado es null o undefined, se asigna ""
                            />
                          </Box>

                          <Box className={"BoxItemInfoDispositivo"}>
                            <TextFieldReadOnlyUno
                              label={"Zona"}
                              value={dispositivoSeleccionado?.nombreZona || "Sin datos"}  // Si dispositivoSeleccionado es null o undefined, se asigna ""
                            />

                          <TextFieldReadOnlyUno
                            label={"Estado"}
                            value={
                              dispositivoSeleccionado 
                                ? dispositivoSeleccionado.estado === true 
                                  ? "Activado" 
                                  : dispositivoSeleccionado.estado === false 
                                  ? "Desactivado" 
                                  : "Sin datos"
                                : "Sin datos"  // Si `dispositivoSeleccionado` no existe, mostrar "Sin Datos"
                            }
                          />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  }

                  {/* En caso de tratarse de una tarea de tipo 'Notificar' */}
                  {tarea.idTipoTarea === idTareaNotificarRef.current && 
                    <Box className="BoxItemInfoTarea">
                      <Typography 
                        className="TituloInfoTarea"
                        variant="h5"
                        sx={{
                          fontWeight: 'normal',
                        }}
                      >
                      {`Correo(s) a notificar`}
                      </Typography>

                      <Box sx={{ width: '85%'}}>
                        <SelectMultipleCheckmarks
                            width={"100%"}
                            label="Seleccionar correo destino"
                            listadoElementos={usuariosPermitidosParaNotificar || []}
                            keyListadoElementos={"idPersona"}
                            atributoMostrable={"correo"}
                            atributoValue={"idPersona"}
                            selectedValues={idsCorreos}
                            setSelectedValues={setIdsCorreos}
                        />
                      </Box>
                    </Box>
                  }
              {/* </Box> */}
            </Box>
            <Box id="BoxBotonAceptarVerDetallesTarea">
                <BotonTipo2 textoBoton={"Actualizar"} event={handleActualizarTarea} />
            </Box>

            <ConfirmationDialog
              icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
              titulo={dialogText.titulo}
              mensaje={dialogText.mensaje}
            />

            <LoadingMasRespuestaCinco
              open={openLoading} 
              setOpen={setOpenLoading} 
              msj={"La tarea está siendo actualizada. Este proceso puede tardar un momento. Agradecemos tu paciencia."} 
              id={"RespuestaGuardarEditarUsuario"} 
              tituloLoading={"Actualizando tarea..."}
              loading={loading} 
              icono={
                //Si la tarea es del tipo notificar
                tarea.idTipoTarea === idTareaNotificarRef.current ?

                  response === 200 && idsCorreos.length > 0 ?
                      <IconoExito id="IconoRespuestaServidor" />
                  :
                      <IconoFallo id="IconoRespuestaServidor" />

                :

                //Si la tarea es del tipo activar dispositivo
                tarea.idTipoTarea === idTareaActivarDispositivoRef.current ?

                  response === 200 && dispositivoSeleccionado !== null && dispositivoSeleccionado !== undefined?
                    <IconoExito id="IconoRespuestaServidor" />
                  :
                    <IconoFallo id="IconoRespuestaServidor" />
                :

                <IconoFallo id="IconoRespuestaServidor" />
              }
              textoBoton={

                //Si la tarea es del tipo notificar
                tarea.idTipoTarea === idTareaNotificarRef.current? 

                  response === 200 && idsCorreos.length > 0? 
                    "Aceptar"
                  :
                    "Reintentar"

                :

                  //Si la tarea es del tipo activar dispositivo
                  tarea.idTipoTarea === idTareaActivarDispositivoRef.current?

                    response === 200 && dispositivoSeleccionado !== null && dispositivoSeleccionado !== undefined?
                      "Aceptar"
                    :
                      "Reintentar"

                  :
                  
                    "Reintentar"
              }
              titulo={
                
                //Si la tarea es del tipo notificar
                tarea.idTipoTarea === idTareaNotificarRef.current?

                  response === 200 && idsCorreos.length > 0? 
                  "Actualización exitosa"
                  :
                  "Error al actualizar"

                :

                //Si la tarea es del tipo activar dispositivo
                tarea.idTipoTarea === idTareaActivarDispositivoRef.current?
                  response === 200 && dispositivoSeleccionado !== null && dispositivoSeleccionado !== undefined?
                    "Actualización exitosa"
                  :
                    "Error al actualizar"
                :
                  "Error al actualizar"
              }
              mensajeRespuesta={

                //Si la tarea es del tipo notificar
                tarea.idTipoTarea === idTareaNotificarRef.current?

                  idsCorreos.length > 0 ? 
                    response === 200 ?
                      "La tarea se ha actualizado correctamente."
                    :
                      "Ha ocurrido un error y no se ha podido actualizar la tarea. Por favor, vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                  :
                    "Debe seleccionar almenos un correo para continuar"

                :


                //Si la tarea es del tipo activar dispositivo
                tarea.idTipoTarea === idTareaActivarDispositivoRef.current?
                  dispositivoSeleccionado !== null && dispositivoSeleccionado !== undefined?
                    response === 200 ?
                      "La tarea se ha actualizado correctamente."
                    :
                      "Ha ocurrido un error y no se ha podido actualizar la tarea. Por favor, vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                  :
                    "Debe seleccionar un dispositivo para continuar"
                :
                  "Ha ocurrido un error y no se ha podido actualizar la tarea. Por favor, vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                }
            />
          </Box>
        </Modal>
      );
    };

export default VerDetallesTarea;