import { useDispatch, useSelector } from "react-redux";
import Dashboard from "../../Components/Dashboard/Dashboard";
import { MapaEmpresa } from "../../Components/Map/Map";
import { useEffect } from "react";
import { actualizarFiltroActualizado, actualizarFiltroTiempoReal, actualizarTipoActuadores, actualizarTiposTareas, actualizarTiposUbicaciones, actualizarTiposUsuarios, actualizarTipoVisitas, actualizarUbicacionesEvento, actualizarZonasFormadas, changeTipoActuadoresCargado, changeTiposTareasCargado, changeTiposUbicacionesCargado, changeTiposUsuariosCargado, changeTipoVisitasCargado, changeUbicacionesEventoCargado, changeZonasFormadasCargado } from "../../store/misSlice";
import useGestZonasEmpresa from "../../Hooks/Zona/useGetZonasEmpresa";
import useTipoActuador from "../../Hooks/TipoActuador/useTipoActuador";
import useTiposEventos from "../../Hooks/TiposEventos/useTiposEventos";
import useUbicacionesEvento from "../../Hooks/Ubicaciones/useUbicaciones";
import { useTiposUsuario } from "../../Hooks/TiposUsuario/useTiposUsuario";
import useTiposUbicaciones from "../../Hooks/TiposUbicaciones/useTiposUbicaciones";
import useTipoTarea from "../../Hooks/useTipoTarea/useTipoTarea";
import useGetActuadores from "../../Hooks/Actuadores/useGetActuadores";

const Inicio = () => {

    const dispatch = useDispatch();

    // Estado de Redux que indica si los actuadores ya fueron cargados.
    const listadoTipoActuadoresCargado = useSelector(
        (state) => state.tipoActuadores.listadoTipoActuadoresCargado
    );

    //En caso de ya estar los datos cargados, el hook no hará la peticion al servidor.
    const {tipoActuadores} = useTipoActuador(!listadoTipoActuadoresCargado);

    useEffect(() => {
        // Solo se ejecuta si `listadoTipoActuadoresCargado` es falso (es decir, aún no se han cargado los datos)
        if (!listadoTipoActuadoresCargado && tipoActuadores?.length > 0) {
          // Si los datos están disponibles, se despacha la acción para actualizar Redux
          dispatch(actualizarTipoActuadores(tipoActuadores));
          dispatch(changeTipoActuadoresCargado(true));
        }
    }, [listadoTipoActuadoresCargado, tipoActuadores, dispatch]);

    // Estado de Redux que indica si las zonas formadas ya fueron cargadas.
    const listadoZonasFormadasCargado = useSelector(
        (state) => state.zonasFormadasRegistradas.listadoZonasFormadasCargado
    );
    //En caso de ya estar los datos cargados, el hook no hará la peticion al servidor.
    const {zonasFormadas} = useGestZonasEmpresa(!listadoZonasFormadasCargado);
    useEffect(() => {
        // Solo se ejecuta si `listadoZonasFormadasCargado` es falso (es decir, aún no se han cargado los datos)
        if (!listadoZonasFormadasCargado && zonasFormadas?.length > 0) {
          // Si los datos están disponibles, se despacha la acción para actualizar Redux
          dispatch(actualizarZonasFormadas(zonasFormadas));
          dispatch(changeZonasFormadasCargado(true));
        }
    }, [listadoZonasFormadasCargado, zonasFormadas, dispatch]);

    //Se obtiene la informacion de actuadores
    const data = useSelector(action => action.user.authenticated);
    const {actuadores} = useGetActuadores(data.idEmpresa);

    const filtroTiempoRealActualizado = useSelector(
        (state) => state.filtroTiempoReal.filtroActualizado
    );

    // Este es el listado de ids de tipos de actuadores que se le aplicará la posibilidad de activar o desactivar el filtrado en tiempo real
    // de los accesos
    const idsTiposActuadoresAccesos = useSelector(
        (state) => state.filtroTiempoReal.idsTiposActuadoresAccesos
    );

    useEffect(() => {

        //En caso de que el filtro de tiempo real no se haya actualizado, se ejecuta el procedimiento
        if(!filtroTiempoRealActualizado && actuadores.length > 0){

            // A partir de este ultimo arreglo, se crea un arreglo de objetos especial con la información de todos los actuadores que serán switcheables de acuerdo al arreglo anterior,
            // En donde solo se guardará la id del actor, el id del tipo de actor y si el filtro fue aplicado o no, este ultimo comenzará en falso.
            const filtroTiempoReal = idsTiposActuadoresAccesos?.reduce((resultado, id) => {

                // Filtramos los actuadores que tengan el mismo idTipoActor
                const actuadoresFiltrados = actuadores.filter(actuador => actuador.idTipoActor === id);
                
                // Por cada actuador filtrado, agregamos el objeto deseado al resultado
                actuadoresFiltrados.forEach(actuador => {
                    resultado.push({
                        idActor: actuador.idActor,
                        idTipoActor: actuador.idTipoActor,
                        filtroActivado: false,
                    });
                });
            
                return resultado;  // Retornamos el acumulador para el siguiente ciclo
            }, []);  // El valor inicial de resultado es un arreglo vacío

            dispatch(actualizarFiltroTiempoReal(filtroTiempoReal));
            dispatch(actualizarFiltroActualizado(true));
        }
      
    }, [actuadores, dispatch, filtroTiempoRealActualizado, idsTiposActuadoresAccesos])
    










    // Estado de Redux que indica si los tipos de visita ya fueron cargados.
    const listadoTipoVisitasCargado = useSelector(
        (state) => state.tipoVisitas.listadoTipoVisitasCargado
    );
    //En caso de ya estar los datos cargados, el hook no hará la peticion al servidor.
    const { tiposVisitas } = useTiposEventos(!listadoTipoVisitasCargado);
    useEffect(() => {
        // Solo se ejecuta si `listadoTipoVisitasCargado` es falso (es decir, aún no se han cargado los datos)
        if (!listadoTipoVisitasCargado && tiposVisitas?.length > 0) {
          // Si los datos están disponibles, se despacha la acción para actualizar Redux
          dispatch(actualizarTipoVisitas(tiposVisitas));
          dispatch(changeTipoVisitasCargado(true));
        }
    }, [listadoTipoVisitasCargado, tiposVisitas, dispatch]);









    // Estado de Redux que indica si las ubicaciones de los eventos ya fueron cargados.
    const listadoUbicacionesEventoCargado = useSelector(
        (state) => state.ubicacionesEvento.listadoUbicacionesEventoCargado
    );
    //En caso de ya estar los datos cargados, el hook no hará la peticion al servidor.
    const { ubicacionesEvento } = useUbicacionesEvento(!listadoUbicacionesEventoCargado);
    useEffect(() => {
        // Solo se ejecuta si `listadoUbicacionesEventoCargado` es falso (es decir, aún no se han cargado los datos)
        if (!listadoUbicacionesEventoCargado && ubicacionesEvento?.length > 0) {
          // Si los datos están disponibles, se despacha la acción para actualizar Redux
          dispatch(actualizarUbicacionesEvento(ubicacionesEvento));
          dispatch(changeUbicacionesEventoCargado(true));
        }
    }, [listadoUbicacionesEventoCargado, ubicacionesEvento, dispatch]);

    // Estado de Redux que indica si los tipos de usuarios ya fueron cargados.
    const listadoTiposUsuariosCargado = useSelector(
        (state) => state.tiposUsuarios.listadoTiposUsuariosCargado
    );
    //En caso de ya estar los datos cargados, el hook no hará la peticion al servidor.
    const { tipos: tiposUsuarios } = useTiposUsuario(!listadoTiposUsuariosCargado);
    useEffect(() => {
        // Solo se ejecuta si `listadoTiposUsuariosCargado` es falso (es decir, aún no se han cargado los datos)
        if (!listadoTiposUsuariosCargado && tiposUsuarios?.length > 0) {
          // Si los datos están disponibles, se despacha la acción para actualizar Redux
          dispatch(actualizarTiposUsuarios(tiposUsuarios));
          dispatch(changeTiposUsuariosCargado(true));
        }
    }, [listadoTiposUsuariosCargado, tiposUsuarios, dispatch]);







    // Estado de Redux que indica si los tipos de ubicaciones ya fueron cargados.
    const listadoTiposUbicacionesCargado = useSelector(
        (state) => state.tiposUbicaciones.listadoTiposUbicacionesCargado
    );

    // En caso de ya estar los datos cargados, el hook no hará la petición al servidor.
    const {tiposUbicaciones} = useTiposUbicaciones(!listadoTiposUbicacionesCargado);

    useEffect(() => {
        // Solo se ejecuta si `listadoTiposUbicacionesCargado` es falso (es decir, aún no se han cargado los datos)
        if (!listadoTiposUbicacionesCargado && tiposUbicaciones?.length > 0) {
            // Si los datos están disponibles, se despacha la acción para actualizar Redux
            dispatch(actualizarTiposUbicaciones(tiposUbicaciones));
            dispatch(changeTiposUbicacionesCargado(true));
        }
    }, [listadoTiposUbicacionesCargado, tiposUbicaciones, dispatch]);



    // Estado de Redux que indica si los tipos de tareas ya fueron cargados.
    const listadoTiposTareasCargado = useSelector(
        (state) => state.tiposTareas.listadoTiposTareasCargado
    );

    // En caso de ya estar los datos cargados, el hook no hará la petición al servidor.
    const {tipoTareas} = useTipoTarea(!listadoTiposTareasCargado);

    useEffect(() => {
        // Solo se ejecuta si `listadoTiposTareasCargado` es falso (es decir, aún no se han cargado los datos)
        if (!listadoTiposTareasCargado && tipoTareas?.length > 0) {
            // Si los datos están disponibles, se despacha la acción para actualizar Redux
            dispatch(actualizarTiposTareas(tipoTareas));
            dispatch(changeTiposTareasCargado(true));
        }
    }, [listadoTiposTareasCargado, tipoTareas, dispatch]);


    return (
        <Dashboard>
            <MapaEmpresa />
        </Dashboard>
    );
}

export default Inicio;