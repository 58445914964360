import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import "./Map.css";
import useMapButtons from "../../Hooks/MapButtons/useMapButtons";
import { BotonesConSusFormatos } from "./Botones";
import { useSelector } from "react-redux";
import useMapUsuario from "../../Hooks/Mapa/useMapUsuario";
import useGetActuadores from "../../Hooks/Actuadores/useGetActuadores";
import { useState } from "react";
import { PopoverBoton } from "./PopoverBoton/PopoverBoton";
import CardImagen from "./CardImagen/CardImagen";

export const MapaEmpresa = () => {
    const data = useSelector(action => action.user.authenticated);
    const { urlMapa, loading } = useMapUsuario(data.idEmpresa);
    
    const { botones } = useMapButtons();
    // useEffect(() => {
    //   console.log("botones: ", botones)
    // }, [botones])
    
    
    const { actuadores } = useGetActuadores(data.idEmpresa);
    // useEffect(() => {
    //   console.log("actuadores: ", actuadores)
    // }, [actuadores])
    

    const [botonSeleccionado, setBotonSeleccionado] = useState("");
    // useEffect(() => {
    //   console.log("botonSeleccionado: ", botonSeleccionado);
    // }, [botonSeleccionado])
    

    const handleChangeBotonSeleccionado = (boton) => {
        setBotonSeleccionado(boton);
    }

    //variables y funciones relacionadas con el manejo del popover
    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    //Variables y funciones para controlar el componente CardImagen
    const [openCard, setOpenCard] = useState(false);
    const handleOpenCard = () => {
        setOpenCard(true);
    };
    const handleCloseCard = () => {
        setOpenCard(false);
    }; 
    
    return (
        <Box id="LayoutMapa">
            {
                loading ?
                <CircularProgress />
                :
                urlMapa === null || urlMapa === undefined ?
                    <Box sx={{ width: "1000px", height: "712px" }} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        {
                            
                            <Typography>
                                {
                                    "No se ha encontrado..."
                                }
                            </Typography>
                        }
                    </Box>
                    :
                    <Box sx={{ width: "1000px", height: "712px" }}>
                        <img src={`data:image/jpeg;base64,${urlMapa}`} alt="mapaempresa" width={"100%"} height={"100%"} />
                        <Grid container spacing={0} id="TestGrid" columns={12}>
                            {
                                botones.length >= 1  ?
                                // <BotonesConSusFormatos setOpenCard={setOpenCard} handlePopoverOpen={handlePopoverOpen} dataBotones={botones} setBotonSeleccionado={(e) => setBotonSeleccionado(e)}/>
                                <BotonesConSusFormatos setOpenCard={setOpenCard} handlePopoverOpen={handlePopoverOpen} dataBotones={botones} handleChangeBotonSeleccionado={handleChangeBotonSeleccionado}/>
                                :
                                null
                            }
                        </Grid>
                    </Box>
            }
            <PopoverBoton
                open={open} 
                anchorEl={anchorEl}
                handlePopoverClose={handlePopoverClose}
                botonSeleccionado={botonSeleccionado}
                handleOpenCard={handleOpenCard}
                actuadores={actuadores}
            />

            <CardImagen open={openCard} onClose={handleCloseCard} botonSeleccionado={botonSeleccionado} />
        </Box>
    );
}



